import React from "react";
import { Box, Divider } from "@material-ui/core";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import MenuSlider from "components/MenuSlider";

import { scroller } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    top: "-1px",
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(5),
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuSlider: {
    backgroundColor: theme.palette.background.default,
  },
  spacer: {
    height: theme.spacing(5),
  },
}));

const StickyMenu = ({ menuItems = [], className, children, ...props }) => {
  const classes = useStyles();

  const handleTabChange = (tabIndex) => {
    // Use the scroller function from 'react-scroll'
    scroller.scrollTo(menuItems[tabIndex].id, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.content}>
        <MenuSlider
          items={menuItems}
          onTabChange={handleTabChange}
          className={classes.menuSlider}
        />
        {children && <Box>{children}</Box>}
      </div>
      <div className={classes.spacer} />
      <Divider />
    </div>
  );
};

StickyMenu.propTypes = {
  className: PropTypes.string,
  menuItems: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default StickyMenu;
