import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "date-fns";
import { Box, Button, Paper, Typography, makeStyles, useTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import { keys } from "lodash/fp";

import AvailabilitySearch from "./AvailabilitySearch";
import LoadingSpinner from "components/LoadingSpinner";
import { formatDateToDay, formatDateToMonth } from "utils/timekeep";
import { handleAvailabilitySummary } from "components/FacilityBookingSideBarModal/utils";
import api from "utils/api";
import NoAvailability from "./NoAvailability";
import SuggestEnquiry from "./SuggestEnquiry";
import MultiTypeInput from "components/MultiTypeInput";
import {
  getBookingSetting,
  getOptions,
} from "components/FacilityBookingSideBarModal/utils/getOptions";
import { formatSearchParams } from "./formatSearchParams";

import stepStyle from "../stepStyle";

const useStyles = makeStyles((theme) => ({
  containerHeading: {
    marginBottom: theme.spacing(1),
  },
  buttonCTA: {
    width: "100%",
  },
  loadingSpinner: {
    margin: "auto",
    textAlign: "center",
    width: "100%",
  },
  textButton: { paddingLeft: 0, "&:hover": { backgroundColor: "inherit" } },
  dateSummaryContainer: {
    display: "flex",
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
  },
  dateSummaryBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.other.disabledBackground,
    borderRadius: "4px",
    width: "48px",
    height: "48px",
  },
}));

const CheckFacilityAvailabilityStep = ({
  searchDetails,
  handleSearchDetailsChange,
  // selectedTimeSlot,
  // setSelectedTimeSlot,
  selectedRoom,
  selectedProperty,
  validateSearchDetails,
  handleEnquiry,
  handleError,
  showEndTime,
  showPrice,
  ...props
}) => {
  const classes = useStyles();
  const stepClasses = stepStyle();

  const theme = useTheme();
  const [availability, setAvailability] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  const makeAvailabilitySearch = async (data) => {
    const response = await api.getFacilityAvailability(data);
    return response.data;
  };

  const handleAvailabilitySearch = (searchDetails, event) => {
    if (!validateSearchDetails()) {
      event.preventDefault();
      return;
    }
    setIsLoading(true);
    makeAvailabilitySearch(formatSearchParams(selectedRoom, searchDetails))
      .then((res) => {
        setAvailability(res);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => setIsLoading(false));
  };

  const handleSearchChange = () => {
    setSelectedTimeSlot(null);
    setAvailability(null);
  };

  const handleNext = () => {
    props.handleNext(selectedTimeSlot);
  };

  useEffect(() => {
    if (selectedTimeSlot) {
      // Skip directly to the next step if a time slot is selected
      handleNext();
    }
  }, [selectedTimeSlot]);

  const canEnquire = getBookingSetting(
    props.bookingSettings,
    searchDetails.arrivalDate?.value,
    "canEnquire"
  );

  return (
    <>
      <Paper className={classNames(stepClasses.stepContainer, stepClasses.stepContainerOverride)}>
        <Box style={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
          <Typography className={classes.containerHeading} variant="subtitle1" color="textPrimary">
            What’s your preferred date and time?
          </Typography>
          {availability == null && !selectedTimeSlot ? (
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ marginBottom: theme.spacing(3) }}>
              In order to find availability, please select a date and time below.
            </Typography>
          ) : (
            <>
              <Box className={classes.dateSummaryContainer}>
                <Box className={classes.dateSummaryBox}>
                  <Typography varinat="body1">
                    {formatDateToDay(searchDetails["arrivalDate"]?.value)}
                  </Typography>
                  <Typography variant="body2">
                    {formatDateToMonth(searchDetails["arrivalDate"]?.value)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1">
                    {handleAvailabilitySummary({
                      eventDetails: searchDetails,
                      selectedTimeSlot,
                      showEndTime,
                      showPrice,
                    })}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {selectedRoom.title}, {selectedProperty?.name}
                  </Typography>
                  <Button
                    className={classes.textButton}
                    color="primary"
                    size="small"
                    variant="text"
                    disableRipple
                    onClick={() => handleSearchChange()}>
                    <Typography variant="subtitle1">Change</Typography>
                  </Button>
                </Box>
              </Box>
            </>
          )}

          {availability == null && !isLoading && !selectedTimeSlot && (
            <form noValidate onSubmit={(event) => handleAvailabilitySearch(searchDetails, event)}>
              {keys(searchDetails).map((key) => {
                const formField = searchDetails[key];
                const options = getOptions(formField, searchDetails.arrivalDate?.value);
                if (options.hide) {
                  return;
                }

                return (
                  <Box key={formField.name} style={{ marginBottom: "20px" }}>
                    <MultiTypeInput
                      name={formField.name}
                      label={formField.label}
                      isRequired={formField.isRequired}
                      type={formField?.type}
                      inputProps={options.inputProps}
                      value={formField.value}
                      inputLabelProps={formField?.inputLabelProps}
                      error={formField?.error}
                      onChange={(evt) => {
                        handleSearchDetailsChange(formField.name, evt.target.value);
                      }}
                    />
                  </Box>
                );
              })}
              <Button
                color="primary"
                variant="contained"
                className={classes.buttonCTA}
                type="submit">
                Search
              </Button>
            </form>
          )}
          {availability?.length > 0 && !isLoading && (
            <AvailabilitySearch
              showEndTime={showEndTime}
              availability={availability}
              setSelectedTimeSlot={setSelectedTimeSlot}
            />
          )}
          {isLoading && (
            <Box className={classes.loadingSpinner}>
              <LoadingSpinner loading={isLoading} />
            </Box>
          )}
        </Box>

        {availability?.length == 0 && !isLoading ? (
          <NoAvailability
            canEquire={canEnquire}
            handleChangeSearch={handleSearchChange}
            handleEnquiry={handleEnquiry}
          />
        ) : canEnquire ? (
          <SuggestEnquiry handleEnquiry={handleEnquiry} />
        ) : (
          <Box style={{ marginBottom: theme.spacing(3) }} />
        )}
      </Paper>
      {!!selectedTimeSlot && (
        <Button
          className={stepClasses.nextStepCTA}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleNext}>
          Next Step
        </Button>
      )}
    </>
  );
};

CheckFacilityAvailabilityStep.propTypes = {
  bookingSettings: PropTypes.object,
  searchDetails: PropTypes.object,
  showEndTime: PropTypes.bool,
  showPrice: PropTypes.bool,
  handleSearchDetailsChange: PropTypes.func,
  selectedRoom: PropTypes.object,
  selectedProperty: PropTypes.object,
  validateSearchDetails: PropTypes.func,
  handleEnquiry: PropTypes.func,
  handleError: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};

export default CheckFacilityAvailabilityStep;
