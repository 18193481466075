import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import Routes from "config/routes";
import { makeStyles } from "@material-ui/core/styles";

import { updatePaymentDetails } from "../../features/payment/paymentSlice";
import { bookingSuccess } from "features/booking/bookingSlice";

import api from "utils/api";
import generateBookingData from "utils/generateBookingData";
import { trackPurchaseEvent } from "utils/analytics";

import Navbar from "components/Navbar";
import LoadingDialog from "components/LoadingDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const FinalizeBooking = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const queryParams = queryString.parse(window.location.search);

  useEffect(() => {
    async function finalizeBooking() {
      const redirectResult = queryParams.redirectResult;

      // Check we have everything we need on this page
      const validState =
        queryParams.redirectResult && queryParams.guestData && props.roomReservations.length > 0;
      if (!validState) {
        props.onNotifOpen("An error occurred finalizing your payment.", {
          variant: "error",
        });

        history.push(Routes.PLAN_STAY);
        return;
      }

      // Submit additional payment details
      try {
        const paymentDetailsResponse = await dispatch(
          updatePaymentDetails({
            details: {
              redirectResult: redirectResult,
            },
          })
        );

        await processPaymentResponse(paymentDetailsResponse.data);
      } catch (error) {
        console.log(`Error finalizing payment: ${error.response.data.error}`);

        const errorMessage = error.response.data.message;
        const message = `Your payment could not be finalized. You will not be charged. ${errorMessage}`;

        redirectToCheckoutWithError(message);
      }
    }

    finalizeBooking();
  }, []);

  const processPaymentResponse = async (paymentRes) => {
    if (paymentRes.action) {
      this.paymentComponent.handleAction(paymentRes.action);
    } else {
      switch (paymentRes.resultCode) {
        case "Authorised": {
          await handlePaymentAuthorized(paymentRes);
          break;
        }
        case "Refused":
        case "Error":
          // Send user back to the checkout page
          redirectToCheckoutWithError(
            "Your payment method was not accepted. Please choose another payment method or try again."
          );
          break;
        case "Cancelled":
          // Send user back to the checkout page
          redirectToCheckoutWithError(
            "Your payment was cancelled. Please choose another payment method or try again."
          );
          break;
        default:
          console.log(paymentRes);
          redirectToCheckoutWithError(null);
          break;
      }
    }
  };

  // Retrieves guest details and makes the booking
  const handlePaymentAuthorized = async (paymentResponse) => {
    let guestDetails;

    try {
      // Restore the guest details. It was base64 encoded, and json stringified
      guestDetails = JSON.parse(atob(queryParams.guestData));
    } catch (error) {
      console.error("Failed to restore guest details for transaction");
      redirectToCheckoutWithError("Unable to complete your booking. You will not be charged.");
    }

    // Make the booking
    try {
      const bookingId = await makeBooking(paymentResponse, guestDetails);
      handleBookingSuccess(bookingId, guestDetails);
    } catch (error) {
      console.log(`Error making booking: ${error.response.data.messages}`);

      const errorMessage = error.response.data.messages[0];
      const message = `Your booking could not be completed. You will not be charged. ${errorMessage}`;
      redirectToCheckoutWithError(message);
    }
  };

  const handleBookingSuccess = (bookingId, guestDetails) => {
    // on success:
    // clear booked reservations State,
    // and redirect to confirmation page
    dispatch(bookingSuccess({ bookingId, guestDetails }));

    history.push(Routes.CONFIRM_STAY);
  };

  const redirectToCheckoutWithError = (message) => {
    props.onNotifOpen(message || "There was an error processing your payment.", {
      variant: "error",
    });

    history.push(Routes.CHECKOUT);
  };

  const makeBooking = async (paymentResponse, guestDetails) => {
    // Combine all reservations for booking
    const bookingReservations = [...props.roomReservations, ...props.parkingReservations];
    const data = generateBookingData({
      bookingReservations: bookingReservations,
      guestDetails: guestDetails,
      paymentResponse: paymentResponse,
      giftCard: props.giftCard,
      propertyId: props.propertyId,
    });

    const response = await api.makeBooking(data);

    trackPurchaseEvent(
      bookingReservations,
      props.promoCode || props.corporateCode,
      props.propertyId,
      response.data.id,
      paymentResponse ? paymentResponse.additionalData.paymentMethod : "giftcard"
    );

    return response.data.id;
  };

  return (
    <div className={classes.root}>
      <Navbar centerLogo onNotifOpen={props.onNotifOpen} />

      <LoadingDialog open message={"Finalizing your booking. Please wait..."} />
    </div>
  );
};

FinalizeBooking.propTypes = {
  onNotifOpen: PropTypes.func,
  // Mappped props
  roomReservations: PropTypes.array,
  parkingReservations: PropTypes.array,
  propertyId: PropTypes.string,
  giftCard: PropTypes.object,
  promoCode: PropTypes.string,
  corporateCode: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    roomReservations: state.booking.roomReservations,
    parkingReservations: state.booking.parkingReservations,
    propertyId: state.booking.commonSearchParams.propertyId,
    giftCard: state.booking.giftCard,
    promoCode: state.booking.commonSearchParams.promoCode,
    corporateCode: state.booking.commonSearchParams.corporateCode,
  };
};

const mapDispatchToProps = {};

const ConnectedFinalizeBooking = connect(mapStateToProps, mapDispatchToProps)(FinalizeBooking);

export default ConnectedFinalizeBooking;
