import React from "react";
import { StepIcon, makeStyles } from "@material-ui/core";
import { PropTypes } from "prop-types";
import CheckIcon from "@material-ui/icons/Check";

const useStyle = makeStyles((theme) => {
  return {
    checkIcon: {
      color: theme.palette.success.main,
    },
  };
});

// NM: We only want to change the CheckIcon.
const CustomizedStepIcon = (props) => {
  const classes = useStyle();

  const forwardProps = { ...props };
  if (forwardProps.completed) {
    forwardProps.icon = <CheckIcon className={classes.checkIcon} />;
  }

  return <StepIcon {...forwardProps} />;
};

CustomizedStepIcon.propTypes = {
  completed: PropTypes.bool,
};

export default CustomizedStepIcon;
