import React from "react";
import PropTypes from "prop-types";

import { Button, Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { ArrowForward, ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: theme.spacing(3),
  },
}));

const FooterNavBar = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));

  return (
    <Grid container className={classes.footer} justifyContent="space-between">
      {props.onBack && (
        <Button
          variant="outlined"
          color="primary"
          size={tabletUpScreen ? "large" : "medium"}
          onClick={props.onBack}
          hidden={!!props.onBack}
          startIcon={<ArrowBack />}>
          Back
        </Button>
      )}
      <Button
        className={classes.continueButton}
        color="primary"
        variant="contained"
        size={tabletUpScreen ? "large" : "medium"}
        onClick={props.onContinue}
        endIcon={<ArrowForward />}>
        {props.continueText}
      </Button>
    </Grid>
  );
};

FooterNavBar.defaultProps = {
  continueText: "Continue",
  loading: true,
};

FooterNavBar.propTypes = {
  onBack: PropTypes.func,
  onContinue: PropTypes.func,
  continueText: PropTypes.string,
};

export default FooterNavBar;
