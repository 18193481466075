import { createSlice } from "@reduxjs/toolkit";
import { childrenAgesArray } from "utils/helpers";
import { calculateServicePrice } from "utils/bookingTotals";
import { cloneDeep, pullAt } from "lodash/fp";
import clone from "lodash/fp/clone";
import compose from "lodash/fp/compose";
import isNumber from "lodash/fp/isNumber";

const initialState = {
  roomReservations: [],
  parkingReservations: [],
  giftCard: null,
  bookingConfirmationId: null,
  roomsOccupancy: [],
  availCheckParams: {},
  searchedBooking: null,
  commonSearchParams: {},
  guestDetails: {
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
  },
  appTheme: null,
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    addRoomReservationToBooking: (state, action) => {
      const reservation = { ...action.payload.reservation };
      reservation.services = action.payload.services || [];

      // Store the occupancy for this room
      const occupancy = state.roomsOccupancy[action.payload.roomOfRooms - 1];
      reservation.adults = occupancy.adults;
      reservation.childrenAges = childrenAgesArray(occupancy.children);

      state.roomReservations.push(reservation);
    },
    addParkingReservationToBooking: (state, action) => {
      const reservation = { ...action.payload.reservation };
      reservation.services = [];

      // Track which room reservation this is related to
      reservation.roomOfRooms = action.payload.roomOfRooms;

      // We'll just set the occupancy to 1 adult for parking spaces
      reservation.adults = 1;

      state.parkingReservations.push(reservation);
    },
    removeRoomReservationFromBooking: (state, { payload: roomIndex }) => {
      if (isNumber(roomIndex)) {
        state.roomReservations = compose(pullAt(roomIndex), clone)(state.roomReservations);

        // Remove any related parking reservations for this room too
        state.parkingReservations = clone(state.parkingReservations).filter(
          (r) => r.roomOfRooms !== roomIndex + 1
        );
      }
    },
    removeParkingReservationFromBooking: (state, { payload: index }) => {
      if (isNumber(index)) {
        state.parkingReservations = compose(pullAt(index), clone)(state.parkingReservations);
      }
    },
    addServiceToRoomReservation: (state, action) => {
      const reservationIdx = action.payload.reservationIdx;

      // Make a copy of the service
      const service = cloneDeep(action.payload.service);
      const count = action.payload.count;

      // Update the price in the service
      service.count = count;

      // Add in the dates (if specified)
      if (action.payload.date) {
        service.dates = [
          {
            serviceDate: action.payload.date,
          },
        ];
      }

      const newGrossAmount = calculateServicePrice(service, count);
      service.totalAmount.grossAmount = newGrossAmount;
      service.totalAmount.netAmount = newGrossAmount / (1 + service.totalAmount.vatPercent / 100.0);

      // Add the service (make a copy of bookings)
      let newBookings = [...state.roomReservations];
      newBookings[reservationIdx].services.push(service);

      state.roomReservations = newBookings;
    },
    removeServiceFromRoomReservation: (state, action) => {
      const reservationIdx = action.payload.reservationIdx;
      const serviceId = action.payload.serviceId;
      const date = action.payload.date;

      let newBookings = [...state.roomReservations];

      let booking = newBookings[reservationIdx];

      booking.services = booking.services.filter((s) => {
        const containsDate = date ? s.dates.some((d) => d.serviceDate === date) : true;
        const toRemove = s.service.id === serviceId && containsDate;

        return !toRemove;
      });

      state.roomReservations = newBookings;
    },
    changeServiceCountForRoomReservation: (state, action) => {
      const reservationIdx = action.payload.reservationIdx;
      const serviceId = action.payload.serviceId;
      const newCount = action.payload.count;
      const date = action.payload.date;

      let newBookings = [...state.roomReservations];

      let booking = newBookings[reservationIdx];

      booking.services = booking.services.map((s) => {
        if (s.service.id !== serviceId) {
          return s;
        }

        // If a date is passed, check the date matches this service row
        if (date && s.date !== date) {
          return s;
        }

        if (newCount <= 0) {
          // Service fully removed
          return null;
        }

        // Update the quantity and recalculte the total price
        s.count = newCount;

        const newGrossAmount = calculateServicePrice(s, newCount);
        s.totalAmount.grossAmount = newGrossAmount;
        s.totalAmount.netAmount = newGrossAmount / (1 + s.totalAmount.vatPercent / 100.0);

        return s;
      });

      // Remove null items (ie those that were fully removed)
      booking.services = booking.services.filter((s) => s);

      state.roomReservations = newBookings;
    },
    bookingSuccess: (state, { payload }) => {
      state.bookingConfirmationId = payload.bookingId;
      state.guestDetails = payload.guestDetails;
      state.roomReservations = [];
      state.parkingReservations = [];
      state.giftCard = null;
    },
    resetBooking: (state) => {
      state.availCheckParams = {};
      state.guestDetails = {};
      state.roomReservations = initialState.roomReservations;
      state.parkingReservations = initialState.parkingReservations;
      state.bookingConfirmationId = initialState.bookingConfirmationId;
    },
    setRoomsOccupancy: (state, action) => {
      state.roomsOccupancy = action.payload;
    },
    incrementRoomsOccupancy: (state) => {
      if (state.roomsOccupancy.length < 4) {
        state.roomsOccupancy = [...state.roomsOccupancy, { adults: 2, children: 0 }];
      }
    },
    decrementRoomsOccupancy: (state) => {
      if (state.roomsOccupancy.length > 1) {
        state.roomsOccupancy = state.roomsOccupancy.slice(0, -1);
      }
    },
    removeRoomsOccupacyAtIndex: (state, action) => {
      if (state.roomsOccupancy.length > 1) {
        state.roomsOccupancy.splice(action.payload, 1);
      }
    },
    changeAdultsInRoom: (state, action) => {
      const count = action.payload.count;
      state.roomsOccupancy[action.payload.roomIndex]["adults"] = count;
    },
    changeChildrenInRoom: (state, action) => {
      const count = action.payload.count;
      state.roomsOccupancy[action.payload.roomIndex]["children"] = count;
    },
    setAvailCheckParams: (state, { payload: { roomIndex, ...rest } = {} }) => {
      state.availCheckParams[roomIndex] = rest;
    },
    setSearchedBooking: (state, action) => {
      state.searchedBooking = action.payload;
    },
    setGiftCard: (state, action) => {
      state.giftCard = action.payload;
    },
    setCommonSearchParams: (state, action) => {
      state.commonSearchParams = action.payload;
    },
    resetCommonSearchParams: (state) => {
      state.commonSearchParams = {};
    },
    setAppTheme: (state, { payload }) => {
      state.appTheme = payload;
    },
    setGuestDetails: (state, { payload }) => {
      state.guestDetails = { payload };
    },
  },
});

export const {
  addRoomReservationToBooking,
  addParkingReservationToBooking,
  addServiceToRoomReservation,
  bookingSuccess,
  changeAdultsInRoom,
  changeChildrenInRoom,
  changeServiceCountForRoomReservation,
  setRoomsOccupancy,
  decrementRoomsOccupancy,
  incrementRoomsOccupancy,
  removeRoomsOccupacyAtIndex,
  removeRoomReservationFromBooking,
  removeParkingReservationFromBooking,
  removeServiceFromRoomReservation,
  resetBooking,
  resetCommonSearchParams,
  setAvailCheckParams,
  setAppTheme,
  setCommonSearchParams,
  setGiftCard,
  setSearchedBooking,
} = bookingSlice.actions;
export default bookingSlice.reducer;
