import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { Grid, Typography } from "@material-ui/core";
import StepperButton from "components/StepperButton";

const useStyles = makeStyles((theme) => ({
  stepper: {
    width: "auto",
  },
  counter: {
    width: "40px",
    textAlign: "center",
  },
  disabledText: {
    color: theme.palette.text.disabled,
  },
  disabledTextOnDark: {
    color: theme.palette.text.onDark.disabled,
  },
}));

const Stepper = (props) => {
  const classes = useStyles();

  const decrementCounter = () => {
    props.onValueChanged(props.name, props.count - 1);
  };

  const incrementCounter = () => {
    props.onValueChanged(props.name, props.count + 1);
  };

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      className={classNames(props.className, classes.stepper)}>
      <StepperButton
        name={`${props.name}-minus-btn`}
        aria-label={`decrement ${props.ariaHint} to ${props.count - 1}`}
        onClick={decrementCounter}
        disabled={props.disabled || props.decrementDisabled}
        onDark={props.onDark}
        remove
      />
      <Typography
        variant="subtitle1"
        className={classNames(
          classes.counter,
          props.disabled && !props.onDark ? classes.disabledText : null,
          props.disabled && props.onDark ? classes.disabledTextOnDark : null
        )}>
        {props.count}
      </Typography>
      <StepperButton
        name={`${props.name}-add-btn`}
        aria-label={`increment ${props.ariaHint} to ${props.count + 1}`}
        onClick={incrementCounter}
        disabled={props.disabled || props.incrementDisabled}
        onDark={props.onDark}
        add
      />
    </Grid>
  );
};

Stepper.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  count: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  incrementDisabled: PropTypes.bool,
  decrementDisabled: PropTypes.bool,
  onValueChanged: PropTypes.func,
  ariaHint: PropTypes.string,
  onDark: PropTypes.bool,
};

export default Stepper;
