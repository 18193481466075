const donationWidgetStyle = (theme) => ({
  // Adyen Drop In widget styles overwrite
  donationContainer: {
    "& .adyen-checkout__campaign": {
      // Hide the campaign details - we will show this ourselves as it isnt very customizable
      display: "none",
    },
    "& .adyen-checkout__button": {
      fontFamily: "inherit",
      fontSize: "inherit",
      borderRadius: "24px",
    },
    "& .adyen-checkout__button--donate": {
      background: theme.palette.primary.main,
    },
    "& .adyen-checkout__button--ghost:hover": {
      background: theme.palette.primary.hover,
    },
    "& .adyen-checkout__button-group .adyen-checkout__button": {
      boxShadow: `inset 0 0 0 1px ${theme.palette.other.lineDetail}`,
    },
    "& .adyen-checkout__button-group .adyen-checkout__button--selected": {
      boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      background: theme.palette.primary.selected,
    },
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  charityDetailsContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "vertical",
  },
  charityLogo: {
    width: "160px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  rtf: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
  },
});

export default donationWidgetStyle;
