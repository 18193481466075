import React from "react";
import PropTypes from "prop-types";

import { makeStyles, Box, Paper } from "@material-ui/core";

import Navbar from "components/Navbar";
import FindBookingContent from "components/FindBookingModal/FindBookingContent";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    margin: "15vh auto",
    padding: "0px 20px",
    [theme.breakpoints.up("tablet")]: {
      width: "50%",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

const FindBooking = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Navbar withProfile onNotifOpen={props.onNotifOpen} />

      <Box className={classes.mainContent}>
        <Paper className={classes.paper}>
          <FindBookingContent onNotifOpen={props.onNotifOpen} />
        </Paper>
      </Box>
    </div>
  );
};

FindBooking.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onNotifOpen: PropTypes.func,
};

export default FindBooking;
