import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  makeStyles,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import { get } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChatOutlined } from "@material-ui/icons";

import { formatFaIcon } from "utils/helpers";
import getFeaturedIcon from "utils/getFeaturedIcon";
import SideBarModal from "components/SideBarModal";
import waveHandLogo from "images/waving_hand.svg";

const useStyles = makeStyles((theme) => ({
  mainContentContainer: {
    width: "100%",
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  roomCardContainer: {
    marginBottom: theme.spacing(3),
  },
  featuredFeatureContainer: {
    display: "flex",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      marginTop: 0,
    },
  },
  detailIcon: {
    fontSize: "20px",
    textAlign: "center",
  },
  textButton: {
    marginTop: theme.spacing(1),
    paddingLeft: 0,
    "&:hover": { backgroundColor: "inherit" },
  },
  buttonActionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: "column",
    },
  },

  img: {
    width: "100%",
    maxHeight: "240px",
    objectFit: "cover",

    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: "240px",
      height: "240px",
      maxHeight: "240px",
    },
  },

  roomCardTitle: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  roomCardContent: {
    padding: theme.spacing(3),

    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      display: "flex",
      justifyContent: "flex-start",
      gap: theme.spacing(3),
    },
  },
  roomCardTextAndActions: {
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: theme.spacing(3),
    },
  },
  informationBox: {
    backgroundColor: theme.palette.info.lightBg,
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  infoButton: {
    color: theme.palette.info.main,
  },
  infoTextMargin: { marginBottom: theme.spacing(1) },
  infoCTAOverride: { justifyContent: "flex-start", marginTop: theme.spacing(3) },
}));

const ChooseSpaceSideBarModal = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));

  const selectedProperty = props.property;
  const roomOptions = props.facilityServices.map((room) => ({
    ...room,
    featuredIcon: getFeaturedIcon(room?.feature_list),
  }));

  const propertyPhoneNumber = get(selectedProperty, ["contact_info", "phone_number"]) || "";

  const handleMoreDetails = (roomId) => {
    props.onShowRoomDetailsClick(roomId);
  };

  const handleEnquiry = (roomId = null) => {
    props.onClose();
    props.setSelectedRoomId(roomId);
    props.handleEnquirySideBarModalOpen();
  };

  const handleBooking = (roomId = null) => {
    props.onClose();
    props.setSelectedRoomId(roomId);
    props.handleBookingSideBarModalOpen();
  };

  const showChatWidget = false;

  const pageContents = (
    <Box className={classes.mainContentContainer}>
      {roomOptions.map((room) => (
        <Paper key={room.id} className={classes.roomCardContainer}>
          <Box className={classes.roomCardTitle}>
            <Typography variant={tabletUpScreen ? "h5" : "h6"}>{room.title}</Typography>
            <Typography>{room.tag_line}</Typography>
          </Box>
          <Divider />
          <Box className={classes.roomCardContent}>
            <Box>
              <img className={classes.img} alt="room" src={room?.hero_image?.url} />
            </Box>
            <Box className={classes.roomCardTextAndActions}>
              <Box>
                <Box className={classes.featuredFeatureContainer}>
                  <FontAwesomeIcon
                    className={classes.detailIcon}
                    icon={formatFaIcon(room.featuredIcon?.icon)}
                  />
                  <Typography variant={"subtitle1"}>
                    Capacity: {room.featuredIcon?.description}
                  </Typography>
                </Box>
                <Typography variant="body1">{room.sub_heading}</Typography>
                <Button
                  className={classes.textButton}
                  color="primary"
                  variant="text"
                  disableRipple
                  onClick={() => handleMoreDetails(room.id)}>
                  More Details
                </Button>
              </Box>
              <Box className={classes.buttonActionsContainer}>
                {room.bookable && (
                  <Button color="primary" variant="outlined" onClick={() => handleBooking(room.id)}>
                    Book now
                  </Button>
                )}
                <Button color="primary" variant="contained" onClick={() => handleEnquiry(room.id)}>
                  Enquire now
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      ))}
      <Paper className={classNames(classes.mainContentContainer, classes.informationBox)}>
        <Box>
          <img
            className={classes.infoTextMargin}
            style={{ width: "32px" }}
            src={waveHandLogo}
            alt="Wave Hand Logo"
          />
        </Box>
        <Typography variant={tabletUpScreen ? "h5" : "h6"} className={classes.infoTextMargin}>
          Can’t find the right space for your event?
        </Typography>
        <Typography>
          {`Let us know your plans, and we’ll see what we can do for you. Give us a call${
            propertyPhoneNumber && ` on ${propertyPhoneNumber}`
          }.`}
        </Typography>
        <Box className={classNames(classes.buttonActionsContainer, classes.infoCTAOverride)}>
          <Button color="primary" variant="contained" onClick={() => handleEnquiry()}>
            Enquire now
          </Button>
          {showChatWidget && (
            <Button
              variant="outlined"
              className={classes.infoButton}
              startIcon={<ChatOutlined />}
              onClick={() => alert("TODO: Handle Talk to our Team")}>
              Talk to our team
            </Button>
          )}
        </Box>
      </Paper>
    </Box>
  );

  return props.embedded ? (
    pageContents
  ) : (
    <SideBarModal modalTitle="Choose your space" {...props}>
      {pageContents}
    </SideBarModal>
  );
};

ChooseSpaceSideBarModal.propTypes = {
  embedded: PropTypes.bool, // Embedded mode - i.e full page
  facilityServices: PropTypes.array,
  property: PropTypes.object,
  onShowRoomDetailsClick: PropTypes.func,
  handleEnquirySideBarModalOpen: PropTypes.func,
  handleBookingSideBarModalOpen: PropTypes.func,
  setSelectedRoomId: PropTypes.func,
  onClose: PropTypes.func,
};

export default ChooseSpaceSideBarModal;
