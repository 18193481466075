import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { IconButton, Box, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import ImageCarousel from "components/ImageCarousel";

const useStyles = makeStyles((theme) => ({
  imageSliderContainer: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  carouselContainer: {
    width: "100%",
    maxWidth: "90vw",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      maxWidth: "70vw",
    },
  },
  closeIcon: {
    zIndex: 1000000,
    position: "absolute",
    right: "5%",
    top: "5%",
    backgroundColor: theme.palette.other.backdrop,
    color: "#FFFFFF",
    maxWidth: "40px",
    maxHeight: "40px",
  },
}));

const ImageSlider = forwardRef(({ mediaList, onClose }, ref) => {
  const classes = useStyles();

  return (
    <>
      <IconButton className={classes.closeIcon} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Box className={classes.imageSliderContainer}>
        <Box className={classes.carouselContainer}>
          <ImageCarousel images={mediaList} isHeaderCarousel hasExternalButtons />
        </Box>
      </Box>
    </>
  );
});

ImageSlider.propTypes = {
  mediaList: PropTypes.array,
  showArrowControls: PropTypes.bool,
  bgColor: PropTypes.string,
  onClose: PropTypes.func,
};

ImageSlider.displayName = "ImageSlider";

export default ImageSlider;
