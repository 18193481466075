import api from "utils/api";

export const createUnpaidReservation = async ({
  selectedTimeSlot,
  bookingContactDetails,
  additionalRequirements,
  selectedRoom,
}) => {
  const data = {
    service_cms_id: selectedRoom.id,
    access_persistent_id: selectedTimeSlot.access_persistent_id,
    shift_persistent_id: selectedTimeSlot.shift_persistent_id,
    date: selectedTimeSlot.date,
    start_time: selectedTimeSlot.start_time,
    end_time: selectedTimeSlot.end_time,
    party_size: selectedTimeSlot.party_size,
    duration_minutes: selectedTimeSlot.duration_minutes,
    note: additionalRequirements.specialRequests.value,
    first_name: bookingContactDetails.firstName.value,
    last_name: bookingContactDetails.lastName.value,
    email: bookingContactDetails.email.value,
    phone: bookingContactDetails.phoneNumber.value,
  };

  const response = await api.createUnpaidReservation(data);
  return response.data;
};
