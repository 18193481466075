import axios from "axios";
import Cookies from "js-cookie";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  maxRedirects: 0,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const api = {
  getRoomDetails: () => axios.get(`${process.env.REACT_APP_AXP_CMS_BASE_URL}/api/rooms`),
  getFacility: (propertyId, facilitySlug) =>
    axios.get(
      `${process.env.REACT_APP_AXP_CMS_BASE_URL}/api/facilities?filters[properties][id][$eq]=${propertyId}&filters[slug][$eq]=${facilitySlug}`
    ),
  getFacilityService: (facilityId, facilityServiceId) =>
    axios.get(
      `${process.env.REACT_APP_AXP_CMS_BASE_URL}/api/facility-services?filters[facility][id][$eq]=${facilityId}&filters[id][$eq]=${facilityServiceId}`
    ),
  getFacilityServices: (facilityId) =>
    axios.get(
      `${process.env.REACT_APP_AXP_CMS_BASE_URL}/api/facility-services?filters[facility][id][$eq]=${facilityId}`
    ),
  getBookableFacilities: (propertyId) =>
    axios.get(
      `${process.env.REACT_APP_AXP_CMS_BASE_URL}/api/facilities?filters[properties][id][$eq]=${propertyId}&filters[availability_check_enabled][$eq]=true`
    ),
  getBookableFacilityServices: (facilityId) =>
    axios.get(
      `${process.env.REACT_APP_AXP_CMS_BASE_URL}/api/facility-services?filters[facility][id][$eq]=${facilityId}&filters[bookable][$eq]=true`
    ),
  getProperties: () =>
    instance({
      method: "GET",
      url: "/api/v1/properties",
    }),
  getReservationAddons: (propertyCode) =>
    axios.get(
      `${process.env.REACT_APP_CUSTOM_CMS_BASE_URL}/api/reservation-addons?filters[properties][pms_id]=${propertyCode}`
    ),
  getMembershipRules: () =>
    axios.get(`${process.env.REACT_APP_CUSTOM_CMS_BASE_URL}/api/membership-rules`),
  getAvailabilities: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/availabilities",
      params: params,
    }),
  getPricingIndex: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/availabilities/pricing_index",
      params: params,
    }),
  getServices: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/services",
      params: params,
    }),
  getBooking: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/bookings",
      params: params,
    }),
  getBookingFromReservation: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/bookings/lookup",
      params: params,
    }),
  makeBooking: (data) =>
    instance({
      method: "POST",
      url: "/api/v1/bookings",
      data: data,
      headers: {
        // Send meta pixel ids for backend conversion api
        fbp: Cookies.get("_fbp") || "",
        fbc: Cookies.get("_fbc") || "",
      },
    }),
  makeEnquiry: (data) =>
    instance({
      method: "POST",
      url: "/api/v1/enquiries",
      data: data,
    }),
  getReservation: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/reservations",
      params: params,
    }),
  getFacilityServiceAvailability: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/facilities/availabilities",
      params: params,
    }),
  cancelReservation: (params) =>
    instance({
      method: "DELETE",
      url: "/api/v1/reservations",
      params: params,
    }),
  cancelBooking: (propertyId, bookingId) =>
    instance({
      method: "DELETE",
      url: `/api/v1/bookings/${bookingId}?propertyId=${propertyId}`,
    }),
  createPaymentIntention: (data) =>
    instance({
      method: "POST",
      url: "/api/v1/facilities/payment_intent_creations",
      data: data,
    }),
  getPaymentMethods: (data) =>
    instance({
      method: "POST",
      url: "/api/v1/payment_methods",
      data: data,
    }),
  makePayment: (data) =>
    instance({
      method: "POST",
      url: "/api/v1/make_payment",
      data: data,
    }),
  makeDonation: (data) =>
    instance({
      method: "POST",
      url: "/api/v1/make_donation",
      data: data,
    }),
  updatePaymentDetails: (data) =>
    instance({
      method: "PUT",
      url: "/api/v1/payment_details",
      data: data,
    }),
  getApplePaySession: () =>
    instance({
      method: "GET",
      url: "/api/v1/applepay_payment_session",
    }),
  getProfile: (firebaseAuthToken) =>
    instance({
      method: "GET",
      url: "/api/v1/profile",
      headers: {
        Authorization: firebaseAuthToken,
      },
    }),
  updateProfile: (firebaseAuthToken, data) =>
    instance({
      method: "PUT",
      url: "/api/v1/profile",
      data: data,
      headers: {
        Authorization: firebaseAuthToken,
      },
    }),
  signIntoFirebaseWithOAuth: (data, params) =>
    axios({
      method: "POST",
      url: "https://identitytoolkit.googleapis.com/v1/accounts:signInWithIdp",
      data: data,
      params: params,
    }),
  verifyFirebaseToken: (firebaseAuthToken) =>
    instance({
      method: "POST",
      url: "/api/v1/verify_firebase_token",
      headers: {
        Authorization: firebaseAuthToken,
      },
    }),
  getGiftCardBalance: (giftCardNumber, roomRateCodes) =>
    instance({
      method: "GET",
      url: `/api/v1/gift_cards/balance?card_reference=${giftCardNumber}&rate_codes=${roomRateCodes.join(
        ","
      )}`,
    }),
  payByTerminal: (bookingId, paymentTerminalId) =>
    instance({
      method: "POST",
      url: "/api/v1/payments/by_terminal",
      data: { bookingId, paymentTerminalId },
    }),
  getPaymentStatus: (paymentId, folioId) =>
    instance({
      method: "GET",
      url: `/api/v1/payments/status?paymentId=${paymentId}&folioId=${folioId}`,
    }),
  cancelTerminalPayment: (paymentId, folioId) =>
    instance({
      method: "PUT",
      url: "/api/v1/payments/cancel_terminal",
      data: { paymentId, folioId },
    }),
  getRestaurantVenues: (propertyId) =>
    axios.get(
      `${process.env.REACT_APP_AXP_CMS_BASE_URL}/api/facilities?filters[type]=Restaurant&filters[properties][code][$in]=${propertyId}&filters[availability_check_enabled]=true`
    ),
  getVenuesAvailabilities: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/sevenrooms/availabilities",
      params,
    }),
  bookDining: (data) =>
    instance({
      method: "POST",
      url: "/api/v1/sevenrooms/bookings",
      data,
    }),
  getFacilityAvailability: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/facilities/availabilities",
      params,
    }),
  createReservationHold: (data) =>
    instance({
      method: "POST",
      url: "/api/v1/facilities/reservation_holds",
      data,
    }),
  createUnpaidReservation: (data) =>
    instance({
      method: "POST",
      url: "/api/v1/facilities/unpaid_reservations",
      data,
    }),
};

export default api;
