import React from "react";
import PropTypes from "prop-types";

import { IconButton, Grid, Typography, makeStyles } from "@material-ui/core";
import { CancelOutlined as CancelIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  servicesSummary: {
    borderTop: `1px solid ${theme.palette.custom.offWhite}`,
    borderBottom: `1px solid ${theme.palette.custom.offWhite}`,
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  pill: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.success.light,
    padding: theme.spacing(1),
    borderRadius: "12px",
    height: "23px",
  },
  priceString: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const ServicesAddedSummary = (props) => {
  const classes = useStyles();

  return (
    <div className={props.className}>
      <Grid container direction="row" alignItems="center" wrap="nowrap">
        <div className={classes.pill}>
          <Typography variant="caption">Added!</Typography>
        </div>
        <Typography variant="h6" className={classes.priceString}>
          {props.priceString}
        </Typography>
        <IconButton color="primary" onClick={props.onRemove}>
          <CancelIcon />
        </IconButton>
      </Grid>
    </div>
  );
};

ServicesAddedSummary.propTypes = {
  className: PropTypes.string,
  priceString: PropTypes.string,
  onRemove: PropTypes.func,
};

export default ServicesAddedSummary;
