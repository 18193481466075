import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";

export const selectAvailCheckParams = (roomIndex = 1) =>
  getOr({}, ["booking", "availCheckParams", roomIndex]);

export const selectAllAvailCheckParams = get(["booking", "availCheckParams"]);

export const selectCommonSearchParams = getOr({}, ["booking", "commonSearchParams"]);

export const selectRoomReservations = get(["booking", "roomReservations"]);

export const selectParkingReservations = get(["booking", "parkingReservations"]);

export const selectDiningSelections = getOr({}, ["dining", "diningSelections"]);

export const selectPrefetchAvailability = getOr({}, ["dining", "prefetchAvailability"]);
