import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Dialog } from "@material-ui/core";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: "575px",
  },
  heading: {
    textAlign: "center",
    margin: "24px 32px",
  },
}));

const LoadingDialog = (props) => {
  const classes = useStyles();

  return (
    <Dialog open={props.open} PaperProps={{ className: classes.dialogPaper }}>
      <Grid container direction="column" justifyContent="center">
        <Typography variant="h6" color="textPrimary" className={classes.heading}>
          {props.message}
        </Typography>
        <LoadingSpinner loading={props.open} />
        <br />
        <br />
      </Grid>
    </Dialog>
  );
};

LoadingDialog.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
};

export default LoadingDialog;
