const paymentWidgetStyle = (theme) => ({
  // Adyen Drop In widget styles overwrite
  payment: {
    // Payment method boxes
    "& .adyen-checkout__payment-method": {
      border: `1px solid ${theme.palette.grey[100]}`,
      background: theme.palette.background.componentBackground1,
      borderRadius: "0px",
    },

    // Payment method radio buttons
    "& .adyen-checkout__payment-method__radio.adyen-checkout__payment-method__radio--selected": {
      backgroundColor: theme.palette.primary.light,
    },

    // Payment method name
    "& .adyen-checkout__payment-method__name": {
      ...theme.typography.subtitle1,
      color: theme.palette.text.primary,
    },

    "& .adyen-checkout__payment-method__name--selected": {
      color: theme.palette.text.red,
    },

    // Input field labels
    "& .adyen-checkout__label__text": {
      ...theme.typography.body2,
      color: theme.palette.text.primary,
    },

    // Input fields
    "& .adyen-checkout__input-wrapper .adyen-checkout__input": {
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: "0px",
      color: theme.palette.text.primary,
    },

    "& .adyen-checkout__input": {
      fontSize: "16px", // Needed to prevent the widget causing zoom on ios safari
    },

    // Pay button
    "& .adyen-checkout__button--pay": {
      ...theme.typography.button,
      backgroundColor: theme.palette.primary.main,
      borderRadius: "24px",
      color: theme.palette.primary.contrastText,

      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
});

export default paymentWidgetStyle;
