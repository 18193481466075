import firebase from "firebase/app";
import "firebase/auth";
import { trackLoginEvent } from "utils/analytics";

import api from "utils/api";

const signIntoSocialProvider = async (provider) => {
  const authResponse = await firebase.auth().signInWithPopup(provider);

  const firebaseToken = await fetchFirebaseTokenWithSocialSignInToken(
    authResponse.credential.idToken,
    authResponse.additionalUserInfo.providerId
  );

  return {
    firebaseToken,
    authUser: authResponse.user,
  };
};

const fetchFirebaseTokenWithSocialSignInToken = async (socialSignInToken, providerId) => {
  try {
    const authResponse = await api.signIntoFirebaseWithOAuth(
      {
        postBody: `id_token=${socialSignInToken}&providerId=${providerId}`,
        requestUri: window.location.origin.toString(),
        returnIdpCredential: true,
        returnSecureToken: true,
      },
      {
        key: process.env.REACT_APP_FIREBASE_API_KEY,
      }
    );
    return authResponse.data.idToken;
  } catch (e) {
    console.error(e);
  }
};

export const performGoogleSignIn = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();

  const response = await signIntoSocialProvider(provider);
  trackLoginEvent("Google");
  return response;
};

export const performAppleSignIn = async () => {
  let provider = new firebase.auth.OAuthProvider("apple.com");
  provider.addScope("email");
  provider.addScope("name");

  const response = await signIntoSocialProvider(provider);
  trackLoginEvent("Apple");
  return response;
};
