import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button, Grid, makeStyles } from "@material-ui/core";
import {
  ChevronRight as ChevronRightIcon,
  KeyboardArrowUpOutlined as ArrowUpIcon,
  KeyboardArrowDownOutlined as ArrowDownIcon,
} from "@material-ui/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Remarkable } from "remarkable";
import { formatFaIcon } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  moreInfoButton: {
    width: "100%",
    backgroundColor: theme.palette.info.lightBg,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: 0,
    justifyContent: "flex-start",
  },
  body: {
    padding: theme.spacing(2),
  },
  linkButton: {
    padding: "4px 0px",
    marginBottom: theme.spacing(1),
    borderRadius: "4px",
  },
  linkIcon: {
    fontSize: "1.1rem",
    marginTop: "-2px",
    marginRight: "10px",
  },
}));

const MoreInfo = (props) => {
  const classes = useStyles();

  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const md = new Remarkable();

  const createMarkup = () => {
    return {
      __html: md.render(props.description),
    };
  };

  const handleLinkButtonPressed = (link) => {
    if (link.url && link.url.length > 0) {
      window.open(link.url, "_blank");
    } else {
      props.onOpenMarkdownModel(link.title, link.text);
    }
  };

  return (
    <div>
      <Button
        className={classes.moreInfoButton}
        endIcon={showMoreInfo ? <ArrowUpIcon /> : <ArrowDownIcon />}
        onClick={() => setShowMoreInfo(!showMoreInfo)}
        size="large">
        {showMoreInfo ? "Hide" : "More"} information
      </Button>
      {showMoreInfo && (
        <div className={classes.body}>
          {/* Links */}
          {props.links && props.links.length > 0 ? (
            <Grid container direction="column" alignItems="flex-start">
              {props.links.map((link, i) => (
                <Button
                  onClick={() => handleLinkButtonPressed(link)}
                  className={classes.linkButton}
                  color="primary"
                  key={i}
                  endIcon={<ChevronRightIcon />}>
                  {link.icon && link.icon.length > 0 ? (
                    <FontAwesomeIcon className={classes.linkIcon} icon={formatFaIcon(link.icon)} />
                  ) : null}
                  {link.title}
                </Button>
              ))}
            </Grid>
          ) : null}

          {/* RTF Description */}
          {props.description && (
            <div className={classes.rtf} dangerouslySetInnerHTML={createMarkup()}></div>
          )}
        </div>
      )}
    </div>
  );
};

MoreInfo.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  description: PropTypes.string, // RTF
  onOpenMarkdownModel: PropTypes.func,
};

export default MoreInfo;
