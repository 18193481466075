import firebase from "firebase/app";
import "firebase/auth";
import { trackLoginEvent, trackSignUpEvent } from "utils/analytics";

export const performEmailSignIn = async (email, password) => {
  const credential = await firebase.auth().signInWithEmailAndPassword(email, password);

  trackLoginEvent("Email");

  const firebaseToken = await credential.user.getIdToken(true);
  return {
    firebaseToken,
    authUser: credential.user,
  };
};

export const performEmailSignUp = async (email, password, firstName, lastName) => {
  await firebase.auth().createUserWithEmailAndPassword(email, password);

  const user = firebase.auth().currentUser;
  await user.updateProfile({
    displayName: `${firstName}|${lastName}`,
  });

  await user.sendEmailVerification();

  trackSignUpEvent("Email");
};
