import React from "react";
import { Button, Paper } from "@material-ui/core";
import PropTypes from "prop-types";

import EventDetails from "./EventDetails.js";
import AdditionalRequirements from "./AdditionalRequirements.js";

import stepStyle from "../stepStyle";

const EventDetailsStep = (props) => {
  const classes = stepStyle();

  const handleNext = () => {
    props.handleNext();
  };

  return (
    <>
      <Paper className={classes.stepContainer}>
        <EventDetails
          eventDetails={props.eventDetails}
          handleEventDetailsChange={props.handleEventDetailsChange}
        />
        <AdditionalRequirements
          additionalRequirements={props.additionalRequirements}
          handleAdditionalRequirementsChange={props.handleAdditionalRequirementsChange}
        />
      </Paper>
      <Button
        className={classes.nextStepCTA}
        variant="contained"
        color="primary"
        size="large"
        onClick={handleNext}>
        Next Step
      </Button>
    </>
  );
};

EventDetailsStep.propTypes = {
  eventDetails: PropTypes.object,
  handleEventDetailsChange: PropTypes.func,
  additionalRequirements: PropTypes.object,
  handleAdditionalRequirementsChange: PropTypes.func,
  handleNext: PropTypes.func.isRequired,
};

export default EventDetailsStep;
