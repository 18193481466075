import React from "react";
import PropTypes from "prop-types";

import { Button, makeStyles } from "@material-ui/core";
import { formatCurrency } from "utils/helpers";

import ServicesAddedSummary from "./ServicesAddedSummary";

const useStyles = makeStyles((theme) => ({
  addButton: {
    float: "right",
  },
}));

const AddServicesButton = (props) => {
  const classes = useStyles();

  const getTotalPriceStr = () => {
    // Returns price string e.g £10 or Free
    return props.amount === 0 ? "Free" : formatCurrency(props.currency, props.amount);
  };

  return (
    <div className={props.className}>
      {props.isInBasket ? (
        <ServicesAddedSummary
          className={classes.addButton}
          priceString={getTotalPriceStr()}
          onRemove={props.onRemoveHandler}
        />
      ) : (
        !props.hideAddButton && (
          <Button
            className={classes.addButton}
            variant="contained"
            color="primary"
            onClick={props.onAddHandler}>
            {`Add  |  ${getTotalPriceStr()}`}
          </Button>
        )
      )}
    </div>
  );
};

AddServicesButton.propTypes = {
  className: PropTypes.string,
  isInBasket: PropTypes.bool,
  hideAddButton: PropTypes.bool,
  currency: PropTypes.string,
  amount: PropTypes.number,
  onAddHandler: PropTypes.func,
  onRemoveHandler: PropTypes.func,
};

export default AddServicesButton;
