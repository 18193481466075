import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import Routes from "config/routes";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, useTheme, useMediaQuery, Paper, Box } from "@material-ui/core";

import EmailAuth from "components/EmailAuth";
import BookingReservationsCard from "components/BookingReservationsCard";
import Heading from "components/Heading";
import GetMolliesApp from "components/GetMolliesApp";
import Navbar from "components/Navbar";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    justifyContent: "center",
    margin: "auto",
    width: "95%",
    marginTop: "45px",
    [theme.breakpoints.up("desktop")]: {
      width: "80%",
      maxWidth: "1300px",
    },
  },
  contentRow: {
    width: "100%",
  },
}));

const ManageBooking = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const authUser = useSelector((state) => state.user.authUser);
  const desktopUpScreen = useMediaQuery(theme.breakpoints.up("desktop"));
  const searchedBooking = useSelector((state) => state.booking.searchedBooking);

  useEffect(() => {
    if (!searchedBooking) {
      history.push(Routes.PLAN_STAY);
    }
  }, [searchedBooking]);

  const handleReservationCancelled = () => {
    history.push(Routes.PLAN_STAY);
  };

  return searchedBooking ? (
    <div className={classes.root}>
      <Navbar withProfile onNotifOpen={props.onNotifOpen} />
      <Grid container className={classes.mainContent} spacing={2}>
        <Grid container className={classes.contentRow}>
          <Heading titleText="Manage booking" />
        </Grid>
        <Grid item xs={desktopUpScreen ? 6 : null}>
          <BookingReservationsCard
            bookingReservations={searchedBooking.reservations}
            onReservationCancelled={handleReservationCancelled}
            onNotifOpen={props.onNotifOpen}
          />
        </Grid>

        <Grid item xs={desktopUpScreen ? 6 : null}>
          {authUser ? null : (
            <Box mb={2}>
              <Paper>
                <EmailAuth
                  signUp
                  open={true}
                  authUser={authUser}
                  onNotifOpen={props.onNotifOpen}
                  shortSignUp={{
                    email: searchedBooking.booker.email,
                    firstName: searchedBooking.booker.firstName,
                    lastName: searchedBooking.booker.lastName,
                  }}
                />
              </Paper>
            </Box>
          )}
          <GetMolliesApp />
        </Grid>
      </Grid>
    </div>
  ) : null;
};

ManageBooking.propTypes = {
  onNotifOpen: PropTypes.func,
};

export default ManageBooking;
