import { getOptions } from "components/FacilityBookingSideBarModal/utils/getOptions";
import { toSafeInteger } from "lodash";

export const formatSearchParams = (selectedRoom, searchParams) => {
  const date = searchParams.arrivalDate.value;
  const options = getOptions(searchParams.arrivalTime, date);

  const time = options.hide
    ? {
        start_time: "00:00",
        end_time: "23:59",
      }
    : options.inputProps.options.find((shift) => shift.value == searchParams.arrivalTime.value);

  const shiftHours = {
    start: {
      hour: toSafeInteger(time.start_time.split(":")[0]),
      minute: toSafeInteger(time.start_time.split(":")[1]),
    },
    end: {
      hour: toSafeInteger(time.end_time.split(":")[0]),
      minute: toSafeInteger(time.end_time.split(":")[1]),
    },
  };

  const startTime = date.clone().hour(shiftHours.start.hour).minute(shiftHours.start.minute);
  const endTime = date.clone().hour(shiftHours.end.hour).minute(shiftHours.end.minute);

  return {
    service_cms_id: selectedRoom.id,
    date: date.format("YYYY-MM-DD"),
    start_time: startTime.format("HH:mm"),
    end_time: endTime.format("HH:mm"),
    party_size: toSafeInteger(searchParams.numberOfPeople.value),
    duration_minutes: getOptions(searchParams.numberOfHours, date).hide
      ? time.duration
      : toSafeInteger(searchParams.numberOfHours.value) * 60,
  };
};
