import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Typography, Box, Button, makeStyles, useTheme } from "@material-ui/core";

import { isEven } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  highlightItem: {
    width: "100%",
    maxWidth: "343px",

    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      maxWidth: "324px",
    },
    color: theme.palette.primary.contrastText,
  },
  highlightTextContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  highlightButton: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    width: "100%",
  },
  highlightButtonOverrides: {
    width: "100%",
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.text.primary,
      boxShadow: "none",
    },
  },
  highlightImage: {
    width: "100%",
    height: "100%",
    maxHeight: "240px",
    minHeight: "240px",
    objectFit: "cover",
    objectPosition: "top",
  },
}));

const Highlights = ({ highlights = [], className }, props) => {
  const classes = useStyles();
  const theme = useTheme();

  return highlights.map((highlight, index) => (
    <Box
      className={classNames(classes.highlightItem, className)}
      key={highlight.id}
      style={{
        marginBottom: index < highlights.length - 1 ? theme.spacing(3) : "0",
        backgroundColor: isEven(index)
          ? theme.palette.custom.plum
          : theme.palette.custom.greenCountry,
      }}>
      <img className={classes.highlightImage} alt="room" src={highlight?.image?.url} />
      <Box className={classes.highlightTextContainer}>
        <Typography variant="h4">{highlight.title}</Typography>
        <Typography>{highlight.description}</Typography>
        <Box className={classes.highlightButton}>
          <Button
            variant="outlined"
            className={classes.highlightButtonOverrides}
            href={highlight?.url}
            target="_blank"
            rel="noopener noreferrer">
            {highlight?.button_cta || "View"}
          </Button>
        </Box>
      </Box>
    </Box>
  ));
};

Highlights.propTypes = {
  className: PropTypes.string,
  highlights: PropTypes.array,
};

export default Highlights;
