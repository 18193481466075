// Helper functions relating to the membership rules

// Returns the first corporate code used in the list of room reservations
// Returns null if no corporate code used
export const corporateCodeForReservations = (roomReservations) => {
  return roomReservations.find((r) => r.corporateCode)?.corporateCode;
};

// Returns boolean whether the room reservations passed in contains a corporate booking
export const containsCorporateRateReservation = (roomReservations) => {
  return roomReservations.some((r) => r.corporateCode);
};

// Find which rule matches the membership code used
export const membershipRuleForCode = (membershipRules, corporateCode) => {
  if (!corporateCode) {
    return null;
  }

  return membershipRules?.find((r) => corporateCode.startsWith(r.code_prefix));
};
