import { capitalize } from "lodash/fp";
import find from "lodash/find";
import { formatCurrency } from "utils/helpers";
import { formatDateMedium, formatDateTo24HrTime } from "utils/timekeep";
import moment from "moment";

export const getSelectedRoomData = (roomId, facilityServices) => {
  if (roomId) {
    const selectedRoom = find(facilityServices, { id: roomId });
    return selectedRoom;
  } else return null;
};

export const handleAvailabilitySummary = ({
  eventDetails,
  selectedTimeSlot,
  showEndTime = true,
  showPrice = false,
}) => {
  if (selectedTimeSlot) {
    const startTimeFormatted = `${formatDateMedium(
      eventDetails["arrivalDate"]?.value
    )}, ${formatDateTo24HrTime(moment.utc(selectedTimeSlot.start_time))}`;
    const endTimeFormatted = formatDateTo24HrTime(moment.utc(selectedTimeSlot.end_time));
    const numberOfHoursFormatted =
      showEndTime && eventDetails["numberOfHours"]?.value
        ? ` (${eventDetails["numberOfHours"]?.value}hrs)`
        : "";
    const numberOfPeopleFormatted = `${eventDetails["numberOfPeople"]?.value} guests`;
    const priceFormatted = showPrice
      ? `${"\u00A0"}|${"\u00A0"} ${formatCurrency("GBP", selectedTimeSlot.cost)}`
      : "";

    const timeSlotFormatted = showEndTime
      ? `${startTimeFormatted} - ${endTimeFormatted}`
      : startTimeFormatted;

    return `${timeSlotFormatted}${numberOfHoursFormatted}, ${numberOfPeopleFormatted}${priceFormatted}`;
  } else {
    const date = formatDateMedium(eventDetails["arrivalDate"]?.value);
    const time = eventDetails["arrivalTime"]?.value
      ? capitalize(eventDetails["arrivalTime"]?.value)
      : null;
    const people = eventDetails["numberOfPeople"]?.value
      ? `${eventDetails["numberOfPeople"]?.value} guests`
      : null;

    return [date, time, people].filter((sentence) => sentence != null).join(", ");
  }
};
