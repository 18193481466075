import moment from "moment";

export const getBookingSetting = (bookingSettings, date, key) => {
  const isToday = date?.isSame(moment(), "day");

  return (isToday && bookingSettings?.todaySettings?.[key]) || bookingSettings?.settings?.[key];
};

export const getOptions = (eventFormField, date) => {
  const isToday = date.isSame(moment(), "day");

  var options = null;
  if (isToday) options = eventFormField.todayOptions;
  if (options == null) options = eventFormField.options;

  return options;
};
