import React, { useCallback, useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import api from "utils/api";
import PayButton from "./PayButton";
import { makeStyles } from "@material-ui/core";
import getDisplayMessage from "./utils/getDisplayMessage";
import { PropTypes } from "prop-types";

const useStyle = makeStyles((theme) => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
  };
});

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyle();

  const [message, setMessage] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);

  useEffect(() => {
    if (!stripe) return;
    if (!props.clientSecret) return;

    stripe.retrievePaymentIntent(props.clientSecret).then(({ paymentIntent }) => {
      setPaymentIntent(paymentIntent);
      setMessage(getDisplayMessage({ paymentIntentStatus: paymentIntent.status }));
    });
  }, [stripe, props.clientSecret]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      props.setIsLoading(true);

      // Stripe.js hasn't yet loaded.
      if (!stripe || !elements || !paymentIntent) return;

      await api.createReservationHold({
        property_code: props.propertyCode,
        payment_intent_id: paymentIntent.id,
      });

      // The return url is used by Stripe if we need to redirect
      // to a payment provider for 3DS.
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: props.buildBookingConfirmationUrl(),
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }

      props.setIsLoading(false);
    },
    [stripe, elements, paymentIntent, props.buildBookingConfirmationUrl]
  );

  return (
    <form id="payment-form" className={classes.formContainer} onSubmit={handleSubmit}>
      <PaymentElement
        id="payment-element"
        options={{
          layout: "tabs",
        }}
      />
      <PayButton disabled={props.isLoading || !stripe || !elements} cost={props.cost} />
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

CheckoutForm.propTypes = {
  clientSecret: PropTypes.string,
  buildBookingConfirmationUrl: PropTypes.func,
  propertyCode: PropTypes.string,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func.isRequired,
  cost: PropTypes.number,
};
