import { flow, map, get, omit, keyBy, mapValues } from "lodash/fp";

export const getRequestFormId = (idValues, selectedFacilityType, facilityTypes) => {
  const facilityKey = Object.entries(facilityTypes).find(
    ([, value]) => value === selectedFacilityType
  )[0];
  return idValues[facilityKey];
};

export const handleEnquirySuccessFormData = (response) => {
  // field labels used for Enquiry Confirmation Page
  const fieldLabels = {
    arrivalDate: "Preferred date",
    arrivalTime: "Preferred time",
    numberOfPeople: "Number of attendees",
    nameOfEvent: "Event name",
    typeOfRoom: "Preferred room(s)",
    contactName: "Company name",
    value: "Budget",
    details: "Are dates flexible?",
    preferences: "Accommodation required?",
    addOns: "Catering or diner reservation required?",
    specialRequests: "Special requests",
  };

  function replaceBooleanWithYesNo(value) {
    // Also capitalise normal 'yes' / 'no
    if (value === "true") return "Yes";
    if (value === "false") return "No";
    if (value === "yes") return "Yes";
    if (value === "no") return "No";
    return value;
  }

  function mapObjectWithFieldLabels(data, fieldLabels) {
    const allFields = Object.keys(fieldLabels);
    return map((key) => {
      // Also creates fieldLabels for fields, even if they are not received in response
      const label = fieldLabels[key] ? fieldLabels[key] : key;
      return { key, label, value: data[key] || "-" };
    }, allFields);
  }
  const formattedResponseData = flow(
    get("fieldsData.requestDetails"),
    // Remove details that do not need to be rendered
    omit(["sendersName", "sendersEmail", "sendersPhone"]),
    // add field labels to each field
    (data) => mapObjectWithFieldLabels(data, fieldLabels),
    // Convert array into object
    keyBy("key"),
    mapValues((item) => {
      const { label, value } = item;
      return { label, value: replaceBooleanWithYesNo(value) };
    })
  )(response);

  return formattedResponseData;
};
