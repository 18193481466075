import React from "react";

const Error = () => {
  return (
    <div>
      <p>Error: Page does not exist!</p>
    </div>
  );
};

export default Error;
