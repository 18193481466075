import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import head from "lodash/fp/head";
import compose from "lodash/fp/compose";
import get from "lodash/fp/get";
import some from "lodash/fp/some";
import isEqual from "lodash/fp/isEqual";

import api from "utils/api";

export const fetchProperties = createAsyncThunk("property/fetchProperties", async () => {
  try {
    const response = await api.getProperties();
    const properties = response.data;
    return properties;
  } catch (e) {
    console.error(e);
  }
});

const isPropertyAvailable = (propertyId) => some(compose(isEqual(propertyId), get(["code"])));

const propertySlice = createSlice({
  name: "property",
  initialState: {
    properties: [],
    selectedPropertyId: null,
  },
  reducers: {
    setSelectedPropertyId: (state, action) => {
      state.selectedPropertyId = action.payload;
    },
  },
  extraReducers: {
    [fetchProperties.fulfilled]: (state, action) => {
      state.properties = action.payload || [];
      if (
        !state.selectedPropertyId ||
        !isPropertyAvailable(state.selectedPropertyId)(action.payload)
      ) {
        state.selectedPropertyId = compose(get(["code"]), head)(action.payload);
      }
    },
  },
});

export const { setSelectedPropertyId } = propertySlice.actions;
export default propertySlice.reducer;
