import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  Divider,
} from "@material-ui/core";

import LowestPrice from "components/LowestPrice";

import { AvailableUnit } from "../AvailableUnit/AvailableUnit";
import { formatFaIcon } from "utils/helpers";
import ImageCarousel from "components/ImageCarousel";
import SideBarModal from "components/SideBarModal";

const useStyles = makeStyles((theme) => ({
  parentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },

  modalTitleContainer: {
    marginTop: "30px",
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(2),
    marginBottom: "1rem",
    [theme.breakpoints.up("tablet")]: {
      marginLeft: theme.spacing(5),
    },
  },
  roomDetailsModalContent: {
    height: "100%",
    width: "100%",
    overflow: "scroll",
    position: "absolute",
    right: 0,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  galleryContainer: {
    overflow: "hidden",
    position: "relative",
    maxHeight: "60%",
  },

  roomContainer: {
    position: "relative",
    flexDirection: "column",
    marginTop: "30px",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginBottom: 0,
    justifyContent: "center",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  descriptionRow: {
    paddingTop: theme.spacing(3),
  },
  roomDescription: {
    whiteSpace: "pre-wrap",
  },

  detailIcon: {
    fontSize: "20px",
    textAlign: "center",
  },
  amenity: {
    width: "100%",
    [theme.breakpoints.up("tablet")]: {
      width: "50%",
    },
  },
  amenityContent: {
    flexWrap: "nowrap",
    flexDirection: "row",
    width: "100%",
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gap: theme.spacing(2),
  },

  detailTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: "100%",
  },

  availableUnitContainer: {
    width: "100%",
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up("tablet")]: {
      display: "flex",
      gap: theme.spacing(3),
    },
  },

  availableUnit: {
    width: "100%",
    backgroundColor: theme.palette.background.componentBackground1,
    [theme.breakpoints.up("tablet")]: {
      width: "50%",
    },
  },
}));

const RoomDetailsSideBarModal = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));

  return props.roomDetails ? (
    <SideBarModal open={props.open} onClose={props.onClose} modalTitle="Room details">
      <Grid container className={classes.parentContainer}>
        <Grid item className={classes.modalTitleContainer}>
          <Typography variant={tabletUpScreen ? "subtitle1" : "subtitle2"}>
            {props.selectedProperty.name}
          </Typography>
          <Typography variant={tabletUpScreen ? "h2" : "h4"}>{props.roomDetails.name}</Typography>
        </Grid>
        <Grid container className={classes.galleryContainer}>
          <ImageCarousel
            images={props.roomDetails?.gallery?.map((g) => g.image).filter((i) => !!i)}
            isHeaderCarousel={true}
          />
        </Grid>
        <Grid container className={classes.roomContainer}>
          <Grid item sm={6} className={classes.descriptionCol}>
            <LowestPrice
              availabilities={props.roomAvails}
              stayNights={props.stayNights}
              onDark={false}
              fromVariant={tabletUpScreen ? "h4" : "h6"}
              priceVariant={tabletUpScreen ? "h4" : "h6"}
            />
            <Grid className={classes.descriptionRow}>
              <Typography variant="body1" className={classes.roomDescription}>
                {props.roomDetails.description}
              </Typography>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container direction="column">
              <Grid container>
                <Typography variant={tabletUpScreen ? "h4" : "h6"} className={classes.detailTitle}>
                  In every room
                </Typography>
              </Grid>
              <Grid container spacing={1}>
                {props.roomDetails.features.map((feature) => (
                  <Grid item container className={classes.amenity} key={feature.id}>
                    <Grid container className={classes.amenityContent}>
                      {feature.icon && (
                        <>
                          <FontAwesomeIcon
                            className={classes.detailIcon}
                            icon={formatFaIcon(feature.icon)}
                          />{" "}
                          <Typography variant="subtitle1" gutterBottom>
                            {feature.title}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item container>
            <Box className={classes.detailTitle}>
              <Typography variant={tabletUpScreen ? "h4" : "h6"}>Select package</Typography>
            </Box>
            <Box className={classes.availableUnitContainer}>
              {props.roomAvails.map((availability, index) => (
                <Box key={index} className={classes.availableUnit}>
                  <AvailableUnit
                    availability={availability}
                    onReservationSelect={(e) => props.onReservationSelect(availability, e)}
                    stayNights={props.stayNights}
                    verticalMode
                    sideBarModalMode
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </SideBarModal>
  ) : null;
};

RoomDetailsSideBarModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  roomDetails: PropTypes.object,
  roomAvails: PropTypes.arrayOf(PropTypes.object),
  stayNights: PropTypes.number,
  onReservationSelect: PropTypes.func,
  selectedProperty: PropTypes.object,
};

export default RoomDetailsSideBarModal;
