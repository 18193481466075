import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Typography, Button, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  actionSheet: {
    borderRadius: "16px 16px 0px 0px",
  },
  header: {
    textAlign: "center",
    padding: "10px",
    borderBottom: `1px solid ${theme.palette.lineDetail}`,
  },
  content: {
    maxHeight: "60vh",
    overflow: "auto",
  },
  footer: {
    padding: "15px 25px",
    borderTop: `1px solid ${theme.palette.lineDetail}`,
  },
  actionButton: {
    marginLeft: "10px",
    minWidth: "115px",
  },
}));

const ActionSheet = (props) => {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.actionSheet}
      anchor={"bottom"}
      open={props.open}
      PaperProps={{ elevation: 8, className: classes.actionSheet }}>
      <div className={classes.header}>
        <Typography variant="h6" color="textPrimary">
          {props.title}
        </Typography>
      </div>
      <div className={classes.content}>{props.children}</div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className={classes.footer}>
        {props.onCancel ? (
          <Button className={classes.actionButton} onClick={props.onCancel} size="medium">
            Cancel
          </Button>
        ) : null}

        <Button
          className={classes.actionButton}
          variant="contained"
          color="primary"
          onClick={props.onOK}
          size="medium">
          OK
        </Button>
      </Grid>
    </Drawer>
  );
};

ActionSheet.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onOK: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ActionSheet;
