import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import Stepper from "components/Stepper";

const useStyles = makeStyles((theme) => ({
  roomsPickerContainer: {
    padding: "20px",
    [theme.breakpoints.up("desktop")]: {
      minWidth: "400px",
    },
    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
      width: "100vw",
    },
  },
  roomConfigRow: {
    borderBottom: `1px solid ${theme.palette.lineDetail}`,
    paddingBottom: "10px",
    marginTop: "5px",
  },
  roomConfigRowSingleRoom: {
    paddingBottom: "10px",
    marginTop: "5px",
  },
  roomHeader: {
    marginBottom: "10px",
  },
  stepperSelectorRow: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  roomSelectorItem: {
    display: "flex",
    alignItems: "center",
  },
  hidden: {
    display: "none",
  },
  addRoomRow: {
    marginTop: "10px",
  },
  addRoomButton: {
    textTransform: "none",
    padding: "5px 0px",
  },
  addRoomIcon: {
    marginRight: "10px",
    color: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
    width: "30px",
    height: "30px",
    borderRadius: "15px",
    padding: "4px",
  },
}));

const RoomPicker = (props) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.roomsPickerContainer}>
      {props.roomOccupancies.map((roomOccupancy, i) => (
        <Grid
          container
          key={`room-${i}`}
          className={props.singleRoom ? classes.roomConfigRowSingleRoom : classes.roomConfigRow}
          direction="column">
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className={classes.roomHeader}>
            {props.singleRoom ? null : (
              <Typography variant="subtitle1" color="textPrimary">
                Room {i + 1}
              </Typography>
            )}
            <Button
              onClick={props.onRemoveRoom}
              className={i === 0 || i < props.roomOccupancies.length - 1 ? classes.hidden : null}>
              Remove
            </Button>
          </Grid>

          <Grid container direction="row" alignItems="flex-start" wrap="nowrap">
            <Grid container direction="column">
              <Grid container className={classes.stepperSelectorRow}>
                <Typography variant="body2" color="textSecondary">
                  Adults
                </Typography>
                <Grid item className={classes.roomSelectorItem}>
                  <Stepper
                    name={`adults_stepper-${i}`}
                    ariaHint={`adults for room ${i + 1}`}
                    variant="primary"
                    count={roomOccupancy.adults}
                    decrementDisabled={roomOccupancy.adults <= 1}
                    incrementDisabled={roomOccupancy.adults + roomOccupancy.children >= 4}
                    onValueChanged={props.onAdultsChanged}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.stepperSelectorRow}>
                <Typography variant="body2" color="textSecondary">
                  Children
                </Typography>
                <Grid item className={classes.roomSelectorItem}>
                  <Stepper
                    name={`children_stepper-${i}`}
                    ariaHint={`children for room ${i + 1}`}
                    variant="primary"
                    count={roomOccupancy.children}
                    decrementDisabled={roomOccupancy.children <= 0}
                    incrementDisabled={roomOccupancy.adults + roomOccupancy.children >= 4}
                    onValueChanged={props.onChildrenChanged}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
      {props.singleRoom ? null : (
        <Grid className={classes.addRoomRow}>
          <Button className={classes.addRoomButton} onClick={props.onAddRoom}>
            <AddIcon className={classes.addRoomIcon} />
            Add another room
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

RoomPicker.propTypes = {
  roomOccupancies: PropTypes.array,
  onAddRoom: PropTypes.func,
  onRemoveRoom: PropTypes.func,
  onAdultsChanged: PropTypes.func,
  onChildrenChanged: PropTypes.func,
  singleRoom: PropTypes.bool,
};

export default RoomPicker;
