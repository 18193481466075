import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Grid, Typography, makeStyles } from "@material-ui/core";
import { formatCurrency, PRICING_UNIT } from "utils/helpers";
import { calculateServicePrice } from "utils/pms_services/helpers";

import Stepper from "components/Stepper";
import Checkbox from "components/Checkbox";
import AddServicesButton from "../AddServicesButton";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
  services: {
    marginBottom: theme.spacing(2),
  },
  serviceListItemPrice: {
    marginLeft: "5px",
  },
  hidden: {
    opacity: 0,
  },
}));

const RegularAddonDetails = (props) => {
  const classes = useStyles();

  // For tracking the service counts before added to the basket
  const [serviceCounts, setServiceCounts] = useState({});

  useEffect(() => {
    // Get the counts for each service
    let thisServiceCounts = {};

    // Set the initial counts for this addon as the defaults
    props.pmsServices.forEach((s) => (thisServiceCounts[s.pmsId] = s.defaultCount));

    setServiceCounts(thisServiceCounts);
  }, [props.pmsServices]);

  const serviceDetailString = (pmsService) => {
    if (pmsService.pricingUnit == PRICING_UNIT.PERSON) {
      return `per ${pmsService.pricingUnit.toLowerCase()}${
        pmsService.numberOfNights > 1 ? ", per night" : ""
      }`;
    } else {
      return pmsService.numberOfNights > 1 ? `per night` : null;
    }
  };

  // View Logic

  // Returns the pms service details (price, defaults, etc)
  const getPmsService = (pmsId) => {
    return props.pmsServices.find((s) => s.pmsId === pmsId);
  };

  // Returns true if any services in this addon are in the basket
  const anyServicesInBasket = () => {
    return props.pmsServices?.some((service) => props.serviceCountInBasket(service.pmsId) > 0);
  };

  // The count in basket, or falls back to the defaults
  const countForService = (pmsId) => {
    const addedToBasket = anyServicesInBasket();

    // If services are already in the basket, use the count from the service
    // Otherwise, use the temporal count
    if (addedToBasket) {
      return props.serviceCountInBasket(pmsId);
    } else {
      return serviceCounts[pmsId] || 0;
    }
  };

  const getTotalPrice = () => {
    // Returns price for all services
    return props.pmsServices.reduce((acc, s) => {
      const count = countForService(s.pmsId);
      return acc + calculateServicePrice(s.price, s.pricingUnit, s.numberOfNights, count);
    }, 0);
  };

  const getTotalItemCount = () => {
    // Returns number of items in basket / pre-basket state for all services
    return props.pmsServices.reduce((acc, s) => {
      const count = countForService(s.pmsId);
      return acc + count;
    }, 0);
  };

  // Button handlers

  const handleAddButtonPressed = (e) => {
    // Add the services
    const serviceSummaries = props.pmsServices
      .map((pmsService) => {
        const service = props.addonDetails.services.find((s) => s.pmsId === pmsService.pmsId);
        return {
          serviceId: pmsService.pmsId,
          count: countForService(pmsService.pmsId),
          maxCount: pmsService.maxCount,
          cmsTitle: service.title,
          additionalInfo: null,
        };
      })
      .filter((s) => s.count > 0);

    props.onAddServices(serviceSummaries);
  };

  const handleRemoveButtonPressed = (e) => {
    // Remove the services
    const serviceIds = props.pmsServices.map((s) => s.pmsId);
    props.onRemoveServices(serviceIds);
  };

  const handleServiceCountChanged = (pmsId, count, maxCount) => {
    // If the item is already in the basket, we'll change the value in basket
    // Otherwise, we'll change the temporal values

    const addedToBasket = anyServicesInBasket();

    // If services are already in the basket, use the count from the service
    // Otherwise, use the temporal count
    if (addedToBasket) {
      const service = props.addonDetails.services.find((s) => s.pmsId === pmsId);
      props.onUpdateServiceCount(
        pmsId,
        count,
        maxCount,
        service.title, // CMS Title
        null // Additional info
      );
    } else {
      setServiceCounts({
        ...serviceCounts,
        [pmsId]: count,
      });
    }
  };

  const createServiceRows = () => {
    return props.addonDetails?.services.map(({ pmsId, title }, i) => {
      const pmsService = getPmsService(pmsId);

      const serviceName = title || pmsService.name;
      const serviceCount = countForService(pmsId);

      return (
        <Grid
          className={classes.services}
          container
          justifyContent="space-between"
          wrap="nowrap"
          alignItems="center"
          key={i}>
          <Grid container direction="column">
            <Grid container alignItems="baseline">
              <Typography variant="subtitle1" text="textPrimary">
                {serviceName}
              </Typography>
              <Typography variant="subtitle1" className={classes.serviceListItemPrice}>
                &nbsp;&nbsp;
                {formatCurrency(pmsService.price.currency, pmsService.price.amount)}
              </Typography>
            </Grid>
            <Typography variant="body2" color="textSecondary">
              {serviceDetailString(pmsService)}
            </Typography>
          </Grid>
          {pmsService.pricingUnit === PRICING_UNIT.PERSON ? (
            <Stepper
              name={`service_stepper-${i}`}
              ariaHint={`number of ${serviceName} services`}
              count={countForService(pmsId)}
              decrementDisabled={serviceCount <= 0}
              incrementDisabled={serviceCount >= pmsService.maxCount}
              onValueChanged={(name, count) =>
                handleServiceCountChanged(pmsId, count, pmsService.maxCount)
              }
            />
          ) : (
            <Checkbox
              checked={countForService(pmsId) > 0}
              onChange={(checked) =>
                handleServiceCountChanged(pmsId, checked ? 1 : 0, pmsService.maxCount)
              }
            />
          )}
        </Grid>
      );
    });
  };

  // Only show service rows if > 1 service (or the single service is person based)
  const pmsServices = props.pmsServices || [];
  const showServiceRows =
    pmsServices.length > 1 || pmsServices[0].pricingUnit === PRICING_UNIT.PERSON;

  const totalItemCount = getTotalItemCount();

  return (
    <Grid
      container
      direction="column"
      justifyContent={showServiceRows ? "space-between" : "flex-end"}
      className={classes.container}>
      <div>{showServiceRows ? createServiceRows() : null}</div>

      {
        /* only show if there's something to add */
        <AddServicesButton
          className={totalItemCount === 0 && showServiceRows ? classes.hidden : null}
          isInBasket={anyServicesInBasket()}
          currency={props.pmsServices[0]?.price?.currency} // use price from the first service
          amount={getTotalPrice()}
          onAddHandler={handleAddButtonPressed}
          onRemoveHandler={handleRemoveButtonPressed}
        />
      }
    </Grid>
  );
};

RegularAddonDetails.propTypes = {
  serviceCountInBasket: PropTypes.func,
  onAddServices: PropTypes.func,
  onRemoveServices: PropTypes.func,
  onUpdateServiceCount: PropTypes.func,
  pmsServices: PropTypes.arrayOf(
    PropTypes.shape({
      pricingUnit: PropTypes.string,
      price: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      defaultCount: PropTypes.number,
      maxCount: PropTypes.number,
    })
  ),
  addonDetails: PropTypes.shape({
    services: PropTypes.arrayOf(
      PropTypes.shape({
        pmsId: PropTypes.string.isRequired,
        name: PropTypes.string,
      })
    ),
  }),
};

export default RegularAddonDetails;
