import React from "react";

import InputMask from "react-input-mask";
import PropTypes from "prop-types";

import { TextField } from "@material-ui/core";

// Wraps a MUI text field in an input mask
const MaskedTextField = (props) => {
  return (
    <InputMask
      mask={props.mask}
      maskChar={props.maskChar || "_"}
      value={props.value}
      onChange={props.onChange}>
      {() => (
        <TextField
          {...props.textFieldProps}
          name={props.name}
          label={props.label}
          type={props.type}
          variant={props.variant}
        />
      )}
    </InputMask>
  );
};

MaskedTextField.propTypes = {
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  textFieldProps: PropTypes.object,

  // Supported text field props
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
};

export default MaskedTextField;
