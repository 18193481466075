import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  makeStyles,
  useTheme,
  useMediaQuery,
  Typography,
  IconButton,
} from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

import { formatFaIcon } from "utils/helpers";
import getFeaturedIcon from "utils/getFeaturedIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    overflowX: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    gap: theme.spacing(2),
  },
  room: {
    minWidth: "320px",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      minWidth: "480px",
    },
  },

  img: {
    width: "100%",
    height: "320px",
    maxWidth: "320px",
    objectFit: "cover",

    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      maxWidth: "480px",
    },
  },
  iconColor: {
    color: theme.palette.text.secondary,
  },
  buttonsContainer: {
    position: "absolute",
    bottom: "45%",
    right: "10%",
    display: "flex",
    gap: theme.spacing(1),
  },
  arrowButtons: {
    color: theme.palette.text.red,
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      transition: "opacity 0.3s ease-in-out",
      opacity: 0.8,
      boxShadow: "none",
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.background.default,
    },
  },
  featuredFeatureContainer: {
    display: "flex",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  detailIcon: {
    fontSize: "20px",
    textAlign: "center",
  },
  CTAContainer: {
    marginTop: theme.spacing(3),
    display: "flex",
    gap: theme.spacing(2),
  },
  textButton: { "&:hover": { backgroundColor: "inherit" } },
}));

const RoomSlider = (
  {
    initialSliderPadding = 0,
    rooms = [],
    onShowRoomDetailsClick,
    handleEnquirySideBarModalOpen,
    setSelectedRoomId,
  },
  props
) => {
  const classes = useStyles();
  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));
  const scrollRef = useRef(null);

  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [isNotOverflowing, setIsNotOverflowing] = useState(true);

  useEffect(() => {
    const checkScrollPosition = () => {
      const { scrollLeft, offsetWidth, scrollWidth } = scrollRef.current;

      setIsAtStart(scrollLeft === 0);
      setIsAtEnd(scrollLeft + offsetWidth >= scrollWidth);
      setIsNotOverflowing(scrollLeft === 0 && scrollLeft + offsetWidth >= scrollWidth);
    };

    checkScrollPosition(); // check initial state

    const scrollContainer = scrollRef.current;

    scrollContainer.addEventListener("scroll", checkScrollPosition);

    return () => {
      scrollContainer.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);

  const scrollCarouselTo = (scrollOffset) => {
    const scrollLeft = scrollRef.current.scrollLeft;
    return scrollRef.current.scrollTo({ left: scrollLeft + scrollOffset, behavior: "smooth" });
  };

  const handleButtonClick = (val) => {
    scrollCarouselTo(val);
  };

  const handleEnquiry = (roomId = null) => {
    setSelectedRoomId(roomId);
    handleEnquirySideBarModalOpen();
  };

  return (
    <div style={{ position: "relative" }}>
      {tabletUpScreen && !isNotOverflowing && (
        <Box className={classes.buttonsContainer}>
          <IconButton
            className={classes.arrowButtons}
            disabled={isAtStart}
            onClick={() => handleButtonClick(-450)}>
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            className={classes.arrowButtons}
            disabled={isAtEnd}
            onClick={() => handleButtonClick(450)}>
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      )}
      <Box
        ref={scrollRef}
        direction="row"
        className={classes.root}
        style={{ paddingRight: `${initialSliderPadding}px` }}>
        {rooms.map((room, index) => {
          const featuredIcon = getFeaturedIcon(room?.feature_list);
          return (
            <Box
              key={room?.id}
              className={classes.room}
              style={{ marginLeft: index === 0 ? `${initialSliderPadding}px` : null }}>
              <img className={classes.img} alt="room" src={room?.hero_image?.url} />
              <Typography variant={"h5"}>{room.title}</Typography>
              <Box className={classes.featuredFeatureContainer}>
                {featuredIcon && (
                  <FontAwesomeIcon
                    className={classes.detailIcon}
                    icon={formatFaIcon(featuredIcon?.icon)}
                  />
                )}
                <Typography variant={"subtitle1"}>Capacity: {featuredIcon?.description}</Typography>
              </Box>
              <Box className={classes.CTAContainer}>
                <Button color="primary" variant="contained" onClick={() => handleEnquiry(room.id)}>
                  Enquire now
                </Button>

                <Button
                  color="primary"
                  variant="text"
                  disableRipple
                  className={classes.textButton}
                  onClick={() => onShowRoomDetailsClick(room.id)}>
                  More Details
                </Button>
              </Box>
            </Box>
          );
        })}
      </Box>
    </div>
  );
};

RoomSlider.propTypes = {
  initialSliderPadding: PropTypes.number,
  rooms: PropTypes.array,
  onShowRoomDetailsClick: PropTypes.func.isRequired,
  handleEnquirySideBarModalOpen: PropTypes.func,
  setSelectedRoomId: PropTypes.func,
};

export default RoomSlider;
