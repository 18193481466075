import { GUEST_TYPE, PRICING_UNIT } from "utils/helpers";

// The defaut number of services to add, if not provided by props
// This is also the max amount they can select
export const defaultCountForService = (pricingUnit, guestType, adultCount, childCount) => {
  if (pricingUnit === PRICING_UNIT.PERSON) {
    switch (guestType) {
      case GUEST_TYPE.ADULTS:
        return adultCount;
      case GUEST_TYPE.CHILDREN:
        return childCount;
      default:
        return adultCount + childCount;
    }
  } else {
    // room or stay
    return 1;
  }
};

// Calculates the total for a service, based on type and number of nights
export const calculateServicePrice = (price, pricingUnit, numberOfNights, count) => {
  const basePrice = price.amount;

  if (pricingUnit === PRICING_UNIT.STAY) {
    // Stay based services (eg ev charging) gives total amount - not nightly
    return basePrice * count;
  } else {
    return basePrice * numberOfNights * count;
  }
};
