import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import ActionSheet from "components/ActionSheet";
import StayDatesPicker from "components/StayDatesPicker";

const useStyles = makeStyles((theme) => ({
  calendar: {
    margin: "30px 25px",
  },
}));

const StayDatesActionSheet = (props) => {
  const classes = useStyles();

  const [checkInDate, setCheckInDate] = useState(props.checkInDate);
  const [checkOutDate, setCheckOutDate] = useState(props.checkOutDate);

  const onCancel = () => {
    // Reset action sheet state to match the caller
    setCheckInDate(props.checkInDate);
    setCheckOutDate(props.checkOutDate);

    props.onCancel();
  };

  const onOK = () => {
    // Make sure we always have a check out date
    const endDate = checkOutDate || moment(checkInDate).add(1, "day").toDate();

    // Pass back the new dates to the caller
    props.onOK(checkInDate, endDate);
  };

  return (
    <ActionSheet title="Select Dates" open={props.open} onCancel={onCancel} onOK={onOK}>
      <div className={classes.calendar}>
        <StayDatesPicker
          checkInDate={checkInDate}
          checkOutDate={checkOutDate}
          checkInDateUpdated={setCheckInDate}
          checkOutDateUpdated={setCheckOutDate}
          priceForDate={props.priceForDate}
          isDateRestricted={props.isDateRestricted}
          onMonthChanged={props.onMonthChanged}
          allowDatesFrom={props.allowDatesFrom}
        />
      </div>
    </ActionSheet>
  );
};

StayDatesActionSheet.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onOK: PropTypes.func,
  checkInDate: PropTypes.instanceOf(Date),
  checkOutDate: PropTypes.instanceOf(Date),
  priceForDate: PropTypes.func,
  isDateRestricted: PropTypes.func,
  onMonthChanged: PropTypes.func,
  allowDatesFrom: PropTypes.instanceOf(moment),
};

export default StayDatesActionSheet;
