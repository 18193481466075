import moment from "moment";

import {
  BREAKFAST_START_TIME,
  BREAKFAST_END_TIME,
  DINNER_START_TIME,
  DINNER_END_TIME,
} from "utils/constants";

// Gets breakfast/dinner times. shiftCategory is either BREAKFAST or DINNER
const getPeriod = (shiftCategory, availabilities) => {
  // Get (unique) times across all shifts
  const allTimes = availabilities.reduce((acc, curr) => acc.concat(curr.times || []), []);

  // Now filter the times for this meal period based on hardcoded meal-periods provided by mollies
  let mealPeriodStartTime;
  let mealPeriodEndTime;

  switch (shiftCategory) {
    case "BREAKFAST":
      mealPeriodStartTime = moment(BREAKFAST_START_TIME, "HH:mm");
      mealPeriodEndTime = moment(BREAKFAST_END_TIME, "HH:mm");
      break;
    case "DINNER":
      mealPeriodStartTime = moment(DINNER_START_TIME, "HH:mm");
      mealPeriodEndTime = moment(DINNER_END_TIME, "HH:mm");
      break;
    default:
      return [];
  }

  const periodTimes = allTimes.filter((t) =>
    // [] indicates inclusive both sides
    moment(t.time, "HH:mm").isBetween(mealPeriodStartTime, mealPeriodEndTime, undefined, "[]")
  );

  // Use a set to get unique times
  return Array.from(
    new Set(
      periodTimes.map((t) => {
        return { time: t.time_iso };
      })
    )
  );
};

export const transformAPIAvailabilities = (availabilities) => {
  return availabilities.map((a) => {
    return {
      date: a.date,
      breakfastTimes: getPeriod("BREAKFAST", a.availability),
      dinnerTimes: getPeriod("DINNER", a.availability),
    };
  });
};
