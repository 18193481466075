import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { ReportProblemOutlined, ErrorOutlineOutlined } from "@material-ui/icons";

const Notice = (props) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: theme.shape.borderRadius,
    },
    containerInfo: {
      backgroundColor: theme.palette.info.lightBg,
      borderColor: theme.palette.info.light,
      color: theme.palette.info.darkText,
    },
    containerWarning: {
      backgroundColor: theme.palette.warning.lightBg,
      borderColor: theme.palette.warning.dark,
      color: theme.palette.warning.darkText,
    },
    containerError: {
      backgroundColor: theme.palette.error.lightBg,
      borderColor: theme.palette.error.dark,
      color: theme.palette.error.darkText,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  let containerStyle = null;
  let Icon = null;
  switch (props.style) {
    case "info":
      containerStyle = classes.containerInfo;
      break;
    case "warning":
      containerStyle = classes.containerWarning;
      Icon = ReportProblemOutlined;
      break;
    case "error":
      containerStyle = classes.containerError;
      Icon = ErrorOutlineOutlined;
      break;
  }

  return (
    <div className={classNames(props.className, classes.container, containerStyle)}>
      {Icon && <Icon className={classes.icon} />}
      <Typography variant="body1">{props.message}</Typography>
    </div>
  );
};

Notice.defaultProps = {
  style: "info",
};

Notice.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOf(["info", "warning", "error"]),
  message: PropTypes.string,
};

export default Notice;
