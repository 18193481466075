import { createSelector } from "reselect";

const getSelectedPropertyId = (state) => state.property.selectedPropertyId;
const getProperties = (state) => state.property.properties;

export const getSelectedProperty = createSelector(
  [getSelectedPropertyId, getProperties],
  (selectedPropertyId, properties) => {
    return properties.find((p) => p.code === selectedPropertyId) || {};
  }
);
