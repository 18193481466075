import React, { useEffect, useState, useRef, useContext } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Button, Typography, Link } from "@material-ui/core";
import { getCookieConsent, setCookieConsent } from "utils/consentCookie";

import { BookingSourceContext } from "contexts/BookingSourceContext";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    padding: "1.5em 1em",
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.primary.contrastText,
  },
  agreeButton: {
    marginTop: "20px",
  },
  spacer: {
    height: "200px",
  },
}));

const CookieConsent = (props) => {
  const classes = useStyles();

  // Keep reference to the banner so we can add a spacer on the bottom of the page
  const bannerRef = useRef(null);

  // The cookie isnt immediately available on prod so use state to track visibiity
  const [showBanner, setShowBanner] = useState(!getCookieConsent());
  const [bannerHeight, setBannerHeight] = useState(0);

  const onAgree = (e) => {
    setCookieConsent(true);
    setShowBanner(false);

    if (props.onAgree) {
      props.onAgree();
    }
  };

  useEffect(() => {
    if (bannerRef.current) {
      setBannerHeight(bannerRef.current.clientHeight);
    }
  }, [bannerRef, bannerHeight]);

  const { bookingSource } = useContext(BookingSourceContext);

  return showBanner && bookingSource.showCookieConsent ? (
    <>
      <Grid className={classes.container} ref={bannerRef}>
        <Typography variant="body2">
          Our website uses cookies to improve site functionality. Detailed information on our use of
          cookies is provided in our{" "}
          <Link href="https://www.molliesmotel.com/cookie-policy/" variant="body2" target="_blank">
            Cookie Policy
          </Link>
        </Typography>

        <Typography variant="body2">
          By continuing to use our website or clicking on any link, you are agreeing to our use of
          cookies.
        </Typography>

        <Button
          variant="contained"
          type="submit"
          color="primary"
          className={classes.agreeButton}
          onClick={onAgree}>
          I Understand
        </Button>
      </Grid>
      <Box height={bannerHeight}></Box>
    </>
  ) : null;
};

CookieConsent.propTypes = {
  onAgree: PropTypes.func,
};

export default CookieConsent;
