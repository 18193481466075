import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Routes from "config/routes";
import {
  Typography,
  Grid,
  Button,
  MenuItem,
  Select,
  TextField,
  makeStyles,
  FormControl,
  InputLabel,
  Box,
  FormHelperText,
} from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/Person";

import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

import { setSearchedBooking } from "features/booking/bookingSlice";
import api from "utils/api";
import { validatePresence } from "utils/validations";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "415px",
    maxWidth: "90%",
    margin: "auto",
    padding: "2rem 1.6rem",
  },
  fieldIcon: {
    marginRight: "16px",
    color: theme.palette.primary.main,
  },
  inputContainer: {
    marginTop: "5px",
  },
  input: {
    flexGrow: 2,
  },
  hidden: {
    display: "none",
  },
}));

const FindBookingContent = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [propertyId, setPropertyId] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [lastName, setLastName] = useState("");

  const [propertyIdError, setPropertyIdError] = useState(null);
  const [reservationIdError, setReservationIdError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);

  const properties = useSelector((state) => state.property.properties);

  const validateForm = () => {
    const propertyIdErrors = validatePresence(propertyId);
    setPropertyIdError(propertyIdErrors);

    const reservationIdErrors = validatePresence(reservationId);
    setReservationIdError(reservationIdErrors);

    const lastNameErrors = validatePresence(lastName);
    setLastNameError(lastNameErrors);

    return !(propertyIdErrors || reservationIdErrors || lastNameErrors);
  };

  const handlePropertyIdChange = (e) => {
    setPropertyId(e.target.value);
  };

  const handleReservationIdChange = (e) => {
    setReservationId(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const params = { propertyId, lastName, reservationId };

    setLoading(true);

    return api
      .getBookingFromReservation(params)
      .then((response) => {
        dispatch(setSearchedBooking(response.data));

        setLoading(false);

        history.push(Routes.MANAGE_BOOKING);
      })
      .catch((error) => {
        console.error(`Failed to fetch booking: ${error}`);

        setLoading(false);

        if (error.response && error.response.status === 404) {
          props.onNotifOpen("Booking not found", { variant: "error" });
        } else {
          props.onNotifOpen(error.message, { variant: "error" });
        }
        return null;
      });
  };

  return (
    <>
      <Box my={2} width={1}>
        <Typography variant="h6" color="textPrimary" align="center">
          Manage booking
        </Typography>
      </Box>
      <Box mb={2} width={1}>
        <Typography variant="body2" color="textSecondary" align="center">
          To access your booking, please enter your details below
        </Typography>
      </Box>

      <LoadingSpinner loading={loading} />
      <div className={loading ? classes.hidden : null}>
        <Grid container alignItems="center" className={classes.inputContainer}>
          <LocationOnIcon className={classes.fieldIcon} />
          <FormControl className={classes.input} error={!!propertyIdError}>
            <InputLabel>Location</InputLabel>
            <Select name="propertyId" value={propertyId} onChange={handlePropertyIdChange}>
              {properties.map((property) => (
                <MenuItem key={property.code} value={property.code}>
                  {property.name}
                </MenuItem>
              ))}
            </Select>
            {propertyIdError && <FormHelperText>{propertyIdError}</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid container alignItems="center" className={classes.inputContainer}>
          <DateRangeIcon className={classes.fieldIcon} />
          <TextField
            className={classes.input}
            label="Reservation number"
            type="text"
            name="reservationId"
            value={reservationId}
            onChange={handleReservationIdChange}
            error={!!reservationIdError}
            helperText={reservationIdError}
          />
        </Grid>

        <Grid container alignItems="center" className={classes.inputContainer}>
          <PersonIcon className={classes.fieldIcon} />
          <TextField
            label="Last name"
            type="text"
            name="lastName"
            autoComplete="family-name"
            className={classes.input}
            value={lastName}
            onChange={handleLastNameChange}
            error={!!lastNameError}
            helperText={lastNameError}
          />
        </Grid>

        <Box mt={4}>
          <Grid container justifyContent="center">
            {props.onCancel && (
              <Box mr={2}>
                <Button variant="outlined" size="large" onClick={props.onCancel} color="primary">
                  Cancel
                </Button>
              </Box>
            )}
            <Button variant="contained" size="large" onClick={handleSubmit} color="primary">
              Submit
            </Button>
          </Grid>
        </Box>
      </div>
    </>
  );
};

FindBookingContent.propTypes = {
  onNotifOpen: PropTypes.func,
  onCancel: PropTypes.func, // If set, cancel button shows
};

export default FindBookingContent;
