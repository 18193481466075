import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { Box, IconButton, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import PaginationDots from "./PaginationDots";

const useStyles = makeStyles((theme) => ({
  carouselAndControlsContainer: {
    position: "relative",
    "&:hover $carouselButtons": {
      opacity: 1,
      transition: "opacity 0.3s ease",
    },
  },

  carouselContainer: {
    margin: "0 auto",
    aspectRatio: 16 / 9,
    display: "flex",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    overflowY: "hidden",
    scrollSnapAlign: "center",
    scrollSnapType: "x mandatory",
    scrollBehavior: "smooth",
  },
  squareImage: {
    aspectRatio: 1 / 1,
  },
  carouselItem: {
    height: "100%",
    flex: "0 0 100%",
    scrollSnapAlign: "center",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
  carouselButtons: {
    display: "flex",
    height: 0,
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    position: "absolute",
    left: "50%",
    top: "50%",
    opacity: 0,
    transform: "translate(-50%, -50%)",
    padding: "0",
    transition: "opacity 0.3s ease",
  },
  carouselButtonLeft: {
    color: "#FFFF",
    padding: "5px",
    height: "35px",
    width: "35px",
    marginRight: "auto",
    maxHeight: "35px",
    maxWidth: "35px",
    borderRadius: "100000px",
    backgroundColor: theme.palette.other.backdrop,
    "&:hover": {
      backgroundColor: theme.palette.other.backdrop,
      boxShadow: "none",
    },
  },
  carouselButtonRight: {
    color: "#FFFF",
    padding: "5px",
    height: "35px",
    width: "35px",
    marginLeft: "auto",
    maxHeight: "35px",
    maxWidth: "35px",
    borderRadius: "100000px",
    backgroundColor: theme.palette.other.backdrop,
    "&:hover": {
      backgroundColor: theme.palette.other.backdrop,
      boxShadow: "none",
    },
  },
  paginationDotsContainer: {
    width: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    marginBottom: "20px",
  },
}));

const ImageCarousel = ({
  images = [],
  enableAutoScroll = false,
  isHeaderCarousel = false,
  hasExternalButtons = false,
}) => {
  const [goingToActiveSlide, setGoingToActiveSlide] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);

  const [cancelAutoScroll, setCancelAutoScroll] = useState(!enableAutoScroll);
  const classes = useStyles();
  const theme = useTheme();
  const isSquareImage = useMediaQuery(theme.breakpoints.up("desktop"));
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));

  const wrapperRef = useRef(null);

  const shouldHideButton = activeSlide === images.length - 1;

  const handleButtonClickScroll = (val) => {
    const nextActiveSlide = Math.max(Math.min(goingToActiveSlide + val, images.length - 1), 0);
    setGoingToActiveSlide(nextActiveSlide);
    scrollCarouselTo(nextActiveSlide);
  };

  const handleScroll = (evt) => {
    const { width } = wrapperRef.current.getBoundingClientRect();
    const { scrollLeft } = wrapperRef.current;
    setActiveSlide(Math.round(scrollLeft / width));
    setGoingToActiveSlide(Math.round(scrollLeft / width));
  };

  const scrollCarouselTo = (slideCounter) => {
    const { width } = wrapperRef.current.getBoundingClientRect();
    return wrapperRef.current.scrollTo({ left: width * slideCounter, behavior: "smooth" });
  };

  const handleButtonClick = (val) => {
    setCancelAutoScroll(true);
    handleButtonClickScroll(val);
  };

  useEffect(() => {
    if (cancelAutoScroll) {
      return;
    }
    let scrollInterval = setTimeout(() => {
      handleButtonClickScroll(1);
    }, 4000);
    return () => {
      clearTimeout(scrollInterval);
    };
  });

  return (
    <Box
      className={classes.carouselAndControlsContainer}
      style={{ height: isHeaderCarousel ? "100%" : "" }}>
      <Box
        ref={wrapperRef}
        onScroll={debounce(handleScroll, 150)}
        className={`${classes.carouselContainer} ${
          isSquareImage && !isHeaderCarousel && classes.squareImage
        }`}>
        {images.map(({ id, url }, index) => (
          <Box key={id} className={classes.carouselItem}>
            <img className={classes.img} alt="room" src={url} />
          </Box>
        ))}
      </Box>

      <Box
        className={classes.carouselButtons}
        style={{
          width: hasExternalButtons && tabletUpScreen ? "120%" : "90%",
        }}>
        {activeSlide > 0 && (
          <IconButton
            className={classes.carouselButtonLeft}
            style={
              hasExternalButtons
                ? { height: "50px", width: "50px", maxHeight: "50px", maxWidth: "50px" }
                : {}
            }
            onClick={() => handleButtonClick(-1)}>
            <ChevronLeftIcon />
          </IconButton>
        )}

        {!shouldHideButton && (
          <IconButton
            className={classes.carouselButtonRight}
            style={
              hasExternalButtons
                ? { height: "50px", width: "50px", maxHeight: "50px", maxWidth: "50px" }
                : {}
            }
            onClick={() => handleButtonClick(1)}>
            <ChevronRightIcon />
          </IconButton>
        )}
      </Box>
      {images.length > 1 && (
        <Box className={classes.paginationDotsContainer}>
          <PaginationDots
            slideIndex={activeSlide}
            slides={images}
            size={8}
            handleButtonClick={handleButtonClick}
          />
        </Box>
      )}
    </Box>
  );
};

ImageCarousel.propTypes = {
  images: PropTypes.array,
  enableAutoScroll: PropTypes.bool,
  isHeaderCarousel: PropTypes.bool,
  hasExternalButtons: PropTypes.bool,
  hasExternalPagination: PropTypes.bool,
};

export default ImageCarousel;
