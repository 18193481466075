import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper, Box } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import RoomSummaryCard from "components/RoomSummaryCard";
import { calculateBookingTotal, calculateBookingTotalVat } from "utils/bookingTotals";
import { formatCurrency, guestCountForReservations } from "utils/helpers";
import { formatDateShort } from "utils/timekeep";

const useStyles = makeStyles((theme) => ({
  bookingSummaryCard: {
    width: "100%",
    marginBottom: "30px",
    maxWidth: "700px",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  editAvailBtn: {
    height: "40px",
  },
  datesOccupancyRow: {
    borderTop: `1px solid ${theme.palette.other.lineDetail}`,
    borderBottom: `1px solid ${theme.palette.other.lineDetail}`,
    height: "max-content",
    padding: `${theme.spacing(2)}px 0`,
    margin: `${theme.spacing(2)}px 0`,
  },
  reservationContainer: {
    borderBottom: "1px solid #CA9B9E",
  },
  summaryRow: {
    justifyContent: "space-between",
  },
  checkDates: {
    height: "max-content",
    width: "100%",
    [theme.breakpoints.up("desktop")]: { width: "65%" },
  },
  guests: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "1rem",
    [theme.breakpoints.up("desktop")]: { margin: "0" },
  },
}));

const BookingReservationsCard = (props) => {
  const classes = useStyles();
  const [checkInDate, setCheckInDate] = useState();
  const [checkOutDate, setCheckOutDate] = useState();
  const properties = useSelector((state) => state.property.properties);
  const [property, setProperty] = useState({});
  const bookingTotal = calculateBookingTotal(props.bookingReservations);
  const bookingTotalVat = calculateBookingTotalVat(props.bookingReservations);

  const isoDateToStr = (isoDate) => {
    if (!isoDate) return "";
    return isoDate.split("T")[0];
  };

  const totalGuests = () => {
    const reservations = props.bookingReservations;
    return guestCountForReservations(reservations);
  };

  useEffect(() => {
    setCheckInDate(isoDateToStr(props.bookingReservations[0]?.arrival));
    setCheckOutDate(isoDateToStr(props.bookingReservations[0]?.departure));
  }, [props.bookingReservations]);

  useEffect((propertyId) => {
    setProperty(
      properties.find((property) => property.code === props.bookingReservations[0].property.id)
    );
  }, []);

  return (
    <Paper className={classes.bookingSummaryCard}>
      <Grid container className={classes.bookingSummary}>
        <Box>
          <Typography variant="h5" color="textPrimary">
            {property.name}
          </Typography>
          {property.contact_info && property.contact_info.address ? (
            <Typography variant="body2" color="textPrimary">
              {property.contact_info.address.address_line_1},{" "}
              {property.contact_info.address.address_line_2}, {property.contact_info.address.city}{" "}
              {property.contact_info.address.postal_code}
            </Typography>
          ) : null}
        </Box>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.datesOccupancyRow}>
          <Grid item className={classes.checkDates}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  Check in
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {formatDateShort(checkInDate, true)}
                </Typography>
              </Grid>
              <NavigateNextIcon fontSize="large" color="action" style={{ height: "100%" }} />
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  Check out
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {formatDateShort(checkOutDate, true)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.guests}>
            <Typography variant="body2" color="textSecondary" align="center">
              Guests
            </Typography>
            <Typography variant="h6" color="textPrimary" align="center">
              {totalGuests()}
            </Typography>
          </Grid>
        </Grid>
        {props.bookingReservations.map((reservation, index) =>
          reservation ? (
            <RoomSummaryCard
              key={index}
              reservation={reservation}
              showCancellation={true}
              onReservationCancelled={props.onReservationCancelled}
              onNotifOpen={props.onNotifOpen}
            />
          ) : null
        )}

        <Box mt={2} width={1}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid>
                <Typography variant="h5" color="textPrimary">
                  Total
                </Typography>
              </Grid>
              {bookingTotalVat > 0 ? (
                <Grid>
                  <Typography variant="body2" color="textSecondary">
                    Including VAT of {formatCurrency("GBP", bookingTotalVat)}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            <Typography variant="h4">{formatCurrency("GBP", bookingTotal)}</Typography>
          </Grid>
        </Box>
      </Grid>
    </Paper>
  );
};

BookingReservationsCard.propTypes = {
  bookingReservations: PropTypes.arrayOf(PropTypes.object),
  onReservationCancelled: PropTypes.func,
  onNotifOpen: PropTypes.func,
};

export default BookingReservationsCard;
