import flow from "lodash/fp/flow";
import map from "lodash/fp/map";
import set from "lodash/fp/set";
import find from "lodash/find";

import { facilityType } from "./facilityServicesConstants";
import { omit } from "lodash";

export const initialEnquiryContactDetails = {
  firstName: { value: "", isRequired: true },
  lastName: { value: "", isRequired: true },
  email: { value: "", isRequired: true },
  phoneNumber: { value: "", isRequired: true },
  subscribeOffers: { value: false },
};

export const initialAdditionalRequirements = {
  accommodation: { value: false, label: "Accommodation", type: "checkbox" },
  cateringOrDinerReservation: {
    value: false,
    label: "Catering or diner reservation",
    type: "checkbox",
  },
  specialRequests: {
    value: "",
    label: "Special Requests?",
    type: "textField",
  },
};

const eventSpaceFormFields = {
  nameOfEvent: {
    name: "nameOfEvent",
    label: "Event Name",
    isRequired: true,
    type: "textField",
    value: "",
  },
  companyName: {
    name: "companyName",
    label: "Company name",
    isRequired: false,
    type: "textField",
    value: "",
  },
  typeOfRoom: {
    name: "typeOfRoom",
    label: "Space(s) most interested in?",
    isRequired: false,
    type: "dropDown",
    inputProps: {
      options: [
        {
          value: "",
          label: "",
        },
      ],
    },
    value: "",
  },
  numberOfPeople: {
    name: "numberOfPeople",
    label: "Number of attendees",
    isRequired: true,
    type: "number",
    inputProps: {
      min: "1",
      max: "12",
    },
    value: "",
  },
  arrivalDate: {
    name: "arrivalDate",
    label: "Preferred date",
    isRequired: true,
    type: "date",
    inputLabelProps: {
      shrink: true,
    },
  },
  arrivalTime: {
    name: "arrivalTime",
    label: "Preferred time",
    isRequired: true,
    type: "time",
    inputLabelProps: {
      shrink: true,
    },
  },
  isDateFlexible: {
    name: "isDateFlexible",
    label: "Are dates flexible?",
    isRequired: false,
    type: "dropDown",
    inputProps: {
      options: [
        {
          value: "yes",
          label: "Yes",
        },
        {
          value: "no",
          label: "No",
        },
      ],
    },
    value: "",
  },
  budget: {
    name: "budget",
    label: "What is your budget? (£)",
    isRequired: false,
    type: "number",
    inputProps: {
      min: "0",
    },
    value: "",
  },
};
const meetingRoomFormFields = {
  nameOfEvent: {
    name: "nameOfEvent",
    label: "Event Name",
    isRequired: true,
    type: "textField",
    value: "",
  },
  companyName: {
    name: "companyName",
    label: "Company name",
    isRequired: false,
    type: "textField",
    value: "",
  },
  typeOfRoom: {
    name: "typeOfRoom",
    label: "Space(s) most interested in?",
    isRequired: false,
    type: "dropDown",
    inputProps: {
      options: [
        {
          value: "",
          label: "",
        },
      ],
    },
    value: "",
  },
  numberOfPeople: {
    name: "numberOfPeople",
    label: "Number of attendees",
    isRequired: true,
    type: "number",
    inputProps: {
      min: "1",
      max: "12",
    },
    value: "",
  },
  arrivalDate: {
    name: "arrivalDate",
    label: "Preferred date",
    isRequired: true,
    type: "date",
    inputLabelProps: {
      shrink: true,
    },
    value: "",
  },
  arrivalTime: {
    name: "arrivalTime",
    label: "Preferred time",
    isRequired: true,
    type: "time",
    inputLabelProps: {
      shrink: true,
    },
    value: "",
  },
  isDateFlexible: {
    name: "isDateFlexible",
    label: "Are dates flexible?",
    isRequired: false,
    type: "dropDown",
    inputProps: {
      options: [
        {
          value: "yes",
          label: "Yes",
        },
        {
          value: "no",
          label: "No",
        },
      ],
    },
    value: "",
  },
  budget: {
    name: "budget",
    label: "What is your budget? (£)",
    isRequired: false,
    type: "number",
    inputProps: {
      min: "0",
    },
    value: "",
  },
};
const privateDiningFormFields = {
  nameOfEvent: {
    name: "nameOfEvent",
    label: "Event Name",
    isRequired: true,
    type: "textField",
    value: "",
  },
  companyName: {
    name: "companyName",
    label: "Company name",
    isRequired: false,
    type: "textField",
    value: "",
  },
  typeOfRoom: {
    name: "typeOfRoom",
    label: "Space(s) most interested in?",
    isRequired: false,
    type: "dropDown",
    inputProps: {
      options: [
        {
          value: "",
          label: "",
        },
      ],
    },
    value: "",
  },
  numberOfPeople: {
    name: "numberOfPeople",
    label: "Number of attendees",
    isRequired: true,
    type: "number",
    inputProps: {
      min: "1",
      max: "12",
    },
    value: "",
  },
  arrivalDate: {
    name: "arrivalDate",
    label: "Preferred date",
    isRequired: true,
    type: "date",
    inputLabelProps: {
      shrink: true,
    },
    value: "",
  },
  arrivalTime: {
    name: "arrivalTime",
    label: "Preferred time",
    isRequired: true,
    type: "time",
    inputLabelProps: {
      shrink: true,
    },
    value: "",
  },
  isDateFlexible: {
    name: "isDateFlexible",
    label: "Are dates flexible?",
    isRequired: false,
    type: "dropDown",
    inputProps: {
      options: [
        {
          value: "yes",
          label: "Yes",
        },
        {
          value: "no",
          label: "No",
        },
      ],
    },
    value: "",
  },
  budget: {
    name: "budget",
    label: "What is your budget? (£)",
    isRequired: false,
    type: "number",
    inputProps: {
      min: "0",
    },
    value: "",
  },
};

function findEventFormFields(selectedFacilityType) {
  if (!selectedFacilityType) return;

  switch (selectedFacilityType) {
    case facilityType.EVENT_SPACES:
      return eventSpaceFormFields;

    case facilityType.MEETING_ROOMS:
      return meetingRoomFormFields;

    case facilityType.PRIVATE_DINING:
      return privateDiningFormFields;

    default:
      console.error("Facility type form fields not found");
  }
}

export const getAdditionalRequirementsFields = (selectedFacilityType) => {
  if (!selectedFacilityType) return;

  switch (selectedFacilityType) {
    case facilityType.EVENT_SPACES:
    case facilityType.MEETING_ROOMS:
      return initialAdditionalRequirements;

    case facilityType.PRIVATE_DINING:
      return omit(initialAdditionalRequirements, ["cateringOrDinerReservation"]);

    default:
      console.error("Facility type form fields not found");
  }
};

const setRoomOptions = (formFields, facilityServices) => {
  const optionsPath = "typeOfRoom.inputProps.options";
  const updatedOptions = map((room) => ({
    value: room.title,
    label: room.title,
  }))(facilityServices);

  return set(optionsPath, updatedOptions, formFields);
};

const setSelectedRoom = (formFields, facilityServices, roomId) => {
  if (roomId) {
    const selectedRoom = find(facilityServices, { id: roomId });
    const optionsPath = "typeOfRoom.value";
    return set(optionsPath, selectedRoom.title, formFields);
  } else {
    return formFields;
  }
};

const getEventFormFields = ({ facilityType, facilityServices, selectedRoomId = null }) =>
  flow(
    findEventFormFields,
    // Set typeOfRoom options with facilityServices
    (formFields) => setRoomOptions(formFields, facilityServices),
    // Update typeOfRoom value to the selectedRoom the user is enquiring about
    (formFields) => setSelectedRoom(formFields, facilityServices, selectedRoomId)
  )(facilityType);

export default getEventFormFields;
