import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "utils/api";
import { setAnalyticsUserId } from "utils/analytics";

export const fetchProfile = createAsyncThunk("user/fetchProfile", async (firebaseToken) => {
  try {
    const respose = await api.getProfile(firebaseToken);
    const profileData = respose.data;
    return profileData;
  } catch (e) {
    console.error(e);
  }
});

const initialState = {
  authUser: null,
  firebaseToken: null,
  profile: {
    apaleoReservations: [],
  },
  mobileAppUser: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signInUser: (state, action) => {
      state.authUser = action.payload;

      setAnalyticsUserId(action.payload.id);
    },
    signOutUser: () => initialState,
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setFirebaseToken: (state, action) => {
      state.firebaseToken = action.payload;
    },
    removeReservationFromProfile: (state, action) => {
      const reservationToRemove = action.payload;
      const profileDeepClone = JSON.parse(JSON.stringify(state.profile));
      profileDeepClone.apaleoReservations = profileDeepClone.apaleoReservations.filter(
        (reservation) => {
          return reservation.id !== reservationToRemove.id;
        }
      );
      state.profile = profileDeepClone;
    },
    setMobileAppUser: (state, action) => {
      state.mobileAppUser = action.payload;
    },
  },
  extraReducers: {
    [fetchProfile.fulfilled]: (state, action) => {
      state.profile = action.payload;
    },
  },
});

export const {
  signInUser,
  signOutUser,
  setProfile,
  setFirebaseToken,
  removeReservationFromProfile,
  setMobileAppUser,
} = userSlice.actions;
export default userSlice.reducer;
