import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Typography, makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textButton: {
    display: "block",
    "&:hover": { backgroundColor: "inherit" },
    "&:active": { style: "none" },
  },
}));

const shortenText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  let trimmedText = text.substring(0, maxLength);

  // Remove the trailing word if it was cut off.
  trimmedText =
    trimmedText.substring(0, Math.min(trimmedText.length, trimmedText.lastIndexOf(" "))) + "...";

  return trimmedText;
};

const ReadMoreTextBox = ({ content = "", sliceLength }) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullText = content;
  const [readMore, setReadMore] = useState(true);
  const hasReadMore = fullText.length > sliceLength;

  return (
    <Typography variant="body1">
      {readMore ? shortenText(fullText, sliceLength) : fullText}
      {hasReadMore && (
        <Button
          variant="text"
          color="primary"
          disableRipple
          onClick={() => setReadMore(!readMore)}
          className={classes.textButton}
          style={{
            paddingLeft: 0,
            marginTop: theme.spacing(2),
          }}>
          {readMore ? "Read More" : "Read Less"}
        </Button>
      )}
    </Typography>
  );
};

ReadMoreTextBox.propTypes = {
  content: PropTypes.string,
  sliceLength: PropTypes.number,
};

export default ReadMoreTextBox;
