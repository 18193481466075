import Cookies from "js-cookie";

const COOKIE_NAME = "ConsentCookie";

export const getCookieConsent = () => {
  return Cookies.get(COOKIE_NAME) === "true";
};

export const setCookieConsent = (allow) => {
  Cookies.set(COOKIE_NAME, allow, {
    expires: 183, // 6 months
    path: "/",
    secure: window.location ? window.location.protocol === "https:" : true,
    sameSite: "lax",
  });
};
