import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";

import EmailAuth from "components/EmailAuth";
import EmailIcon from "@material-ui/icons/Email";
import AppleIcon from "@material-ui/icons/Apple";
import googleIcon from "images/google_icon.svg";
import { setProfile, signInUser, setFirebaseToken } from "features/user/userSlice";
import api from "utils/api";
import { performGoogleSignIn, performAppleSignIn } from "utils/firebase/auth/social";
import { serializeFirebaseUser, sleep } from "utils/helpers";

const AuthCTA = (props) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.user.authUser);
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      padding: signInModalOpen ? "0" : "10px",
      [theme.breakpoints.up("tablet")]: {
        padding: "30px 45px",
      },
    },
    signInOptionLabel: {
      ...theme.typography.button,
    },
    button: {
      // Special styling for sign in buttons
      ...theme.typography.button,
      background: theme.palette.grey[900],
      color: "white",
      margin: "0.5rem 0",
      padding: "8px 18px",

      "&:hover": {
        background: theme.palette.primary.main,
        boxShadow: "none",
      },
    },
    buttonIcon: {
      width: "22px",
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const toggleSignInModal = () => {
    setSignInModalOpen(!signInModalOpen);
  };

  const toggleSignUpModal = () => {
    setSignUpModalOpen(!signUpModalOpen);
  };

  const fetchProfile = async (firebaseToken) => {
    try {
      const response = await api.getProfile(firebaseToken);
      dispatch(setProfile(response.data));
    } catch (e) {
      console.error(e);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const { firebaseToken, authUser } = await performGoogleSignIn();
      await processSocialSignInResponse(firebaseToken, authUser);
    } catch (e) {
      console.error(e);
    }
  };

  const signInWithApple = async () => {
    try {
      const { firebaseToken, authUser } = await performAppleSignIn();
      await processSocialSignInResponse(firebaseToken, authUser);
    } catch (e) {
      console.error(e);
    }
  };

  const processSocialSignInResponse = async (firebaseToken, authUser) => {
    if (authUser) {
      dispatch(signInUser(serializeFirebaseUser(authUser)));
    }

    if (firebaseToken) {
      dispatch(setFirebaseToken(firebaseToken));
      await sleep(500); // need to wait for Firebase to associate firebase token (gotten from exchanging OAuth token) to user
      await fetchProfile(firebaseToken);
    }
  };

  useEffect(() => {
    if (authUser && props.authCompleted) {
      props.authCompleted();
    }
  }, [authUser]);

  return (
    <div className={classes.root}>
      {signInModalOpen ? (
        <EmailAuth
          signIn
          open={signInModalOpen}
          onBack={toggleSignInModal}
          authUser={authUser}
          onNotifOpen={props.onNotifOpen}
        />
      ) : signUpModalOpen ? (
        <EmailAuth
          signUp
          open={signUpModalOpen}
          onBack={toggleSignUpModal}
          authUser={authUser}
          onNotifOpen={props.onNotifOpen}
        />
      ) : (
        <div>
          <Box width={1} mb={4}>
            <Typography
              variant={props.headingTextVariant || "h6"}
              color="textPrimary"
              align="center">
              {props.logIn ? props.loginText || "Log in" : "Sign up"}
            </Typography>
          </Box>
          <Grid container direction="column" justifyContent="center" wrap="nowrap">
            <Button
              variant="contained"
              size="small"
              onClick={props.logIn ? toggleSignInModal : toggleSignUpModal}
              className={classes.button}
              startIcon={<EmailIcon />}>
              {props.logIn ? "Login with email" : "Continue with email"}
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={signInWithGoogle}
              className={classes.button}>
              <img
                src={googleIcon}
                className={classes.buttonIcon}
                alt="Google Icon"
                style={{ width: "18px" }}
              />
              {props.logIn ? "Login with Google" : "Continue with Google"}
            </Button>
            <Button variant="outlined" onClick={signInWithApple} className={classes.button}>
              <AppleIcon className={classes.buttonIcon} />
              {props.logIn ? "Login with Apple" : "Continue with Apple"}
            </Button>
          </Grid>
        </div>
      )}
      {!props.onlySignIn ? (
        <Box mt={4}>
          <Grid container alignItems="baseline">
            <Typography variant="body2" color="textSecondary">
              {signUpModalOpen || props.signUp ? "Have an account?" : "Don't have an account?"}
            </Typography>
            <Button
              size="small"
              onClick={() => {
                setSignInModalOpen(signUpModalOpen || props.signUp);
                setSignUpModalOpen(!signUpModalOpen || props.signUp);
              }}>
              {signUpModalOpen || props.signUp ? "Log in" : "Sign up"}
            </Button>
          </Grid>
        </Box>
      ) : null}
    </div>
  );
};

AuthCTA.propTypes = {
  authCompleted: PropTypes.func,
  onNotifOpen: PropTypes.func,
  headingTextVariant: PropTypes.string,
  logIn: PropTypes.bool,
  signUp: PropTypes.bool,
  onlySignIn: PropTypes.bool,
  loginText: PropTypes.string,
};

export default AuthCTA;
