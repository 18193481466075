import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { formatCurrency, formatFaIcon, GUEST_TYPE, PRICING_UNIT } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: "flex",
    minHeight: "64px",
    flexDirection: "column",

    [theme.breakpoints.up(theme.breakpoints.values.sidebar)]: {
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "nowrap",
      justifyContent: "space-between",
    },
  },
  headerIcon: {
    fontSize: "24px",
    marginRight: theme.spacing(2),
  },
  titleContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  priceSummary: {
    flexShrink: 0,
    marginTop: 0,

    [theme.breakpoints.up(theme.breakpoints.values.sidebar)]: {
      marginLeft: theme.spacing(1),
    },
  },
  priceSummaryIcon: {
    [theme.breakpoints.down(theme.breakpoints.values.sidebar)]: {
      marginLeft: "40px",
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet));

  // If the addon contains adult pricing, we want to show that over child pricing (so its not misleading)
  // Otherwise we will show the lowest price across all services
  const calculateLowestPrice = (services) => {
    // Use the pricing unit from the first service. They should all be the same
    const pricingUnit = services[0]?.pricingUnit;

    if (pricingUnit === PRICING_UNIT.PERSON) {
      // If its a child-only service, we need to use all services to calculate
      // Otherwise, we'll just use the adult pricing

      const containsAdultPricing = services.some((s) => s.guestType === GUEST_TYPE.ADULTS);
      if (containsAdultPricing) {
        // If it contains adult pricing, filter out child prices for the purpose of lowest price
        return Math.min(
          ...services
            .filter((s) => s.guestType !== GUEST_TYPE.CHILDREN)
            .map((s) => s.price?.amount || 0)
        );
      }
    }

    // Fallback to showing lowest price across all services
    return Math.min(...services.map((s) => s.price?.amount || 0));
  };

  const priceSummary = () => {
    // If all services are the same price, show "only £xx"
    // Otherwise show "from £xx"
    // If £0, show "Free"

    // Doesnt consider currency (but mollies is just gbp so thats ok)
    const currency = props.services[0]?.price?.currency || "GBP";

    const lowestPrice = calculateLowestPrice(props.services);

    const allSamePrices = !!props.services.reduce(
      (prev, curr) => (curr.price?.amount === prev ? prev : null),
      props.services[0]?.price?.amount
    );

    if (!lowestPrice) {
      return null;
    } else if (lowestPrice?.amount == 0) {
      return <Typography variant={tabletUpScreen ? "subtitle1" : "subtitle2"}>Free</Typography>;
    } else {
      return (
        <Typography variant={tabletUpScreen ? "body1" : "body2"} color="textSecondary">
          {allSamePrices ? "only" : "from"}{" "}
          <Typography
            variant={tabletUpScreen ? "subtitle1" : "subtitle2"}
            component="span"
            color="textPrimary">
            {formatCurrency(currency || "GBP", lowestPrice)}
          </Typography>
        </Typography>
      );
    }
  };

  return (
    <Grid container className={classes.header}>
      <div className={classes.titleContainer}>
        {!!props.icon && (
          <>
            <FontAwesomeIcon className={classes.headerIcon} icon={formatFaIcon(props.icon)} />{" "}
          </>
        )}

        <Typography variant="h5">{props.title}</Typography>
      </div>

      <Grid className={classNames(classes.priceSummary, !!props.icon && classes.priceSummaryIcon)}>
        {priceSummary()}
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      pmsId: PropTypes.string.isRequired,
      name: PropTypes.string,
      price: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      isSelected: PropTypes.bool,
      count: PropTypes.number,
      defaultCount: PropTypes.number,
      maxCount: PropTypes.number,
    })
  ).isRequired,
};

export default Header;
