import React, { useState } from "react";
import PropTypes from "prop-types";

import { Typography, Grid, Button, TextField, makeStyles, Dialog, Box } from "@material-ui/core";
import DayHeader from "../DayHeader.js";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "500px",
    maxWidth: "90%",
    margin: "auto",
    padding: "2rem 1.6rem 0rem 1.6rem",
  },
  daySection: {
    marginBottom: theme.spacing(2),
  },
  mealPeriodNote: {
    flexDirection: "column",
    marginBottom: theme.spacing(1),
  },
  bottomContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: "2rem",
    position: "sticky",
    bottom: 0,
    backgroundColor: theme.palette.background.default,
  },
}));

const AddNoteModal = (props) => {
  const classes = useStyles();

  // Takes a copy of the selections and allows local modification
  const [selectionEdits, setSelectionEdits] = useState({});

  useState(() => {
    // Take a copy of the current selections for edits
    // Filter out any dates that have no selections
    const clone = JSON.parse(JSON.stringify(props.selections));
    setSelectionEdits(clone);
  }, []);

  const updateSelection = (date, mealPeriod, note) => {
    const thisSelections = { ...selectionEdits };
    thisSelections[date][mealPeriod]["note"] = note;
    setSelectionEdits(thisSelections);
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      PaperProps={{ elevation: 8, className: classes.dialogPaper }}>
      <Box mb={2} width={1}>
        <Typography variant="h4" color="textPrimary" align="center" gutterBottom>
          Add booking notes
        </Typography>

        <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
          Special occasion? Dietary restrictions?
          <br />
          Let us know
        </Typography>
      </Box>

      {/* Notes for each selections */}
      {Object.entries(selectionEdits).map(([date, mealPeriods], dayIndex) => (
        <div key={dayIndex} className={classes.daySection}>
          {Object.keys(mealPeriods).length > 0 && <DayHeader dateString={date} />}

          {Object.entries(mealPeriods).map(([mealPeriod, selection]) => (
            <Grid container key={`${dayIndex}-${mealPeriod}`} className={classes.mealPeriodNote}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ textTransform: "capitalize" }}
                gutterBottom>
                {mealPeriod} ({selection.time})
              </Typography>
              <TextField
                multiline
                variant="outlined"
                minRows={1}
                placeholder="Notes and special requests"
                value={selection.note}
                onChange={(e) => updateSelection(date, mealPeriod, e.target.value)}
              />
            </Grid>
          ))}
        </div>
      ))}

      <Box mt={4} className={classes.bottomContainer}>
        <Grid container justifyContent="center">
          <Box mr={2}>
            <Button variant="outlined" size="large" onClick={props.onClose} color="primary">
              Cancel
            </Button>
          </Box>
          <Button
            variant="contained"
            size="large"
            onClick={() => props.onSave(selectionEdits)}
            color="primary">
            Save
          </Button>
        </Grid>
      </Box>
    </Dialog>
  );
};

AddNoteModal.propTypes = {
  selections: PropTypes.object,
  open: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

export default AddNoteModal;
