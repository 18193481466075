import { getCookieConsent } from "utils/consentCookie";
import MetaPixel from "utils/MetaPixel";
import TiktokPixel from "tiktok-pixel";
import MicrosoftUet from "utils/MicrosoftUet";
import { EVPARKING_UNITGROUP_CODE } from "utils/constants";
import { calculateBookingTotal, calculateBookingTotalVat } from "utils/bookingTotals";

//--- GTM Code ---
const pushGTMDataCalls = {};

function pushGTMData(args) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(args);
}

function pushGTMDataThrottled(args = {}) {
  const key = JSON.stringify(args);

  const timeLastCalled = pushGTMDataCalls[key] || [0];

  if (Date.now() - timeLastCalled > 500) {
    pushGTMData(args);
    pushGTMDataCalls[key] = Date.now();
  }
}
//--- END ---

const initializeFacebookPixel = () => {
  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true,
    debug: false, // enable logs
  };
  MetaPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, advancedMatching, options);
};

const initializeTiktokPixel = () => {
  const advancedMatching = {};
  const options = {
    debug: false, // enable logs
  };

  TiktokPixel.init(process.env.REACT_APP_TIKTOK_PIXEL_ID, advancedMatching, options);
};

export const initializeAnalytics = () => {
  initializeFacebookPixel();
  initializeTiktokPixel();

  MicrosoftUet.init(process.env.REACT_APP_MICROSOFT_UET_ID, { debug: false });

  // Revoke consent if we havent asked the user yet
  // For ga, it needs to be done before init.
  // For fb pixel, it needs to be done after init, so put it between.
  if (!getCookieConsent()) {
    revokeCookieConsent();
  }
};

export const giveCookieConsent = () => {
  // Allow consent for google analytics and fb pixel id
  MetaPixel.grantConsent();

  // Tiktok library doesnt track consent - we'll rely on the cookie consent cookie

  // Firebase analytics
  delete window[`ga-disable-G-${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`];

  // Google analytics
  delete window[`ga-disable-${process.env.REACT_APP_GTM_ID}`];

  // Track the first page view in GA
  trackPageView(window.location.pathname);
};

export const revokeCookieConsent = () => {
  // Firebase analytics
  window[`ga-disable-G-${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`] = true;

  // Google analytics
  window[`ga-disable-${process.env.REACT_APP_GTM_ID}`] = true;
  MetaPixel.revokeConsent();

  // Tiktok library doesnt track consent - we'll rely on the cookie consent cookie
};

export const setAnalyticsUserId = (userId) => {
  if (!getCookieConsent()) {
    return;
  }
  pushGTMDataThrottled({
    user_id: userId,
  });
};

export const setAnalyticsSource = (source) => {
  if (!getCookieConsent()) {
    return;
  }

  pushGTMDataThrottled({
    source,
  });
};

// Standard GA / Facebook events

export const trackLoginEvent = (method) => {
  if (!getCookieConsent()) {
    return;
  }

  MetaPixel.track("Login", { method });

  // Tiktok doesnt have this event so ignore for now

  pushGTMDataThrottled({
    event: "login",
    method,
  });
};

export const trackSignUpEvent = (method) => {
  if (!getCookieConsent()) {
    return;
  }

  MetaPixel.track("CompleteRegistration", { method });

  TiktokPixel.track("CompleteRegistration", { method });

  pushGTMDataThrottled({
    event: "sign_up",
    method,
  });
};

const trackAddItemsToCart = (currency, total, cartItems) => {
  if (!getCookieConsent()) {
    return;
  }

  MetaPixel.track("AddToCart", {
    currency: currency,
    value: total,
    content_type: "product",
    contents: cartItems,
  });

  TiktokPixel.track("AddToCart", {
    currency: currency,
    value: total,
    content_type: "product",
    contents: cartItems,
  });

  // Track event to 'save' the ec data
  const itemParams = cartItems.map((cartItem) => ({
    item_name: cartItem.name,
    item_id: cartItem.id,
    price: cartItem.price,
    quantity: cartItem.quantity,
    item_category: cartItem?.category,
  }));

  pushGTMDataThrottled({
    event: "add_to_cart",
    items: itemParams,
  });
};

const trackRemoveItemsFromCart = (currency, total, cartItems) => {
  if (!getCookieConsent()) {
    return;
  }

  MetaPixel.track("RemoveFromCart", {
    currency: currency,
    value: total,
    content_type: "product",
    contents: cartItems,
  });

  // TikTok doesnt support removing from cart event

  // Track event to 'save' the ec data
  const itemParams = cartItems.map((cartItem) => ({
    item_name: cartItem.name,
    item_id: cartItem.id,
    price: cartItem.price,
    quantity: cartItem.quantity,
    item_category: cartItem?.category,
  }));

  pushGTMDataThrottled({
    event: "remove_from_cart",
    items: itemParams,
  });
};

export const trackAddReservationToCartEvent = (reservation) => {
  const currency = reservation.totalGrossAmount.currency;
  const total = calculateBookingTotal([reservation]);
  const cartItems = cartItemsFromReservation(reservation);

  trackAddItemsToCart(currency, total, cartItems);
};

export const trackRemoveReservationFromCartEvent = (reservation) => {
  const currency = reservation.totalGrossAmount.currency;
  const total = calculateBookingTotal([reservation]);
  const cartItems = cartItemsFromReservation(reservation);

  trackRemoveItemsFromCart(currency, total, cartItems);
};

export const trackAddServiceToCartEvent = (service, count) => {
  const currency = service.totalAmount.currency;
  const total = count * service.service.defaultGrossPrice.amount;
  const cartItems = [cartItemForService(service, count)];

  trackAddItemsToCart(currency, total, cartItems);
};

export const trackRemoveServiceFromCartEvent = (service, count) => {
  const currency = service.totalAmount.currency;
  const total = count * service.service.defaultGrossPrice.amount;
  const cartItems = [cartItemForService(service, count)];

  trackRemoveItemsFromCart(currency, total, cartItems);
};

export const trackBeginCheckoutEvent = (reservations, promoCode) => {
  if (!getCookieConsent()) {
    return;
  }

  const currency = reservations[0].totalGrossAmount.currency;
  const total = calculateBookingTotal(reservations);
  const cartItems = cartItemsFromReservations(reservations);

  MetaPixel.track("InitiateCheckout", {
    currency: currency,
    value: total,
    content_type: "product",
    contents: cartItems,
  });

  TiktokPixel.track("InitiateCheckout", {
    currency: currency,
    value: total,
    content_type: "product",
    contents: cartItems,
  });

  // Track non-interactive event to 'save' the ec data
  const itemParams = cartItems.map((cartItem) => ({
    item_name: cartItem.name,
    item_id: cartItem.id,
    price: cartItem.price,
    quantity: cartItem.quantity,
    item_category: cartItem?.category,
  }));

  pushGTMDataThrottled({
    event: "begin_checkout",
    currency,
    value: total,
    coupon: promoCode,
    items: itemParams,
  });
};

export const trackPurchaseEvent = (
  reservations,
  promoCode,
  propertyId,
  transactionId,
  paymentMethod
) => {
  if (!getCookieConsent()) {
    return;
  }

  const currency = reservations[0].totalGrossAmount.currency;
  const total = calculateBookingTotal(reservations);
  const cartItems = cartItemsFromReservations(reservations);

  MetaPixel.track(
    "Purchase",
    {
      currency: currency,
      value: total,
      content_type: "product",
      contents: cartItems,
    },
    {
      eventID: transactionId, // This matches the front-end meta pixel tracking for deduplication
    }
  );

  TiktokPixel.track("CompletePayment", {
    currency: currency,
    value: total,
    content_type: "product",
    contents: cartItems,
  });

  // Track non-interactive event to 'save' the ec data
  const itemParams = cartItems.map((cartItem) => ({
    item_name: cartItem.name,
    affiliation: propertyId,
    item_id: cartItem.id,
    price: cartItem.price,
    quantity: cartItem.quantity,
    item_category: cartItem?.category,
  }));

  pushGTMDataThrottled({
    event: "add_payment_info",
    currency,
    value: total,
    coupon: promoCode,
    items: itemParams,
    payment_type: paymentMethod,
  });

  pushGTMDataThrottled({
    event: "purchase",
    currency,
    value: total,
    coupon: promoCode,
    items: itemParams,
    payment_type: paymentMethod,
    transaction_id: transactionId,
    tax: calculateBookingTotalVat(reservations),
  });

  // Track the revenue value using the destination url conversion mechanism
  MicrosoftUet.trackEvent("", {
    revenue_value: total,
    currency: "GBP",
  });
};

export const trackRefundEvent = (reservation) => {
  if (!getCookieConsent()) {
    return;
  }

  pushGTMDataThrottled({
    event: "refund",
    transaction_id: reservation.id,
  });
};

export const trackError = (description, fatal) => {
  pushGTMDataThrottled({
    event: "error",
    description,
    fatal,
  });
};

export const trackPageView = (pageName) => {
  MetaPixel.pageView();

  TiktokPixel.pageView();

  // Microsoft analytics tracking - https://bingadsuet.azurewebsites.net/SPA_Index.html
  MicrosoftUet.pageView(pageName);

  pushGTMDataThrottled({
    event: "page_view",
    pageName,
  });
};

//
// Helpers
//

// Takes a reservation and returns an array of cart items for analytics
const cartItemsFromReservation = (reservation) => {
  let items = [];

  // Add the room
  items.push({
    id: reservation.unitGroup.id,
    content_type: "product",
    name: reservation.unitGroup.name,
    category: reservation.unitGroup.code === EVPARKING_UNITGROUP_CODE ? "Parking" : "Room",
    price: reservation.totalGrossAmount.amount,
    quantity: 1,
  });

  // Add any services
  if (reservation.services) {
    items = items.concat(reservation.services.map((s) => cartItemForService(s, s.count)));
  }

  return items;
};

const cartItemForService = (service, count) => {
  return {
    id: service.service.id,
    content_type: "product",
    name: service.service.name,
    category: "Add-On",
    price: service.service.defaultGrossPrice.amount,
    quantity: count,
  };
};

// Takes an array of reservations and returns cart items for them all
const cartItemsFromReservations = (reservations) => {
  return reservations.reduce(
    (acc, reservation) => [...acc, ...cartItemsFromReservation(reservation)],
    []
  );
};
