const Routes = {
  PLAN_STAY: "/plan-stay",
  CHOOSE_ROOM: "/choose-room",
  SELECT_ADDONS: "/select-addons",
  CHECKOUT: "/checkout",
  CONFIRM_STAY: "/confirm-stay",
  FINALIZE_BOOKING: "/finalize-booking",
  ACCOUNT: "/account/profile",
  ACCOUNT_BOOKINGS: "/account/bookings",
  LOGIN: "/login",
  SIGNUP: "/signup",
  FIND_BOOKING: "/find-booking",
  MANAGE_BOOKING: "/manage-booking",
  PRIVATE_DINING: "/private-dining",
  EVENT_SPACES: "/event-spaces",
  FACILITIES: "/facilities",
  EMBEDDED_FACILITY_BOOK: "/embedded-facility-booking",
  EMBEDDED_FACILITY_ENQUIRY: "/embedded-facility-enquiry",
  PROPERTIES: "/properties",
};

export default Routes;
