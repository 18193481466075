const getDisplayMessage = ({ paymentIntentStatus }) => {
  switch (paymentIntentStatus) {
    case "succeeded":
      return "Payment succeeded!";
    case "processing":
      return "Your payment is processing.";
    case "requires_payment_method":
      return null;
    default:
      return "Something went wrong.";
  }
};

export default getDisplayMessage;
