export const removeQueryParams = (paramsToRemove) => {
  // Get the current URL
  const currentUrl = new URL(window.location.href);

  // Remove the specified query parameters
  paramsToRemove.forEach((param) => currentUrl.searchParams.delete(param));

  // Modify the browser URL without refreshing the page
  window.history.replaceState({}, "", currentUrl.toString());
};
