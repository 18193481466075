import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography, makeStyles, Divider } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  infoItemContainer: {
    width: "100%",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: "75%",
    },
    [theme.breakpoints.up(theme.breakpoints.values.desktop)]: {
      width: "50%",
    },
  },
  infoItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    width: "100%",
    "&:hover": {
      background: "none",
      marginLeft: "2px",
    },
  },
  infoItemIcon: {
    padding: theme.spacing(1.5),
    color: theme.palette.text.red,
    "&:hover": {
      background: "none",
    },
  },
}));

const InformationMenu = ({ infoSections = [], handleOpenGeneralModal, ...props }) => {
  const classes = useStyles();

  const handleMoreInfoClick = (item) => {
    if (item?.url) {
      return item.url;
    } else {
      handleOpenGeneralModal({ title: item?.title, text: item?.text, image: item?.image });
    }
  };

  return (
    <>
      {infoSections.length > 0 &&
        infoSections
          .filter(({ list_items }) => Boolean(list_items))
          .map(({ title, list_items }, index) => {
            return (
              <Box key={`${title}-info-section`}>
                {props.beforeEach(index)}
                <Typography variant="h4">{title}</Typography>
                {list_items.map((item, index) => {
                  return (
                    <Box key={index} className={classes.infoItemContainer}>
                      <Button
                        disableRipple
                        className={classes.infoItem}
                        href={item?.url}
                        target="_blank"
                        onClick={() => handleMoreInfoClick(item)}
                        rel="noopener noreferrer">
                        <Typography variant="body1">{item.title}</Typography>
                        <div className={classes.infoItemIcon}>
                          <ChevronRight />
                        </div>
                      </Button>
                      <Divider />
                    </Box>
                  );
                })}
              </Box>
            );
          })}
    </>
  );
};

InformationMenu.propTypes = {
  infoSections: PropTypes.array,
  handleOpenGeneralModal: PropTypes.func,
  beforeEach: PropTypes.func,
};

export default InformationMenu;
