import { makeStyles } from "@material-ui/core";

const stepStyle = makeStyles((theme) => ({
  stepContainer: {
    padding: `${theme.spacing(2)}px`,
    marginBottom: `${theme.spacing(2)}px`,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      padding: `${theme.spacing(3)}px`,
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  stepContainerOverride: {
    padding: 0,
    paddingTop: theme.spacing(3),
  },
  selectFormControl: {
    width: "100%",
  },
  selectInput: {
    backgroundColor: "unset",
  },
  nextStepCTA: {
    float: "right",
  },
}));

export default stepStyle;
