import React from "react";
import { Box, TextField, Typography, MenuItem, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { keys } from "lodash";

const useStyles = makeStyles((theme) => ({
  containerHeading: {
    marginBottom: theme.spacing(2),
  },
  inputField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 20000,
      backgroundColor: theme.palette.background.default,
    },
  },
}));

const EventDetails = ({ eventDetails, handleEventDetailsChange }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.containerHeading} variant="subtitle1" color="textPrimary">
        About your event
      </Typography>
      {keys(eventDetails).map((key) => (
        <Box key={eventDetails[key].name} style={{ marginBottom: "20px" }}>
          <TextField
            id={`textField-${eventDetails[key].name}`}
            className={classes.inputField}
            fullWidth
            size="small"
            label={eventDetails[key].label}
            required={eventDetails[key].isRequired}
            variant="outlined"
            type={eventDetails[key]?.type}
            select={eventDetails[key].type === "dropDown"}
            inputProps={eventDetails[key]?.options.inputProps}
            value={eventDetails[key].value}
            InputLabelProps={eventDetails[key]?.inputLabelProps}
            error={eventDetails[key]?.error}
            helperText={eventDetails[key]?.error ? "This field is required." : ""}
            onChange={(evt) => {
              handleEventDetailsChange(eventDetails[key].name, evt.target.value);
            }}>
            {eventDetails[key].type === "dropDown" &&
              eventDetails[key]?.options?.inputProps?.options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        </Box>
      ))}
    </>
  );
};

EventDetails.propTypes = {
  eventDetails: PropTypes.object,
  handleEventDetailsChange: PropTypes.func,
};

export default EventDetails;
