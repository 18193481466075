import firebase from "firebase/app";
import "firebase/auth";
import { initializeAnalytics } from "../analytics";

export const initFirebase = () => {
  // Init firebase if it has not been previously inited
  let fbApp;
  if (!firebase.apps.length) {
    const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: `${projectId}.firebaseapp.com`,
      databaseURL: `https://${projectId}.firebaseio.com`,
      projectId: projectId,
      storageBucket: `${projectId}.appspot.com`,
      messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: `G-${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
    };
    fbApp = firebase.initializeApp(firebaseConfig);

    // Init analytics
    initializeAnalytics();
  } else {
    fbApp = firebase.apps[0];
  }
  return fbApp;
};

export const sendVerificationEmail = async (onNotifOpen) => {
  try {
    const user = firebase.auth().currentUser;
    await user.sendEmailVerification();
    onNotifOpen(
      `A verification email has been sent to ${user.email}.\nPlease check your inbox and junk folder.`,
      { variant: "success" }
    );
  } catch (e) {
    if (e.code === "auth/too-many-requests") {
      onNotifOpen(
        "Please wait before trying again. If you haven't received an email, check your junk folder.",
        { variant: "error" }
      );
    } else {
      console.log(e);
    }
  }
};

export const reloadUser = async () => {
  var user = await firebase.auth().currentUser;
  await user.reload();
  const token = await user.getIdToken(true);

  return {
    token: token,
    verified: user.emailVerified,
  };
};
