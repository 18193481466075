import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { BOOKING_SOURCES } from "utils/constants";

export const BookingSourceContext = createContext(null);

export const BookingSourceProvider = ({ children }) => {
  const [bookingSource, setBookingSource] = useState(BOOKING_SOURCES.DEFAULT);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const initialSource = searchParams.get("source");

    if (initialSource) {
      // Set the booking source
      const src =
        Object.values(BOOKING_SOURCES).find(
          (s) => s.id?.toLowerCase() === initialSource?.toLowerCase()
        ) || BOOKING_SOURCES.DEFAULT;

      setBookingSource(src);
    }
  }, []);

  return (
    <BookingSourceContext.Provider value={{ bookingSource, setBookingSource }}>
      {children}
    </BookingSourceContext.Provider>
  );
};

BookingSourceProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
