import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { KeyboardArrowUpOutlined, KeyboardArrowDownOutlined } from "@material-ui/icons";
import { formatCurrency, PRICING_UNIT } from "utils/helpers";
import { calculateServicePrice } from "utils/pms_services/helpers";

import Stepper from "components/Stepper";
import Checkbox from "components/Checkbox";
import AddServicesButton from "../AddServicesButton";

const useStyles = makeStyles((theme) => ({
  services: {
    marginBottom: theme.spacing(1),
  },
  fromPrice: {
    marginLeft: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(1),
  },
  showOptionsButton: {
    marginLeft: -theme.spacing(1),
  },
  hidden: {
    opacity: 0,
  },
}));

const ServiceRow = (props) => {
  const classes = useStyles();

  const [showOptions, setShowOptions] = useState(false);

  // For tracking the service counts before added to the basket
  const [serviceCounts, setServiceCounts] = useState({});

  useEffect(() => {
    // Get the counts for each service
    let thisServiceCounts = {};

    // Set the initial counts for this addon as the defaults
    props.pmsServices.forEach((s) => (thisServiceCounts[s.pmsId] = s.defaultCount));

    setServiceCounts(thisServiceCounts);
  }, [props.pmsServices]);

  // View Logic

  // Returns the pms service details (price, defaults, etc)
  const getPmsService = (pmsId) => {
    return props.pmsServices.find((s) => s.pmsId === pmsId);
  };

  // Returns true if any services in this addon are in the basket
  const anyServicesInBasket = () => {
    return props.pmsServices?.some((service) => props.serviceCountInBasket(service.pmsId) > 0);
  };

  // The count in basket, or falls back to the defaults
  const countForService = (pmsId) => {
    const addedToBasket = anyServicesInBasket();

    // If services are already in the basket, use the count from the service
    // Otherwise, use the temporal count
    if (addedToBasket) {
      return props.serviceCountInBasket(pmsId);
    } else {
      return serviceCounts[pmsId] || 0;
    }
  };

  const getTotalPrice = () => {
    // Returns price for all services
    return props.pmsServices.reduce((acc, s) => {
      const count = countForService(s.pmsId);
      return acc + calculateServicePrice(s.price, s.pricingUnit, s.numberOfNights, count);
    }, 0);
  };

  // Button handlers

  const handleAddButtonPressed = (e) => {
    // Add the services
    const serviceSummaries = props.pmsServices
      .map((pmsService) => {
        const option = props.options.find((s) => s.pmsId === pmsService.pmsId);
        return {
          serviceId: pmsService.pmsId,
          count: countForService(pmsService.pmsId),
          maxCount: pmsService.maxCount,
          cmsTitle: props.title,
          additionalInfo: option.title,
        };
      })
      .filter((s) => s.count > 0);

    props.onAddServices(serviceSummaries);
  };

  const handleRemoveButtonPressed = (e) => {
    // Remove the services
    const serviceIds = props.pmsServices.map((s) => s.pmsId);
    props.onRemoveServices(serviceIds);
  };

  const handleServiceCountChanged = (pmsId, count, maxCount) => {
    // If the item is already in the basket, we'll change the value in basket
    // Otherwise, we'll change the temporal values

    const addedToBasket = anyServicesInBasket();

    // If services are already in the basket, use the count from the service
    // Otherwise, use the temporal count
    if (addedToBasket) {
      const option = props.options.find((s) => s.pmsId === pmsId);
      props.onUpdateServiceCount(
        pmsId,
        count,
        maxCount,
        props.title, // CMS Title
        option.title // Additional info
      );
    } else {
      setServiceCounts({
        ...serviceCounts,
        [pmsId]: count,
      });
    }
  };

  const getTotalItemCount = () => {
    // Returns number of items in basket / pre-basket state for all services
    return props.pmsServices.reduce((acc, s) => {
      const count = countForService(s.pmsId);
      return acc + count;
    }, 0);
  };

  const createOptionsRows = () => {
    return props.options.map(({ pmsId, title }, i) => {
      const pmsService = getPmsService(pmsId);
      const serviceCount = countForService(pmsId);

      return (
        <Grid
          className={classes.services}
          container
          justifyContent="space-between"
          wrap="nowrap"
          alignItems="center"
          key={i}>
          <Grid container direction="column">
            <Grid container alignItems="baseline">
              <Typography variant="body2" text="textPrimary">
                {title}
              </Typography>
              <Typography variant="subtitle2">
                &nbsp;&nbsp;
                {formatCurrency(pmsService.price.currency, pmsService.price.amount, false)}
              </Typography>
            </Grid>
          </Grid>
          {pmsService.pricingUnit === PRICING_UNIT.PERSON ? (
            <Stepper
              name={`service_stepper-${i}`}
              ariaHint={`number of ${title} services`}
              count={countForService(pmsId)}
              decrementDisabled={serviceCount <= 0}
              incrementDisabled={serviceCount >= pmsService.maxCount}
              onValueChanged={(name, count) =>
                handleServiceCountChanged(pmsId, count, pmsService.maxCount)
              }
            />
          ) : (
            <Checkbox
              checked={countForService(pmsId) > 0}
              onChange={(checked) =>
                handleServiceCountChanged(pmsId, checked ? 1 : 0, pmsService.maxCount)
              }
            />
          )}
        </Grid>
      );
    });
  };

  const getFromPrice = () => {
    return Math.min(...props.pmsServices.map((s) => s.price?.amount || 0));
  };

  const totalItemCount = getTotalItemCount();
  const currency = props.pmsServices[0]?.price?.currency;

  return (
    <Grid container direction="column" className={props.className}>
      <Grid container>
        <Typography variant="subtitle1" gutterBottom>
          {props.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" className={classes.fromPrice}>
          from{" "}
          <Typography variant="subtitle1" color="textPrimary" component="span">
            {formatCurrency(currency, getFromPrice(), false)}
          </Typography>
        </Typography>
      </Grid>
      <Typography variant="body2" color="textSecondary" style={{ whiteSpace: "pre-wrap" }}>
        {props.description}
      </Typography>

      {showOptions && <Box mt={2}>{createOptionsRows()}</Box>}

      <Grid container className={classes.footer} justifyContent="space-between">
        <Button
          className={classes.showOptionsButton}
          color="primary"
          onClick={() => setShowOptions(!showOptions)}
          endIcon={showOptions ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}>
          {showOptions ? "Hide" : "Choose options"}
        </Button>

        <AddServicesButton
          className={totalItemCount === 0 && classes.hidden}
          isInBasket={anyServicesInBasket()}
          currency={currency} // use price from the first service
          amount={getTotalPrice()}
          hideAddButton={!showOptions}
          onAddHandler={handleAddButtonPressed}
          onRemoveHandler={handleRemoveButtonPressed}
        />
      </Grid>
    </Grid>
  );
};

ServiceRow.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  serviceCountInBasket: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      pmsId: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  pmsServices: PropTypes.arrayOf(
    PropTypes.shape({
      pricingUnit: PropTypes.string,
      price: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      defaultCount: PropTypes.number,
      maxCount: PropTypes.number,
    })
  ),
  onAddServices: PropTypes.func,
  onRemoveServices: PropTypes.func,
  onUpdateServiceCount: PropTypes.func,
};

export default ServiceRow;
