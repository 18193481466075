import React from "react";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { formatDateTo24HrTime } from "utils/timekeep";
import moment from "moment";
import { formatCurrency } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  availabilitySlot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "solid 1px",
    borderBottomColor: theme.palette.other.lineDetail,
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  availabilityTimeAndCost: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  },
}));

const AvailabilitySearch = ({ showEndTime, availability, setSelectedTimeSlot }) => {
  const availableSlots = availability || [];
  const classes = useStyles();

  const handleSelectedTimeSlot = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
  };

  return (
    <Box>
      <Typography variant="subtitle1">Select a Time</Typography>

      {availableSlots.map((timeSlot) => (
        <Box key={timeSlot.start_time + timeSlot.description} className={classes.availabilitySlot}>
          <div>
            <Box className={classes.availabilityTimeAndCost}>
              <Typography variant="subtitle1">
                {formatDateTo24HrTime(moment.utc(timeSlot.start_time))}
                {showEndTime ? (
                  <>
                    {" - "} {formatDateTo24HrTime(moment.utc(timeSlot.end_time))}
                  </>
                ) : (
                  ""
                )}
              </Typography>
              {timeSlot.cost && (
                <Typography color="textSecondary">
                  {formatCurrency("GBP", timeSlot.cost)}
                </Typography>
              )}
            </Box>
            {timeSlot.description && (
              <Typography color="textPrimary">({timeSlot.description})</Typography>
            )}
          </div>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleSelectedTimeSlot(timeSlot)}>
            Select
          </Button>
        </Box>
      ))}
    </Box>
  );
};

AvailabilitySearch.propTypes = {
  showEndTime: PropTypes.bool,
  availability: PropTypes.array,
  setSelectedTimeSlot: PropTypes.func,
};

export default AvailabilitySearch;
