import React, { useReducer, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSelector, useDispatch, connect } from "react-redux";
import { eachDayOfInterval, parseISO, formatISO } from "date-fns";
import { BREAKFAST_START_TIME, DINNER_END_TIME } from "utils/constants";
import { compose, first, get, last, map, set, size, unset } from "lodash/fp";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { KeyboardArrowUpOutlined, KeyboardArrowDownOutlined, OpenInNew } from "@material-ui/icons";

import { childrenCount } from "utils/helpers";
import { getSelectedProperty } from "../../selectors/selectedProperty";
import { addDiningSelections, addDiningBookingResponse } from "features/dining/diningSlice";
import {
  selectCommonSearchParams,
  selectAllAvailCheckParams,
  selectDiningSelections,
} from "selectors/booking";
import { transformAPIAvailabilities } from "./utils";
import LoadingDialog from "components/LoadingDialog";
import LoadingSpinner from "components/LoadingSpinner";
import api from "utils/api";

// Child components
import DiningTimesRow from "./sections/DiningTimesRow";
import DiningSelectionSummary from "./sections/DiningSelectionSummary";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    flexDirection: "column",
    alignItems: "stretch",
  },
  readLess: {
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 3,
    overflow: "hidden",
    overflowWrap: "break-word",
    textOverflow: "ellipsis",
  },
  imageContainer: {
    width: "100%",
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  loadingSpinner: {
    display: "grid",
    padding: "3rem 0",
  },
  expandDining: {
    marginTop: theme.spacing(2),
  },
  diningSummaryDivider: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(-2.5),
    marginRight: theme.spacing(-2.5),
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    float: "right",
  },
  linkButton: {
    marginLeft: theme.spacing(-1),
  },
  expandButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  seeMoreLink: {
    display: "flex",
    alignItems: "center",
  },
}));

const ACTION_TYPES = {
  SELECT: "select",
  DESELECT: "deselect",
  REPLACE: "replace",
};

const reducer = (state, { type, payload: payload }) => {
  switch (type) {
    case ACTION_TYPES.SELECT: {
      const { date, shiftCategory, selection } = payload;
      return compose(
        set([date, shiftCategory], { time: selection.time, note: selection.note }),
        unset([date, shiftCategory])
      )(state);
    }
    case ACTION_TYPES.DESELECT: {
      const { date, shiftCategory } = payload;
      return unset([date, shiftCategory], state);
    }
    case ACTION_TYPES.REPLACE:
      return payload;
    default:
      return state;
  }
};

const SelectDiningTimes = (props) => {
  const classes = useStyles();
  const reduxDispatch = useDispatch();

  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet));

  const [expandedOnMobile, setExpandedOnMobile] = useState(false);
  const [availabilities, setAvailabilities] = useState([]);

  const [venueDescriptionExpanded, setVenueDescriptionExpanded] = useState(false);
  const [bookingInProgress, setBookingInProgress] = useState(false);

  const loading = size(availabilities) === 0;

  const previouslySelectedDiningChoices = useSelector(selectDiningSelections);
  const [selections, dispatch] = useReducer(reducer, previouslySelectedDiningChoices || {});

  const bookDining = () => {
    setBookingInProgress(true);

    api
      .bookDining({
        facility_cms_id: props.diningVenue.id,
        first_name: props.guestDetails.firstName,
        last_name: props.guestDetails.lastName,
        email: props.guestDetails.email,
        phone: props.guestDetails.phoneNumber,
        party_size: partySize,
        reservations: selections,
      })
      .then((response) => {
        const data = response.data;

        // If none are successfull, error out. Otherwise, we'll show user on
        // confirmation page which ones failed to book.
        // This way, we dont need to handle partial bookings.
        if (!data.some((d) => d.success)) {
          throw new Error(data[0]?.message); // Just throw first error
        }

        // Add selections to redux
        reduxDispatch(addDiningSelections(selections));
        reduxDispatch(addDiningBookingResponse(response.data));

        // Set loading false
        setBookingInProgress(false);

        // We'll show errors on the next page, but let's also show a notification if any bookings fails to highlight it
        // We'll only get here if some were successful
        if (data.some((d) => !d.success)) {
          props.onNotifOpen(
            `One or more of your dining reservations could not be completed. Please contact the hotel to make these reservations.`,
            {
              variant: "warning",
            }
          );
        }
      })
      .catch((error) => {
        setBookingInProgress(false);

        props.onNotifOpen(`Failed to make booking: ${error.message}`, {
          variant: "error",
        });
      });
  };

  // Get the availability check params for all room
  const { arrival: arrivalDate, departure: departureDate } = useSelector(selectCommonSearchParams);

  // Get all the availability check params for all rooms so we can get guest count
  const allAvailCheckParams = useSelector(selectAllAvailCheckParams);

  const partySize = Object.values(allAvailCheckParams).reduce((acc, cur) => {
    const adultCount = parseInt(cur.adults);
    const childCount = childrenCount(cur);
    return acc + adultCount + childCount;
  }, 0);

  let dates;
  if (arrivalDate && departureDate) {
    dates = compose(
      map((date) => formatISO(date, { representation: "date" })),
      eachDayOfInterval
    )({
      start: parseISO(arrivalDate),
      end: parseISO(departureDate),
    });
  }

  useEffect(() => {
    fetchAvailabilities();
  }, [props.diningVenue]);

  const fetchAvailabilities = async () => {
    const response = await api.getVenuesAvailabilities({
      party_size: partySize,
      start_date: arrivalDate,
      end_date: departureDate,
      start_time: BREAKFAST_START_TIME,
      end_time: DINNER_END_TIME,
      facility_cms_id: props.diningVenue.id,
    });

    const availabilities = transformAPIAvailabilities(response.data || []);
    setAvailabilities(availabilities);
  };

  const handleTimeSelected = (date, shiftCategory, time) => {
    const selection = get([date, shiftCategory], selections);

    dispatch({
      type:
        get([date, shiftCategory, "time"], selections) === time
          ? ACTION_TYPES.DESELECT
          : ACTION_TYPES.SELECT,
      payload: {
        date,
        shiftCategory,
        selection: { ...selection, time: time },
      },
    });
  };

  const handleUpdateSelections = (newSelections) => {
    // Replace the selections with the new updates
    dispatch({
      type: ACTION_TYPES.REPLACE,
      payload: newSelections,
    });
  };

  return (
    <>
      <Grid container className={classes.mainContent}>
        <Typography variant="body1">
          Save from disappointment when you arrive to Mollie&apos;s {props.selectedProperty.name} by
          booking at our restaurant ahead of time. Select your dining times below.
        </Typography>

        <div>
          <Button
            className={classNames(classes.linkButton, classes.expandButton)}
            color="primary"
            onClick={() => setVenueDescriptionExpanded(!venueDescriptionExpanded)}
            endIcon={
              venueDescriptionExpanded ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />
            }>
            More about our diner
          </Button>
        </div>

        {loading ? (
          <div className={classes.loadingSpinner}>
            <LoadingSpinner loading={loading} size={70} />
          </div>
        ) : (
          <>
            {/* Restaurant details */}
            {venueDescriptionExpanded && (
              <Box pb={4}>
                <Grid container direction={tabletUpScreen ? "row" : "column"} spacing={3}>
                  <Grid item xs={tabletUpScreen && 8}>
                    <Typography variant="h5" gutterBottom>
                      {props.diningVenue?.name}
                    </Typography>
                    <Typography
                      className={venueDescriptionExpanded ? null : classes.readLess}
                      variant="body1"
                      color="textSecondary"
                      gutterBottom>
                      {props.diningVenue?.description}
                    </Typography>
                    {props.diningVenue?.contact_info?.website_url && (
                      <Link
                        className={classes.seeMoreLink}
                        variant="subtitle1"
                        target="_blank"
                        href={props.diningVenue?.contact_info?.website_url}>
                        See more&nbsp;
                        <OpenInNew fontSize="small" />
                      </Link>
                    )}
                  </Grid>
                  <Grid item xs={tabletUpScreen && 4}>
                    <div className={classes.imageContainer}>
                      <img
                        className={classes.image}
                        src={props.diningVenue?.hero_image?.url}
                        alt={props.diningVenue?.hero_image?.name}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Box>
            )}

            {tabletUpScreen || expandedOnMobile ? (
              <>
                {/* Availabilities by day */}
                {availabilities.map((availability, index) => {
                  const date = availability.date;

                  // Dont render breakfast on check in date, or dinner on check-out date
                  const renderBreakfast = first(dates) !== date;
                  const renderDinner = last(dates) !== date;

                  return (
                    <DiningTimesRow
                      key={availability.date}
                      partySize={partySize}
                      renderBreakfast={renderBreakfast}
                      renderDinner={renderDinner}
                      dayIndex={index}
                      availability={availability}
                      selections={selections}
                      onTimeSelected={handleTimeSelected}
                    />
                  );
                })}
                <Divider className={classes.diningSummaryDivider} />
                <DiningSelectionSummary
                  dates={dates}
                  availabilities={availabilities}
                  selections={selections}
                  partySize={partySize}
                  onBookDining={bookDining}
                  onUpdateSelections={handleUpdateSelections}
                />
              </>
            ) : (
              <Button
                className={classes.expandDining}
                variant="contained"
                color="primary"
                onClick={() => setExpandedOnMobile(true)}>
                Book dining now
              </Button>
            )}
          </>
        )}
      </Grid>
      <LoadingDialog
        open={bookingInProgress}
        message={"Connecting to the booking system. Please wait..."}
      />
    </>
  );
};

SelectDiningTimes.propTypes = {
  onNotifOpen: PropTypes.func,
  diningVenue: PropTypes.object,
  // Mapped state props
  propertyId: PropTypes.string,
  selectedProperty: PropTypes.object,
  guestDetails: PropTypes.object,
  bookingConfirmationId: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    propertyId: state.booking.commonSearchParams.propertyId,
    selectedProperty: getSelectedProperty(state),
    guestDetails: state.booking.guestDetails,
    bookingConfirmationId: state.booking.bookingConfirmationId,
  };
};

const mapDispatchToProps = {};

const ConnectedSelectDiningTimes = connect(mapStateToProps, mapDispatchToProps)(SelectDiningTimes);
export default ConnectedSelectDiningTimes;
