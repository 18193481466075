import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import merge from "lodash/fp/merge";

import { selectCommonSearchParams } from "selectors/booking";

export default function useSearchParams() {
  const storedSearchParams = useSelector(selectCommonSearchParams);
  return merge(storedSearchParams, queryString.parse(useLocation().search));
}
