import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paginationDotsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },

  paginationDots: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    padding: "0",
  },

  paginationDot: {
    background: "rgba(0,0,0, 0.4)",
    width: "20px",
    height: "20px",
    borderRadius: "10000px",
    flexGrow: "0",
    flexShrink: "0",
    transition: "width .2s ease, height .2s ease",
    "&:hover": {
      cursor: "pointer",
    },
  },

  paginationDotSelected: {
    backgroundColor: "#FFFF",
  },
}));

export default function PaginationDots({
  slideIndex,
  slides = [],
  size = 20,
  gap = 4,
  numberToDisplay = 5,
  handleButtonClick,
}) {
  const classes = useStyles();
  const scrollEl = useRef();

  const middle = Math.ceil(numberToDisplay / 2);

  const maxWidth = numberToDisplay * size + (numberToDisplay - 1) * gap;

  useEffect(() => {
    scrollEl.current.scrollTo({
      left: slideIndex * (size + gap) - (middle - 1) * (size + gap),
      behavior: "smooth",
    });
  }, [slideIndex, gap, size, middle]);

  return (
    <div className={classes.paginationDotsContainer}>
      <div
        className={classes.paginationDots}
        ref={scrollEl}
        style={{ gap: `${gap}px`, maxWidth: `${maxWidth}px` }}>
        {slides.map((_, index) => {
          const awayFromSelected = Math.abs(slideIndex - index);

          const dotSize = Math.max(size * (1 - Math.max(awayFromSelected - 1, 0) * 0.1), 0);

          return (
            <div
              key={index}
              style={{
                width: `${size}px`,
                height: `${size}px`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 0,
                flexShrink: 0,
              }}>
              <div
                className={
                  index === slideIndex
                    ? `${classes.paginationDot} ${classes.paginationDotSelected}`
                    : classes.paginationDot
                }
                onClick={() => {
                  handleButtonClick(index - slideIndex);
                }}
                style={{
                  width: `${dotSize}px`,
                  height: `${dotSize}px`,
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

PaginationDots.propTypes = {
  slideIndex: PropTypes.number,
  slides: PropTypes.array,
  size: PropTypes.number,
  gap: PropTypes.number,
  numberToDisplay: PropTypes.number,
  handleButtonClick: PropTypes.func,
};
