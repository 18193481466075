import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core";
import { Check as CheckIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    width: "28px",
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: `1px solid ${theme.palette.divider}`,
  },
  checked: {
    backgroundColor: theme.palette.custom.nightBlue,
  },
  checkmark: {
    color: theme.palette.text.onDark.primary,
    fontSize: "20px",
  },
}));

const Checkbox = (props) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.checkbox, props.checked ? classes.checked : null)}
      onClick={() => props.onChange(!props.checked)}>
      {props.checked && <CheckIcon className={classes.checkmark} />}
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
