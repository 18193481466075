import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import api from "utils/api";

import { Button, InputLabel, FormControl, MenuItem, Select } from "@material-ui/core";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner.js";

import stepStyle from "../stepStyle";

const ChooseRoomStep = (props) => {
  const classes = stepStyle();

  const [isLoading, setIsLoading] = useState(false);

  const [facilityServices, setFacilityServices] = useState([]);
  const [currentFacilityService, setCurrentFacilityService] = useState(null);

  useEffect(() => {
    if (!(props.property && props.facility)) return;

    setIsLoading(true);

    api.getBookableFacilityServices(props.facility.id).then((response) => {
      const facilityServices = response["data"];

      setFacilityServices(facilityServices);

      setIsLoading(false);
    });
  }, [props.property]);

  const handleFacilityServiceChange = (e) => {
    const facilityServiceId = e.target.value;
    const facilityService = facilityServices.find((service) => service.id === facilityServiceId);
    setCurrentFacilityService(facilityService);
  };

  const handleNext = () => {
    if (!currentFacilityService) {
      return;
    }

    props.handleNext(facilityServices, currentFacilityService.id);
  };

  return isLoading ? (
    <LoadingSpinner loading={isLoading} />
  ) : (
    <>
      <div className={classNames(classes.stepContainer, classes.stepContainerOverride)}>
        <FormControl required className={classes.selectFormControl} variant="outlined">
          <InputLabel id="facility-service-select-label">Space</InputLabel>
          <Select
            className={classes.selectInput}
            name="facilityServiceId"
            labelId="facility-service-select-label"
            label="Space"
            value={currentFacilityService?.id}
            onChange={handleFacilityServiceChange}>
            {facilityServices.map((facilityService) => (
              <MenuItem key={facilityService.id} value={facilityService.id}>
                {facilityService.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <Button
        className={classes.nextStepCTA}
        variant="contained"
        color="primary"
        size="large"
        disabled={!currentFacilityService}
        onClick={handleNext}>
        Next Step
      </Button>
    </>
  );
};

ChooseRoomStep.propTypes = {
  property: PropTypes.object,
  facility: PropTypes.object,
  handleNext: PropTypes.func.isRequired,
};

export default ChooseRoomStep;
