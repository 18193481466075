import React from "react";
import "date-fns";
import { Box, Button, Typography, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import { ChatOutlined } from "@material-ui/icons";
import waveHandLogo from "images/waving_hand.svg";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  informationBox: {
    backgroundColor: theme.palette.info.lightBg,
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  infoTextMargin: { marginBottom: theme.spacing(1) },
  buttonActionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: "column",
    },
  },
  infoCTAOverride: { justifyContent: "flex-start", marginTop: theme.spacing(3) },
}));

const SuggestEnquiry = (props) => {
  const showChatWidget = false;
  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));
  const classes = useStyles();

  return (
    <Box className={classes.informationBox}>
      <Box>
        <img
          className={classes.infoTextMargin}
          style={{ width: "32px" }}
          src={waveHandLogo}
          alt="Wave Hand Logo"
        />
      </Box>
      <Typography variant={tabletUpScreen ? "h5" : "h6"} className={classes.infoTextMargin}>
        Need more space?
      </Typography>
      <Typography>
        Are you organising an event that also requires dining and somewhere to stay? Let us know
        your plans, and we’ll do what we can do for you.
      </Typography>
      <Box className={classNames(classes.buttonActionsContainer, classes.infoCTAOverride)}>
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonCTA}
          onClick={props.handleEnquiry}>
          Enquire now
        </Button>
        {showChatWidget && (
          <Button
            variant="outlined"
            className={classes.infoButton}
            startIcon={<ChatOutlined />}
            onClick={() => alert("TODO: Handle Talk to our Team")}>
            Talk to our team
          </Button>
        )}
      </Box>
    </Box>
  );
};

SuggestEnquiry.propTypes = {
  searchDetails: PropTypes.object,
  handleSearchDetailsChange: PropTypes.func,
  selectedTimeSlot: PropTypes.object,
  setSelectedTimeSlot: PropTypes.func,
  selectedRoom: PropTypes.object,
  selectedProperty: PropTypes.object,
  validateSearchDetails: PropTypes.func,
  handleEnquiry: PropTypes.func,
};

export default SuggestEnquiry;
