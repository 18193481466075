import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Box, Divider, Grid, Typography, makeStyles } from "@material-ui/core";

import { getSelectedProperty } from "../../selectors/selectedProperty";
import api from "utils/api";
import { calculateBookingTotal, calculateBookingTotalVat } from "utils/bookingTotals";
import { formatCurrency } from "utils/helpers";

import LoadingSpinner from "components/LoadingSpinner";
import RoomSummaryCard from "components/RoomSummaryCard";

const useStyles = makeStyles((theme) => ({
  manageBookingButton: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,

    "&:hover": {
      backgroundColor: theme.palette.grey[50],
      boxShadow: "none",
    },
  },
  hidden: {
    display: "none",
  },
  noDecorator: {
    textDecoration: "none",
  },
  vatSummary: {
    float: "right",
  },
}));

const StayBookingSummary = (props) => {
  const classes = useStyles();
  const [bookedReservations, setBookedReservations] = useState([]);
  const [restaurantVenue, setRestaurantVenue] = useState(null);
  const [checkInDate, setCheckInDate] = useState();
  const [checkOutDate, setCheckOutDate] = useState();
  const [loading, setLoading] = useState(true);

  const [bookingTotal, setBookingTotal] = useState(0);
  const [bookingTotalVat, setBookingTotalVat] = useState(0);

  const formatDate = (date) => {
    return new Date(date).toString().split(" ").slice(0, 3).join(" ");
  };

  useEffect(() => {
    api
      .getBooking({
        bookingId: props.bookingConfirmationId,
      })
      .then((response) => {
        setBookedReservations(response.data.reservations);
        setBookingTotal(calculateBookingTotal(response.data.reservations));
        setBookingTotalVat(calculateBookingTotalVat(response.data.reservations));

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.bookingConfirmationId]);

  useEffect(() => {
    // Dont fetch if we already have it
    if (restaurantVenue) {
      return;
    }

    // For now we only support a single venue so fetch the venue for first booking
    const venueId = props.diningReservations[0]?.venue_id;

    if (!venueId) {
      return;
    }

    api
      .getRestaurantVenues(props.selectedProperty.code)
      .then((response) => {
        setRestaurantVenue(response.data[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.diningReservations, props.selectedProperty.code, restaurantVenue]);

  useEffect(() => {
    if (
      (restaurantVenue || props.diningReservations.length === 0) &&
      bookedReservations.length > 0
    ) {
      setLoading(false);
    }
  }, [restaurantVenue, bookedReservations, props.diningReservations]);

  useEffect(() => {
    setCheckInDate(bookedReservations[0]?.arrival);
    setCheckOutDate(bookedReservations[0]?.departure);
  }, [bookedReservations]);

  return (
    <Grid container>
      <Box p={4} width={1} className={loading ? null : classes.hidden}>
        <LoadingSpinner loading={loading} />
      </Box>
      <Box width={1} className={loading ? classes.hidden : null}>
        <Grid container justifyContent="space-between" alignItems="flex-start" spacing={3}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              {props.selectedProperty.name}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {formatDate(checkInDate)} - {formatDate(checkOutDate)}
            </Typography>
          </Grid>
        </Grid>
        <Box my={2}>
          <Divider />
          {bookedReservations.map((reservation, index) => (
            <RoomSummaryCard
              key={index}
              reservation={reservation}
              onNotifOpen={props.onNotifOpen}
            />
          ))}
        </Box>
        <Box mt={2} width={1}>
          <Grid container justifyContent="space-between" wrap="nowrap">
            <Grid item>
              <Grid>
                <Typography variant="h6" color="textPrimary">
                  Total Paid
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="h4">{formatCurrency("GBP", bookingTotal)}</Typography>
          </Grid>
          {bookingTotalVat > 0 ? (
            <Typography className={classes.vatSummary} variant="body2" color="textSecondary">
              Including VAT of {formatCurrency("GBP", bookingTotalVat)}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Grid>
  );
};

StayBookingSummary.propTypes = {
  bookingConfirmationId: PropTypes.string,
  onNotifOpen: PropTypes.func,

  // Mapped properties
  selectedProperty: PropTypes.object,
  diningReservations: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => {
  return {
    selectedProperty: getSelectedProperty(state),
    diningReservations: state.dining.diningBookingsResponse || [],
  };
};

const ConnectedStayBookingSummary = connect(mapStateToProps)(StayBookingSummary);
export default ConnectedStayBookingSummary;
