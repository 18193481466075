import { useEffect } from "react";
import { useDispatch } from "react-redux";
import api from "utils/api";
import { serializeFirebaseUser } from "utils/helpers";

import { setProfile, signInUser, signOutUser } from "features/user/userSlice";

export default function useSignInWithFirebaseToken(firebaseToken) {
  const dispatch = useDispatch();

  const handleAuthUserChange = (userData) => {
    dispatch(signInUser(serializeFirebaseUser(userData)));
  };

  const signInWithFbToken = async (firebaseToken) => {
    try {
      const verifyResponse = await api.verifyFirebaseToken(firebaseToken);
      const userData = verifyResponse.data;
      handleAuthUserChange(userData);
      fetchProfile(firebaseToken);
    } catch (e) {
      if (e.response.status === 401) {
        // Clear any state
        dispatch(signOutUser());
      } else {
        console.error(e);
      }
    }
  };

  const fetchProfile = (firebaseToken) => {
    api
      .getProfile(firebaseToken)
      .then((res) => {
        dispatch(setProfile(res.data));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    if (firebaseToken) {
      signInWithFbToken(firebaseToken);
    }
  }, []);

  return { signInWithFbToken };
}
