import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { formatDateMedium } from "utils/timekeep";

const useStyles = makeStyles((theme) => ({
  roomSummaryBarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "0",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      marginBottom: "0",
      marginRight: theme.spacing(1),
    },
  },
  text: {
    color: theme.palette.text.primary,
  },
}));

const RoomSummaryBar = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.roomSummaryBarContainer}>
      <Typography variant="body1" className={classes.text}>
        {formatDateMedium(props.arrival)} - {formatDateMedium(props.departure)} -
        {props.roomCount ? ` ${props.roomCount} Rooms, ` : " "}
        {props.guestCount} Guests
      </Typography>
    </div>
  );
};

RoomSummaryBar.propTypes = {
  arrival: PropTypes.string,
  departure: PropTypes.string,
  roomCount: PropTypes.number,
  guestCount: PropTypes.number,
};

export default RoomSummaryBar;
