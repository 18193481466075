import React, { useState } from "react";
import PropTypes from "prop-types";

import { Divider, Tab, makeStyles } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import ServiceRow from "./ServiceRow";

const useStyles = makeStyles((theme) => ({
  services: {
    marginBottom: theme.spacing(2),
  },
  disabledText: {
    color: theme.palette.text.secondary,
  },
  serviceListItemPrice: {
    marginLeft: "5px",
  },
  serviceRow: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tabBar: {
    maxWidth: "100%",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tab: {
    paddingLeft: 0,
    paddingRight: 0,

    marginRight: theme.spacing(3),

    "&:last-child": {
      marginRight: 0,
    },
  },
  tabPanel: {
    padding: 0,
  },
}));

const BasketAddonDetails = (props) => {
  const classes = useStyles();

  const [currentTab, setCurrentTab] = useState("0");

  // View Logic

  const createServiceRows = (services) => {
    return services.map((service, i) => {
      // Calculate the pms services for this option set
      const pmsServicesForOptions = service.options.map((o) =>
        props.pmsServices.find((s) => s.pmsId === o.pmsId)
      );

      return (
        <>
          <ServiceRow
            key={`service-${i}`}
            className={classes.serviceRow}
            title={service.name}
            description={service.description}
            serviceCountInBasket={props.serviceCountInBasket}
            options={service.options}
            pmsServices={pmsServicesForOptions}
            onAddServices={props.onAddServices}
            onRemoveServices={props.onRemoveServices}
            onUpdateServiceCount={props.onUpdateServiceCount}
          />
          {i < services.length - 1 && <Divider />}
        </>
      );
    });
  };

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <TabContext value={currentTab}>
      <TabList
        className={classes.tabBar}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="off"
        aria-label="Service categories">
        {props.addonDetails?.categories.map((category, index) => (
          <Tab
            key={`tab-${index}`}
            label={category.name}
            className={classes.tab}
            value={`${index}`}
          />
        ))}
      </TabList>

      {props.addonDetails?.categories.map((category, index) => (
        <TabPanel classes={{ root: classes.tabPanel }} key={`tabpanel-${index}`} value={`${index}`}>
          {createServiceRows(category.services)}
        </TabPanel>
      ))}
    </TabContext>
  );
};

BasketAddonDetails.propTypes = {
  serviceCountInBasket: PropTypes.func,
  onAddServices: PropTypes.func,
  onRemoveServices: PropTypes.func,
  onUpdateServiceCount: PropTypes.func,
  pmsServices: PropTypes.arrayOf(
    PropTypes.shape({
      pricingUnit: PropTypes.string,
      price: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      defaultCount: PropTypes.number,
      maxCount: PropTypes.number,
    })
  ),
  addonDetails: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        services: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string,
            options: PropTypes.arrayOf(
              PropTypes.shape({
                pmsId: PropTypes.string.isRequired,
                name: PropTypes.string,
              })
            ),
          })
        ),
      })
    ),
  }),
};

export default BasketAddonDetails;
