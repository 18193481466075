import { createTheme } from "@material-ui/core/styles";

import baseTheme from "./theme";

// DEVNOTE: 'extends' key does not work in this instance, hence using spread operator to fallback to baseTheme when properties are not overwritten by sohoSkinTheme
const sohoSkinTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    background: {
      default: "#EAEAE2",
      componentBackground1: "#FFFFFF",
      componentBackground2: "#FFFFFF",
      componentBackground3: "#FFFFFF",
    },
    primary: {
      main: "#25241F",
      light: "#4C4A3F",
      dark: "#060605",
      contrastText: "#FFFFFF",
      hover: "rgba(37, 36, 31, 0.04)",
      selected: "rgba(37, 36, 31, 0.08)",
      active: "rgba(37, 36, 31, 0.24)",
      border: "rgba(37, 36, 31, 0.5)",
      containedActive: "rgba(37, 36, 31, 0.24)",
    },
    info: {
      main: "#2A4C7A",
      dark: "#1C3250",
      light: "#3C6CAF",
      contrastText: "#FFFFFF",
      darkText: "#182A44",
      lightBg: "#E8EEF7",
    },
    success: {
      main: "#89A790",
      dark: "#6A8E72",
      light: "#B2C6B6",
      contrastText: "#25241F",
      darkText: "#27352A",
      lightBg: "#EDF2EF",
    },
    error: {
      main: "#AE3E18",
      dark: "#7D2C11",
      light: "#E2592B",
      contrastText: "#FFFFFF",
      darkText: "#511D0B",
      lightBg: "#FBEAE4",
    },
    warning: {
      main: "#DFA239",
      dark: "#C1851F",
      light: "#E9BF76",
      contrastText: "#25241F",
      darkText: "#4F370D",
      lightBg: "#FBF3E5",
    },
    action: {
      main: "#EAEAE2",
      border: "rgba(71, 58, 68, 0.15)",
      hover: "rgba(255, 255, 255, 0.04)",
      selected: "rgba(255, 255, 255, 0.08)",
      disabledBackground: "rgba(33, 38, 43, 0.12)",
      disabledText: "rgba(255, 255, 255, 0.26)",
    },
    text: {
      red: "#25241F",
      primary: "#25241F",
      secondary: "#787370",
      disabled: "#A49F9D",
      onDark: {
        primary: "#EAEAE2",
        secondary: "rgba(255, 250, 231, 0.6)",
        disabled: "rgba(255, 250, 231, 0.38)",
      },
    },
    other: {
      lineDetail: "rgba(32, 38, 44, 0.2)",
    },
    grey: {
      50: "#FBFBFB",
      100: "#F7F7F7",
      200: "#F2F2F2",
      300: "#E7E7E7",
      400: "#D6D6D6",
      500: "#BEBEBE",
      600: "#9F9F9F",
      700: "#797979",
      800: "#505050",
      900: "#242424",
      1000: "#121212",
    },
    divider: "rgba(37, 36, 31, 0.2)",
  },
  typography: {
    fontFamily: `Faro, Cardo, "Helvetica", "Arial", sans-serif`,
    h1: {
      fontFamily: `Cardo, "Helvetica", "Arial", sans-serif`,
      fontSize: "100px",
      lineHeight: "1.2em",
      letterSpacing: "0.12em",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: `Cardo, "Helvetica", "Arial", sans-serif`,
      fontSize: "56px",
      lineHeight: "1.25em",
      letterSpacing: "0.12em",
      fontWeight: 400,
      textTransform: "uppercase",
    },
    h3: {
      fontFamily: `Cardo, "Helvetica", "Arial", sans-serif`,
      fontSize: "48px",
      lineHeight: "1.3em",
      letterSpacing: "0.06em",
      fontWeight: 400,
      textTransform: "none",
    },
    h4: {
      fontFamily: `Faro, "Helvetica", "Arial", sans-serif`,
      fontSize: "30px",
      lineHeight: "1.4em",
      letterSpacing: "0.06em",
      fontWeight: 400,
      textTransform: "none",
    },
    h5: {
      fontFamily: `Faro, "Helvetica", "Arial", sans-serif`,
      fontSize: "24px",
      lineHeight: "1.5em",
      letterSpacing: "0.06em",
      fontWeight: 400,
      textTransform: "none",
    },
    h6: {
      fontFamily: `Faro, "Helvetica", "Arial", sans-serif`,
      fontSize: "18px",
      lineHeight: "1.5em",
      letterSpacing: "0.06em",
      fontWeight: 400,
      textTransform: "none",
    },
    subtitle1: {
      fontFamily: `Faro, "Helvetica", "Arial", sans-serif`,
      fontSize: "16px",
      lineHeight: "1.5em",
      letterSpacing: "0.06em",
      fontWeight: 400,
      textTransform: "none",
    },
    subtitle2: {
      fontFamily: `Faro, "Helvetica", "Arial", sans-serif`,
      fontSize: "14px",
      lineHeight: "1.5em",
      letterSpacing: "0.06em",
      fontWeight: 700,
      textTransform: "none",
    },
    body1: {
      fontFamily: `Faro, "Helvetica", "Arial", sans-serif`,
      fontSize: "18px",
      lineHeight: "1.5em",
      letterSpacing: "0.02em",
      fontWeight: 400,
    },
    body2: {
      fontFamily: `Faro, "Helvetica", "Arial", sans-serif`,
      fontSize: "16px",
      lineHeight: "1.5em",
      letterSpacing: "0.02em",
      fontWeight: 400,
    },
    button: {
      fontFamily: `Faro, "Helvetica", "Arial", sans-serif`,
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "1.5em",
      letterSpacing: "0.12em",
      textTransform: "none",
      fontWeight: 700,
    },
    caption: {
      fontFamily: `Faro, "Helvetica", "Arial", sans-serif`,
      fontSize: "12px",
      lineHeight: "1.2em",
      letterSpacing: "0.06em",
      textTransform: "none",
      fontWeight: 400,
    },
    overline: {
      fontFamily: `Faro, "Helvetica", "Arial", sans-serif`,
      fontSize: "12px",
      lineHeight: "2.5em",
      letterSpacing: "0.12em",
      textTransform: "none",
      fontWeight: 400,
    },
  },
});

sohoSkinTheme.overrides.MuiPaper = {
  root: {
    borderRadius: "0px",
    boxShadow: "none",
  },
  elevation1: {
    boxShadow: "none",
    border: `1px solid ${sohoSkinTheme.palette.other.lineDetail}`,
    backgroundColor: "#FFFFFF",
  },
  elevation8: {
    backgroundColor: sohoSkinTheme.palette.custom.offWhite,
  },
};

sohoSkinTheme.overrides.MuiButton = {
  root: {
    borderRadius: "24px",
    padding: "8px 16px", // medium size
  },
  contained: {
    boxShadow: "none",
    padding: "8px 16px", // medium size
  },
  outlined: {
    padding: "8px 16px", // medium size
  },
  sizeSmall: {
    ...sohoSkinTheme.typography.button2,
    padding: "8px 16px",
  },
  sizeLarge: {
    // Mobile style - large doesnt exist on mobile - use medium
    padding: "8px 16px",

    [sohoSkinTheme.breakpoints.up(sohoSkinTheme.breakpoints.values.tablet)]: {
      padding: "12px 24px",
      borderRadius: "28px",
    },
  },
  containedPrimary: {
    "&:hover": {
      backgroundColor: sohoSkinTheme.palette.grey[900],
      boxShadow: "none",
    },
  },
};

sohoSkinTheme.overrides.MuiInput = {
  root: {
    ...sohoSkinTheme.typography.body2,
    fontSize: "16px", // Needed to prevent zoom on ios safari
  },
};

sohoSkinTheme.overrides.MuiOutlinedInput = {
  root: {
    backgroundColor: "#FFFFFF",
    "& $notchedOutline": {
      borderColor: sohoSkinTheme.palette.other.lineDetail,
    },
    "&:hover $notchedOutline": {
      borderColor: sohoSkinTheme.palette.other.lineDetail,
    },
  },
};

sohoSkinTheme.overrides.MuiPopover = {
  paper: {
    backgroundColor: sohoSkinTheme.palette.background.default,
  },
};

sohoSkinTheme.overrides.MuiFormControl = {
  root: {
    minWidth: "80px",
  },
};

sohoSkinTheme.overrides.MuiStepContent = {
  root: {
    borderColor: sohoSkinTheme.palette.other.lineDetail,
  },
};

sohoSkinTheme.overrides.MuiStepConnector = {
  line: {
    borderColor: sohoSkinTheme.palette.other.lineDetail,
  },
};

sohoSkinTheme.overrides.MuiStepIcon = {
  root: {
    "&$active": {
      color: sohoSkinTheme.palette.text.primary,
    },
    text: {
      fill: sohoSkinTheme.palette.text.onDark.primary,
    },
  },
};

export default sohoSkinTheme;
