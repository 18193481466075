import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumbs, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { STEPS } from "utils/constants";

const useStyle = makeStyles((theme) => ({
  breadcrumb: {
    marginBottom: "16px",
  },
  breadcrumbItem: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    "& p": {
      margin: "0",
    },
  },
  selectedBreadcrumbItem: {
    color: theme.palette.text.red,
  },
  upcomingBreadcrumbItem: {
    color: theme.palette.text.disabled,
  },
}));

const StepBreadcrumb = ({ currentStep }) => {
  const classes = useStyle();
  const theme = useTheme();
  const desktopUpScreen = useMediaQuery(theme.breakpoints.up("desktop"));

  return (
    desktopUpScreen && (
      <Breadcrumbs
        className={classes.breadcrumb}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb">
        {[
          STEPS.PLAN_STAY.STEPPER_TITLE,
          STEPS.CHOOSE_ROOMS.STEPPER_TITLE,
          STEPS.CUSTOMIZE_STAY.STEPPER_TITLE,
          STEPS.PAYMENT.STEPPER_TITLE,
        ].map((description, index) => {
          const step = index + 1;
          const isSelected = currentStep === step;
          const isUpcoming = step > currentStep;
          return (
            <div
              className={clsx(classes.breadcrumbItem, {
                [classes.selectedBreadcrumbItem]: isSelected,
                [classes.upcomingBreadcrumbItem]: isUpcoming,
              })}
              key={index}>
              {desktopUpScreen || isSelected ? (
                <Typography variant="body2" component="p">
                  {description}
                </Typography>
              ) : null}
            </div>
          );
        })}
      </Breadcrumbs>
    )
  );
};

StepBreadcrumb.propTypes = {
  currentStep: PropTypes.number,
};

export default StepBreadcrumb;
