import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useSignInWithFirebaseToken } from "hooks";
import { useSearchParams } from "hooks";
import { makeStyles } from "@material-ui/core/styles";

import EnquirySideBarModal from "components/EnquirySideBarModal";
import FacilityBookingSideBarModal from "components/FacilityBookingSideBarModal";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  facilityPageRoot: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      // To accommodate fixed button at bottom of page on mobile
      marginBottom: theme.spacing(10),
    },
  },
}));

const EmbeddedAllFacilitiesBookingPage = (props) => {
  // Restore the booking confirmation if available. It was base64 encoded, and json stringified
  const queryParams = queryString.parse(window.location.search);
  const bookingConfirmation = queryParams.bookingConfirmationString
    ? JSON.parse(atob(queryParams.bookingConfirmationString))
    : null;
  const classes = useStyles();
  const searchParams = useSearchParams();
  const params = useParams();
  useSignInWithFirebaseToken(searchParams.fbauthtoken);
  const [enquiryMode, setEnquiryMode] = useState(false);
  // In the case we redirected from a successful open the modal to show success message.

  const properties = useSelector((state) => state.property.properties);
  const [property, setProperty] = useState(null);

  // Enquiry Side Bar Modal Open
  const handleEnquirySideBarModalOpen = () => {
    setEnquiryMode(true);
  };

  const handleCloseEnquiryPage = () => {
    setEnquiryMode(false);
  };
  // END//

  useEffect(() => {
    const property = properties.find((property) => property.slug === params.propertySlug);
    setProperty(property);
  }, [params.propertySlug]);

  useEffect(() => {
    if (!property) return;

    setEnquiryMode(props.launchInEnquiryMode);
  }, [params, property]);

  return (
    <div className={classes.facilityPageRoot}>
      {enquiryMode ? (
        <EnquirySideBarModal
          embedded
          open
          chooseFacility
          hideBackButton={props.launchInEnquiryMode}
          onClose={handleCloseEnquiryPage}
          onNotifOpen={props.onNotifOpen}
          property={property}
        />
      ) : (
        <FacilityBookingSideBarModal
          embedded
          open
          chooseFacility
          onNotifOpen={props.onNotifOpen}
          property={property}
          handleEnquirySideBarModalOpen={handleEnquirySideBarModalOpen}
          bookingConfirmation={bookingConfirmation}
        />
      )}
    </div>
  );
};

EmbeddedAllFacilitiesBookingPage.propTypes = {
  launchInEnquiryMode: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onNotifOpen: PropTypes.func,
};

export default EmbeddedAllFacilitiesBookingPage;
