import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper, Box, Stepper, Step, StepLabel, Link } from "@material-ui/core";

import downloadGooglePlayIcon from "images/get_in_on_google_play.svg";
import downloadAppleStoreIcon from "images/download_on_apple_store.svg";
import MolliesAppLogo from "images/mollies_app_logo.png";
import QRCodeImage from "images/get_app_qr_code.png";

const useStyles = makeStyles((theme) => ({
  appFeaturesStepper: {
    padding: "0",
    background: "none",
  },
  centered: {
    textAlign: "center",
  },
  appLogo: {
    width: "45px",
    marginRight: theme.spacing(2),
  },
  featureItem: {
    marginBottom: theme.spacing(1),
  },
}));

const GetMolliesApp = () => {
  const classes = useStyles();

  const mobileAppUser = useSelector((state) => state.user.mobileAppUser);

  return mobileAppUser ? null : (
    <Paper>
      <Box p={3}>
        <Grid container alignItems="center" wrap="nowrap" direction="row">
          <img className={classes.appLogo} src={MolliesAppLogo} alt="Mollie's App Logo" />
          <Typography variant="h5" color="textPrimary">
            Get the Mollie&apos;s app!
          </Typography>
        </Grid>
        <Box mt={2} mb={1}>
          <Typography variant="subtitle1" color="textPrimary" gutterBottom>
            Why download our app?
          </Typography>
        </Box>
        <Stepper orientation="vertical" connector={null} className={classes.appFeaturesStepper}>
          {[
            "Access your digital room key",
            "Book our motels and diners",
            "Speak to staff with our chat service",
            "Control the TV from your phone",
            "Manage your EV charging",
          ].map((appFeature) => (
            <Step active={true} key={appFeature}>
              <StepLabel className={classes.featureItem}>
                <Typography variant="body1" color="textSecondary">
                  {appFeature}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box mt={2}>
          <Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
            <Grid item>
              <img src={QRCodeImage} alt="Qr Code" width="126px" />
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Link href="https://apps.apple.com/gb/app/mollies/id1448501507" target="_blank">
                  <img src={downloadAppleStoreIcon} alt="Download on App store" />
                </Link>

                <Link
                  href="https://play.google.com/store/apps/details?id=com.mollies.mobile&hl=en_CA&gl=US"
                  target="_blank">
                  <img src={downloadGooglePlayIcon} alt="Get it on Google Play" />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

export default GetMolliesApp;
