import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "react-dates/initialize";
import { DayPickerRangeController } from "react-dates";
import { START_DATE } from "react-dates/constants";
import "react-dates/lib/css/_datepicker.css";

import { formatCurrency } from "utils/helpers";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dayCell: {
    width: "38px",
    height: "38px",
    padding: "2px",

    ".CalendarDay__selected > &": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },

    ".CalendarDay__selected_start > &": {
      borderRadius: "4px 0px 0px 4px",
    },

    ".CalendarDay__selected_end > &": {
      borderRadius: "0px 4px 4px 0px",
    },
  },
  dayCellDate: {
    lineHeight: "inherit",
    marginBottom: "-3px",
  },
  dayCellPrice: {
    // Custom style
    fontFamily: `Apercu, "Helvetica", "Arial", sans-serif`,
    fontStyle: "normal",
    fontSize: "10px",
    lineHeight: "12px",
    fontWeight: 400,
    letterSpacing: "0.4px",
  },
  dayCellRestricted: {
    outlineWidth: "1px",
    outlineStyle: "solid",
    outlineOffset: "-2px",
    outlineColor: theme.palette.primary.main,

    /* Show a different treatment for the start of a selection */
    ".CalendarDay__selected_start &": {
      outlineColor: theme.palette.grey[50],
      outlineOffset: "-4px",
    },

    /* Restricted dates only apply for arrival dates, so don't show during / end of the selection */
    ".CalendarDay__selected_span &, .CalendarDay__selected_end &": {
      outline: "none",
    },
  },
  key: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  keyIndicator: {
    width: "20px",
    height: "20px",
    marginRight: theme.spacing(1),
  },
  datePickerContainer: {
    /* react-dates date range picker style overrides */

    // Modifies the container
    "& .DayPicker": {
      background: "transparent",
    },

    // The month grid
    "& .CalendarMonth, & .CalendarMonthGrid": {
      background: "transparent",
    },

    // The month name
    "& .CalendarMonth_caption ": {
      color: theme.palette.text.primary,
    },

    // The month forward / back buttons
    "& .DayPickerNavigation_button": {
      background: "transparent",
      border: "none",
    },

    // Color the arrow on forward / back buttons
    "& .DayPickerNavigation_svg__horizontal": {
      fill: theme.palette.text.primary,
    },

    // Day of week (Mon - Sun) headers
    "& .DayPicker_weekHeader_li ": {
      color: theme.palette.text.disabled,
    },

    // Modifies all days
    "& .CalendarDay": {
      background: "transparent",
      color: theme.palette.text.primary,
      border: "none !important" /* we never want to show borders so make important */,
    },

    // Modifies all days hover effect
    "& .CalendarDay:hover": {
      background: theme.palette.primary.hover,
      border: "none",
    },

    // Will edit style of items between the range
    "& .CalendarDay__hovered_span": {
      background: theme.palette.primary.hover,
      border: "none",
    },

    // Modifies all dates outside the range
    "& .CalendarDay__blocked_out_of_range, & .CalendarDay__blocked_out_of_range:hover": {
      background: "transparent",
      color: theme.palette.text.disabled,
    },

    // Will edit everything selected
    "& .CalendarDay__selected_span": {
      background: theme.palette.primary.active,
      color: theme.palette.text.primary,
      border: "none",
    },

    // Hover effect for selected dates in a range
    "& .CalendarDay__selected_span:hover": {
      background: theme.palette.primary.border,
      border: "none",
    },

    // Will edit selected date or the endpoints of a range of dates
    "& .CalendarDay__selected": {
      color: theme.palette.grey[50],
    },

    // /* Style the start and end date with custom border radius */
    "& .CalendarDay__selected_start": {
      background: theme.palette.primary.active,
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",

      "&.CalendarDay:hover": {
        background: theme.palette.primary.active,
        border: "none",
      },
    },

    "& .CalendarDay__selected_end": {
      background: theme.palette.primary.active,
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",

      "&.CalendarDay:hover": {
        background: theme.palette.primary.active,
        border: "none",
      },
    },
  },
}));

const StayDatesPicker = (props) => {
  const classes = useStyles();

  const [focusedInput, setFocusedInput] = useState(START_DATE);

  const onDatesChanged = ({ startDate, endDate }) => {
    props.checkInDateUpdated(startDate ? startDate.toDate() : null);
    props.checkOutDateUpdated(endDate ? endDate.toDate() : null);
  };

  const onFocusChange = (focusedInput) => {
    setFocusedInput(
      // Force the focusedInput to always be truthy so that dates are always selectable
      !focusedInput ? START_DATE : focusedInput
    );
  };

  const renderMonthContent = ({ month }) => {
    return (
      <Typography variant="body1" color="textPrimary">
        {month.format("MMMM YYYY")}
      </Typography>
    );
  };

  const isOutsideRange = (date) => {
    const fromDate = props.allowDatesFrom || moment();
    const today = moment();

    // Block anything before the from date or today - whichever is latest
    const latest = moment.max(fromDate, today);
    return date.isBefore(latest, "days");
  };

  const renderDayContents = (date) => {
    const price = props.priceForDate ? props.priceForDate(date) : null;
    let priceString = "";

    if (price) {
      const amount = price.amount;
      const currency = price.currency;

      priceString = formatCurrency(currency, amount, false);
    }

    const isDateRestricted = props.isDateRestricted(date);

    return (
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        wrap="nowrap"
        className={classNames(
          classes.dayCell,
          isDateRestricted ? classes.dayCellRestricted : null
        )}>
        <Typography variant="body1" component="span" className={classes.dayCellDate}>
          {date.date()}
        </Typography>
        {price ? (
          <Typography variant="caption" component="span" className={classes.dayCellPrice}>
            {priceString}
          </Typography>
        ) : null}
      </Grid>
    );
  };

  return (
    <div className={classes.datePickerContainer}>
      <DayPickerRangeController
        startDate={moment(props.checkInDate)}
        endDate={moment(props.checkOutDate)}
        onDatesChange={onDatesChanged}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        renderMonthElement={renderMonthContent}
        renderDayContents={renderDayContents}
        noBorder={true}
        hideKeyboardShortcutsPanel={true}
        daySize={40}
        numberOfMonths={1}
        isOutsideRange={isOutsideRange}
        onNextMonthClick={props.onMonthChanged}
        onPrevMonthClick={props.onMonthChanged}
      />
      <Grid container direction="row" alignItems="center" className={classes.key}>
        <div className={classNames(classes.keyIndicator, classes.dayCellRestricted)}></div>
        <Typography variant="body2" component="span">
          Restrictions may apply
        </Typography>
      </Grid>
    </div>
  );
};

StayDatesPicker.propTypes = {
  checkInDate: PropTypes.instanceOf(Date),
  checkOutDate: PropTypes.instanceOf(Date),
  checkInDateUpdated: PropTypes.func,
  checkOutDateUpdated: PropTypes.func,
  priceForDate: PropTypes.func,
  isDateRestricted: PropTypes.func,
  onMonthChanged: PropTypes.func,
  allowDatesFrom: PropTypes.object,
};

export default StayDatesPicker;
