import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Grid,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import filter from "lodash/fp/filter";

import { formatFaIcon } from "utils/helpers";
import getFeaturedIcon from "utils/getFeaturedIcon";
import ImageCarousel from "components/ImageCarousel";
import ReadMoreTextBox from "components/ReadMoreTextBox/ReadMoreTextBox";
import SideBarModal from "components/SideBarModal";

const useStyles = makeStyles((theme) => ({
  parentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },

  modalTitleContainer: {
    marginTop: "30px",
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(2),
    marginBottom: "1rem",
    [theme.breakpoints.up("tablet")]: {
      marginLeft: theme.spacing(5),
    },
  },
  roomDetailsModalContent: {
    height: "100%",
    width: "100%",
    overflow: "scroll",
    position: "absolute",
    right: 0,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  galleryContainer: {
    overflow: "hidden",
    position: "relative",
    maxHeight: "60%",
  },

  roomContainer: {
    position: "relative",
    flexDirection: "column",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginBottom: 0,
    justifyContent: "center",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  descriptionRow: {
    paddingTop: theme.spacing(3),
  },
  roomDescription: {
    whiteSpace: "pre-wrap",
  },

  detailIcon: {
    fontSize: "20px",
    textAlign: "center",
  },
  amenity: {
    width: "100%",
    [theme.breakpoints.up("tablet")]: {
      width: "50%",
    },
  },
  featureIcon: {
    minWidth: "25px",
    maxWidth: "25px",
    display: "flex",
    justifyContent: "center",
  },
  featuredFeatureContainer: {
    display: "flex",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  amenityContent: {
    flexWrap: "nowrap",
    flexDirection: "row",
    width: "100%",
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gap: theme.spacing(2),
  },

  detailTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: "100%",
  },

  availableUnit: {
    width: "100%",
    backgroundColor: theme.palette.background.componentBackground1,
    [theme.breakpoints.up("tablet")]: {
      width: "50%",
    },
  },
  buttonCTA: {
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("tablet")]: {
      marginBottom: 0,
    },
  },
  buttonActionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(5),
    gap: theme.spacing(2),
  },
}));
const FacilityDetailsSideBarModal = ({
  roomDetails = {},
  open,
  onClose,
  selectedProperty,
  handleEnquirySideBarModalOpen,
  setSelectedRoomId,
  handleBookingSideBarModalOpen,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));

  const handleEnquiryClick = () => {
    onClose();
    handleEnquirySideBarModalOpen();
  };

  const handleBookingClick = (roomId = null) => {
    onClose();
    setSelectedRoomId(roomId);
    handleBookingSideBarModalOpen();
  };

  const featuredIcon = getFeaturedIcon(roomDetails?.feature_list);

  const handleRemoveFetauredIcon = (featureList) =>
    filter((featureItem) => !featureItem?.featured)(featureList);

  return Object.keys(roomDetails).length > 0 ? (
    <SideBarModal open={open} onClose={onClose} modalTitle="Room details">
      <Grid container className={classes.parentContainer}>
        <Grid container className={classes.galleryContainer}>
          <ImageCarousel
            images={roomDetails?.gallery?.map((g) => g.image).filter((i) => !!i)}
            isHeaderCarousel={true}
          />
        </Grid>
        {tabletUpScreen && (
          <Box className={classes.buttonActionsContainer}>
            <Button
              color="primary"
              variant="outlined"
              className={classes.buttonCTA}
              onClick={() => handleBookingClick(roomDetails.id)}>
              Book now
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonCTA}
              onClick={handleEnquiryClick}>
              Enquire now
            </Button>
          </Box>
        )}
        <Grid item className={classes.modalTitleContainer}>
          <Typography color="primary" variant={tabletUpScreen ? "subtitle1" : "subtitle2"}>
            {selectedProperty?.name}
          </Typography>
          <Typography variant={tabletUpScreen ? "h2" : "h4"}>{roomDetails?.title}</Typography>
        </Grid>
        <Grid container className={classes.roomContainer}>
          <Grid item sm={6} className={classes.descriptionCol}>
            {roomDetails?.tag_line && (
              <Typography
                variant={tabletUpScreen ? "h4" : "h6"}
                className={classes.text}
                style={{ whiteSpace: "nowrap" }}>
                {roomDetails.tag_line}
              </Typography>
            )}
            <Box className={classes.featuredFeatureContainer}>
              {featuredIcon && (
                <FontAwesomeIcon
                  className={classes.detailIcon}
                  icon={formatFaIcon(featuredIcon?.icon)}
                />
              )}
              <Typography variant={"subtitle1"}>Capacity: {featuredIcon?.description}</Typography>
            </Box>
            <Grid className={classes.descriptionRow}>
              <ReadMoreTextBox content={roomDetails?.description} sliceLength={500} />
            </Grid>
            <Divider className={classes.divider} />
            <Grid container direction="column">
              <Grid container>
                <Typography variant={tabletUpScreen ? "h4" : "h6"} className={classes.detailTitle}>
                  In every room
                </Typography>
              </Grid>
              <Grid container spacing={1}>
                {handleRemoveFetauredIcon(roomDetails?.feature_list)?.map((feature) => (
                  <Grid item container className={classes.amenity} key={feature.id}>
                    <Grid container className={classes.amenityContent}>
                      {feature?.icon && (
                        <>
                          <Grid item className={classes.featureIcon}>
                            {feature.icon && (
                              <FontAwesomeIcon
                                className={classes.detailIcon}
                                icon={formatFaIcon(feature?.icon)}
                              />
                            )}
                          </Grid>
                          <Typography variant="subtitle1" gutterBottom>
                            {feature.title}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          {!tabletUpScreen && (
            <>
              <Divider className={classes.divider} />
              <Button
                color="primary"
                variant="contained"
                className={classes.buttonCTA}
                onClick={handleEnquiryClick}>
                Enquire now
              </Button>
              <Button
                color="primary"
                variant="outlined"
                className={classes.buttonCTA}
                onClick={() => handleBookingClick(roomDetails.id)}>
                Book now
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </SideBarModal>
  ) : null;
};

FacilityDetailsSideBarModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  roomDetails: PropTypes.object,
  onEnquirySelect: PropTypes.func,
  handleEnquirySideBarModalOpen: PropTypes.func,
  selectedProperty: PropTypes.object,
  setSelectedRoomId: PropTypes.func,
  handleBookingSideBarModalOpen: PropTypes.func,
};

export default FacilityDetailsSideBarModal;
