import { toSafeInteger } from "lodash";
import validator from "validator";

const validate = (formField) => {
  const isEmpty = formField.value === "" || formField.value === null;

  if (formField.isRequired && isEmpty) {
    return "This field is required";
  } else if (
    formField.inputProps?.min != null &&
    !isEmpty &&
    toSafeInteger(formField.value) < toSafeInteger(formField.inputProps.min)
  ) {
    return `Should be greater or equal to ${formField.inputProps.min}`;
  } else if (
    formField.inputProps?.max != null &&
    !isEmpty &&
    toSafeInteger(formField.value) > toSafeInteger(formField.inputProps.max)
  ) {
    return `Should be lesser or equal to ${formField.inputProps.max}`;
  } else {
    null;
  }
};

export const validateRequiredInputs = (formFields) => {
  let isValid = true;

  Object.keys(formFields).forEach((key) => {
    const field = formFields[key];
    const validationError = validate(field);
    if (validationError != null) {
      field.error = validationError;
      isValid = false;
    } else {
      delete field.error;
    }
  });

  return isValid;
};

const validators = {
  firstName: (value) => !validator.isEmpty(value),
  lastName: (value) => !validator.isEmpty(value),
  email: (value) => validator.isEmail(value),
  phoneNumber: (value) => validator.isMobilePhone(value),
};

// This function uses validators above
export const validateContactDetails = (formFields) => {
  let isValid = true;

  Object.keys(formFields).forEach((key) => {
    const field = formFields[key];
    if (field.isRequired && (field.value === "" || field.value === null)) {
      field.error = true;
      isValid = false;
    } else if (validators[key]) {
      const validation = validators[key](field?.value);
      if (!validation) {
        field.error = true;
        isValid = false;
      } else {
        delete field.error;
      }
    } else {
      delete field.error;
    }
  });

  return isValid;
};

export const applyErrorStateToFormFields = (formFields) => {
  let updatedFormFields = { ...formFields };
  Object.keys(updatedFormFields).forEach((key) => {
    const field = updatedFormFields[key];
    field.error = validate(field);
  });

  return updatedFormFields;
};

export const applyErrorStateToContactFormFields = (formFields) => {
  let updatedFormFields = { ...formFields };
  Object.keys(updatedFormFields).forEach((key) => {
    const field = updatedFormFields[key];
    if (validators[key]) {
      const validation = validators[key](field?.value);
      field.error = !validation;
    } else {
      delete field.error;
    }
  });

  return updatedFormFields;
};
