import moment from "moment";
import queryString from "query-string";
import pick from "lodash/fp/pick";

import { EVPARKING_UNITGROUP_CODE } from "utils/constants";

// PMS service pricing unit types
export const PRICING_UNIT = {
  PERSON: "Person",
  ROOM: "Room",
  STAY: "Stay",
};

// CMS service guest types
export const GUEST_TYPE = {
  ADULTS: "Adults",
  CHILDREN: "Children",
};

export const childrenAgesArray = (numberOfChildren) => {
  return Array(parseInt(numberOfChildren)).fill(0);
};

export const childrenAgesStr = (numberOfChildren) => {
  return `${childrenAgesArray(numberOfChildren).toString()}`;
};

export const childrenCount = (availCheckParams) => {
  let childrenCount = 0;
  if (availCheckParams.childrenAges) {
    childrenCount = availCheckParams.childrenAges.split(",").length;
  }
  return childrenCount;
};

export const guestCountForReservation = (reservation) => {
  // Parking reservations have 0 guests
  if (reservation.unitGroup.code === EVPARKING_UNITGROUP_CODE) {
    return 0;
  } else {
    const adults = reservation.adults;
    const children = (reservation.childrenAges || []).length;

    return adults + children;
  }
};

export const guestCountForReservations = (reservations) => {
  return reservations.reduce((acc, res) => acc + guestCountForReservation(res), 0);
};

const chooseRoomParamsOrder = [
  "propertyId",
  "arrival",
  "departure",
  "adults",
  "childrenAges",
  "promoCode",
  "corporateCode",
];

export const getChooseRoomQueryString = (availabilityParamsObj, roomsOccupancyObj, nextRoomCount) =>
  queryString.stringify(
    {
      propertyId: availabilityParamsObj.propertyId,
      arrival: availabilityParamsObj.arrival,
      departure: availabilityParamsObj.departure,
      adults: roomsOccupancyObj[nextRoomCount].adults,
      childrenAges: childrenAgesStr(roomsOccupancyObj[nextRoomCount].children),
      promoCode: availabilityParamsObj.promoCode,
      corporateCode: availabilityParamsObj.corporateCode,
    },
    {
      sort: (a, b) => chooseRoomParamsOrder.indexOf(a) - chooseRoomParamsOrder.indexOf(b),
    }
  );

export const nightCount = (availCheckParams) => {
  const arrival = moment(availCheckParams.arrival);
  const departure = moment(availCheckParams.departure);

  return departure.diff(arrival, "days");
};

export const serializeFirebaseUser = (userData) => {
  if (userData.displayName) {
    return {
      id: userData.userId,
      displayName: userData.displayName,
      email: userData.email,
      emailVerified: userData.emailVerified,
      phoneNumber: userData.phoneNumber,
    };
  } else {
    return {
      id: userData.user_id,
      displayName: userData.name,
      email: userData.email,
      emailVerified: userData.email_verified,
      phoneNumber: null,
    };
  }
};

const splitName = (fullName) => {
  // Split by | if present, otherwise by space
  return fullName.includes("|") ? fullName.split("|") : fullName.split(" ");
};

export const generateFirstName = (fullName) => {
  return splitName(fullName).slice(0, -1).join(" ").trim();
};

export const generateLastName = (fullName) => {
  return splitName(fullName).slice(-1)[0].trim();
};

export const formatCurrency = (currencyStr, value, decimals = true) => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currencyStr,
    minimumFractionDigits: decimals ? 2 : 0,
    maximumFractionDigits: decimals ? 2 : 0,
  }).format(value);
};

export const formatQuantity = (quantity, item) => {
  return `${quantity} ${quantity > 1 ? item + "s" : item}`;
};

export const initialsForName = (name) => {
  return name
    .match(/(^\S\S?|\b\S)?/g)
    .join("")
    .match(/(^\S|\S$)?/g)
    .join("")
    .toUpperCase();
};

export const isSingleRoomModeFromSearchParams = (params) => params.paymentTerminalId != null;

export const getCommonSearchParams = pick([
  "arrival",
  "corporateCode",
  "departure",
  "promoCode",
  "propertyId",
  "source",
  "paymentTerminalId",
]);

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

// Formats icon from cms for use with font awesome
export const formatFaIcon = (iconName) => {
  let [collection, icon] = iconName.split(" ");
  icon = icon.replace("fa-", "", 1);
  return [collection, icon];
};

export const isEven = (number) => {
  return number % 2 === 0;
};
export const isOdd = (number) => {
  return number % 2 !== 0;
};
