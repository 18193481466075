import React from "react";
import PropTypes from "prop-types";

import { Box, Grid, Link, Paper, Typography, makeStyles } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/PhoneOutlined";
import EmailIcon from "@material-ui/icons/EmailOutlined";

const useStyles = makeStyles((theme) => ({
  contactIcon: {
    color: theme.palette.text.secondary,
  },
}));

const ContactUsSection = (props) => {
  const classes = useStyles();

  return (
    <Paper>
      <Box p={2}>
        <Box pb={1}>
          <Typography variant="h5" color="textPrimary" gutterBottom>
            Contact us
          </Typography>
        </Box>
        <Grid container spacing={1} direction="column">
          {props.phoneNumber ? (
            <Grid item>
              <Grid container direction="row">
                <Box mr={1}>
                  <PhoneIcon className={classes.contactIcon} />
                </Box>
                <Link href={`tel:${props.phoneNumber}`}>
                  <Typography variant="subtitle1" color="primary">
                    {props.phoneNumber}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          ) : null}
          {props.emailAddress ? (
            <Grid item>
              <Grid container direction="row">
                <Box mr={1}>
                  <EmailIcon className={classes.contactIcon} />
                </Box>
                <Link href={`mailto:${props.emailAddress}`}>
                  <Typography variant="subtitle1" color="primary">
                    {props.emailAddress}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Paper>
  );
};

ContactUsSection.propTypes = {
  phoneNumber: PropTypes.string,
  emailAddress: PropTypes.string,
};

export default ContactUsSection;
