import React from "react";
import PropTypes from "prop-types";

import { makeStyles, Dialog } from "@material-ui/core";

import FindBookingContent from "./FindBookingContent";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "415px",
    maxWidth: "90%",
    margin: "auto",
    padding: "2rem 1.6rem",
  },
  fieldIcon: {
    marginRight: "16px",
    color: theme.palette.primary.main,
  },
  inputContainer: {
    marginTop: "5px",
  },
  input: {
    flexGrow: 2,
  },
  hidden: {
    display: "none",
  },
}));

const FindBookingModal = (props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      PaperProps={{ elevation: 8, className: classes.dialogPaper }}>
      <FindBookingContent
        onCancel={() => {
          props.setOpen(false);
        }}
        onNotifOpen={props.onNotifOpen}
      />
    </Dialog>
  );
};

FindBookingModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onNotifOpen: PropTypes.func,
};

export default FindBookingModal;
