import React from "react";
import "date-fns";
import { Button, Grid, Typography, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import InfoIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  informationBox: {
    backgroundColor: theme.palette.info.lightBg,
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  infoTextMargin: { marginBottom: theme.spacing(1) },
  buttonActionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: "column",
    },
  },
  infoCTAOverride: { justifyContent: "flex-start", marginTop: theme.spacing(3) },
  infoIcon: {},
  enquiryNowButton: {
    color: theme.palette.info.main,
    paddingLeft: 0,
    paddingTop: theme.spacing(2),
  },
}));

const NoAvailabilityEnquire = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));

  return (
    <Grid
      container
      direction={tabletUpScreen ? "row" : "column"}
      className={classes.informationBox}>
      <Grid item xs={1}>
        <InfoIcon className={classes.infoIcon} />
      </Grid>
      <Grid item xs={11}>
        <Typography className={classes.infoTextMargin} style={{ fontWeight: 700 }}>
          {props.canEnquire ? "No availability, but don’t fret..." : "No availability"}
        </Typography>
        <Typography>
          {props.canEnquire
            ? "At the moment, there is no online availability for the day you selected. We may be able to make space for you. Please get in touch and we’ll see what we can do."
            : "At the moment, there is no availability for the day you selected. Please try another date / time."}
        </Typography>
        {props.canEnquire ? (
          <Button
            color="primary"
            variant="text"
            className={classes.enquiryNowButton}
            onClick={props.handleEnquiry}>
            Enquire now
          </Button>
        ) : (
          <Button
            color="primary"
            variant="text"
            className={classes.enquiryNowButton}
            onClick={props.handleChangeSearch}>
            Change search
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

NoAvailabilityEnquire.propTypes = {
  canEnquire: PropTypes.bool,
  handleChangeSearch: PropTypes.func,
  handleEnquiry: PropTypes.func,
};

export default NoAvailabilityEnquire;
