import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSignInWithFirebaseToken } from "hooks";
import { useSearchParams } from "hooks";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { get } from "lodash";

import { formatFaIcon } from "utils/helpers";
import api from "utils/api";
import ChooseSpaceSideBarModal from "components/ChooseSpaceSideBarModal";
import EnquirySideBarModal from "components/EnquirySideBarModal";
import FacilityBookingSideBarModal from "components/FacilityBookingSideBarModal";
import FacilityDetailsSideBarModal from "components/FacilityDetailsSideBarModal";
import HeadingV2 from "components/HeadingV2";
import Highlights from "components/Highlights";
import molliesLogoRed from "images/Mollies_Ico_Red.svg";
import Navbar from "components/Navbar";
import InformationMenu from "components/InformationMenu";
import PhotoGallery from "components/PhotoGallery";
import ReadMoreTextBox from "components/ReadMoreTextBox";
import RoomSlider from "components/RoomSlider";
import SideBarModal from "components/SideBarModal";
import StickyMenu from "components/StickyMenu";
import queryString from "query-string";
import LoadingSpinner from "components/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  facilityPageRoot: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      // To accommodate fixed button at bottom of page on mobile
      marginBottom: theme.spacing(10),
    },
  },
  mainContent: {
    flexDirection: "column",
    margin: "auto",
    width: theme.contentSize.mobilePageWidth,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      maxWidth: theme.contentSize.maxPageWidth,
    },
  },
  priceSummaryBarContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginRight: "0",
    gap: "16px",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      marginBottom: "0",
    },
  },
  availabilityButton: {
    fontSize: "16px",
    whiteSpace: "nowrap",
    alignSelf: "flex-end",
    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
      alignSelf: "flex-start",
    },
  },
  fullWidthFixedButtonContainer: {
    position: "fixed",
    bottom: "0",
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderTop: "solid 1px",
    borderColor: theme.palette.other.lineDetail,
  },
  fullWidthFixedButton: { width: "100%" },
  highlightContainer: { marginInline: "auto" },
  photoGalleryContainer: {
    width: "100%",
    maxWidth: theme.contentSize.maxContentWidth,
    margin: "0 auto",
    height: "100%",
    maxHeight: "600px",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      maxWidth: theme.contentSize.maxContentWidth,
    },
  },
  text: {
    color: theme.palette.text.primary,
  },
  divider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  sectionTitle: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      marginBottom: theme.spacing(5),
    },
  },
  featuresContainer: {
    justifyContent: "space-between",
  },
  featureItemContainer: {
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
    paddingRight: theme.spacing(2),
    flexWrap: "nowrap",
  },
  featureIcon: {
    minWidth: "25px",
    maxWidth: "25px",
    display: "flex",
    justifyContent: "center",
  },
  detailIcon: {
    fontSize: "20px",
    textAlign: "center",
  },
  stickyMenu: {
    zIndex: 50,
  },
  roomSlider: {
    width: "100%",
    maxWidth: theme.contentSize.maxContentWidth,
    margin: "0 auto",
  },
  modalInfoContent: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      padding: theme.spacing(5),
    },
  },
  modalImage: {
    marginTop: theme.spacing(3),
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "320px",
    objectFit: "cover",
  },
  bottomWhiteSpace: {
    paddingTop: theme.spacing(40),
    display: "flex",
    justifyContent: "center",
  },
  bottomImage: {
    paddingBottom: theme.spacing(4),
    width: "25%",
  },
  heading: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
  },
}));

const menuItems = [
  { name: "Overview", label: "Overview", id: "1" },
  { name: "Rooms", label: "Rooms", id: "2" },
  { name: "Menus", label: "Menus", id: "3" },
];

const FacilityPage = (props) => {
  // Restore the booking confirmation if available. It was base64 encoded, and json stringified
  const queryParams = queryString.parse(window.location.search);
  const bookingConfirmation = queryParams.bookingConfirmationString
    ? JSON.parse(atob(queryParams.bookingConfirmationString))
    : null;
  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));
  const classes = useStyles();
  const searchParams = useSearchParams();
  const params = useParams();
  useSignInWithFirebaseToken(searchParams.fbauthtoken);
  const [isLoading, setIsLoading] = useState(true);
  const spacerHeight = tabletUpScreen ? theme.spacing(10) : theme.spacing(5);
  const fullContentWidthRef = useRef(null);
  const mainContentRef = useRef(null);
  const [enquirySideBarModalOpen, setEnquirySideBarModalOpen] = useState(false);
  // In the case we redirected from a successful open the modal to show success message.
  const [bookingSideBarModalOpen, setBookingSideBarModalOpen] = useState(!!bookingConfirmation);
  const [roomDetailsModalOpen, setRoomDetailsModalOpen] = useState(false);
  const [chooseRoomModalOpen, setChooseRoomModalOpen] = useState(false);
  const [roomDetailsToShow, setRoomDetailsToShow] = useState({});
  const [facility, setFacility] = useState({});
  const [facilityServices, setFacilityServices] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [generalModalOpen, setGeneralModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const hasHighlights = facility?.highlights?.length > 0;

  const properties = useSelector((state) => state.property.properties);
  const property = properties.find((property) => property.slug === params.propertySlug);

  // Enquiry Side Bar Modal Open
  const handleEnquirySideBarModalOpen = () => {
    handleBookingSideBarModalClose(false);
    setEnquirySideBarModalOpen(true);
  };

  const handleEnquirySideBarModalClose = () => {
    setEnquirySideBarModalOpen(false);
  };
  // END//

  const handleBookingSideBarModalOpen = () => {
    setBookingSideBarModalOpen(true);
  };

  const handleBookingSideBarModalClose = () => {
    setBookingSideBarModalOpen(false);
  };

  const handleShowRoomDetails = (roomId) => {
    setRoomDetailsToShow(findRoomDetailsFromId(roomId, facilityServices));
    setRoomDetailsModalOpen(true);
  };

  const handleRoomDetailsModalClose = () => {
    setRoomDetailsModalOpen(false);
  };

  const handleChooseRoomModalOpen = () => {
    setChooseRoomModalOpen(true);
  };

  const handleChooseRoomModalClose = () => {
    setChooseRoomModalOpen(false);
  };

  const findRoomDetailsFromId = (selectedId, rooms) =>
    rooms.filter(({ id }) => id === selectedId).pop();

  const handleOpenGeneralModal = ({ title, text, image }) => {
    setModalContent({ title, text, image });
    setGeneralModalOpen(true);
  };

  const handleCloseGeneralModal = () => {
    setModalContent(null);
    setGeneralModalOpen(false);
  };

  useEffect(() => {
    if (!property) return;

    let apiFacilities = api.getFacility(property.id, params.facilitySlug);

    apiFacilities
      .then((response) => {
        const res = get(response, ["data", 0]);
        setFacility(res);
        setIsLoading(false);
        return res;
      })
      .then((facility) =>
        api.getFacilityServices(facility?.id).then((response) => {
          const res = get(response, ["data"]);
          setFacilityServices(res);
        })
      );
  }, [params, property]);

  const createHTML = (text) => {
    return {
      __html: text,
    };
  };

  return (
    <div className={classes.facilityPageRoot}>
      <Navbar withProfile onNotifOpen={props.onNotifOpen} />
      {isLoading ? (
        <LoadingSpinner loading={isLoading} size={128} />
      ) : (
        <>
          {tabletUpScreen && (
            <Grid container className={classes.mainContent}>
              <HeadingV2
                titleText={facility?.name}
                location={property?.name}
                className={classes.heading}>
                <Box className={classes.priceSummaryBarContainer}>
                  {facility?.tag_line && (
                    <Typography
                      variant="h6"
                      className={classes.text}
                      style={{ whiteSpace: "nowrap" }}>
                      {facility.tag_line}
                    </Typography>
                  )}
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    className={classes.availabilityButton}
                    onClick={handleChooseRoomModalOpen}>
                    Enquire now
                  </Button>
                </Box>
              </HeadingV2>
            </Grid>
          )}
          <Box className={classes.photoGalleryContainer} ref={fullContentWidthRef}>
            <PhotoGallery medias={facility?.gallery} />
          </Box>
          {!tabletUpScreen && (
            <Grid container className={classes.mainContent}>
              <HeadingV2
                titleText={facility?.name}
                location={property?.name}
                className={classes.heading}
              />
            </Grid>
          )}
          <Grid container className={classes.mainContent} ref={mainContentRef}>
            {tabletUpScreen && (
              <>
                <StickyMenu className={classes.stickyMenu} menuItems={menuItems}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    className={classes.availabilityButton}
                    onClick={handleChooseRoomModalOpen}>
                    Enquire now
                  </Button>
                </StickyMenu>
              </>
            )}
            <Grid container>
              <Grid
                item
                xs={hasHighlights && tabletUpScreen ? 8 : 12}
                style={{
                  paddingRight: tabletUpScreen && hasHighlights ? theme.spacing(5) : 0,
                }}>
                <div style={{ height: `${spacerHeight}px`, visibility: `hidden` }} id="1"></div>
                <Typography className={classes.sectionTitle} variant={tabletUpScreen ? "h4" : "h6"}>
                  {facility?.subheading}
                </Typography>
                {facility?.description && (
                  <ReadMoreTextBox content={facility?.description} sliceLength={500} />
                )}
                <div style={{ height: `${spacerHeight}px`, visibility: `hidden` }} id="2"></div>
                <Box>
                  {facility?.feature_list?.length > 0 && (
                    <Typography className={classes.sectionTitle} variant="h4">
                      What comes as standard?
                    </Typography>
                  )}
                  <Grid container direction="row" className={classes.featuresContainer}>
                    {facility?.feature_list?.length > 0 &&
                      facility?.feature_list
                        .filter(({ title }) => title)
                        .map((feature) => {
                          return (
                            <Grid
                              key={feature.id}
                              container
                              direction="row"
                              className={classes.featureItemContainer}>
                              <Grid item className={classes.featureIcon}>
                                {feature.icon && (
                                  <FontAwesomeIcon
                                    className={classes.detailIcon}
                                    icon={formatFaIcon(feature?.icon)}
                                  />
                                )}
                              </Grid>
                              <Grid item>
                                <Typography variant={"subtitle1"}>{feature?.title}</Typography>
                                <Typography
                                  variant={"body2"}
                                  dangerouslySetInnerHTML={createHTML(
                                    feature?.description
                                  )}></Typography>
                              </Grid>
                            </Grid>
                          );
                        })}
                  </Grid>
                </Box>
              </Grid>
              {hasHighlights && (
                <Grid
                  item
                  xs={tabletUpScreen ? 4 : 12}
                  style={{
                    paddingLeft: tabletUpScreen && hasHighlights ? theme.spacing(5) : 0,
                  }}>
                  <Highlights
                    highlights={facility?.highlights}
                    className={classes.highlightContainer}
                  />
                </Grid>
              )}
            </Grid>
            <div style={{ height: `${spacerHeight}px`, visibility: `hidden` }} id="3"></div>
            {facilityServices.length > 0 && (
              <Box className={classes.roomSlider}>
                <Typography className={classes.sectionTitle} variant="h4">
                  Spaces
                </Typography>
                <RoomSlider
                  rooms={facilityServices}
                  onShowRoomDetailsClick={handleShowRoomDetails}
                  handleEnquirySideBarModalOpen={handleEnquirySideBarModalOpen}
                  setSelectedRoomId={setSelectedRoomId}
                />
              </Box>
            )}
            <InformationMenu
              infoSections={facility?.information_sections}
              handleOpenGeneralModal={handleOpenGeneralModal}
              beforeEach={(index) => (
                <div
                  style={{ height: `${spacerHeight}px`, visibility: `hidden` }}
                  id={(4 + index).toString()}
                />
              )}
            />
          </Grid>
          {!tabletUpScreen && (
            <Box className={classes.fullWidthFixedButtonContainer}>
              <Button
                color="primary"
                variant="contained"
                className={classes.fullWidthFixedButton}
                onClick={handleChooseRoomModalOpen}>
                Enquire now
              </Button>
            </Box>
          )}

          {/* We need to add some whitespace in the bottom so 'MenuItems' can scroll to 'Menu' */}
          <Box className={classes.bottomWhiteSpace}>
            <img className={classes.bottomImage} src={molliesLogoRed} alt="Mollies Logo" />
          </Box>
          <ChooseSpaceSideBarModal
            open={chooseRoomModalOpen}
            onClose={handleChooseRoomModalClose}
            facilityServices={facilityServices}
            property={property}
            onShowRoomDetailsClick={handleShowRoomDetails}
            handleEnquirySideBarModalOpen={handleEnquirySideBarModalOpen}
            handleBookingSideBarModalOpen={handleBookingSideBarModalOpen}
            setSelectedRoomId={setSelectedRoomId}
          />
          <EnquirySideBarModal
            open={enquirySideBarModalOpen}
            onClose={handleEnquirySideBarModalClose}
            onNotifOpen={props.onNotifOpen}
            facility={facility}
            facilityServices={facilityServices}
            property={property}
            selectedRoomId={selectedRoomId}
            handleChooseRoomModalOpen={handleChooseRoomModalOpen}
          />
          <FacilityBookingSideBarModal
            open={bookingSideBarModalOpen}
            onClose={handleBookingSideBarModalClose}
            onNotifOpen={props.onNotifOpen}
            facilityServices={facilityServices}
            facility={facility}
            property={property}
            selectedRoomId={selectedRoomId}
            setSelectedRoomId={setSelectedRoomId}
            handleEnquirySideBarModalOpen={handleEnquirySideBarModalOpen}
            handleChooseRoomModalOpen={handleChooseRoomModalOpen}
            bookingConfirmation={bookingConfirmation}
          />
          <FacilityDetailsSideBarModal
            open={roomDetailsModalOpen}
            onClose={handleRoomDetailsModalClose}
            roomDetails={roomDetailsToShow}
            selectedProperty={property}
            handleEnquirySideBarModalOpen={handleEnquirySideBarModalOpen}
            setSelectedRoomId={setSelectedRoomId}
            handleBookingSideBarModalOpen={handleBookingSideBarModalOpen}
          />
          <SideBarModal
            open={generalModalOpen}
            onClose={handleCloseGeneralModal}
            modalTitle={modalContent?.title}>
            <Box className={classes.modalInfoContent}>
              <Typography variant="h4" style={{ marginBottom: theme.spacing(2) }}>
                {modalContent?.title}
              </Typography>
              {modalContent?.text && <Typography>{modalContent?.text}</Typography>}
              {modalContent?.image?.url && (
                <img
                  className={classes.modalImage}
                  alt={modalContent?.image?.alternativeText}
                  src={modalContent?.image?.url}
                />
              )}
            </Box>
          </SideBarModal>
        </>
      )}
    </div>
  );
};

FacilityPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onNotifOpen: PropTypes.func,
};

export default FacilityPage;
