import React, { useEffect, useState } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import PropTypes from "prop-types";
import omit from "lodash/fp/omit";
import includes from "lodash/fp/includes";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Alert } from "@material-ui/lab";
import { SnackbarProvider, useSnackbar } from "notistack";
import { createBrowserHistory } from "history";

import "./App.css";
import CookieConsent from "components/CookieConsent";
import AuthPage from "components/AuthPage";
import PlanStay from "components/PlanStay";
import FacilityPage from "components/FacilityPage";
import EmbeddedFacilityBookingPage from "components/EmbeddedFacilityBookingPage";
import EmbeddedAllFacilitiesBookingPage from "components/EmbeddedAllFacilitiesBookingPage";
import ChooseRoom from "components/ChooseRoom";
import Checkout from "components/Checkout";
import ConfirmStay from "components/ConfirmStay";
import FinalizeBooking from "components/FinalizeBooking";
import Error from "components/Error";
import ErrorBoundary from "components/ErrorBoundary";
import FindBookingPage from "components/FindBookingPage";
import ManageBooking from "components/ManageBooking";
import Account from "components/Account";
import SelectAddOns from "components/SelectAddOns";
import { setAppTheme as setAppThemeRedux } from "features/booking/bookingSlice";
import { fetchProperties } from "features/property/propertySlice";
import { setMobileAppUser } from "features/user/userSlice";
import baseTheme from "theme";
import sohoSkinTheme from "sohoSkinTheme";
import Routes from "config/routes";

import { initFirebase } from "utils/firebase/firebase";
import { useSignInWithFirebaseToken } from "hooks";
import { giveCookieConsent, setAnalyticsSource, trackError, trackPageView } from "utils/analytics";
import { BookingSourceProvider } from "contexts/BookingSourceContext";

library.add(fas, far, fab, fal, fad);

const history = createBrowserHistory();

const App = () => {
  const dispatch = useDispatch();

  const [appTheme, setAppTheme] = useState(baseTheme);
  const storedAppTheme = useSelector((state) => state.booking.appTheme);
  const isOnPlanStay = includes(Routes.PLAN_STAY)(history.location.pathname);
  const isOnChooseRoom = includes(Routes.CHOOSE_ROOM)(history.location.pathname);
  const hasReservationInProgress =
    useSelector((state) => state.booking.roomReservations).length > 0;
  const hasBookingConfirmation = useSelector((state) =>
    Boolean(state.booking.bookingConfirmationId)
  );

  /* BEGIN NOTISTACK CUSTOMIZATION */
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const closeOnClick = () => closeSnackbar();
  const handleNotifOpen = (message, options = {}) => {
    options = {
      TransitionProps: { direction: "up" },
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      autoHideDuration: 4000,
      onClose: closeOnClick,
      content: (
        <Alert onClose={closeOnClick} severity={options.variant}>
          {message}
        </Alert>
      ),
      ...options,
    };
    enqueueSnackbar(message, options);

    if (options.variant === "error") {
      trackError(message, true);
    }
  };
  /* END NOTISTACK CUSTOMIZATION */

  const updateAppTheme = () => {
    const searchParams = queryString.parse(history.location.search);

    // Handle Redirect from soho
    if (isOnChooseRoom && searchParams.bookingChannel === "soho") {
      setAppTheme(sohoSkinTheme);
      dispatch(setAppThemeRedux("soho"));
    }
    // Handle Redirect from Mollies.com
    else if (
      isOnChooseRoom &&
      searchParams.bookingChannel !== "soho" &&
      !hasReservationInProgress
    ) {
      setAppTheme(baseTheme);
      dispatch(setAppThemeRedux("mollies"));
    }
    // Handle Refresh on other pages of Booking Flow Booking including Multi-Room Booking Flow
    else if (
      storedAppTheme === "soho" &&
      !isOnPlanStay &&
      (hasReservationInProgress || hasBookingConfirmation)
    ) {
      setAppTheme(sohoSkinTheme);
      dispatch(setAppThemeRedux("soho"));
    } else {
      setAppTheme(baseTheme);
      dispatch(setAppThemeRedux("mollies"));
    }
  };

  useEffect(() => {
    dispatch(fetchProperties());
  }, []);

  useEffect(() => {
    const searchParams = queryString.parse(history.location.search);

    if (searchParams.source) {
      setAnalyticsSource(searchParams.source);
    }

    updateAppTheme();

    // Track app users
    dispatch(setMobileAppUser(searchParams.source === "mobileapp"));

    // Track the first page view, then listen for history changes
    trackPageView(history.location.pathname);
    history.listen((location) => {
      trackPageView(location.pathname);
    });
  }, [history]);

  const authCompleted = () => {
    // Redirect user to the page they were trying to access before being redirected to login
    const redirectPath = history.location.state?.redirectPath;

    history.push(redirectPath || Routes.PLAN_STAY);
  };
  const redirectAuth = (redirectPath) => {
    history.push({
      pathname: Routes.LOGIN,
      search: history.location.search,
      state: {
        redirectPath,
      },
    });
  };

  let promoCode = "";
  let corporateCode = "";

  const handlePromoAndCorporateCodes = (props) => {
    //Will fetch and remove promo code and corporate code if found in query params and up
    const searchParams = queryString.parse(props.location.search);

    promoCode = searchParams.promoCode || props.location.state?.promoCode;
    corporateCode = searchParams.corporateCode || props.location.state?.corporateCode;

    if (searchParams.promoCode || searchParams.corporateCode) {
      props.history.replace({
        search: queryString.stringify(omit(["promoCode", "corporateCode"])(searchParams)),
        state: {
          promoCode,
          corporateCode,
        },
      });

      return <div></div>;
    }
  };

  // Setup firebase (auth and analytics)
  initFirebase();

  // Re-authenticate the user if needed. If there's an auth token
  // in the redux store, grab it and try to reauthenticate with it
  const authToken = useSelector((state) => state.user.firebaseToken);
  useSignInWithFirebaseToken(authToken);

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <Router history={history}>
        <BookingSourceProvider>
          <div>
            <ErrorBoundary
              fallbackUI={(_hasReactError) => (
                <PlanStay history={history} onNotifOpen={handleNotifOpen} />
              )}>
              <Switch>
                <Route exact path="/">
                  <Redirect to={Routes.PLAN_STAY} />
                </Route>
                <Route
                  path={Routes.PLAN_STAY}
                  render={(props) => <PlanStay {...props} onNotifOpen={handleNotifOpen} />}
                />
                <Route
                  path={`${Routes.PROPERTIES}/:propertySlug${Routes.EMBEDDED_FACILITY_BOOK}/:facilitySlug/:facilityServiceId`}
                  render={(props) => (
                    <EmbeddedFacilityBookingPage {...props} onNotifOpen={handleNotifOpen} />
                  )}
                />
                <Route
                  path={`${Routes.PROPERTIES}/:propertySlug${Routes.EMBEDDED_FACILITY_BOOK}`}
                  render={(props) => (
                    <EmbeddedAllFacilitiesBookingPage {...props} onNotifOpen={handleNotifOpen} />
                  )}
                />
                <Route
                  path={`${Routes.PROPERTIES}/:propertySlug${Routes.EMBEDDED_FACILITY_ENQUIRY}/:facilitySlug/:facilityServiceId`}
                  render={(props) => (
                    <EmbeddedFacilityBookingPage
                      launchInEnquiryMode={true}
                      {...props}
                      onNotifOpen={handleNotifOpen}
                    />
                  )}
                />
                <Route
                  path={`${Routes.PROPERTIES}/:propertySlug${Routes.EMBEDDED_FACILITY_ENQUIRY}`}
                  render={(props) => (
                    <EmbeddedAllFacilitiesBookingPage
                      launchInEnquiryMode={true}
                      {...props}
                      onNotifOpen={handleNotifOpen}
                    />
                  )}
                />
                <Route
                  path={`${Routes.PROPERTIES}/:propertySlug${Routes.FACILITIES}/:facilitySlug`}
                  render={(props) => <FacilityPage {...props} onNotifOpen={handleNotifOpen} />}
                />
                <Route
                  path={Routes.CHOOSE_ROOM}
                  render={(props) => {
                    handlePromoAndCorporateCodes(props);

                    return (
                      <ChooseRoom
                        {...props}
                        onNotifOpen={handleNotifOpen}
                        promoCode={promoCode}
                        corporateCode={corporateCode}
                      />
                    );
                  }}
                />
                <Route
                  path={Routes.SELECT_ADDONS}
                  render={(props) => <SelectAddOns {...props} onNotifOpen={handleNotifOpen} />}
                />
                <Route
                  path={Routes.CHECKOUT}
                  render={(props) => <Checkout {...props} onNotifOpen={handleNotifOpen} />}
                />
                <Route
                  path={Routes.CONFIRM_STAY}
                  render={(props) => <ConfirmStay {...props} onNotifOpen={handleNotifOpen} />}
                />
                <Route
                  path={Routes.FINALIZE_BOOKING}
                  render={(props) => <FinalizeBooking {...props} onNotifOpen={handleNotifOpen} />}
                />
                <Route
                  path={Routes.ACCOUNT_BOOKINGS}
                  render={(props) => (
                    <Account
                      {...props}
                      onNotifOpen={handleNotifOpen}
                      redirectAuth={redirectAuth}
                      showDetails={false}
                    />
                  )}
                />
                <Route
                  path={Routes.ACCOUNT}
                  render={(props) => (
                    <Account
                      {...props}
                      onNotifOpen={handleNotifOpen}
                      redirectAuth={redirectAuth}
                      showDetails={true}
                    />
                  )}
                />
                <Route
                  path={Routes.LOGIN}
                  render={(props) => (
                    <AuthPage
                      {...props}
                      login
                      onNotifOpen={handleNotifOpen}
                      authCompleted={authCompleted}
                    />
                  )}
                />
                <Route
                  path={Routes.SIGNUP}
                  render={(props) => (
                    <AuthPage
                      {...props}
                      signUp
                      onNotifOpen={handleNotifOpen}
                      authCompleted={authCompleted}
                    />
                  )}
                />
                <Route
                  path={Routes.FIND_BOOKING}
                  render={(props) => <FindBookingPage {...props} onNotifOpen={handleNotifOpen} />}
                />
                <Route
                  path={Routes.MANAGE_BOOKING}
                  render={(props) => <ManageBooking {...props} onNotifOpen={handleNotifOpen} />}
                />
                <Route component={Error} />
              </Switch>
            </ErrorBoundary>
          </div>
          <CookieConsent onAgree={giveCookieConsent} />
        </BookingSourceProvider>
      </Router>
    </ThemeProvider>
  );
};

App.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
  location: PropTypes.object,
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}>
      <App />
    </SnackbarProvider>
  );
}
