import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  findBookingsBox: {
    marginTop: "10px",
  },
  whiteText: {
    color: theme.palette.primary.contrastText,
  },
}));

const FindBookingsBox = (props) => {
  const classes = useStyles();

  return (
    <Box p={4} bgcolor="other.backdrop" hidden={props.hidden} className={classes.findBookingsBox}>
      <Box my={2}>
        <Typography variant="h6" className={classes.whiteText} align="center">
          Can’t find your booking?
        </Typography>
      </Box>
      <Box my={2}>
        <Typography variant="body2" className={classes.whiteText} align="center">
          Fetch your booking with your booking reference.
        </Typography>
      </Box>
      <Box my={2} align="center">
        <Button onClick={props.setOpen} color="primary" variant="contained">
          Find other booking
        </Button>
      </Box>
    </Box>
  );
};

FindBookingsBox.propTypes = {
  hidden: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default FindBookingsBox;
