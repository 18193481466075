import React, { useState } from "react";
import { DayPickerSingleDateController } from "react-dates";
import { PropTypes } from "prop-types";
import { Popover, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import ActionSheet from "components/ActionSheet";

const useStyle = makeStyles((theme) => ({
  datePopover: {
    padding: "5px 30px",
  },
  datePickerContainer: {
    /* react-dates date picker style overrides */

    // Modifies the container
    "& .DayPicker": {
      background: "transparent",
      margin: "auto",
    },

    // The month grid
    "& .CalendarMonth, & .CalendarMonthGrid": {
      background: "transparent",
    },

    // The month name
    "& .CalendarMonth_caption ": {
      color: theme.palette.text.primary,
    },

    // The month forward / back buttons
    "& .DayPickerNavigation_button": {
      background: "transparent",
      border: "none",
    },

    // Color the arrow on forward / back buttons
    "& .DayPickerNavigation_svg__horizontal": {
      fill: theme.palette.text.primary,
    },

    // Day of week (Mon - Sun) headers
    "& .DayPicker_weekHeader_li ": {
      color: theme.palette.text.disabled,
    },

    // Modifies all days
    "& .CalendarDay": {
      background: "transparent",
      color: theme.palette.text.primary,
      border: "none !important" /* we never want to show borders so make important */,
    },

    // Modifies all days hover effect
    "& .CalendarDay:hover": {
      background: theme.palette.primary.hover,
      border: "none",
    },

    // Will edit style of items between the range
    "& .CalendarDay__hovered_span": {
      background: theme.palette.primary.hover,
      border: "none",
    },

    // Modifies all dates outside the range
    "& .CalendarDay__blocked_out_of_range, & .CalendarDay__blocked_out_of_range:hover": {
      background: "transparent",
      color: theme.palette.text.disabled,
    },

    // Will edit everything selected
    "& .CalendarDay__selected_span": {
      background: theme.palette.primary.active,
      color: theme.palette.text.primary,
      border: "none",
    },

    // Hover effect for selected dates in a range
    "& .CalendarDay__selected_span:hover": {
      background: theme.palette.primary.border,
      border: "none",
    },

    // Will edit selected date or the endpoints of a range of dates
    "& .CalendarDay__selected": {
      background: theme.palette.primary.main,
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px", //here
      color: theme.palette.text.onDark.primary,

      "&.CalendarDay:hover": {
        background: theme.palette.primary.main,
        border: "none",
      },
    },
  },
}));

const ResponsiveSingleDayPicker = (props) => {
  const classes = useStyle();

  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));
  const [date, setDate] = useState(props.date);

  return tabletUpScreen ? (
    <Popover
      id={props.focus ? "date-picker-popover" : undefined}
      open={!!props.focus}
      onClose={() => props.setFocus(null)}
      anchorReference="anchorEl"
      anchorEl={props.focus}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        className: classes.datePopover,
      }}>
      <div className={classes.datePickerContainer}>
        <DayPickerSingleDateController
          date={props.date}
          noBorder={true}
          hideKeyboardShortcutsPanel={true}
          onDateChange={props.onDateChange}
          daySize={40}
          numberOfMonths={1}
          isOutsideRange={(date) => date.isBefore(props.minDate, "day")}
        />
      </div>
    </Popover>
  ) : (
    <ActionSheet
      title="Select Date"
      open={!!props.focus}
      onCancel={() => {
        setDate(props.date);
        props.setFocus(false);
      }}
      onOK={() => {
        props.onDateChange(date);
      }}>
      <div className={classes.datePickerContainer}>
        <DayPickerSingleDateController
          date={date}
          noBorder={true}
          hideKeyboardShortcutsPanel={true}
          onDateChange={setDate}
          daySize={40}
          numberOfMonths={1}
          isOutsideRange={(date) => date.isBefore(props.minDate, "day")}
        />
      </div>
    </ActionSheet>
  );
};

ResponsiveSingleDayPicker.propTypes = {
  date: PropTypes.object,
  onDateChange: PropTypes.func,
  focus: PropTypes.bool,
  setFocus: PropTypes.func,
  minDate: PropTypes.object,
};

export default ResponsiveSingleDayPicker;
