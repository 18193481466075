export const facilityType = {
  EVENT_SPACES: "ExecutiveClub",
  MEETING_ROOMS: "MeetingRoom",
  PRIVATE_DINING: "OtherDining",
};

export const requestFormIdKeys = {
  EVENT_SPACES: process.env.REACT_APP_EVENT_SPACES_REQUEST_FORM_ID,
  MEETING_ROOMS: process.env.REACT_APP_MEETING_ROOMS_REQUEST_FORM_ID,
  PRIVATE_DINING: process.env.REACT_APP_PRIVATE_DINING_REQUEST_FORM_ID,
};
