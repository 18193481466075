import React from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { compose, get, getOr, isEmpty, map, negate, update } from "lodash/fp";

import {
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

import DayHeader from "./DayHeader.js";
import MealPeriodLabel from "./MealPeriodLabel.js";
import { DINING_TIMES_OUT, DINING_TIMES_IN } from "utils/dateFormats";

const minHeight = "2em";
const useStyles = makeStyles((theme) => ({
  tick: {
    color: theme.palette.success.light,
  },
  timesRowContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dayHeader: {
    marginTop: theme.spacing(1),
  },
  tabs: {
    minHeight,
  },
  tab: {
    padding: "0 5px",
    width: "88px",
    border: `1px solid ${theme.palette.other.lineDetail}`,
    borderRadius: "16px",
    height: "32px",
    margin: `0px ${theme.spacing(0.5)}px`,
    minWidth: 0,
    minHeight,
    ...theme.typography.body2,
  },
  selectedTab: {
    backgroundColor: theme.palette.grey[900],
    color: "white",
  },
  scrollButtons: {
    color: theme.palette.primary.main,
    width: "auto",
    "&.Mui-disabled": {
      opacity: 1,
      color: theme.palette.text.disabled,
    },
  },
  tabsIndicator: {
    display: "none",
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

const DiningTimesRow = (props) => {
  const classes = useStyles();

  const renderRow = (kitchen) => {
    const Row = (date) => {
      const breakfastTimes = getOr([], ["breakfastTimes"], kitchen);
      const dinnerTimes = getOr([], ["dinnerTimes"], kitchen);

      return (
        <>
          {props.renderBreakfast && renderPeriod("breakfast", breakfastTimes)(date)}
          {props.renderDinner && renderPeriod("dinner", dinnerTimes)(date)}
        </>
      );
    };

    Row.displayName = "Row";

    return Row;
  };

  const renderPeriod = (mealPeriod, periodTimes) => {
    const Period = (date) => {
      const theme = useTheme();
      const tabletUpScreen = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet));

      return periodTimes ? (
        <Box pt={2}>
          <Grid
            container
            direction={tabletUpScreen ? "row" : "column"}
            alignItems={tabletUpScreen ? "center" : "flex-start"}
            spacing={1}>
            <Grid item xs={tabletUpScreen && 2}>
              <MealPeriodLabel
                mealPeriod={mealPeriod}
                selected={compose(
                  negate(isEmpty),
                  get([date, mealPeriod, "time"])
                )(props.selections)}
              />
            </Grid>
            <Grid item xs={tabletUpScreen && 10}>
              <div className={classes.timesRowContainer}>
                {periodTimes.length > 0 ? (
                  <>
                    <div style={{ display: "grid" }}>
                      {renderKitchenTimes(date, mealPeriod)(periodTimes)}
                    </div>
                  </>
                ) : (
                  <Grid container alignItems="center">
                    <Typography variant="body2" color="textSecondary">
                      No availability
                    </Typography>
                    <Tooltip
                      title={
                        <Typography variant="body2">
                          Please contact the property to make a booking.
                        </Typography>
                      }>
                      <InfoOutlined className={classes.infoIcon} />
                    </Tooltip>
                  </Grid>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      ) : null;
    };

    Period.displayName = "Period";

    return Period;
  };

  const renderKitchenTimes = (date, shiftCategory) => {
    const Times = (times, index) => {
      const formattedTimes = map(
        update("time", (time) => moment(time, DINING_TIMES_IN).format(DINING_TIMES_OUT)),
        times
      );

      return (
        <Tabs
          key={`${date}:${shiftCategory}:${index}`}
          value={getOr(false, [date, shiftCategory, "time"], props.selections)}
          onChange={(_, value) => {
            props.onTimeSelected(date, shiftCategory, value);
          }}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="select dinning slot"
          classes={{
            root: classes.tabs,
            scrollButtons: classes.scrollButtons,
            indicator: classes.tabsIndicator,
          }}>
          {map(
            ({ time, available = true }) => (
              <Tab
                key={time}
                classes={{
                  root: classes.tab,
                  selected: classes.selectedTab,
                }}
                value={time}
                label={time}
                disabled={!available}
              />
            ),
            formattedTimes
          )}
        </Tabs>
      );
    };

    Times.displayName = "Times";

    Times.propTypes = {
      times: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
    };

    return Times;
  };

  return (
    <Grid container direction="column">
      <Divider />

      <DayHeader
        className={classes.dayHeader}
        dayIndex={props.dayIndex + 1}
        dateString={props.availability.date}
        partySize={props.partySize}
      />

      <Box mb={2}>{renderRow(props.availability)(props.availability.date)}</Box>
    </Grid>
  );
};

DiningTimesRow.propTypes = {
  partySize: PropTypes.number,
  renderBreakfast: PropTypes.bool,
  renderDinner: PropTypes.bool,
  dayIndex: PropTypes.number,
  availability: PropTypes.object,
  times: PropTypes.arrayOf(PropTypes.object),
  selections: PropTypes.object,
  onTimeSelected: PropTypes.func,
};

export default DiningTimesRow;
