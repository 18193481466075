import React from "react";
import PropTypes from "prop-types";

import { get } from "lodash/fp";

import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import CommentOutlined from "@material-ui/icons/CommentOutlined";

import DayHeader from "../DayHeader.js";
import MealPeriodLabel from "../MealPeriodLabel.js";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(0.5),
  },
  noTimeSelected: {
    color: theme.palette.text.disabled,
  },
  timeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const DiningSummaryRow = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet));

  const renderRow = (availability) => {
    return (
      <>
        {props.renderBreakfast && renderPeriod("breakfast", availability.date)}
        {props.renderDinner && renderPeriod("dinner", availability.date)}
      </>
    );
  };

  const renderPeriod = (mealPeriod, date) => {
    const selectedTime = get([date, mealPeriod, "time"], props.selections);
    const note = get([date, mealPeriod, "note"], props.selections);

    return (
      <>
        <Grid container direction="row" alignItems="flex-start" justifyContent="space-between">
          <Grid item>
            <MealPeriodLabel mealPeriod={mealPeriod} />
          </Grid>
          <Grid item>
            <Typography variant="button" className={selectedTime ? null : classes.noTimeSelected}>
              {selectedTime || "None"}
            </Typography>
            {note ? (
              <Tooltip title={<Typography variant="caption">{note}</Typography>}>
                <IconButton onClick={props.onShowNoteDialog} size="small">
                  <CommentOutlined />
                </IconButton>
              </Tooltip>
            ) : null}
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Grid
      container
      className={classes.container}
      direction={tabletUpScreen ? "row" : "column"}
      spacing={1}>
      <Grid item xs={tabletUpScreen ? 4 : null}>
        <DayHeader dayIndex={props.dayIndex + 1} dateString={props.availability.date} />
      </Grid>

      <Grid item xs={tabletUpScreen ? 8 : null}>
        {renderRow(props.availability)}
      </Grid>
    </Grid>
  );
};

DiningSummaryRow.propTypes = {
  partySize: PropTypes.number,
  renderBreakfast: PropTypes.bool,
  renderDinner: PropTypes.bool,
  dayIndex: PropTypes.number,
  availability: PropTypes.object,
  times: PropTypes.arrayOf(PropTypes.object),
  selections: PropTypes.object,
  onShowNoteDialog: PropTypes.func,
};

export default DiningSummaryRow;
