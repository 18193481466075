import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  TextField,
  FormControlLabel,
  Typography,
  makeStyles,
  Checkbox,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  moreDetailsSection: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
  },
  checkbox: {
    padding: `0px ${theme.spacing(1)}px 0px 0px`,
  },
  specialRequestInputField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "20px",
      backgroundColor: theme.palette.background.default,
    },
  },
}));

const AdditionalRequirements = ({ additionalRequirements, handleAdditionalRequirementsChange }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.moreDetailsSection}>
        {Object.keys(additionalRequirements)
          .filter((request) => additionalRequirements[request].type === "checkbox")
          .map((request) => (
            <FormControlLabel
              key={request}
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={additionalRequirements[request].value}
                  onChange={(evt) =>
                    handleAdditionalRequirementsChange(request, evt.currentTarget.checked)
                  }
                  color="primary"
                />
              }
              label={
                <Typography variant="body1" color="textPrimary">
                  {additionalRequirements[request].label}
                </Typography>
              }
            />
          ))}
      </Box>
      <Box mb={2} mt={2}>
        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
          {additionalRequirements?.specialRequests?.label}
        </Typography>
        <TextField
          fullWidth
          className={classes.specialRequestInputField}
          variant="outlined"
          multiline
          minRows={2}
          value={additionalRequirements?.specialRequests?.value}
          onChange={(e) => {
            handleAdditionalRequirementsChange("specialRequests", e.currentTarget.value);
          }}
        />
        <Typography variant="caption" color="textSecondary">
          Special requests cannot be guaranteed – but we will do our best to meet your needs.
        </Typography>
      </Box>
    </>
  );
};

AdditionalRequirements.propTypes = {
  additionalRequirements: PropTypes.object,
  handleAdditionalRequirementsChange: PropTypes.func,
};

export default AdditionalRequirements;
