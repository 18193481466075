import { cloneDeep } from "lodash/fp";
import { GUEST_TYPE } from "utils/helpers";
import {
  ADDON_TYPE_REGULAR,
  ADDON_TYPE_BASKET,
  ADDON_TYPE_DATE,
  EVPARKING_SERVICE_CODE,
} from "utils/constants";
import { PRICING_UNIT } from "utils/helpers";

import { defaultCountForService } from "./helpers";

// Maps the pms services to a reservation addon cms model
export const mapServicesForAddon = (
  addon,
  pmsServices,
  parkingAvailability,
  numberOfNights,
  numberOfAdults,
  numberOfChildren
) => {
  // We need to map differently depending on the addon type as they all
  // have different structures
  const addonType = addon.addon_details?.__component;

  switch (addonType) {
    case ADDON_TYPE_REGULAR:
      return mapServicesForRegularAddon(
        addon,
        pmsServices,
        parkingAvailability, // Legacy - Only supported for regular addons
        numberOfNights,
        numberOfAdults,
        numberOfChildren
      );
    case ADDON_TYPE_BASKET:
      return mapServicesForBasketAddon(addon, pmsServices, numberOfNights);
    case ADDON_TYPE_DATE:
      return mapServicesForDateAddon(
        addon,
        pmsServices,
        numberOfNights,
        numberOfAdults,
        numberOfChildren
      );
    default:
      // Unsupported type
      return null;
  }
};

const mapServicesForRegularAddon = (
  addon,
  pmsServices,
  parkingAvailability,
  numberOfNights,
  numberOfAdults,
  numberOfChildren
) => {
  // All pms services inside this addon
  let allPmsServices = [];

  // Go through each of the addon's pms service link and associate the
  // actual service data from apaleo
  let mappedServices = addon?.addon_details?.services.map((addonService) => {
    // Special handling for EV parking sold as a reservation
    if (addonService.pms_code === EVPARKING_SERVICE_CODE) {
      if (parkingAvailability === undefined) return null;

      const pmsServiceDetails = {
        pmsId: EVPARKING_SERVICE_CODE,
        name: parkingAvailability.unitGroup.name,
        price: parkingAvailability.totalGrossAmount,
        pricingUnit: "Stay", // EV is charged daily, by the stay
        numberOfNights: numberOfNights,
        defaultCount: 1,
        maxCount: 1,
      };

      allPmsServices.push(pmsServiceDetails);

      return {
        ...addonService,
        pmsId: EVPARKING_SERVICE_CODE,
      };
    } else {
      // Lookup and associate with the service from pms
      const pmsService = pmsServices.find((pms) => addonService.pms_code === pms.service.code);
      if (!pmsService) return null;

      // Exclude children only services if no children in the search
      if (addonService.guest_type === GUEST_TYPE.CHILDREN && numberOfChildren <= 0) {
        return null;
      }

      const defaultServiceCount = defaultCountForService(
        pmsService.service.pricingUnit,
        addonService.guest_type,
        numberOfAdults,
        numberOfChildren
      );

      // Make sure there is availability. If no availability imposed on PMS,
      // we will allow user to add up to the default count
      const availableCount = Math.min(
        pmsService.availableCount || defaultServiceCount,
        defaultServiceCount
      );
      if (availableCount.availableCount === 0) return null;

      const maxCount =
        pmsService.service.pricingUnit === PRICING_UNIT.PERSON
          ? Math.min(defaultServiceCount, availableCount)
          : 1;

      const pmsServiceDetails = {
        pmsId: pmsService.service.id,
        name: pmsService.service.name,
        price: pmsService.service.defaultGrossPrice,
        pricingUnit: pmsService.service.pricingUnit,
        numberOfNights: pmsService.dates.length,
        guestType: addonService.guest_type,
        defaultCount: defaultServiceCount,
        maxCount: maxCount,
      };

      allPmsServices.push(pmsServiceDetails);

      return {
        ...addonService,
        pmsId: pmsService.service.id, // Map the underlying PMS id from the code
      };
    }
  });

  // Remove services with values (ie no valid pms service)
  mappedServices = mappedServices.filter((s) => !!s);

  // Make sure this addon is available - ie it has at least 1 sellable service
  if (mappedServices.length === 0) return null;

  // Finaly, put the mapped services back into the cms addon data structure
  let newAddon = cloneDeep(addon);
  newAddon.addon_details.services = mappedServices;

  // Add in the underlying pms services for easy access. This makes a common way
  // to query services across the various types of addon.
  newAddon.pmsServices = allPmsServices;

  return newAddon;
};

const mapServicesForBasketAddon = (addon, pmsServices, numberOfNights) => {
  let allPmsServices = [];

  // Go through each of the addon's pms service link and associate the
  // actual service data from apaleo

  let newAddon = cloneDeep(addon);

  for (let i = 0; i < newAddon.addon_details?.categories?.length || 0; i += 1) {
    const category = newAddon.addon_details?.categories[i];

    category.services = category.services?.map((service) => {
      service.options = service.options?.map((option) => {
        // Lookup and associate with the service from pms
        const pmsService = pmsServices.find((pms) => option.pms_code === pms.service.code);
        if (!pmsService) return null;

        // Make sure there is availability. If no availability imposed on PMS,
        // we will allow user to add up to 10 items, just so there is sensible limit
        const availableCount = Math.min(pmsService.availableCount || 10, 10);
        if (availableCount.availableCount === 0) return null;

        // Add an arbritrary limit of 10 items in the basket. Cant see a reason
        // why you'd want more for any services they might sell. This also
        // factors in availability
        const maxCount =
          pmsService.service.pricingUnit === PRICING_UNIT.PERSON ? availableCount : 1;

        const pmsServiceDetails = {
          pmsId: pmsService.service.id,
          name: pmsService.service.name,
          price: pmsService.service.defaultGrossPrice,
          pricingUnit: pmsService.service.pricingUnit,
          numberOfNights: pmsService.dates.length,
          defaultCount: 0, // Basket items default to 0
          maxCount: maxCount,
        };

        allPmsServices.push(pmsServiceDetails);

        return {
          ...option,
          pmsId: pmsService.service.id,
        };
      });

      // Remove any unavailable options
      service.options = service.options.filter((o) => !!o);

      return service;
    });

    // Remove any services with no sellable options
    category.services = category.services?.filter((service) => service.options?.length || 0 > 0);
  }

  if (allPmsServices.length === 0) return null;

  // Add in the underlying pms services for easy access. This makes a common way
  // to query services across the various types of addon.
  newAddon.pmsServices = allPmsServices;

  return newAddon;
};

const mapServicesForDateAddon = (
  addon,
  pmsServices,
  numberOfNights,
  numberOfAdults,
  numberOfChildren
) => {
  // All pms services inside this addon
  let allPmsServices = [];

  // Go through each of the addon's pms service link and associate the
  // actual service data from apaleo
  let mappedServices = addon?.addon_details?.services.map((addonService) => {
    // Lookup and associate with the service from pms
    const pmsService = pmsServices.find((pms) => addonService.pms_code === pms.service.code);
    if (!pmsService) return null;

    // Exclude children only services if no children in the search
    if (addonService.guest_type === GUEST_TYPE.CHILDREN && numberOfChildren <= 0) {
      return null;
    }

    const pmsServiceDetails = {
      pmsId: pmsService.service.id,
      name: pmsService.service.name,
      price: pmsService.service.defaultGrossPrice,
      pricingUnit: pmsService.service.pricingUnit,
      numberOfNights: pmsService.dates.length,
      guestType: addonService.guest_type,
      dates: pmsService.dates
        .map((d) => {
          // If person based, make sure there is availability.
          // If no availability imposed on PMS, we will allow user to add up to
          // 10 items (a sensible limit)
          const maxCount =
            pmsService.service.pricingUnit === PRICING_UNIT.PERSON
              ? Math.min(d.availableCount || 10, 10)
              : 1;
          if (maxCount === 0) return null;

          return {
            ...d,
            defaultCount: 0, // Date based services default to 0
            maxCount: maxCount,
          };
        })
        .filter((d) => !!d),
    };

    allPmsServices.push(pmsServiceDetails);

    return {
      ...addonService,
      pmsId: pmsService.service.id,
    };
  });

  // Remove services with values (ie no valid pms service)
  mappedServices = mappedServices.filter((s) => !!s);

  // Make sure this addon is available - ie it has at least 1 sellable service
  if (mappedServices.length === 0) return null;

  // Finaly, put the mapped services back into the cms addon data structure
  let newAddon = cloneDeep(addon);
  newAddon.addon_details.services = mappedServices;

  // Add in the underlying pms services for easy access. This makes a common way
  // to query services across the various types of addon.
  newAddon.pmsServices = allPmsServices;

  return newAddon;
};
