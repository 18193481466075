import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Box,
  Button,
  Divider,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";

import LoadingSpinner from "components/LoadingSpinner";
import { formatDateMedium } from "utils/timekeep";

const useStyles = makeStyles((theme) => ({
  mainContentContainer: {
    width: "100%",
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  stepContainer: {
    padding: `${theme.spacing(2)}px`,
    marginBottom: `${theme.spacing(2)}px`,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      padding: `${theme.spacing(3)}px`,
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  textButton: { paddingLeft: 0, "&:hover": { backgroundColor: "inherit" } },
  textButtonOverride: {
    paddingLeft: 0,
    "&:hover": { backgroundColor: "inherit" },
    marginTop: theme.spacing(1.5),
    color: theme.palette.text.red,
  },
  loadingContainer: {
    outline: "solid 1px red",
    textAlign: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  submittedFormContainer: {
    marginBottom: theme.spacing(3),
  },
  enquirySummaryContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  successHeading: {
    color: theme.palette.success.contrastText,
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    padding: "12px 16px",
    backgroundColor: theme.palette.success.main,
  },
  formFieldsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(0.5),
    gap: theme.spacing(2),
  },
  finishButton: {
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
}));

const EnquiryConfirmation = ({
  setHasSubmitted,
  isLoading,
  onClose,
  enquiryConfirmation,
  setEnquiryConfirmation,
  setActiveStep,
  selectedProperty,
  facilityName,
  embedded,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));
  const [showDetails, setShowDetails] = useState(false);

  const handleCloseConfirmation = () => {
    setEnquiryConfirmation(null);
    setActiveStep(0);
    setHasSubmitted(false);
    onClose();
  };

  useEffect(() => {
    setShowDetails(false);
  }, [enquiryConfirmation]);

  const moreDetailsSortOrder = [
    // Also omits 'preferred date' as already shown
    "arrivalTime", //preferred time
    "typeOfRoom", // Preferred room(s)
    "nameOfEvent", // Event name
    "contactName", // Company name
    "numberOfPeople", // Number of attendees
    "details", // Are dates flexible?
    "value", // Budget
    "preferences", // Accommodation required?
    "addOns", // Catering or diner reservation required?
    "specialRequests", // Special requests
  ];

  return (
    <>
      {(isLoading || enquiryConfirmation) && (
        <Box style={{ width: "100%", height: "100%" }}>
          {isLoading && (
            <Box className={classes.loadingContainer}>
              <Box>
                <LoadingSpinner loading={isLoading} />
              </Box>
              <Typography variant="subtitle1" color="textPrimary">
                Sending your enquiry...
              </Typography>
            </Box>
          )}
          {!isLoading && enquiryConfirmation && (
            <Box
              className={classNames(classes.mainContentContainer, classes.submittedFormContainer)}>
              <Paper>
                <Box className={classes.successHeading}>
                  <ThumbUpAltIcon />
                  <Typography variant="h5">Thanks for your enquiry!</Typography>
                </Box>
                <Box className={classes.stepContainer}>
                  <Box>
                    Your enquiry has been sent to our team. They’ll take a look and get back to you
                    shortly.
                  </Box>
                  <Divider className={classes.divider} />
                  <Box className={classes.enquirySummaryContainer}>
                    <Box>
                      <Typography variant="subtitle1">{selectedProperty?.name}</Typography>
                      <Typography
                        variant={
                          tabletUpScreen ? "h5" : "h6"
                        }>{`Mollie's ${facilityName}`}</Typography>
                    </Box>
                    <Box className={classes.formFieldsContainer}>
                      <Typography variant="subtitle2">
                        {enquiryConfirmation["arrivalDate"].label}
                      </Typography>
                      <Typography>
                        {formatDateMedium(enquiryConfirmation["arrivalDate"].value)}
                      </Typography>
                    </Box>
                  </Box>
                  {showDetails && (
                    <Box>
                      {moreDetailsSortOrder.map((field, index) => (
                        <Box key={index} className={classes.formFieldsContainer}>
                          <Typography variant="subtitle2">
                            {enquiryConfirmation[field]?.label}
                          </Typography>
                          <Typography style={{ textAlign: "end" }}>
                            {enquiryConfirmation[field]?.value}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                  <Button
                    className={classes.textButtonOverride}
                    aria-label={showDetails ? "Hide details" : "Show details"}
                    variant="text"
                    disableRipple
                    onClick={() => setShowDetails(!showDetails)}
                    endIcon={showDetails ? <ExpandLess /> : <ExpandMore />}>
                    {showDetails ? "Hide details" : "Show details"}
                  </Button>
                </Box>
              </Paper>
              {embedded ? null : (
                <Box className={classes.finishButton}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleCloseConfirmation}
                    size={tabletUpScreen ? "large" : "medium"}>
                    Finish and carry on browsing
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

EnquiryConfirmation.propTypes = {
  embedded: PropTypes.bool, // Embedded mode - i.e full page
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  setEventDetails: PropTypes.func,
  enquiryConfirmation: PropTypes.object,
  setEnquiryConfirmation: PropTypes.func,
  setActiveStep: PropTypes.func,
  setHasSubmitted: PropTypes.func,
  selectedProperty: PropTypes.object,
  facilityName: PropTypes.string,
};

export default EnquiryConfirmation;
