import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Grid, Typography, makeStyles } from "@material-ui/core";
import { formatCurrency, PRICING_UNIT } from "utils/helpers";
import { ADDON_DATE_IN, ADDON_DATE_OUT } from "utils/dateFormats";
import { calculateServicePrice } from "utils/pms_services/helpers";
import { eachDayOfInterval, parseISO } from "date-fns";

import moment from "moment";

import Stepper from "components/Stepper";
import Checkbox from "components/Checkbox";
import AddServicesButton from "../AddServicesButton";

const useStyles = makeStyles((theme) => ({
  services: {
    marginBottom: theme.spacing(1),
  },
  pill: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.info.lightBg,
    padding: `2px ${theme.spacing(1)}px`,
    borderRadius: "20px",
  },
  pillUnavailable: {
    backgroundColor: theme.palette.other.disabledBackground,
    color: theme.palette.text.secondary,
  },
  hidden: {
    opacity: 0,
  },
}));

const ServiceByDatesRow = (props) => {
  const classes = useStyles();

  // For tracking the service counts before added to the basket
  const [serviceCounts, setServiceCounts] = useState({});

  useEffect(() => {
    // Get the counts for each service
    let thisServiceCounts = {};

    // Set the initial counts for this addon as the defaults
    getServiceDates().forEach((date) => {
      const service = getPmsServiceDate(date);
      thisServiceCounts[date] = service ? service.defaultCount : 0;
    });

    setServiceCounts(thisServiceCounts);
  }, [props.pmsService]);

  // View Logic

  // Returns the pms service date (default, max count, etc)
  const getPmsServiceDate = (date) => {
    return props.pmsService.dates.find((d) => d.serviceDate === date);
  };

  const serviceDetailString = (pmsService) => {
    if (pmsService.pricingUnit !== PRICING_UNIT.PERSON) return null;

    return `per ${pmsService.pricingUnit.toLowerCase()}${
      pmsService.numberOfNights > 1 ? ", per day" : ""
    }`;
  };

  // Returns all the dates this service can be sold as iso strings YYYY-MM-DD
  // (between check in and check out date, inclusive)
  const getServiceDates = () => {
    return eachDayOfInterval({
      start: parseISO(props.checkInDate),
      end: parseISO(props.checkOutDate),
    }).map((d) => moment(d).format(ADDON_DATE_IN));
  };

  // Returns true if any services in this addon are in the basket
  const anyServiceDatesInBasket = () => {
    return getServiceDates()?.some(
      (date) => props.serviceCountInBasket(props.pmsService.pmsId, date) > 0
    );
  };

  // The count in basket, or falls back to the defaults
  const countForServiceDate = (date) => {
    const addedToBasket = anyServiceDatesInBasket();

    // If services are already in the basket, use the count from the service
    // Otherwise, use the temporal count
    if (addedToBasket) {
      return props.serviceCountInBasket(props.pmsService.pmsId, date);
    } else {
      return serviceCounts[date] || 0;
    }
  };

  const getTotalPrice = () => {
    // Returns price for all services
    return getServiceDates().reduce((acc, date) => {
      const count = countForServiceDate(date);

      return (
        acc + calculateServicePrice(props.pmsService.price, props.pmsService.pricingUnit, 1, count)
      );
    }, 0);
  };

  const getTotalItemCount = () => {
    // Returns number of items in basket / pre-basket state for all services
    return getServiceDates().reduce((acc, date) => {
      const count = countForServiceDate(date);
      return acc + count;
    }, 0);
  };

  // Button handlers

  const handleAddButtonPressed = (e) => {
    // Add the services. We'll add one for each date, separately
    const serviceSummaries = getServiceDates()
      .map((date) => {
        const pmsServiceDate = getPmsServiceDate(date);
        if (!pmsServiceDate) return null;

        return {
          serviceId: props.pmsService.pmsId,
          count: countForServiceDate(date),
          maxCount: pmsServiceDate.maxCount,
          cmsTitle: props.service.title,
          additionalInfo: moment(date).format(ADDON_DATE_OUT),
          date: date,
          dates: [
            {
              serviceDate: date,
            },
          ],
        };
      })
      .filter((s) => !!s && s.count > 0);

    props.onAddServices(serviceSummaries);
  };

  const handleRemoveButtonPressed = (e) => {
    // Remove the services
    props.onRemoveServices([props.pmsService.pmsId]);
  };

  const handleServiceCountChanged = (date, count, maxCount) => {
    // If the item is already in the basket, we'll change the value in basket
    // Otherwise, we'll change the temporal values

    const addedToBasket = anyServiceDatesInBasket();

    // If services are already in the basket, use the count from the service
    // Otherwise, use the temporal count
    if (addedToBasket) {
      props.onUpdateServiceCount(
        props.pmsService.pmsId,
        count,
        maxCount,
        props.service.title, // CMS title
        moment(date).format(ADDON_DATE_OUT), // Additional info
        date
      );
    } else {
      setServiceCounts({
        ...serviceCounts,
        [date]: count,
      });
    }
  };

  const createDateRows = () => {
    // Create a date row for each date between check-in and check-out date (inclusive)
    const dates = getServiceDates();

    // Special handling - a service can only be valid for the number of nights booked.
    // It can either be inclusive of the check-in date, or check-out date, but apaleo doesnt tell us
    // If there is availability for the check-in date (first date), we can assume its check-in inclusive
    // Otherwise we default to check-out inclusive
    const includeCheckInDate = !!getPmsServiceDate(dates[0]) || false;

    return dates.map((date, i) => {
      const pmsServiceDate = getPmsServiceDate(date);

      // Remove the check-in or check-out date, depending on whether which is inclusive
      if (includeCheckInDate && i === dates.length - 1) {
        return null;
      } else if (!includeCheckInDate && i === 0) {
        return null;
      }

      const serviceCount = countForServiceDate(date);

      const formattedDate = moment(date).format(ADDON_DATE_OUT);

      // If there's no pms service date, its not available
      // If there is a pms service date, there may or may not be an availability count (ie could be unlimited)
      const availableCount = pmsServiceDate ? pmsServiceDate.availableCount : 0;

      return (
        <Grid
          className={classes.services}
          container
          justifyContent="space-between"
          wrap="nowrap"
          alignItems="center"
          key={i}>
          <Grid container alignItems="baseline">
            <Typography variant="body2" text="textPrimary">
              {formattedDate}
            </Typography>
            {availableCount != null ? (
              <div
                className={classNames(
                  classes.pill,
                  availableCount === 0 ? classes.pillUnavailable : null
                )}>
                <Typography variant="caption">
                  {availableCount > pmsServiceDate?.maxCount ? "10+" : availableCount} available
                </Typography>
              </div>
            ) : null}
          </Grid>
          {props.pmsService.pricingUnit === PRICING_UNIT.PERSON ? (
            <Stepper
              name={`service_stepper-${i}`}
              ariaHint={`number of services for date ${formattedDate}`}
              count={countForServiceDate(date)}
              disabled={availableCount === 0}
              decrementDisabled={serviceCount <= 0}
              incrementDisabled={serviceCount >= pmsServiceDate?.maxCount}
              onValueChanged={(name, count) =>
                handleServiceCountChanged(date, count, pmsServiceDate?.maxCount)
              }
            />
          ) : (
            <Checkbox
              checked={countForServiceDate(date) > 0}
              onChange={(checked) =>
                handleServiceCountChanged(date, checked ? 1 : 0, pmsServiceDate.maxCount)
              }
            />
          )}
        </Grid>
      );
    });
  };

  // Only show service rows if > 1 service (or the single service is person based)
  const totalItemCount = getTotalItemCount();

  const currency = props.pmsService?.price?.currency;

  return (
    <>
      <Grid container>
        <Typography variant="subtitle1">{props.service?.title || props.pmsService.name}</Typography>
        <Typography variant="subtitle1">
          &nbsp;&nbsp;
          {formatCurrency(props.pmsService?.price?.currency, props.pmsService?.price?.amount)}
        </Typography>
      </Grid>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {serviceDetailString(props.pmsService)}
      </Typography>

      {createDateRows()}

      {
        /* only show if there's something to add */
        <AddServicesButton
          className={totalItemCount === 0 ? classes.hidden : null}
          isInBasket={anyServiceDatesInBasket()}
          currency={currency}
          amount={getTotalPrice()}
          onAddHandler={handleAddButtonPressed}
          onRemoveHandler={handleRemoveButtonPressed}
        />
      }
    </>
  );
};

ServiceByDatesRow.propTypes = {
  serviceCountInBasket: PropTypes.func,
  onAddServices: PropTypes.func,
  onRemoveServices: PropTypes.func,
  onUpdateServiceCount: PropTypes.func,
  checkInDate: PropTypes.string,
  checkOutDate: PropTypes.string,
  service: PropTypes.shape({
    pmsId: PropTypes.string.isRequired,
    title: PropTypes.string,
  }),
  pmsService: PropTypes.shape({
    pmsId: PropTypes.string,
    name: PropTypes.string,
    pricingUnit: PropTypes.string,
    price: PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.string,
    }),
    dates: PropTypes.arrayOf(
      PropTypes.shape({
        serviceDate: PropTypes.string,
        availableCount: PropTypes.number,
        defaultCount: PropTypes.number,
        maxCount: PropTypes.number,
      })
    ),
  }),
};

export default ServiceByDatesRow;
