import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { formatCurrency } from "utils/helpers";
import { calculateBookingTotal } from "utils/bookingTotals";

import { Grid, IconButton, Typography, makeStyles } from "@material-ui/core";
import { ArrowUpward, KeyboardArrowDown } from "@material-ui/icons";

import Basket from "components/Basket";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerFooter: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  basket: {
    padding: theme.spacing(2),
  },
  scrollFooter: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: theme.palette.custom.nightBlue,
    color: theme.palette.text.onDark.primary,
  },
  jumpToTopButton: {
    position: "fixed",
    bottom: theme.spacing(9),
    right: theme.spacing(1),
    zIndex: 1000,
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
    boxShadow: "0px 16px 40px rgba(52, 39, 22, 0.28)",

    "&:hover": {
      background: theme.palette.background.default,
    },
  },
}));

const BookingSummary = (props) => {
  const classes = useStyles();

  const [showBasket, setShowBasket] = useState(false);

  const roomReservations = useSelector((state) => state.booking.roomReservations);
  const parkingReservations = useSelector((state) => state.booking.parkingReservations);
  const bookingTotal = calculateBookingTotal([...roomReservations, ...parkingReservations]);

  const { ref, inView } = useInView({ threshold: 0 });

  const jumpToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className={classNames(classes.root, props.className)}>
      <Grid container className={classes.headerFooter} onClick={() => setShowBasket(!showBasket)}>
        <Typography variant="button" color="primary">
          Booking summary
        </Typography>
        <KeyboardArrowDown color="primary" />
      </Grid>

      {showBasket ? (
        <div className={classes.basket}>
          <Basket
            embedded
            onNotifOpen={props.onNotifOpen}
            onServiceRemoved={props.onServiceRemoved}
            onReservationRemoved={props.handleReservationRemoved}
            canRemoveReservations={props.canRemoveReservations}
            showGiftCard={props.showGiftCard}
          />
        </div>
      ) : (
        /* Booking total */
        <Grid container ref={ref} className={classNames(classes.headerFooter)}>
          <Typography variant="h6">Total to pay</Typography>
          <Typography variant="h6">{formatCurrency("GBP", bookingTotal)}</Typography>
        </Grid>
      )}

      {/* Footer visible when the other booking total scrolls off screen */}
      {!inView && (
        <>
          <Grid container className={classNames(classes.headerFooter, classes.scrollFooter)}>
            <Typography variant="h6">Total to pay</Typography>
            <Typography variant="h6">{formatCurrency("GBP", bookingTotal)}</Typography>
          </Grid>

          {/* A jump to top button that also appears on scroll and makes the basket visible again */}
          <IconButton className={classes.jumpToTopButton} size="medium" onClick={jumpToTop}>
            <ArrowUpward />
          </IconButton>
        </>
      )}
    </div>
  );
};

BookingSummary.propTypes = {
  className: PropTypes.string,
  onNotifOpen: PropTypes.func,
  bookingTotal: PropTypes.number,
  onServiceRemoved: PropTypes.func,
  canRemoveReservations: PropTypes.bool,
  handleReservationRemoved: PropTypes.func,
  showGiftCard: PropTypes.bool,
};

export default BookingSummary;
