import { createSlice } from "@reduxjs/toolkit";

const roomSlice = createSlice({
  name: "room",
  initialState: {
    roomDetails: [],
  },
  reducers: {
    setRoomDetails: (state, action) => {
      state.roomDetails = action.payload;
    },
  },
});

export const { setRoomDetails } = roomSlice.actions;
export default roomSlice.reducer;
