import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";

import moment from "moment";

import { DINING_DATES_IN, DINING_DATES_OUT } from "utils/dateFormats";
import { formatQuantity } from "utils/helpers";

const DayHeader = (props) => {
  return (
    <Grid container className={props.className} alignItems="baseline" spacing={1}>
      <Grid item>
        <Typography variant="subtitle2">
          {props.dayIndex && `Day ${props.dayIndex} - `}
          {moment(props.dateString, DINING_DATES_IN).format(DINING_DATES_OUT)}
        </Typography>
      </Grid>
      {props.partySize && (
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            {formatQuantity(props.partySize, "guest")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

DayHeader.propTypes = {
  className: PropTypes.string,
  dayIndex: PropTypes.number,
  dateString: PropTypes.string,
  partySize: PropTypes.number,
};

export default DayHeader;
