import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Paper,
  TextField,
  Typography,
  useTheme,
  makeStyles,
} from "@material-ui/core";

import PhoneInput from "components/PhoneInput";
import AdditionalRequirements from "../Step 3/AdditionalRequirements.js";

import stepStyle from "../stepStyle";

const useStyles = makeStyles((theme) => ({
  containerHeading: {
    marginBottom: theme.spacing(2),
  },
  inputField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 20000,
      backgroundColor: theme.palette.background.default,
    },
  },
  phoneInputField: {
    borderRadius: 20000,
    backgroundColor: theme.palette.background.default,
  },
}));

const ContactDetailsStep = (props) => {
  const classes = useStyles();
  const stepClasses = stepStyle();

  const theme = useTheme();

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsValidationError, setTermsValidationError] = useState(false);

  const toggleTermsAcceptance = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const validateTermsAccepted = () => {
    if (!termsAccepted) {
      props.onNotifOpen("Please accept terms and conditions to continue", {
        variant: "error",
      });

      setTermsValidationError(true);
    }
    return termsAccepted;
  };

  const handleNext = () => {
    if (!validateTermsAccepted()) {
      return false;
    }

    props.handleNext();
  };

  return (
    <>
      <Paper className={stepClasses.stepContainer}>
        <Box>
          <Typography variant="subtitle1" color="textPrimary">
            Enter your details
          </Typography>
          <Typography variant="body1" color="textSecondary">
            * required information
          </Typography>
        </Box>
        <Box
          style={{
            marginTop: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}>
          <TextField
            fullWidth
            name="given-name"
            label="First name"
            value={props.bookingContactDetails?.firstName?.value}
            required={props.bookingContactDetails?.firstName?.isRequired}
            error={props.bookingContactDetails?.firstName?.error}
            helperText={
              props.bookingContactDetails?.firstName?.error ? "This field is required." : ""
            }
            size="small"
            variant="outlined"
            autoComplete="given-name"
            className={classes.inputField}
            InputLabelProps={{
              classes: {
                shrink: classes.shrink,
              },
            }}
            onChange={(evt) => {
              props.handleBookingContactDetailsChange("firstName", evt.target.value);
            }}
          />
          <TextField
            fullWidth
            name="family-name"
            label="Last name"
            size="small"
            value={props.bookingContactDetails?.lastName?.value}
            required={props.bookingContactDetails?.lastName?.isRequired}
            error={props.bookingContactDetails?.lastName?.error}
            helperText={
              props.bookingContactDetails?.lastName?.error ? "This field is required." : ""
            }
            variant="outlined"
            autoComplete="family-name"
            className={classes.inputField}
            InputLabelProps={{
              classes: {
                shrink: classes.shrink,
              },
            }}
            onChange={(evt) => {
              props.handleBookingContactDetailsChange("lastName", evt.target.value);
            }}
          />
          <TextField
            fullWidth
            name="email"
            label="Email"
            size="small"
            value={props.bookingContactDetails?.email?.value}
            variant="outlined"
            autoComplete="given-name"
            required={props.bookingContactDetails?.email?.isRequired}
            error={props.bookingContactDetails?.email?.error}
            helperText={
              props.bookingContactDetails?.email?.error ? "Please enter a valid email address." : ""
            }
            className={classes.inputField}
            InputLabelProps={{
              classes: {
                shrink: classes.shrink,
              },
            }}
            onChange={(evt) => {
              props.handleBookingContactDetailsChange("email", evt.target.value);
            }}
          />
          <Box>
            <PhoneInput
              defaultCountry="GB"
              placeholder="e.g +44 7911 123456"
              countryOptionsOrder={["GB", "|"]}
              value={props.bookingContactDetails?.phoneNumber?.value}
              required={props.bookingContactDetails?.phoneNumber?.isRequired}
              error={props.bookingContactDetails?.phoneNumber?.error}
              errorHelperText={
                props.bookingContactDetails?.phoneNumber?.error
                  ? "Please enter a valid phone number"
                  : ""
              }
              className={classes.phoneInputField}
              isV2
              onChange={(value) => {
                props.handleBookingContactDetailsChange("phoneNumber", value);
              }}
            />
          </Box>
          <Box>
            <FormControlLabel
              className={classNames(
                classes.termsBox,
                termsValidationError ? classes.validationFailed : null
              )}
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={termsAccepted}
                  onChange={toggleTermsAcceptance}
                  color="primary"
                />
              }
              label={
                <Typography variant="body1" color="textPrimary">
                  I have read and confirmed the&#8287;
                  <Link
                    href="https://www.molliesmotel.com/privacy-booking-policies/"
                    target="_blank">
                    Terms & conditions
                  </Link>
                </Typography>
              }
            />
            <FormControlLabel
              className={classes.termsBox}
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={props.bookingContactDetails?.subscribeOffers?.value}
                  onChange={(e) =>
                    props.handleBookingContactDetailsChange("subscribeOffers", e.target.checked)
                  }
                  color="primary"
                />
              }
              label={
                <Typography variant="body1" color="textPrimary">
                  I would like to receive exclusive offers and updates via email from Mollie&apos;s
                </Typography>
              }
            />
          </Box>
          {props.collectAdditionalRequirements && (
            <Box>
              <AdditionalRequirements
                additionalRequirements={props.additionalRequirements}
                handleAdditionalRequirementsChange={props.handleAdditionalRequirementsChange}
              />
            </Box>
          )}
        </Box>
      </Paper>
      <Button
        className={stepClasses.nextStepCTA}
        variant="contained"
        color="primary"
        size="large"
        onClick={handleNext}>
        {props.shouldPay() ? "Next Step" : "Book"}
      </Button>
    </>
  );
};

ContactDetailsStep.propTypes = {
  bookingContactDetails: PropTypes.object,
  handleBookingContactDetailsChange: PropTypes.func,
  shouldPay: PropTypes.func,
  collectAdditionalRequirements: PropTypes.bool, // Only collected when step 3 is skipped
  additionalRequirements: PropTypes.object,
  handleAdditionalRequirementsChange: PropTypes.func,
  handleNext: PropTypes.func.isRequired,
  onNotifOpen: PropTypes.func.isRequired,
};

export default ContactDetailsStep;
