import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { IconButton } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "1px 0px",
  },
  icon: {
    // Circular
    width: "30px",
    height: "30px",
    borderRadius: "15px",
    padding: "4px",
  },
  primaryIcon: {
    border: `1px solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.main,
  },
  onDarkIcon: {
    border: `1px solid ${theme.palette.custom.offWhite}`,
    color: theme.palette.text.onDark.primary,
  },
  disabled: {
    border: `1px solid ${theme.palette.text.disabled}`,
    color: theme.palette.text.disabled,
  },
  disabledOnDark: {
    border: `1px solid ${theme.palette.text.onDark.disabled}`,
    color: theme.palette.text.onDark.disabled,
  },
}));

const StepperButton = (props) => {
  const classes = useStyles();

  return (
    <IconButton
      name={props.name}
      aria-label={props["aria-label"]}
      onClick={props.onClick}
      disabled={props.disabled}
      size="small"
      className={classes.button}>
      {props.add ? (
        <AddIcon
          className={classNames(
            classes.icon,
            props.onDark ? classes.onDarkIcon : classes.primaryIcon,
            props.disabled && !props.onDark ? classes.disabled : null,
            props.disabled && props.onDark ? classes.disabledOnDark : null
          )}
        />
      ) : null}

      {props.remove ? (
        <RemoveIcon
          className={classNames(
            classes.icon,
            props.onDark ? classes.onDarkIcon : classes.primaryIcon,
            props.disabled && !props.onDark ? classes.disabled : null,
            props.disabled && props.onDark ? classes.disabledOnDark : null
          )}
        />
      ) : null}
    </IconButton>
  );
};

StepperButton.propTypes = {
  name: PropTypes.string.isRequired,
  "aria-label": PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  add: PropTypes.bool,
  remove: PropTypes.bool,
  onDark: PropTypes.bool,
};

export default StepperButton;
