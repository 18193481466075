import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

// Styled phone input field

const useStyles = makeStyles((theme) => ({
  input: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[100]}`,

    "&:focus": {
      border: `2px solid ${theme.palette.primary.main}`,
      outline: "none",
    },
  },
  inputError: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.error.main,
  },
  errorHelperLabel: {
    color: theme.palette.error.main,
    marginLeft: "14px",
    marginRight: "14px",
  },

  phoneTitleBackground: {
    position: "absolute",
    backgroundColor: theme.palette.background.default,
    top: "0px",
    height: "10px",
    background: "white",
    width: "fit-content",
    transform: "translate(-13px, -3px) scale(0.75)",
    padding: "0px 6px",
  },

  phoneTitle: {
    color: theme.palette.text.disabled,
    transform: "translate(0px, -11px)",
  },

  container: {
    /* Custom styles for the phone input field to look like outlined mui input */
    position: "relative",
    border: `1px solid rgba(0, 0, 0, 0.25)`,
    backgroundColor: theme.palette.background.default,
    borderRadius: 2000000,

    "& .PhoneInputCountry": {
      borderRight: "none",
      margin: 0,
      padding: `0px 0px 0px ${theme.spacing(2)}px`,
    },

    "& .PhoneInputInput": {
      backgroundColor: theme.palette.background.default,
      borderRadius: 2000000,
      border: "none",
      margin: 0,
      padding: "6.5px",
      paddingLeft: "14px",
    },
  },
}));

const StyledPhoneInput = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <PhoneInput
        defaultCountry={props.defaultCountry}
        placeholder={props.placeholder}
        countryOptionsOrder={props.countryOptionsOrder}
        value={props.value}
        onChange={props.onChange}
        numberInputProps={{
          className: classNames(classes.input, props.error ? classes.inputError : null),
          classes: "MuiInputBase-root PhoneInputInput",
        }}
      />
      <Box className={classes.phoneTitleBackground}>
        <Typography className={classes.phoneTitle} variant="body1">
          Phone Number *
        </Typography>
      </Box>
      {props.error ? (
        <Typography variant="caption" className={classes.errorHelperLabel}>
          {props.errorHelperText}
        </Typography>
      ) : null}
    </div>
  );
};

StyledPhoneInput.propTypes = {
  defaultCountry: PropTypes.string,
  placeholder: PropTypes.string,
  countryOptionsOrder: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  errorHelperText: PropTypes.string,
};

export default StyledPhoneInput;
