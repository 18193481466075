import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery, Typography, Grid } from "@material-ui/core";
import classNames from "classnames";

const HeadingV2 = ({ titleText = "", location = "", children, className }, props) => {
  const useStyles = makeStyles((theme) => ({
    titleContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      width: "100%",
      [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
        marginBottom: "0",
      },
    },
    headingContainer: {
      width: "100%",
    },
    children: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
        justifyContent: "space-between",
        flexWrap: "nowrap",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const desktopUpScreen = useMediaQuery(theme.breakpoints.up("desktop"));
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));

  return (
    <div className={classNames(className, classes.headingContainer)}>
      <div className={classes.titleContainer}>
        <div>
          {location && (
            <Typography variant="subtitle1" color="primary">
              {location}
            </Typography>
          )}
          <Typography variant="h2" color="textPrimary">
            {tabletUpScreen ? titleText : `${titleText}, ${location}`}
          </Typography>
        </div>
        {children && desktopUpScreen ? <Grid className={classes.children}>{children}</Grid> : null}
      </div>
      {children && !desktopUpScreen ? <Grid className={classes.children}>{children}</Grid> : null}
    </div>
  );
};

HeadingV2.propTypes = {
  titleText: PropTypes.string,
  location: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default HeadingV2;
