import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loadingSpinner: {
    margin: "auto",
  },
}));

const LoadingSpinner = ({ loading, size }) => {
  const classes = useStyles();
  return loading ? <CircularProgress className={classes.loadingSpinner} size={size} /> : null;
};

LoadingSpinner.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.number,
};

export default LoadingSpinner;
