import React, { useState, useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";

import CheckoutForm from "./CheckoutForm";
import LoadingSpinner from "components/LoadingSpinner";
import createPaymentIntention from "./utils/createPaymentIntention";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PAYMENT_PK);

import stepStyle from "../stepStyle";

const StripePaymentWidgetStep = (props) => {
  const stepClasses = stepStyle();

  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    createPaymentIntention(props.detailsToBook)
      .then((response) => {
        setClientSecret(response.client_secret);
      })
      .catch((error) => {
        props.handleError(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Paper className={stepClasses.stepContainer}>
        <Box>
          {clientSecret && (
            <Elements
              options={{
                clientSecret,
                appearance: {
                  theme: "stripe",
                },
              }}
              stripe={stripePromise}>
              <CheckoutForm
                clientSecret={clientSecret}
                propertyCode={props.propertyCode}
                buildBookingConfirmationUrl={props.buildBookingConfirmationUrl}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                cost={props.cost}
              />
            </Elements>
          )}
          <Box
            style={{
              margin: "16px auto",
              textAlign: "center",
              width: "100%",
            }}>
            <LoadingSpinner loading={isLoading} />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

StripePaymentWidgetStep.propTypes = {
  propertyCode: PropTypes.string,
  detailsToBook: PropTypes.object,
  onNotifOpen: PropTypes.func,
  buildBookingConfirmationUrl: PropTypes.func,
  handleError: PropTypes.func,
  cost: PropTypes.number,
};

export default StripePaymentWidgetStep;
