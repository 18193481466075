import React from "react";
import PropTypes from "prop-types";

import { Grid, makeStyles } from "@material-ui/core";

import ServiceByDatesRow from "./ServiceByDatesRow";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
}));

const DateAddonDetails = (props) => {
  const classes = useStyles();

  // View Logic

  // Returns the pms service details (price, defaults, etc)
  const getPmsService = (pmsId) => {
    return props.pmsServices.find((s) => s.pmsId === pmsId);
  };

  const createServiceRows = () => {
    return props.addonDetails.services.map((service, idx) => {
      const pmsService = getPmsService(service.pmsId);
      return (
        <ServiceByDatesRow
          key={idx}
          service={service}
          pmsService={pmsService}
          checkInDate={props.checkInDate}
          checkOutDate={props.checkOutDate}
          serviceCountInBasket={props.serviceCountInBasket}
          onAddServices={props.onAddServices}
          onRemoveServices={props.onRemoveServices}
          onUpdateServiceCount={props.onUpdateServiceCount}
        />
      );
    });
  };

  return (
    <Grid container direction="column" className={classes.container}>
      {createServiceRows()}
    </Grid>
  );
};

DateAddonDetails.propTypes = {
  serviceCountInBasket: PropTypes.func,
  onAddServices: PropTypes.func,
  onRemoveServices: PropTypes.func,
  onUpdateServiceCount: PropTypes.func,
  checkInDate: PropTypes.string,
  checkOutDate: PropTypes.string,
  pmsServices: PropTypes.array,
  addonDetails: PropTypes.shape({
    services: PropTypes.array,
  }),
};

export default DateAddonDetails;
