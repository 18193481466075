import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";

import Routes from "config/routes";
import { formatCurrency } from "utils/helpers";
import { getSelectedProperty } from "../../selectors/selectedProperty";
import { Box, Button, Link, Paper, Typography, withStyles } from "@material-ui/core";
import MarkdownPopover from "components/MarkdownPopover";

import { Remarkable } from "remarkable";

import { initiateDonation, setDonationError } from "../../features/payment/paymentSlice";

import donationWidgetStyle from "./donationWidgetStyle.jsx";

class DonationWidget extends React.Component {
  constructor(props) {
    super(props);

    this.donationContainer = React.createRef();

    this.handleOnDonate = this.handleOnDonate.bind(this);
    this.handleOnCancel = this.handleOnCancel.bind(this);
    this.processDonationResponse = this.processDonationResponse.bind(this);
    this.showDonationError = this.showDonationError.bind(this);

    this.openMarkdownModal = this.openMarkdownModal.bind(this);

    this.state = {
      markdownPopoverOpen: false,
    };
  }

  async componentDidMount() {
    await this.setupCheckout();
  }

  async componentDidUpdate(prevProps) {
    const donationResponse = this.props.donationResponse;
    const donationError = this.props.donationError;

    // Show any donation errors that might have occurred
    if (donationError) {
      this.showDonationError(donationError);
      return;
    }

    // Process the donation response if received
    if (donationResponse && donationResponse !== prevProps.donationResponse) {
      this.processDonationResponse(donationResponse);
    }
  }

  async setupCheckout() {
    const donationConfig = this.props.selectedProperty?.booking_flow_settings?.donation_config;

    const checkoutParams = {
      amounts: {
        currency: "GBP",
        // Amounts are in cents
        values: donationConfig?.donation_amounts?.map((d) => d.amount * 100),
      },
      name: donationConfig?.charity_name,
      description: donationConfig?.charity_description,
      logoUrl: donationConfig?.charity_logo?.url,
      url: donationConfig?.charity_url,
      showCancelButton: true,
      onDonate: this.handleOnDonate,
      onCancel: this.handleOnCancel,
    };

    if (this.checkout == null) {
      this.checkout = await AdyenCheckout(this.checkoutParams);
      this.checkout.create("donation", checkoutParams).mount(this.donationContainer.current);
    } else {
      this.checkout.update(checkoutParams);
    }
  }

  processDonationResponse(donationRes) {
    switch (donationRes.status) {
      case "pending":
      case "completed": {
        const amount = donationRes.amount.value / 100.0;
        const currency = donationRes.amount?.currency || "GBP";

        const donationAmount = formatCurrency(currency, amount, true);

        this.showDonationSuccess(`Thank you for your donation of ${donationAmount}.`);
        this.props.setDonationStepComplete(true);

        break;
      }
      case "refused":
        this.showDonationError(
          "Your donation payment was not accepted. Please try again or contact your bank for additional information."
        );
        break;

      default:
        this.showDonationError(null);
        break;
    }
  }

  showDonationSuccess(message) {
    this.props.onNotifOpen(message, { variant: "success" });
  }

  showDonationError(message) {
    console.log("Donation error: ", message);
    this.props.onNotifOpen(message || "There was an error processing your donation.", {
      variant: "error",
    });

    // Clear the error
    this.props.setDonationError(null);

    // Reset the donation widget
    this.setupCheckout();
  }

  // charity stuff

  async handleOnDonate(state, component) {
    if (!state.isValid) {
      return;
    }

    const returnUrl = window.location.origin + Routes.CONFIRM_STAY;

    this.props.initiateDonation({
      ...state.data, // contains amount object
      propertyId: this.props.selectedPropertyId,
      returnUrl: returnUrl,
      donationToken: this.props.donation?.token,
      pspReference: this.props.donation?.pspReference,
    });
  }

  handleOnCancel(state, component) {
    this.props.setDonationStepComplete(true);
  }

  openMarkdownModal(open) {
    this.setState({
      markdownPopoverOpen: open,
    });
  }

  render() {
    const { classes } = this.props;

    const donationConfig = this.props.selectedProperty?.booking_flow_settings?.donation_config;

    const charityDescription = donationConfig?.charity_description;
    const charityLogoUrl = donationConfig?.charity_logo?.url;
    const charityUrl = donationConfig?.charity_url;
    const leanMoreDescription = donationConfig?.charity_learn_more;

    const md = new Remarkable();

    const createMarkup = () => {
      return {
        __html: md.render(charityDescription),
      };
    };

    return (
      <Paper className={classes.paper}>
        <Link href={charityUrl} target="_blank">
          <img alt="Charity Logo" className={classes.charityLogo} src={charityLogoUrl} />
        </Link>
        <Typography variant="h5" color="textPrimary">
          Would you like to donate?
        </Typography>
        <Box className={classes.charityDetailsContainer}>
          <div>
            <div className={classes.rtf} dangerouslySetInnerHTML={createMarkup()}></div>
            {leanMoreDescription ? (
              <Button color="primary" onClick={() => this.openMarkdownModal(true)}>
                Learn More
              </Button>
            ) : null}
          </div>
        </Box>
        <div className="donation-container">
          <div ref={this.donationContainer} className={classes.donationContainer}></div>
        </div>
        <MarkdownPopover
          open={this.state.markdownPopoverOpen}
          onClose={() => this.openMarkdownModal(false)}
          title="Charity Donation"
          markdown={leanMoreDescription}
        />
      </Paper>
    );
  }
}

DonationWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  onNotifOpen: PropTypes.func,
  setDonationStepComplete: PropTypes.func,

  // Mapped props
  selectedPropertyId: PropTypes.string,
  selectedProperty: PropTypes.object,
  donation: PropTypes.object,
  donationResponse: PropTypes.object,
  donationError: PropTypes.string,

  initiateDonation: PropTypes.func,
  setDonationError: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    selectedPropertyId: state.property.selectedPropertyId,
    selectedProperty: getSelectedProperty(state),
    donation: state.payment?.donation,
    donationResponse: state.payment?.donation?.response,
    donationError: state.payment?.donation?.error,
  };
};

const mapDispatchToProps = {
  initiateDonation,
  setDonationError,
};

const ConnectedDonationWidget = connect(mapStateToProps, mapDispatchToProps)(DonationWidget);
export default withStyles(donationWidgetStyle)(ConnectedDonationWidget);
