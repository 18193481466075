import { flow, keys, reduce, toSafeInteger, toString } from "lodash/fp";

import { getRequestFormId } from "./utils";
import { facilityType, requestFormIdKeys } from "./facilityServicesConstants";
import { toISOString } from "utils/timekeep";

export const handleFormValuesToSubmit = ({
  eventDetails,
  enquiryContactDetails,
  additionalRequirements,
  selectedFacilityType,
  selectedProperty,
}) => {
  const initialFormValues = {
    ...eventDetails,
    ...enquiryContactDetails,
    ...additionalRequirements,
  };

  const contactDetailsKeys = ["firstName", "lastName", "email", "phoneNumber"];
  const requestFormId = getRequestFormId(requestFormIdKeys, selectedFacilityType, facilityType);

  const contactDetails = contactDetailsKeys.reduce((acc, key) => {
    const value = initialFormValues[key].value;
    switch (key) {
      case "firstName":
        acc["first_name"] = value;
        break;
      case "lastName":
        acc["last_name"] = value;
        break;
      case "email":
        acc["email"] = value;
        break;
      case "phoneNumber":
        acc["phone_number"] = value;
        break;
    }
    return acc;
  }, {});

  //DEV NOTE: Updates keys to those required for submission to AXP
  const keyMapping = {
    budget: "value",
    isDateFlexible: "details",
    companyName: "contactName",
    email: "sendersEmail",
    phoneNumber: "sendersPhone",
    cateringOrDinerReservation: "addOns",
    accommodation: "preferences",
  };

  const requestDetails = flow(
    keys,
    reduce((acc, key) => {
      if (!contactDetailsKeys.includes(key)) {
        const newKey = keyMapping[key] || key;
        acc[newKey] = initialFormValues[key].value;
      }
      return acc;
    }, {})
  )(initialFormValues);

  // Add sendersName to requestDetails using the contactDetails first_name and last_name
  requestDetails["sendersName"] = `${contactDetails.first_name} ${contactDetails.last_name}`;
  requestDetails["sendersPhone"] = contactDetails.phone_number;
  requestDetails["sendersEmail"] = contactDetails.email;
  // format values to that required for AXP
  requestDetails["numberOfPeople"] = toSafeInteger(requestDetails["numberOfPeople"]);
  requestDetails["value"] = toSafeInteger(requestDetails["value"]);
  requestDetails["preferences"] = toString(requestDetails["preferences"]);
  requestDetails["addOns"] = toString(requestDetails["addOns"]);

  const formattedDateTime = toISOString(
    initialFormValues.arrivalDate.value.format("yyyy-MM-DD"),
    initialFormValues.arrivalTime.value
  );

  return {
    ...contactDetails,
    start_at: formattedDateTime,
    request_form_id: requestFormId,
    location_id: selectedProperty.axp_location_id,
    request_details: requestDetails,
  };
};

export default handleFormValuesToSubmit;
