import React, { useState } from "react";
import PropTypes from "prop-types";

import { first, last } from "lodash/fp";
import { Button, Grid, Typography, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import AddNoteModal from "../AddNoteModal";
import DiningSelectionSummaryRow from "./DiningSelectionSummaryRow";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2),
  },
  bookingContainer: {
    marginTop: theme.spacing(3),
  },
  submitButton: {
    marginTop: theme.spacing(1),
  },
}));

const DiningSelectionSummary = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet));

  const [showNoteDialog, setShowNoteDialog] = useState(false);

  const handleSaveNote = (newSelections) => {
    props.onUpdateSelections(newSelections);
    setShowNoteDialog(false);
  };

  // Returns true if there are any selections
  // Selections is in format: { "yyyy-mm-dd": { "mealPeriod": "HH:mm" } }
  const anySelections = () => {
    return Object.keys(props.selections).some((date) => {
      return Object.keys(props.selections[date]).length > 0;
    });
  };

  return (
    <div>
      <Typography className={classes.heading} variant="h5">
        Dining summary
      </Typography>

      {props.availabilities.map((availability, index) => {
        const date = availability.date;

        // Dont render breakfast on check in date, or dinner on check-out date
        const renderBreakfast = first(props.dates) !== date;
        const renderDinner = last(props.dates) !== date;

        return (
          <DiningSelectionSummaryRow
            key={availability.date}
            partySize={props.partySize}
            renderBreakfast={renderBreakfast}
            renderDinner={renderDinner}
            dayIndex={index}
            availability={availability}
            selections={props.selections}
            onShowNoteDialog={() => setShowNoteDialog(true)}
          />
        );
      })}

      {/* Book Button */}
      <Grid
        container
        className={classes.bookingContainer}
        direction={tabletUpScreen ? "row" : "column"}
        justifyContent="space-between"
        align="center">
        <Button
          color="primary"
          disabled={!anySelections()} // disable when no selections
          onClick={() => setShowNoteDialog(true)}>
          Add Special Requests?
        </Button>
        <Button
          className={classes.submitButton}
          variant="contained"
          color="primary"
          disabled={!anySelections()} // disable when no selections
          onClick={props.onBookDining}>
          Confirm dining selections
        </Button>
      </Grid>

      {/* Note dialog */}
      {showNoteDialog ? (
        <AddNoteModal
          selections={props.selections}
          open={showNoteDialog}
          onSave={handleSaveNote}
          onClose={() => setShowNoteDialog(false)}
        />
      ) : null}
    </div>
  );
};

DiningSelectionSummary.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  availabilities: PropTypes.arrayOf(PropTypes.object),
  selections: PropTypes.object,
  partySize: PropTypes.number,
  onBookDining: PropTypes.func,
  onUpdateSelections: PropTypes.func,
};

export default DiningSelectionSummary;
