import moment from "moment";

import { parse, formatISO } from "date-fns";

export const today = () => {
  return new Date();
};

export const tomorrow = () => {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

// Takes a date object and returns format YYYY-MM-DD
export const dateToStr = (dateObj) => {
  return moment(dateObj).format("YYYY-MM-DD");
};

// Takes a date object and returns format "1 Mar"
// or "1 Mar, 2021" if includeYear = true
export const formatDateShort = (dateObj, includeYear = false) => {
  return moment(dateObj).format(includeYear ? "D MMM, YYYY" : "D MMM");
};

// Takes a date object and returns format "Thu, 1 Mar"
export const formatDateMedium = (dateObj) => {
  return moment(dateObj).format("ddd, D MMM");
};

export const formatDateToDay = (dateObj) => {
  return moment(dateObj).format("D");
};
export const formatDateToMonth = (dateObj) => {
  return moment(dateObj).format("MMM");
};

//Takes a date object and returns format "14:00"
export const formatDateTo24HrTime = (dateObj) => {
  return moment(dateObj).format("HH:mm");
};

export const toISOString = (date, time) => {
  const datetimeString = `${date} ${time}`;
  const parsedDate = parse(datetimeString, "yyyy-MM-dd HH:mm", new Date());
  return formatISO(parsedDate);
};
