import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  appBar: {},
}));

function MenuSlider({ items = [], onTabChange, className }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabChange && onTabChange(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        className={classNames(classes.appBar, className)}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off"
          aria-label="scrollable auto tabs">
          {items.map((item, index) => (
            <Tab label={item.label} key={item.id} />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}

MenuSlider.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
  onTabChange: PropTypes.any,
};

export default MenuSlider;
