import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import api from "utils/api";

import { Button, InputLabel, FormControl, MenuItem, Select } from "@material-ui/core";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner.js";

import stepStyle from "../stepStyle";

const ChooseFacilityStep = (props) => {
  const classes = stepStyle();

  const [isLoading, setIsLoading] = useState(false);
  const [isFacilityServicesLoading, setIsFacilityServicesLoading] = useState(false);

  const [facilities, setFacilities] = useState([]);
  const [currentFacility, setCurrentFacility] = useState(null);

  useEffect(() => {
    if (!props.property) return;

    setIsLoading(true);

    // Fetch all bookable facilities
    let apiFacilities = api.getBookableFacilities(props.property.id);

    apiFacilities.then((response) => {
      const facilities = response["data"];
      setFacilities(facilities);
      setIsLoading(false);
      return facilities;
    });
  }, [props.property]);

  const handleFacilityChange = (e) => {
    const facilityId = e.target.value;
    const facility = facilities.find((facility) => facility.id === facilityId);
    setCurrentFacility(facility);
  };

  const handleNext = () => {
    // Perform validations
    if (!currentFacility) {
      return;
    }

    setIsFacilityServicesLoading(true);

    api.getFacilityServices(currentFacility?.id).then((response) => {
      const facilityServices = response["data"];
      setIsFacilityServicesLoading(false);

      props.handleNext(currentFacility, facilityServices);
      return facilityServices;
    });
  };

  return isLoading ? (
    <LoadingSpinner loading={isLoading} />
  ) : (
    <>
      <div className={classNames(classes.stepContainer, classes.stepContainerOverride)}>
        <FormControl required className={classes.selectFormControl} variant="outlined">
          <InputLabel id="facility-select-label">Facility</InputLabel>
          <Select
            className={classes.selectInput}
            name="facilityId"
            labelId="facility-select-label"
            label="Facility"
            value={currentFacility?.id}
            onChange={handleFacilityChange}>
            {facilities.map((facility) => (
              <MenuItem key={facility.id} value={facility.id}>
                {facility.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {isFacilityServicesLoading ? (
        <LoadingSpinner loading={isFacilityServicesLoading} />
      ) : (
        <Button
          className={classes.nextStepCTA}
          variant="contained"
          color="primary"
          size="large"
          disabled={!currentFacility}
          onClick={handleNext}>
          Next Step
        </Button>
      )}
    </>
  );
};

ChooseFacilityStep.propTypes = {
  property: PropTypes.object,
  handleNext: PropTypes.func.isRequired,
};

export default ChooseFacilityStep;
