import React from "react";
// import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper, Box, Button } from "@material-ui/core";

import qrCodeImage from "images/get_app_qr_code.png";
import registerImage from "images/DigitalKey_Register.png";
import signInImage from "images/DigitalKey_SignIn.png";
import Heading from "components/Heading";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    flexDirection: "column",
    margin: "1rem auto",
    width: theme.contentSize.mobilePageWidth,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: theme.contentSize.pageWidth,
      maxWidth: theme.contentSize.maxPageWidth,
    },
  },
  steps: {
    gridGap: "7rem",
    flexWrap: "nowrap",
    padding: "2rem 3rem",
  },
  image: {
    width: "12rem",
  },
  message: {
    backgroundColor: "rgb(248, 246, 247)",
    margin: "0 3rem",
    padding: ".5rem 0",
  },
  label: {
    padding: "1.5rem 0",
  },
  gettingYour: {
    fontSize: "1rem",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    bottom: "0",
    width: "100%",
    padding: "1rem 0",
    borderTop: theme.palette.other.lineDetail,
  },
  footer: {
    width: "80%",
    maxWidth: "1300px",
    display: "flex",
    justifyContent: "end",
  },
  finishedButton: {
    paddingLeft: "5rem",
    paddingRight: "5rem",
  },
  badgeContainer: {
    marginBottom: "3rem",
  },
  badge: {
    padding: ".9rem 1.5rem",
    borderRadius: "50%",
    backgroundColor: "rgb(170, 33, 55)",
    color: "white",
    fontSize: "2rem",
    fontFamily: "Monospace",
  },
}));

export default function KioskConfirmStay() {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.mainContent}>
        <Heading titleText="Thank you for booking" />
        <Paper className={classes.bookingSummaryCard}>
          <Box my={2} p={2}>
            <Grid container justifyContent="center">
              <Box mb={2}>
                <Typography variant="overline" color="primary" className={classes.gettingYour}>
                  getting your
                </Typography>
              </Box>
            </Grid>
            <Grid container justifyContent="center">
              <Box mb={2}>
                <Typography variant="h3" color="primary">
                  Digital key
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.steps}>
              <Grid container direction="column" justifyContent="center" align="center">
                <Box className={classes.badgeContainer}>
                  <span className={classes.badge}>1</span>
                </Box>
                <Box>
                  <img src={qrCodeImage} alt="Qr Code" className={classes.image} />
                  <Typography variant="h4" color="primary" className={classes.label}>
                    Get the App
                  </Typography>
                </Box>
                <Typography variant="body2" color="textPrimary">
                  Download the Mollie&#39;s app from the app store or scan the QR code.
                </Typography>
              </Grid>
              <Grid container direction="column" justifyContent="center" align="center">
                <Box className={classes.badgeContainer}>
                  <span className={classes.badge}>2</span>
                </Box>
                <Box>
                  <img src={signInImage} alt="Sign in" className={classes.image} />
                  <Typography variant="h4" color="primary" className={classes.label}>
                    Sign in
                  </Typography>
                </Box>
                <Typography variant="body2" color="textPrimary">
                  Sign into the app to access your booking. Then, tap &#8220;link booking&#8221;.
                </Typography>
              </Grid>
              <Grid container direction="column" justifyContent="center" align="center">
                <Box className={classes.badgeContainer}>
                  <span className={classes.badge}>3</span>
                </Box>
                <Box>
                  <img src={registerImage} alt="Qr Code" className={classes.image} />
                  <Typography variant="h4" color="primary" className={classes.label}>
                    Register
                  </Typography>
                </Box>
                <Typography variant="body2" color="textPrimary">
                  Register for a digital key by clicking the button on your booking.
                </Typography>
              </Grid>
            </Grid>
            <Box className={classes.message} align="center">
              <Typography variant="body1" color="gray">
                If you would like a physical key, please go to the front desk.
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Paper className={classes.footerContainer}>
        <Grid container className={classes.footer} justifyContent="space-between">
          <Box>
            <Button
              href="mollies://app/closeBookingFlow"
              color="primary"
              variant="contained"
              className={classes.finishedButton}>
              Finished
            </Button>
          </Box>
        </Grid>
      </Paper>
    </>
  );
}
