let initialized = false;
let debug = false;

const log = (...args) => {
  if (!debug) {
    return;
  }
  // eslint-disable-next-line no-console
  console.info(...["[microsoft-uet]"].concat(args));
};

const warn = (...args) => {
  if (!debug) {
    return;
  }
  // eslint-disable-next-line no-console
  console.info(...["[microsoft-uet]"].concat(args));
};

const defaultOptions = {
  debug: false,
};

const verifyInit = () => {
  if (!initialized) {
    warn("MicrosoftUet not initialized before using call MicrosoftUet.init with required params");
  }
  return initialized;
};

export default {
  init: (uetId, options = defaultOptions) => {
    if (!uetId) {
      warn("Init called without a uetId");
      return;
    }

    initialized = typeof window !== "undefined" && !!window.uetq;

    /* eslint-disable */
    (function(w,d,t,r,u)
    {
      var f,n,i;
      w[u]=w[u]||[],f=function()
      {
        var o={ti:uetId,disableAutoPageView:true};
        o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
      },
      n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
      {
        var s=this.readyState;
        s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
      },
      i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
    })
    (window,document,"script","//bat.bing.com/bat.js","uetq");
    /* eslint-enable */

    initialized = true;
    debug = options.debug;

    log("initialized");
  },
  pageView: (pageName) => {
    if (!verifyInit()) {
      return;
    }

    log("pageView: ", pageName);

    // https://bingadsuet.azurewebsites.net/SPA_Index.html
    window.uetq = window.uetq || [];
    window.uetq.push("event", "page_view", { page_path: pageName });
  },
  trackEvent: (eventName, eventParams) => {
    if (!verifyInit()) {
      return;
    }

    log("track event: ", eventName, eventParams);

    window.uetq = window.uetq || [];
    window.uetq.push("event", eventName, eventParams);
  },
};
