import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import Routes from "config/routes";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Button, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  noAvailModalContent: {
    boxShadow: theme.shadows[5],
    backgroundColor: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    padding: "20px",
  },
  modalHeader: {
    justifyContent: "center",
  },
  contentRow: {
    marginBottom: "40px",
  },
  modalActions: {
    justifyContent: "flex-end",
  },
}));

const EditAvailCheckModal = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const handleContinueClick = () => {
    history.push(Routes.PLAN_STAY);
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Grid container className={classes.noAvailModalContent}>
        <Grid container className={`${classes.modalHeader} ${classes.contentRow}`}>
          <Typography variant="h6">
            Editing your search will remove all current reservations from your cart.
          </Typography>
        </Grid>
        <Grid container className={classes.modalActions}>
          <Button variant="outlined" onClick={props.onClose}>
            Cancel
          </Button>
          &nbsp;
          <Button variant="contained" color="primary" onClick={handleContinueClick}>
            Continue
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

EditAvailCheckModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EditAvailCheckModal;
