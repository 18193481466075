import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  makeStyles,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { omit, pick } from "lodash/fp";

import FacilityBookingConfirmation from "components/FacilityBookingConfirmation";
import getEventFormFields, {
  getBookingSettings,
  initialAdditionalRequirements,
  initialBookingContactDetails,
} from "./BookingEventFormFields.js";
import handleFormValuesToSubmit from "./handleFormValuesToSubmit.js";
import SideBarModal from "components/SideBarModal";
import { getSelectedRoomData, handleAvailabilitySummary } from "./utils.js";
import moment from "moment";
import {
  validateRequiredInputs,
  validateContactDetails,
  applyErrorStateToFormFields,
  applyErrorStateToContactFormFields,
} from "utils/formHelpers.js";
import CustomizedStepIcon from "./CustomizedStepIcon.js";
import { getBookingSetting, getOptions } from "./utils/getOptions.js";
import { createUnpaidReservation } from "./utils/createUnpaidReservation.js";
import { buildBookingConfirmationUrl } from "./utils/buildBookingConfirmationUrl.js";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner.js";

// Step Components

// Step 1a - Choose facilities (optional depending on whether facility is provided)
import ChooseFacilityStep from "./steps/Step 1a/ChooseFacilityStep.js";

// Step 1b - Choose space (optional depending on whether facility is provided)
import ChooseSpaceStep from "./steps/Step 1b/ChooseSpaceStep.js";

// Step 2 - Check availability
import CheckFacilityAvailabilityStep from "./steps/Step 2/CheckFacilityAvailabilityStep.js";

// Step 3 - Event details
import EventDetailsStep from "./steps/Step 3/EventDetailsStep.js";

// Step 4 - Your details
import ContactDetailsStep from "./steps/Step 4/ContactDetailsStep.js";

// Step 5 - Payment details (optional depending on whether payment is needed)
import StripePaymentWidgetStep from "./steps/Step 5/StripePaymentWidgetStep.js";

const useStyles = makeStyles((theme) => ({
  mainContentContainer: {
    width: "100%",
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  detailsCard: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  CTAContainer: {
    display: "flex",
    gap: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    alignItems: "center",
  },
  stepper: {
    background: "none",
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  stepperEditIcon: {
    color: theme.palette.primary.main,
    width: "22px",
  },
  stepHeadingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textButton: { paddingLeft: 0, "&:hover": { backgroundColor: "inherit" } },
  textButtonOverride: {
    paddingLeft: 0,
    "&:hover": { backgroundColor: "inherit" },
    marginTop: theme.spacing(2),
    color: theme.palette.text.red,
  },
  inputField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 20000,
      backgroundColor: theme.palette.background.default,
    },
  },
  loadingContainer: {
    outline: "solid 1px red",
    textAlign: "center",
    width: "100%",
    height: "100vh",
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  submittedFormContainer: {
    marginBottom: theme.spacing(3),
  },
  enquirySummaryContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  successHeading: {
    color: theme.palette.success.contrastText,
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    padding: "12px 16px",
    backgroundColor: theme.palette.success.main,
  },
  dateContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  finishButton: {
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
  validationFailed: {
    backgroundColor: theme.palette.error.background,
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: "4px",
  },
}));

const FacilityBookingSideBarModal = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));
  const desktopUpScreen = useMediaQuery(theme.breakpoints.up("desktop"));

  // Initialize state with props. If the facility is not provided, the user will have to choose a facility first
  const [facility, setFacility] = useState(props.facility);
  const [facilityServices, setFacilityServices] = useState(props.facilityServices);
  const [selectedRoomId, setSelectedRoomId] = useState(props.selectedRoomId);

  const [stepLabels, setStepLabels] = useState([]);
  const [activeStep, setActiveStep] = useState(
    props.bookingConfirmation ? stepLabels.length + 1 : 0
  );

  const selectedProperty = props.property;

  const [bookingSettings, setBookingSettings] = useState({});
  const [eventDetails, setEventDetails] = useState({});
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [detailsToBook, setDetailsToBook] = useState(null);

  const [additionalRequirements, setAdditionalRequirements] = useState(
    initialAdditionalRequirements
  );

  const handleEnquiry = () => {
    props.handleEnquirySideBarModalOpen();
  };

  const [bookingContactDetails, setBookingContactDetails] = useState(initialBookingContactDetails);

  const selectedRoom = getSelectedRoomData(selectedRoomId, facilityServices);

  const restartBooking = () => {
    setBookingContactDetails(initialBookingContactDetails);
    setAdditionalRequirements(initialAdditionalRequirements);
    setActiveStep(0);
  };

  useEffect(() => {
    setFacility(props.facility);
    setFacilityServices(props.facilityServices);
    setSelectedRoomId(props.selectedRoomId);

    restartBooking();
  }, [props.facilityServices, props.facility, props.selectedRoomId]);

  useEffect(() => {
    // DEV NOTE: Needs to be in useEffect as modal may load before facilityServices
    if (!facility || !facilityServices) return;

    setBookingSettings(getBookingSettings(facility));

    setEventDetails(
      getEventFormFields({
        facility: facility,
        facilityServices: facilityServices,
        selectedRoomId: selectedRoomId,
      })
    );
  }, [facilityServices, facility, selectedRoomId]);

  const handleAdditionalRequirementsChange = (key, value) => {
    setAdditionalRequirements((prev) => ({ ...prev, [key]: { ...prev[key], value } }));
  };

  const handleBookingContactDetailsChange = (key, value) => {
    setBookingContactDetails((prevState) => ({
      ...prevState,
      [key]: { ...prevState[key], value },
    }));
  };
  const handleEventDetailsChange = (key, value) => {
    setEventDetails((prevState) => ({ ...prevState, [key]: { ...prevState[key], value } }));
  };

  const addDefaults = (formFields) => {
    // NM: Start on today.
    const defaultDate = moment();

    Object.entries(formFields).forEach(([formFieldKey, formFieldValues]) => {
      if (formFieldValues.value) return;

      const options = getOptions(formFieldValues, defaultDate);
      switch (formFieldKey) {
        case "arrivalDate":
          formFields[formFieldKey].value = defaultDate;
          break;
        case "arrivalTime":
          if (!options.hide) {
            formFields[formFieldKey].value = options.inputProps.options[0].value;
          }
          break;
      }
    });

    return formFields;
  };

  const handleRemoveFieldsFromPrevStep = (formFields) => {
    return omit(["arrivalDate", "arrivalTime", "numberOfHours", "numberOfPeople", "typeOfRoom"])(
      formFields
    );
  };
  const handlePickFieldsForAvailabilitySearch = (formFields) => {
    return pick(["arrivalDate", "arrivalTime", "numberOfHours", "numberOfPeople"])(formFields);
  };

  const handleDetailsToBook = ({
    eventDetails,
    bookingContactDetails,
    additionalRequirements,
    selectedRoom,
    selectedTimeSlot,
  }) => {
    const data = handleFormValuesToSubmit({
      eventDetails,
      bookingContactDetails,
      additionalRequirements,
      selectedFacilityType: facility?.type,
      selectedRoom,
      selectedTimeSlot,
    });

    setDetailsToBook(data);
  };

  const handleError = (error) => {
    const apiErrorMessage = error?.response?.data?.error?.message;
    props.onNotifOpen(apiErrorMessage || "Something went wrong", {
      variant: "error",
    });
  };

  // NM: Under certain conditions users don't have to pay the booking.
  const shouldPay = () => !!selectedTimeSlot && selectedTimeSlot.cost != null;

  const createBookingSteps = () => {
    let steps = [];

    const showEndTime = getBookingSetting(
      bookingSettings,
      eventDetails["arrivalDate"]?.value,
      "showEndTime"
    );

    const collectEventDetails = getBookingSetting(
      bookingSettings,
      eventDetails["arrivalDate"]?.value,
      "collectEventDetails"
    );

    const showPrice = !!selectedTimeSlot?.cost;

    if (props.chooseFacility) {
      steps.push({
        label: "Choose facility",
        labelContent: (index, activeStep) =>
          activeStep > index &&
          facility && (
            // Only show label content for this step if it's been completed
            <Typography variant="body2" color="textSecondary">
              {facility.name}
            </Typography>
          ),
        content: (
          <ChooseFacilityStep
            property={selectedProperty}
            handleNext={(facility) => {
              setFacility(facility);
              setActiveStep((prev) => prev + 1);
            }}
          />
        ),
      });

      // We also need to capture the room they want to book
      steps.push({
        label: "Choose space",
        labelContent: (index, activeStep) =>
          activeStep > index &&
          selectedRoom && (
            // Only show label content for this step if it's been completed
            <Typography variant="body2" color="textSecondary">
              {selectedRoom.title}
            </Typography>
          ),
        content: (
          <ChooseSpaceStep
            property={selectedProperty}
            facility={facility}
            handleNext={(facilityServices, selectedRoomId) => {
              setFacilityServices(facilityServices);
              setSelectedRoomId(selectedRoomId);

              setActiveStep((prev) => prev + 1);
            }}
          />
        ),
      });
    }

    steps.push({
      label: "Check availability",
      labelContent: (index, activeStep) =>
        activeStep > index &&
        selectedTimeSlot && (
          // Only show label content for this step if it's been completed
          <Typography variant="body2" color="textSecondary">
            {handleAvailabilitySummary({
              eventDetails,
              selectedTimeSlot,
              showEndTime,
              showPrice,
            })}
          </Typography>
        ),
      content: (
        <CheckFacilityAvailabilityStep
          bookingSettings={bookingSettings}
          showEndTime={showEndTime}
          showPrice={showPrice}
          searchDetails={addDefaults(handlePickFieldsForAvailabilitySearch(eventDetails))}
          selectedRoom={selectedRoom}
          selectedProperty={selectedProperty}
          handleSearchDetailsChange={handleEventDetailsChange}
          handleEnquiry={handleEnquiry}
          validateSearchDetails={() => {
            const isValid = validateRequiredInputs(
              handlePickFieldsForAvailabilitySearch(eventDetails)
            );

            if (isValid) return true;

            // Update errors in eventDetails
            setEventDetails((prevState) => {
              const updatedFormFields = {
                ...prevState,
                ...applyErrorStateToFormFields(handlePickFieldsForAvailabilitySearch(prevState)),
              };
              return updatedFormFields;
            });

            return false;
          }}
          handleError={handleError}
          handleNext={(selectedTimeSlot) => {
            setSelectedTimeSlot(selectedTimeSlot);

            const hasValidSearchDetails = validateRequiredInputs(
              handlePickFieldsForAvailabilitySearch(eventDetails)
            );

            if (!hasValidSearchDetails) {
              // Update errors in eventDetails
              setEventDetails((prevState) => {
                const updatedFormFields = {
                  ...prevState,
                  ...applyErrorStateToFormFields(handlePickFieldsForAvailabilitySearch(prevState)),
                };
                return updatedFormFields;
              });

              return false;
            }

            return setActiveStep((prev) => prev + 1);
          }}
        />
      ),
    });

    if (collectEventDetails) {
      steps.push({
        label: "Event details",
        labelContent: null,
        content: (
          <EventDetailsStep
            eventDetails={handleRemoveFieldsFromPrevStep(eventDetails)}
            handleEventDetailsChange={handleEventDetailsChange}
            additionalRequirements={additionalRequirements}
            handleAdditionalRequirementsChange={handleAdditionalRequirementsChange}
            handleNext={() => {
              const isValid = validateRequiredInputs(eventDetails);
              if (!isValid) {
                // Update errors in eventDetails
                setEventDetails((prevState) => {
                  const updatedFormFields = applyErrorStateToFormFields(prevState);
                  return updatedFormFields;
                });

                return false;
              } else {
                setActiveStep((prev) => prev + 1);
              }
            }}
          />
        ),
      });
    }

    steps.push({
      label: "Your details",
      labelContent: null,
      content: (
        <ContactDetailsStep
          onNotifOpen={props.onNotifOpen}
          bookingContactDetails={bookingContactDetails}
          handleBookingContactDetailsChange={handleBookingContactDetailsChange}
          shouldPay={shouldPay}
          collectAdditionalRequirements={!collectEventDetails} // If step 3 is skipped, we need to collect them on step 4 instead
          additionalRequirements={additionalRequirements}
          handleAdditionalRequirementsChange={handleAdditionalRequirementsChange}
          handleNext={() => {
            const isContactDetailsValid = validateContactDetails(bookingContactDetails);

            if (!isContactDetailsValid) {
              setBookingContactDetails((prevState) => {
                const updatedFormFields = applyErrorStateToContactFormFields(prevState);
                return updatedFormFields;
              });
              return false;
            }

            handleDetailsToBook({
              eventDetails,
              bookingContactDetails,
              additionalRequirements,
              selectedRoom,
              selectedTimeSlot,
            });

            if (shouldPay()) {
              setActiveStep((prev) => prev + 1);
            } else {
              setIsLoading(true);
              createUnpaidReservation({
                selectedTimeSlot,
                selectedRoom,
                bookingContactDetails,
                additionalRequirements,
              })
                .then(() => {
                  setIsLoading(false);
                  window.location.replace(
                    buildBookingConfirmationUrl({
                      facility,
                      eventDetails,
                      selectedRoom,
                      selectedTimeSlot,
                      additionalRequirements,
                      showEndTime,
                      collectEventDetails,
                      showPrice,
                    })
                  );
                })
                .catch((error) => {
                  console.error("Error occurred: ", error);

                  const errorMessage = error?.response?.data?.error?.message || "Unknown error";
                  const message = `Your reservation could not be completed. ${errorMessage}`;
                  setIsLoading(false);
                  props.onNotifOpen(message, { variant: "error" });
                  return Promise.reject(errorMessage);
                });
            }
          }}
        />
      ),
    });

    if (shouldPay()) {
      steps.push({
        label: "Payment details",
        labelContent: null,
        content: (
          <StripePaymentWidgetStep
            buildBookingConfirmationUrl={() =>
              buildBookingConfirmationUrl({
                facility,
                eventDetails,
                selectedRoom,
                selectedTimeSlot,
                additionalRequirements,
                showEndTime,
                collectEventDetails,
                showPrice,
              })
            }
            propertyCode={selectedProperty?.code}
            detailsToBook={detailsToBook}
            handleError={handleError}
            cost={selectedTimeSlot?.cost}
          />
        ),
      });
    }

    return steps;
  };

  function getStep(index) {
    return createBookingSteps()[index];
  }

  function handleShowEditIcon({ currentStep, activeStep }) {
    if (currentStep >= activeStep) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    // Recalculate steps when bookingSettings changes upon first load
    // Recalculate steps when selectedTimeSlot changes in case payment is no longer required
    setStepLabels(createBookingSteps().map((step) => step.label));
  }, [selectedTimeSlot, bookingSettings]);

  const stepTo = (step) => {
    if (step <= activeStep) {
      setActiveStep(step);
    }
  };

  const handleChangeRoom = () => {
    props.onClose();
    props.handleChooseRoomModalOpen();
  };

  const [isLoading, setIsLoading] = useState(false);

  const pageContents = isLoading ? (
    <Box className={classes.loadingContainer}>
      <Box>
        <LoadingSpinner loading={isLoading} />
      </Box>
      <Typography variant="subtitle1" color="textPrimary">
        Sending your reservation...
      </Typography>
    </Box>
  ) : (
    <>
      {!props.bookingConfirmation && (
        <Box className={classes.mainContentContainer}>
          <Box>
            <Box className={classes.CTAContainer}>
              <Typography variant="subtitle1">{selectedRoom?.title}</Typography>
              {props.embedded ? null : (
                <Button
                  className={classes.textButton}
                  color="primary"
                  size="small"
                  variant="text"
                  disableRipple
                  onClick={() => handleChangeRoom()}>
                  <Typography variant="subtitle1">Change</Typography>
                </Button>
              )}
            </Box>
            <Box className={classes.detailsCard}>
              <Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
                {stepLabels.map((stepLabel, index) => {
                  const step = getStep(index);
                  return (
                    step && (
                      <Step
                        style={{
                          position: "relative",
                        }}
                        key={stepLabel}
                        active={activeStep === index}>
                        <StepLabel
                          StepIconComponent={CustomizedStepIcon}
                          onClick={() => {
                            stepTo(index);
                          }}>
                          <Box className={classes.stepHeadingContainer}>
                            <div>
                              <Typography variant={tabletUpScreen ? "h4" : "h5"}>
                                {stepLabel}
                              </Typography>
                              {step.labelContent && step.labelContent(index, activeStep)}
                            </div>
                            {handleShowEditIcon({ currentStep: index, activeStep }) && (
                              <Button>
                                <CreateOutlinedIcon
                                  className={classes.stepperEditIcon}
                                  color="primary"
                                />
                              </Button>
                            )}
                          </Box>
                        </StepLabel>
                        <StepContent
                          hidden={activeStep !== index && !desktopUpScreen}
                          style={{ paddingRight: tabletUpScreen ? "inherit" : 0 }}>
                          {step.content}
                        </StepContent>
                      </Step>
                    )
                  );
                })}
              </Stepper>
            </Box>
          </Box>
        </Box>
      )}

      {props.bookingConfirmation && (
        <FacilityBookingConfirmation
          embedded={props.embedded}
          onClose={() => {
            restartBooking();
            props.onClose();
          }}
          selectedProperty={selectedProperty}
          bookingConfirmation={props.bookingConfirmation}
        />
      )}
    </>
  );

  return props.embedded ? (
    pageContents
  ) : (
    <SideBarModal modalTitle="Book room" {...props}>
      {pageContents}
    </SideBarModal>
  );
};

FacilityBookingSideBarModal.propTypes = {
  embedded: PropTypes.bool, // Embedded mode - i.e full page
  property: PropTypes.object,
  chooseFacility: PropTypes.bool,
  facility: PropTypes.object,
  facilityServices: PropTypes.array,
  selectedRoomId: PropTypes.number,
  onClose: PropTypes.func,
  onNotifOpen: PropTypes.func,
  handleEnquirySideBarModalOpen: PropTypes.func,
  handleChooseRoomModalOpen: PropTypes.func,
  setSelectedRoomId: PropTypes.func,
  bookingConfirmation: PropTypes.object,
};

export default FacilityBookingSideBarModal;
