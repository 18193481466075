import React, { useState } from "react";
import PropTypes from "prop-types";

import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography, Box, Link, Dialog } from "@material-ui/core";
import { KeyboardArrowUpOutlined, KeyboardArrowDownOutlined } from "@material-ui/icons";

import { ADDON_DATE_IN, ADDON_DATE_OUT } from "utils/dateFormats";
import CancelReservationModal from "components/CancelReservationModal";

import { formatCurrency, formatQuantity, guestCountForReservation } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  summaryItem: {
    padding: `${theme.spacing(1)}px 0px`,
    borderBottom: `1px solid ${theme.palette.other.lineDetail}`,
  },
  summaryItemAttribute: {
    flexGrow: 5,
  },
  roomHeader: {
    marginBottom: "1rem",
  },
  roomImage: {
    objectFit: "cover",
    height: "160px",
    width: "160px",
    marginRight: "1rem",
  },
  cancelButton: {
    marginBottom: "16px",
  },
  redText: {
    color: theme.palette.text.red,
    fontWeight: 700,
  },
}));

const RoomSummaryCard = (props) => {
  const classes = useStyles();
  const [openPolicy, setOpenPolicy] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const [cancelReservationModalOpen, setCancelReservationModalOpen] = useState(false);

  const isParking = (reservation) => reservation.unitGroup.code === "PARKING";

  const renderAttributes = () => {
    const attributes = [
      {
        name: isParking(props.reservation)
          ? "1 space"
          : formatQuantity(props.reservation.timeSlices.length, "night"),
        price: formatCurrency(
          props.reservation.noShowFee.fee.currency,
          props.reservation.noShowFee.fee.amount
        ),
      },
    ];

    if (props.reservation.services) {
      props.reservation.services.forEach((service) => {
        // Calculate quantity of services

        // We'll show the individual date count if the number of services is different on each day
        // Or if the service is for multiple days
        const showIndividualDates =
          service.dates.length > 1 || service.dates.some((d) => d.count != service.dates[0]?.count);

        const attribute = {
          price: formatCurrency(service.totalAmount.currency, service.totalAmount.grossAmount),
        };

        if (showIndividualDates) {
          attribute.name = `${service.service.name}`;
          attribute.details = service.dates.map((date) => {
            return `${date.count} x ${moment(date.serviceDate, ADDON_DATE_IN).format(
              ADDON_DATE_OUT
            )}`;
          });
        } else {
          const quantity = service.dates.reduce((acc, s) => acc + s.count, 0);
          attribute.name = `${quantity} x ${service.service.name}`;
        }

        attributes.push(attribute);
      });
    }

    return attributes;
  };

  const isCancellable = (reservation) => {
    return (
      reservation.status === "Confirmed" && Date.parse(reservation.arrival) > Date.parse(new Date())
    );
  };

  const handleCancelReservationModalClose = () => {
    setCancelReservationModalOpen(false);
  };

  const handleSelectReservationForCancel = (reservationObj) => {
    // setSelectedReservationToCancel(reservationObj);
    setCancelReservationModalOpen(true);
  };

  return (
    <>
      <Grid container className={classes.summaryItem}>
        <Grid container>
          <Grid item className={classes.summaryItemAttribute}>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Reservation number: {props.reservation.id}
              </Typography>
              {props.showCancellation && isCancellable(props.reservation) ? (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.cancelButton}
                  onClick={() => handleSelectReservationForCancel(props.reservation)}>
                  Cancel
                </Button>
              ) : null}
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                {props.reservation.unitGroup.name}
              </Typography>
              {!isParking(props.reservation) ? (
                <Typography variant="body1" color="textSecondary">
                  {formatQuantity(guestCountForReservation(props.reservation), "guest")}{" "}
                </Typography>
              ) : null}
            </Grid>
            {showDetails &&
              renderAttributes().map((attribute, index) => (
                <Grid container key={index} justifyContent="space-between" spacing={2}>
                  <Grid item style={{ whiteSpace: "pre" }}>
                    <Typography variant="body1">{attribute.name}</Typography>
                    {attribute.details?.map((detail) => (
                      <Typography key={detail} variant="body2" color="textSecondary">
                        {detail}
                      </Typography>
                    ))}
                  </Grid>
                  <Grid item>
                    <Typography className={classes.redText} variant="body1" color="textSecondary">
                      {attribute.price}
                    </Typography>
                  </Grid>
                </Grid>
              ))}

            <Button
              color="primary"
              onClick={() => setShowDetails(!showDetails)}
              startIcon={showDetails ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}>
              Show {showDetails ? "Less" : "More"}
            </Button>

            {props.showCancellation && !isParking(props.reservation) ? (
              <Box my={1}>
                <Typography variant="body2" color="textSecondary">
                  Cancellation policy{" "}
                  <Link
                    onClick={() => setOpenPolicy(true)}
                    underline="always"
                    className={classes.cancellationPolicyLink}>
                    {props.reservation.cancellationFee.name}
                  </Link>
                  <Dialog open={openPolicy} onClose={() => setOpenPolicy(false)}>
                    <Box p={2}>{props.reservation.cancellationFee.description}</Box>
                  </Dialog>
                </Typography>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <CancelReservationModal
        open={cancelReservationModalOpen}
        reservation={props.reservation}
        onClose={handleCancelReservationModalClose}
        onReservationCancelled={props.onReservationCancelled}
        onNotifOpen={props.onNotifOpen}
      />
    </>
  );
};

RoomSummaryCard.propTypes = {
  onNotifOpen: PropTypes.func,
  reservation: PropTypes.object,
  showCancellation: PropTypes.bool,
  onReservationCancelled: PropTypes.func,
};

export default RoomSummaryCard;
