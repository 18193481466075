import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";

import Routes from "config/routes";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Button,
  Popover,
  Grid,
  Dialog,
  Typography,
  MenuList,
  MenuItem,
  Divider,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";

import FindBookingModal from "components/FindBookingModal";
import AuthCTA from "components/AuthCTA";
import molliesLogoRed from "images/Mollies_Ico_Red.svg";
import molliesLogoText from "images/mollies_logo_text.svg";
import sohoHouseLogo from "images/soho_house_logo.svg";
import { signOutUser } from "features/user/userSlice";
import { initialsForName } from "utils/helpers";
import { BookingSourceContext } from "contexts/BookingSourceContext";

const useStyles = makeStyles((theme) => ({
  logoAppBar: {
    position: "static",
    backgroundColor: theme.palette.background.componentBackground1,
    padding: "0 !important", // Material UI bug see https://github.com/mui-org/material-ui/issues/17353
    boxShadow: theme.navBarShadow,
  },
  logoToolbar: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    margin: "0px auto",
    width: "95%",
    maxWidth: theme.contentSize.maxContentWidth,
    minHeight: "76px",
    paddingLeft: "0px",
    paddingRight: "0px",
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: "100%",
      minHeight: "auto",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  logoContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  navbarLogo: {
    height: "27px",
    [theme.breakpoints.up("tablet")]: {
      height: "33px",
    },
  },
  navbarMultiLogo: {
    height: "13px",
    [theme.breakpoints.up("tablet")]: {
      height: "13px",
    },
  },
  navbarLogoCentered: {
    [theme.breakpoints.up("tablet")]: {
      position: "absolute",
      transform: "translateX(-50%)",
      left: "50%",
    },
  },
  plusIcon: {
    display: "flex",
    alignItems: "center",
  },
  menu: {
    "& li, a": {
      minHeight: "32px",
    },
  },
  menuDivider: {
    margin: "5px 10px",
  },
  navActionsContainer: {
    display: "flex",
    alignItems: "center",
  },
  navActionContainer: {
    display: "flex",
    alignItems: "center",
  },
  dialogPaper: {
    width: "580px",
    margin: "auto",
  },
  closeButton: {
    position: "absolute",
    right: "1rem",
    top: "1rem",
    cursor: "pointer",
    color: theme.palette.textSecondary,
  },
  menuButton: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    border: "1px solid",
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.grey[900],
    },
  },
  menuText: {
    paddingTop: "1px",
    marginRight: "8px",
    marginLeft: "6px",
  },
  avatar: {
    backgroundColor: theme.palette.text.primary, // To replace when implementing full re-design
    height: "28px",
    width: "28px",
    borderRadius: "14px",
    marginRight: "8px",
    paddingTop: "2px",
  },
  initials: {
    color: theme.palette.text.onDark.primary,
  },
  hidden: {
    display: "none",
  },
}));

const Navbar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));

  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.user.authUser);
  const appTheme = useSelector((state) => state.booking.appTheme);
  const mobileAppUser = useSelector((state) => state.user.mobileAppUser);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [findBookingModalOpen, setFindBookingModalOpen] = useState(false);

  const menuOpen = Boolean(menuAnchorEl);

  const handleSignOut = () => {
    dispatch(signOutUser());
    setMenuAnchorEl(null);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuOpen = (e) => {
    setMenuAnchorEl(e.currentTarget);
  };

  const toggleSignInModal = () => {
    setSignInModalOpen(!signInModalOpen);
    setMenuAnchorEl(null);
  };

  const toggleSignUpModal = () => {
    setSignUpModalOpen(!signUpModalOpen);
    setMenuAnchorEl(null);
  };

  const toggleFindBookingModal = () => {
    setFindBookingModalOpen(!findBookingModalOpen);
    setMenuAnchorEl(null);
  };

  const { bookingSource } = useContext(BookingSourceContext);
  const isSohoMode = Boolean(appTheme === "soho");

  return bookingSource.showNavbar ? (
    <AppBar className={classes.logoAppBar}>
      <Toolbar className={classes.logoToolbar}>
        <Link className={classes.logoContainer} to="/">
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
            {isSohoMode && (
              <>
                <Grid item style={{ display: "flex" }}>
                  <img
                    className={classNames(
                      classes.navbarMultiLogo,
                      props.centerLogo ? classes.navbarLogoCentered : null
                    )}
                    src={sohoHouseLogo}
                    alt="Soho Logo"
                  />
                </Grid>
                <Grid item style={{ color: "black" }}>
                  <AddIcon className={classes.plusIcon} />
                </Grid>
              </>
            )}
            <Grid item style={{ display: "flex" }}>
              <img
                className={classNames(
                  isSohoMode ? classes.navbarMultiLogo : classes.navbarLogo,
                  props.centerLogo ? classes.navbarLogoCentered : null
                )}
                src={isSohoMode ? molliesLogoText : molliesLogoRed}
                alt="Mollies Logo"
              />
            </Grid>
          </Grid>
        </Link>
        {!isSohoMode && (
          <div
            className={classNames(
              classes.navActionsContainer,
              mobileAppUser ? classes.hidden : null
            )}>
            {props.withProfile ? (
              <div className={classes.navActionContainer}>
                <Button color="inherit" onClick={handleMenuOpen} className={classes.menuButton}>
                  <Grid container alignItems="center" justifyContent="center">
                    {authUser ? (
                      <div className={classes.avatar}>
                        <Typography variant="caption" className={classes.initials}>
                          {initialsForName(authUser.displayName)}
                        </Typography>
                      </div>
                    ) : tabletUpScreen ? (
                      <Typography variant="button" className={classes.menuText}>
                        Menu
                      </Typography>
                    ) : null}
                    <MenuIcon />
                  </Grid>
                </Button>
                <Popover
                  open={menuOpen}
                  anchorEl={menuAnchorEl}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}>
                  {authUser ? (
                    <MenuList className={classes.menu}>
                      <MenuItem component={Link} to={Routes.ACCOUNT}>
                        My account
                      </MenuItem>
                      <Divider light className={classes.menuDivider} />
                      <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                    </MenuList>
                  ) : (
                    <MenuList className={classes.menu}>
                      <MenuItem onClick={toggleSignInModal}>Log in</MenuItem>
                      <MenuItem onClick={toggleSignUpModal}>Sign up</MenuItem>
                      <Divider light className={classes.menuDivider} />
                      <MenuItem onClick={toggleFindBookingModal}>Manage booking</MenuItem>
                    </MenuList>
                  )}
                </Popover>
                <FindBookingModal
                  open={findBookingModalOpen}
                  setOpen={setFindBookingModalOpen}
                  onNotifOpen={props.onNotifOpen}
                />
                <Dialog
                  open={signInModalOpen || signUpModalOpen}
                  onClose={() => {
                    setSignInModalOpen(false);
                    setSignUpModalOpen(false);
                  }}
                  PaperProps={{ elevation: 8, className: classes.dialogPaper }}>
                  <CloseIcon
                    className={classes.closeButton}
                    onClick={() => {
                      setSignInModalOpen(false);
                      setSignUpModalOpen(false);
                    }}
                  />
                  {signInModalOpen ? (
                    <AuthCTA
                      logIn={true}
                      onNotifOpen={props.onNotifOpen}
                      splitLinks={true}
                      authCompleted={() => {
                        setSignInModalOpen(false);
                      }}
                    />
                  ) : signUpModalOpen ? (
                    <AuthCTA
                      signUp={true}
                      onNotifOpen={props.onNotifOpen}
                      splitLinks={true}
                      authCompleted={() => {
                        setSignUpModalOpen(false);
                      }}
                    />
                  ) : null}
                </Dialog>
              </div>
            ) : null}
          </div>
        )}
      </Toolbar>
    </AppBar>
  ) : null;
};

Navbar.propTypes = {
  centerLogo: PropTypes.bool,
  withProfile: PropTypes.bool,
  onNotifOpen: PropTypes.func,
};

export default Navbar;
