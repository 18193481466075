import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import moment from "moment";

import { groupBy, sortBy } from "lodash/fp";
import { Typography } from "@material-ui/core";

import DiningBookingSummaryRow from "./DiningBookingSummaryRow.js";

const DiningBookingSummary = (props) => {
  const [reservationsByDate, setReservationsByDate] = useState(null);

  // Takes reservations and groups them by day so we can display each day as a
  // row with multiple meal periods
  // Returns array of dates & reservations: [{date: "", reservations: []}]
  const transformReservations = (reservations) => {
    const groups = groupBy("date", reservations);

    const result = Object.keys(groups).map((key) => {
      return {
        date: key,
        reservations: sortBy((r) => moment(r.time, "H:mm A"), groups[key]),
      };
    });

    // Make sure the results are sorted
    const sortedResult = sortBy((r) => moment(r.date, "YYYY-MM-DD"), result);

    setReservationsByDate(sortedResult);
  };

  useEffect(() => {
    transformReservations(props.reservations);
  }, [props.reservations]);

  return (
    <>
      <Typography variant="h5" style={{ marginBottom: "16px" }}>
        {props.restaurantVenue?.name}
      </Typography>
      <div>
        {reservationsByDate?.map((group, index) => {
          return (
            <DiningBookingSummaryRow
              key={index}
              dayIndex={index}
              date={group.date}
              reservations={group.reservations}
            />
          );
        })}
      </div>
    </>
  );
};

DiningBookingSummary.propTypes = {
  restaurantVenue: PropTypes.object,
  reservations: PropTypes.array,
};

export default DiningBookingSummary;
