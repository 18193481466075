import moment from "moment";

export const addDefaults = (eventDetails) => {
  Object.entries(eventDetails).forEach(([formFieldKey, formFieldValues]) => {
    if (formFieldValues.value) return;

    switch (formFieldKey) {
      case "arrivalDate":
        eventDetails[formFieldKey].value = moment();
        break;
      case "arrivalTime":
        eventDetails[formFieldKey].value = "15:30";
        break;
    }
  });

  return eventDetails;
};
