import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, TextField, Typography, Box } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import firebase from "firebase/app";
import "firebase/auth";

import backIcon from "images/back_icon.svg";
import { setFirebaseToken, setProfile, signInUser } from "features/user/userSlice";
import api from "utils/api";
import { validatePresence, validateEmailFormat, validateMinCharacters } from "utils/validations";
import { performEmailSignIn, performEmailSignUp } from "utils/firebase/auth/email";
import { serializeFirebaseUser } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  authContent: {
    padding: "25px",
    alignItems: "center",
  },
  contentRow: {
    marginBottom: "30px",
    justifyContent: "center",
    position: "relative",
  },
  nameRow: {
    justifyContent: "space-between",
  },
  backButton: {
    position: "absolute",
    left: "2px",
    [theme.breakpoints.up("tablet")]: {
      left: "15px",
    },
  },
  closeButton: {
    position: "absolute",
    right: "1rem",
    top: "1rem",
    color: theme.palette.textSecondary,
  },
  passwordVisibility: {
    position: "absolute",
    right: "0",
  },
}));

const EmailAuth = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(props.shortSignUp?.email || "");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState(props.shortSignUp?.firstName || "");
  const [lastName, setLastName] = useState(props.shortSignUp?.lastName || "");
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setFirstName("");
    setLastName("");
    setShowPasswordReset(false);
  };

  const HeaderText = () => {
    if (props.signUp && !props.shortSignUp) {
      return "Sign up with email";
    } else if (props.signIn && showPasswordReset) {
      return "Reset password";
    } else if (props.shortSignUp) {
      return "Add a password to create an account";
    } else {
      return "Log in with email";
    }
  };

  const SubmitButton = () => {
    if (props.signUp) {
      return (
        <Button color="primary" variant="contained" onClick={handleSignUp}>
          {props.shortSignUp ? "Create account" : "Sign up"}
        </Button>
      );
    } else if (props.signIn && showPasswordReset) {
      return (
        <Button color="primary" variant="contained" onClick={handlePasswordReset}>
          Continue
        </Button>
      );
    } else {
      return (
        <Button color="primary" variant="contained" size="large" onClick={handleSignIn}>
          Log in
        </Button>
      );
    }
  };

  const signIntoFirebase = async () => {
    try {
      const { firebaseToken, authUser } = await performEmailSignIn(email, password);
      dispatch(signInUser(serializeFirebaseUser(authUser)));
      resetForm();

      return firebaseToken;
    } catch (e) {
      console.error(e);
      props.onNotifOpen(e.message, { variant: "error" });
    }
  };

  const handlePasswordResetToggle = () => {
    setShowPasswordReset(!showPasswordReset);
  };

  const goBack = () => {
    if (showPasswordReset) {
      setShowPasswordReset(false);
    } else {
      props.onBack();
    }
  };

  const handlePasswordReset = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((res) => {
        props.onNotifOpen("Please check your email and follow the link to reset your password", {
          variant: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        props.onNotifOpen(e.message, { variant: "error" });
      });
  };

  const fetchProfile = (firebaseToken) => {
    api
      .getProfile(firebaseToken)
      .then((res) => {
        dispatch(setProfile(res.data));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleSignIn = async () => {
    const firebaseToken = await signIntoFirebase();
    if (firebaseToken) {
      dispatch(setFirebaseToken(firebaseToken));
      fetchProfile(firebaseToken);
    }
  };

  const validateSignUpForm = () => {
    const emailErrors = validatePresence(email) || validateEmailFormat(email);
    setEmailError(emailErrors);

    const passwordErrors = validatePresence(password) || validateMinCharacters(password, 8);
    setPasswordError(passwordErrors);

    const firstNameErrors = validatePresence(firstName);
    setFirstNameError(firstNameErrors);

    const lastNameErrors = validatePresence(lastName);
    setLastNameError(lastNameErrors);

    return !(passwordErrors || emailErrors || firstNameErrors || lastNameErrors);
  };

  const handleSignUp = async () => {
    if (validateSignUpForm()) {
      try {
        await performEmailSignUp(email, password, firstName, lastName);

        resetForm();

        props.onNotifOpen(
          "Signed up successfully, please check your inbox for a verification email",
          { variant: "success" }
        );
        handleSignIn();
      } catch (e) {
        console.error(e);
        props.onNotifOpen(e.message, {
          variant: "error",
        });
      }
    }
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.currentTarget.value);
  };

  const handleSecondNameChange = (e) => {
    setLastName(e.currentTarget.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.currentTarget.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.currentTarget.value);
  };

  return (
    <div hidden={!props.open}>
      <Grid container direction="column" className={classes.authContent}>
        <Grid container className={classes.contentRow}>
          {props.onBack ? (
            <Button className={classes.backButton} onClick={goBack}>
              <img src={backIcon} alt="back button" />
            </Button>
          ) : null}
          <Typography variant="h6" color={props.shortSignUp ? "primary" : "textPrimary"}>
            {HeaderText()}
          </Typography>
        </Grid>
        {props.signUp && !props.shortSignUp ? (
          <Grid container className={`${classes.contentRow} ${classes.nameRow}`}>
            <Grid item xs={5}>
              <TextField
                fullWidth
                required
                name="given-name"
                autoComplete="given-name"
                label="First name"
                error={firstNameError}
                helperText={firstNameError}
                value={firstName}
                onChange={handleFirstNameChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                label="Last name"
                name="family-name"
                autoComplete="family-name"
                error={lastNameError}
                helperText={lastNameError}
                value={lastName}
                onChange={handleSecondNameChange}
              />
            </Grid>
          </Grid>
        ) : null}
        <Grid container className={classes.contentRow}>
          <TextField
            fullWidth
            required
            name="email"
            autoComplete="email"
            label="Email"
            error={emailError}
            helperText={emailError}
            value={email}
            disabled={!!props.shortSignUp}
            onChange={handleEmailChange}
          />
        </Grid>
        {props.signUp || (props.signIn && !showPasswordReset) ? (
          <Box width={1} className={classes.contentRow}>
            <TextField
              fullWidth
              required
              name={props.signUp ? "new-password" : "current-password"}
              autoComplete={props.signUp ? "new-password" : "current-password"}
              label="Password"
              error={passwordError}
              type={showPassword ? "text" : "password"}
              helperText={
                passwordError ? passwordError : props.signUp ? "At least 8 characters" : null
              }
              value={password}
              onChange={handlePasswordChange}
            />

            <IconButton
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              className={classes.passwordVisibility}>
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>

            {props.signIn && !showPasswordReset ? (
              <Box mt={1}>
                <Typography color="primary" variant="caption" onClick={handlePasswordResetToggle}>
                  Forgot password?
                </Typography>
              </Box>
            ) : null}
          </Box>
        ) : null}
        <Grid item className={classes.contentRow}>
          {SubmitButton()}
        </Grid>
      </Grid>
    </div>
  );
};

EmailAuth.propTypes = {
  signIn: PropTypes.bool,
  signUp: PropTypes.bool,
  open: PropTypes.bool,
  onBack: PropTypes.func,
  shortSignUp: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  onNotifOpen: PropTypes.func,
};

export default EmailAuth;
