import { groupBy, get, tail } from "lodash/fp";
import { calculateBookingTotal } from "./bookingTotals";

Date.prototype.addDays = function (days) {
  var dat = new Date(this.valueOf());
  dat.setDate(dat.getDate() + days);
  return dat;
};

function calculateReservationTotal(reservation) {
  // Sum of the room total, and all service total
  const roomTotal = reservation.totalGrossAmount.amount;
  const servicesTotal = reservation.services.reduce(
    (accumulator, service) => accumulator + service.totalAmount.grossAmount,
    0
  );

  return roomTotal + servicesTotal;
}

const generateBookingData = (bookingData) => {
  const paymentResponse = bookingData.paymentResponse;

  let booking = {
    paymentAccount: {},
    booker: null,
    reservations: [],
    giftCard: bookingData.giftCard,
    total: {
      amount: calculateBookingTotal(bookingData.bookingReservations),
      currency: "GBP",
    },
    propertyId: bookingData.propertyId,
    transactionReference: paymentResponse ? paymentResponse.pspReference : null,
  };

  if (paymentResponse) {
    booking["paymentAccount"] = {
      accountNumber: paymentResponse.additionalData.cardSummary,
      accountHolder: paymentResponse.additionalData.cardHolderName,
      expiryMonth: paymentResponse.additionalData.expiryDate.split("/")[0],
      expiryYear: paymentResponse.additionalData.expiryDate.split("/")[1],
      paymentMethod: paymentResponse.additionalData.paymentMethod,
      payerReference: paymentResponse.shopperReference,
      isVirtual: false,
    };
  }

  booking["booker"] = {
    firstName: bookingData.bookerDetails.firstName,
    middleInitial: bookingData.bookerDetails.middleInitial,
    lastName: bookingData.bookerDetails.lastName,
    email: bookingData.bookerDetails.email,
    phone: bookingData.bookerDetails.phoneNumber,
  };

  booking["subscribeOffers"] = bookingData.bookerDetails.subscribeOffers;

  let remainingGiftCardBalance_100 = bookingData.giftCard ? bookingData.giftCard.balance : 0;

  bookingData.bookingReservations.forEach((reservation, idx) => {
    // Calculate the prepayment amount, using gift card balance where possible
    // Word in pennies to avoid floating point rounding errors
    const reservationTotal_100 = calculateReservationTotal(reservation) * 100;

    const giftCardToRedeem_100 = Math.min(reservationTotal_100, remainingGiftCardBalance_100);

    const prepaymentTotal_100 = Math.max(reservationTotal_100 - giftCardToRedeem_100, 0);

    remainingGiftCardBalance_100 -= giftCardToRedeem_100;

    const addAdditionalGuests = (bookingResIndex, additionalGuests = {}) => {
      let additionalGuestArr = [];

      // eslint-disable-next-line no-unused-vars
      for (const [key, guest] of Object.entries(additionalGuests)) {
        if (guest.reservationIdx === bookingResIndex) {
          const guestObj = {
            firstName: guest.firstName.value,
            lastName: guest.lastName.value,
          };

          // email is optional - only add to object if email present
          if (guest?.email?.value) {
            guestObj.email = guest.email.value;
          }
          additionalGuestArr.push(guestObj);
        }
      }

      return additionalGuestArr;
    };

    let reservationObj = {
      arrival: reservation.arrival,
      departure: reservation.departure,
      channelCode: "Ibe", // HARD CODED
      guaranteeType: "Prepayment", // HARD CODED
      ratePlanCode: reservation.ratePlan.code,
      timeSlices: reservation.timeSlices.map(() => ({
        ratePlanId: reservation.ratePlan.id,
      })),
      services: flattenServices(reservation.services),
      prepaymentAmount: {
        amount: prepaymentTotal_100 / 100.0, // convert back to £
        currency: reservation.totalGrossAmount.currency,
      },
      adults: reservation.adults,
      childrenAges: reservation.childrenAges,
      companyId: reservation.companyId,
      corporateCode: reservation.corporateCode,
      promoCode: reservation.promoCode,
      primaryGuest: get([0])(addAdditionalGuests(idx, bookingData.additionalGuests)),
      // Remove the first guest from the array of additional guests as this is the Primary Guest for each room.
      additionalGuests: tail(addAdditionalGuests(idx, bookingData.additionalGuests)),
    };

    if (paymentResponse) {
      reservationObj.externalCode = `${paymentResponse.merchantReference}-${idx + 1}`; // Set to merchant ref for reconcilliation purposes
    }

    if (reservation.unitGroup.code !== "PARKING") {
      // Add travelPurpose
      let purposeOfTrip = bookingData.travelPurpose;
      // Add room reservation details
      let comment = bookingData.bookerDetails.comments;

      if (bookingData.bookerDetails.membershipNumber) {
        // Append membership number to the comments if used
        comment = `Membership Number: ${bookingData.bookerDetails.membershipNumber}\n\n${comment}`;
      }

      reservationObj.guestComment = comment;
      reservationObj.travelPurpose = purposeOfTrip;
    }

    booking["reservations"].push(reservationObj);
  });
  return booking;
};

// For date-based addons, we track one service per day for the basket
// Apaleo needs services to be unique, so we need to combine them before sending
const flattenServices = (services) => {
  const groups = groupBy("serviceId", services);

  return Object.keys(groups).map((serviceId) => {
    const options = groups[serviceId];

    let s = {
      serviceId,
    };

    // If there is a date on the service, we need to group the dates
    // Otherwise the count applies to the whole service
    if (options[0].date) {
      s.dates = options.reduce((acc, curr) => {
        const thisDates = curr.dates.map((d) => {
          return { serviceDate: d.serviceDate, count: curr.count };
        });

        return acc.concat(thisDates);
      }, []);
    } else {
      s.count = options[0].count;
    }

    return s;
  });
};

export default generateBookingData;
