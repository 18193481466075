import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { Box, Paper } from "@material-ui/core";
import AuthCTA from "components/AuthCTA";
import Navbar from "components/Navbar";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  mainContent: {
    margin: "15vh auto",
    padding: "0px 20px",
    [theme.breakpoints.up("tablet")]: {
      width: "50%",
    },
  },
}));

const AuthPage = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Navbar withProfile onNotifOpen={props.onNotifOpen} />
      <Box className={classes.mainContent}>
        <Paper>
          <AuthCTA
            onNotifOpen={props.onNotifOpen}
            logIn={props.login}
            signUp={props.signUp}
            splitLinks={true}
            authCompleted={props.authCompleted}
          />
        </Paper>
      </Box>
    </div>
  );
};

AuthPage.propTypes = {
  login: PropTypes.bool,
  signUp: PropTypes.bool,
  onNotifOpen: PropTypes.func,
  authCompleted: PropTypes.func,
};

export default AuthPage;
