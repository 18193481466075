export default function createGuestDetailsForm(reservation, captureAdditionalGuestDetails) {
  const createGuestDetailsObj = (guestIndex) => ({
    firstName: { label: "First Name", value: "" },
    lastName: { label: "Last Name", value: "" },
    email: { label: guestIndex === 0 ? "Email" : "Email (Optional)", value: "" },
  });

  let totalNumAdults = 0;

  return reservation.map((reservationDetails, reservationIdx) => {
    const { adults } = reservationDetails;
    const guestDetails = [];

    const guestsToCaptureDetailsFor = captureAdditionalGuestDetails ? adults : 1;

    for (let i = 1; i <= guestsToCaptureDetailsFor; i++) {
      // Special logic for the first guest
      const guestIndex = i - 1;

      guestDetails.push({
        guestNum: i + totalNumAdults,
        ...createGuestDetailsObj(guestIndex),
        guestIdx: guestIndex,
        reservationIdx,
      });
    }
    totalNumAdults += guestsToCaptureDetailsFor;

    return {
      roomNum: reservationIdx + 1,
      guestDetails,
      reservationIdx,
    };
  });
}
