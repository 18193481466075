import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  makeStyles,
  useTheme,
  useMediaQuery,
  Typography,
  Modal,
} from "@material-ui/core";
import { CollectionsOutlined } from "@material-ui/icons";
import ImageSlider from "components/ImageSlider";

const useStyles = makeStyles((theme) => ({
  galleryContainer: {
    width: "100%",
    height: "100%",
    maxHeight: "600px",
    minHeight: "240px",
    display: "grid",
    position: "relative",
    gap: theme.spacing(1),
    gridTemplateColumns: "2fr 1fr",
    gridAutoRows: "1fr",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      gridTemplateColumns: "1fr 240px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.desktop)]: {
      gridTemplateColumns: "1fr 400px",
    },
  },
  buttonContainer: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),

    backgroundColor: theme.palette.other.backdrop,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      padding: "8px 16px",
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },
  },

  icon: {
    color: "#FFFFFF",
    marginRight: theme.spacing(1),
  },
}));

const MediaContent = ({ media, ...props }) => {
  if (media?.video) {
    return (
      <video {...props} alt="Gallery Video" autoPlay loop muted>
        <source src={media.video.url} />
        Your browser does not support the video tag.
      </video>
    );
  }

  return <img {...props} alt="Gallery Image" src={media?.image?.url} />;
};

const PhotoGallery = ({ medias = [], className, ...props }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));

  const sliderRef = useRef(null);

  const isMoreThanTwo = medias.length > 2;

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const filterOnlyImages = (media) =>
    media.filter((media) => Boolean(media?.image)).map(({ image }) => image);

  return (
    <>
      <Grid className={classes.galleryContainer}>
        <MediaContent
          media={medias[0]}
          style={{
            width: "100%",
            height: "100%",
            gridColumn: isMoreThanTwo ? "1" : "1 / span 2",
            gridRow: "1 / span 2",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
        {isMoreThanTwo ? (
          <>
            <MediaContent
              media={medias[1]}
              alt="Gallery image"
              style={{ gridColumn: 2, objectFit: "cover", height: "100%", width: "100%" }}
            />
            <MediaContent
              media={medias[2]}
              alt="Gallery image"
              style={{
                gridColumn: 2,
                gridRow: 2,
                objectFit: "cover",
                height: "100%",
                width: "100%",
              }}
            />
          </>
        ) : null}
        <Button className={classes.buttonContainer} onClick={openModal}>
          {tabletUpScreen && <CollectionsOutlined className={classes.icon} />}
          <Typography variant={tabletUpScreen ? "body1" : "body2"} style={{ color: "#FFFFFF" }}>
            +{medias.length}
          </Typography>
        </Button>
      </Grid>
      <Modal open={open} onClose={closeModal}>
        <ImageSlider onClose={closeModal} ref={sliderRef} mediaList={filterOnlyImages(medias)} />
      </Modal>
    </>
  );
};

PhotoGallery.propTypes = {
  medias: PropTypes.array,
  className: PropTypes.string,
};

MediaContent.propTypes = {
  media: PropTypes.object,
};

export default PhotoGallery;
