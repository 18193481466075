import { toSafeInteger } from "lodash/fp";
import moment from "moment";

export const handleFormValuesToSubmit = ({
  eventDetails,
  bookingContactDetails,
  selectedRoom,
  selectedTimeSlot,
  additionalRequirements,
}) => {
  return {
    first_name: bookingContactDetails.firstName.value,
    last_name: bookingContactDetails.lastName.value,
    email: bookingContactDetails.email.value,
    phone: bookingContactDetails.phoneNumber.value,
    duration_minutes: toSafeInteger(selectedTimeSlot.duration_minutes),
    party_size: toSafeInteger(eventDetails.numberOfPeople.value),
    date: eventDetails.arrivalDate.value.format("YYYY-MM-DD"),
    cost: selectedTimeSlot.cost,
    start_time: moment.utc(selectedTimeSlot.start_time).format("HH:mm"),
    end_time: moment.utc(selectedTimeSlot.end_time).format("HH:mm"),
    shift_persistent_id: selectedTimeSlot.shift_persistent_id,
    access_persistent_id: selectedTimeSlot.access_persistent_id,
    service_cms_id: selectedRoom.id,
    subscribe_offers: bookingContactDetails.subscribeOffers.value,
    name_of_event: eventDetails.nameOfEvent.value,
    event_type: eventDetails.eventType.value,
    company_name: eventDetails.companyName.value,
    special_requests: additionalRequirements.specialRequests.value,
  };
};

export default handleFormValuesToSubmit;
