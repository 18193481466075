import React from "react";
import PropTypes from "prop-types";

import { Grid, Tooltip, Typography, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

import DayHeader from "components/SelectDiningTimes/sections/DayHeader.js";
import MealPeriodLabel from "components/SelectDiningTimes/sections/MealPeriodLabel";

const useStyles = makeStyles((theme) => ({
  dayHeader: {
    marginBottom: theme.spacing(1),
  },
  successLabel: {
    color: theme.palette.success.dark,
  },
  errorLabel: {
    color: theme.palette.error.main,
  },
  failedContainer: {
    display: "flex",
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  },
}));

const DiningBookingSummaryRow = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet));

  const renderPeriod = (mealPeriod, reservation) => {
    return (
      <Grid container direction="row" alignItems="flex-start" justifyContent="space-between">
        <Grid item>
          <MealPeriodLabel mealPeriod={mealPeriod} secondaryText={reservation.time} />
        </Grid>
        <Grid item>
          {reservation.success ? (
            <Typography variant="button" className={classes.successLabel}>
              Booked!
            </Typography>
          ) : (
            <div className={classes.failedContainer}>
              <Typography variant="button" className={classes.errorLabel}>
                Error
              </Typography>
              <Tooltip
                title={
                  <Typography variant="caption">
                    Unfortunately, we couldn&apos;t complete your reservation.
                    <br />
                    {reservation.message}
                    <br />
                    Please contact the property to make a booking.
                  </Typography>
                }>
                <InfoOutlined className={classes.infoIcon} />
              </Tooltip>
            </div>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container className={classes.container} direction={tabletUpScreen ? "row" : "column"}>
      <Grid item xs={tabletUpScreen ? 4 : null}>
        <DayHeader
          className={classes.dayHeader}
          dayIndex={props.dayIndex + 1}
          dateString={props.date}
        />
      </Grid>

      <Grid item xs={tabletUpScreen ? 8 : null}>
        {props.reservations?.map((reservation) =>
          renderPeriod(reservation.meal_period, reservation)
        )}
      </Grid>
    </Grid>
  );
};

DiningBookingSummaryRow.propTypes = {
  dayIndex: PropTypes.number,
  date: PropTypes.string,
  reservations: PropTypes.object,
};

export default DiningBookingSummaryRow;
