import React from "react";
import PropTypes from "prop-types";

import { Grid, Typography, makeStyles } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const useStyles = makeStyles((theme) => ({
  periodLabel: {
    textTransform: "capitalize",
  },
  checkmark: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1),
  },
}));

const MealPeriodLabel = (props) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" wrap="nowrap">
      {props.selected && <CheckCircleOutlineIcon className={classes.checkmark} />}
      <Typography variant="body2" className={classes.periodLabel}>
        {props.mealPeriod}{" "}
        {props.secondaryText && (
          <Typography variant="body1" component="span" color="textSecondary">
            &nbsp;{props.secondaryText}
          </Typography>
        )}
        {props.children}
      </Typography>
    </Grid>
  );
};

MealPeriodLabel.propTypes = {
  mealPeriod: PropTypes.oneOf(["breakfast", "dinner"]),
  selected: PropTypes.bool,
  secondaryText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default MealPeriodLabel;
