import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormControl, MenuItem, TextField, makeStyles } from "@material-ui/core";
import ResponsiveSingleDayPicker from "components/ReponsiveSingleDayPicker/ResponsiveSingleDayPicker";
import moment from "moment";

const useStyle = makeStyles((theme) => {
  return {
    inputField: {
      "& .MuiOutlinedInput-root": {
        borderRadius: 20000,
        backgroundColor: theme.palette.background.default,
      },
    },
  };
});

const MultiTypeInput = (props) => {
  const classes = useStyle();
  const [focus, setFocus] = useState(null);

  if (props.type == "date") {
    return (
      <div onClick={(e) => (focus ? setFocus(null) : setFocus(e.currentTarget))}>
        <FormControl fullWidth>
          <TextField
            id={`textField-${props.name}`}
            className={classes.inputField}
            fullWidth
            size="small"
            label={props.label}
            required={props.isRequired}
            variant="outlined"
            // Only standard formats accepted such as "yyyy-MM-DD"
            // if we want to display format "dddd, D MMM" this needs to
            // be a textField.
            type="textField"
            select={props.type === "dropDown"}
            inputProps={{
              readOnly: true,
              style: { cursor: "pointer" },
              ...props.inputProps,
            }}
            value={props.value.format("ddd, D MMM")}
            InputLabelProps={props.inputLabelProps}
            error={props.error != null}
            helperText={props.error ? props.error : ""}
            onChange={props.onChange}></TextField>
          <ResponsiveSingleDayPicker
            date={props.value}
            onDateChange={(date) => {
              props.onChange({ target: { value: date } });
            }}
            focus={focus}
            setFocus={setFocus}
            minDate={moment()}
          />
        </FormControl>
      </div>
    );
  }

  return (
    <TextField
      id={`textField-${props.name}`}
      className={classes.inputField}
      fullWidth
      size="small"
      label={props.label}
      required={props.isRequired}
      variant="outlined"
      type={props.type}
      select={props.type === "dropDown"}
      inputProps={props.inputProps}
      value={props.value}
      InputLabelProps={props.inputLabelProps}
      error={props.error != null}
      helperText={props.error ? props.error : ""}
      onChange={props.onChange}>
      {props.type === "dropDown" &&
        props?.inputProps?.options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  );
};

MultiTypeInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  type: PropTypes.oneOf(["number", "password", "date", "dropDown", "time", "textField"]),
  inputProps: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
  }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),
  inputLabelProps: PropTypes.object,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default MultiTypeInput;
