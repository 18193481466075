import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";

import withStyles from "@material-ui/core/styles/withStyles";
import api from "utils/api";
import {
  getPaymentMethods,
  initiatePayment,
  setPaymentError,
  updatePaymentDetails,
} from "../../features/payment/paymentSlice";
import { calculateTotalToPay } from "utils/bookingTotals";
import { trackError } from "utils/analytics";

import paymentWidgetStyle from "./paymentWidgetStyle.jsx";

class PaymentWidget extends React.Component {
  constructor(props) {
    super(props);

    this.paymentContainer = React.createRef();
    this.paymentComponent = null;

    this.onPayButtonClicked = this.onPayButtonClicked.bind(this);
    this.onApplePayValidateMerchant = this.onApplePayValidateMerchant.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onError = this.onError.bind(this);
    this.onAdditionalDetails = this.onAdditionalDetails.bind(this);
    this.processPaymentResponse = this.processPaymentResponse.bind(this);
    this.showPaymentError = this.showPaymentError.bind(this);
  }

  componentDidMount() {
    this.addGooglePayScript();

    this.props.getPaymentMethods(this.props.selectedPropertyId, this.props.totalToPay);
  }

  async componentDidUpdate(prevProps) {
    const {
      paymentMethodsRes: paymentMethodsResponse,
      config,
      paymentRes,
      error,
    } = this.props.payment;

    // Show any payment errors that might have occurred
    if (error) {
      this.showPaymentError(error);
      return;
    }

    // Re-fetch payment methods if the booking or gift card changes
    if (
      this.props.roomReservations !== prevProps.roomReservations ||
      this.props.parkingReservations !== prevProps.parkingReservations ||
      this.props.giftCard !== prevProps.giftCard
    ) {
      this.props.getPaymentMethods(this.props.selectedPropertyId, this.props.totalToPay);
      return;
    }

    // Create or update the checkout if needed
    if (
      paymentMethodsResponse &&
      config &&
      (paymentMethodsResponse !== prevProps.payment.paymentMethodsRes ||
        config !== prevProps.payment.config)
    ) {
      const checkoutParams = {
        ...config,
        paymentMethodsResponse,
        onSubmit: this.onSubmit,
        onError: this.onError,
        onAdditionalDetails: this.onAdditionalDetails,
        paymentMethodsConfiguration: {
          ...config.paymentMethodsConfiguration,
          paywithgoogle: {
            ...config.paymentMethodsConfiguration.paywithgoogle,
            configuration: {
              ...config.paymentMethodsConfiguration.paywithgoogle.configuration,
              gatewayMerchantId: this.props.merchantAccountId,
            },
            onClick: this.onPayButtonClicked,
          },
          applepay: {
            ...config.paymentMethodsConfiguration.applepay,
            onClick: this.onPayButtonClicked,
            onValidateMerchant: this.onApplePayValidateMerchant,
          },
        },
      };

      if (this.checkout == null) {
        this.checkout = await AdyenCheckout(checkoutParams);
        this.checkout.create("dropin").mount(this.paymentContainer.current);
      } else {
        this.checkout.update(checkoutParams);
      }
    }

    // Process the payment response if received
    if (paymentRes && paymentRes !== prevProps.payment.paymentRes) {
      this.processPaymentResponse(paymentRes);
    }
  }

  processPaymentResponse(paymentRes) {
    if (paymentRes.action) {
      // We need to capture some more details, so remove the booking in progress dialog
      this.props.setLoading(false);

      this.paymentComponent.handleAction(paymentRes.action);
    } else {
      switch (paymentRes.resultCode) {
        case "Authorised":
          this.paymentComponent.setStatus("success", {
            message: "Your payment has been authorised.\nFinalizing booking...",
          });

          this.props.makeBooking(paymentRes);
          break;
        case "Pending":
        case "Received":
          this.paymentComponent.setStatus("success", {
            message: "Your payment is pending.\nFinalizing booking...",
          });

          this.props.makeBooking(paymentRes);
          break;
        case "Refused":
          this.showPaymentError("Your payment method was not accepted.");
          break;
        default:
          this.showPaymentError(null);
          break;
      }
    }
  }

  showPaymentError(message) {
    this.props.onNotifOpen(message || "There was an error processing your payment.", {
      variant: "error",
      autoHideDuration: null,
    });

    // Clear the error
    this.props.setPaymentError(null);

    if (this.paymentComponent) {
      this.paymentComponent.setStatus("ready");
    }

    this.props.setLoading(false);
  }

  onPayButtonClicked(resolve, reject) {
    if (!this.props.validateCheckoutForm()) {
      console.log("Checkout form validation failed");
      reject("Checkout form validation failed");
      return;
    }

    resolve();
  }

  async onApplePayValidateMerchant(resolve, reject, validationURL) {
    try {
      const response = await api.getApplePaySession();
      resolve(response.data);
    } catch (error) {
      console.log(`Failed to get apple pay session: ${error}`);
      trackError(error, true);
      reject(error);
    }
  }

  onSubmit(state, component) {
    if (!this.props.validateCheckoutForm()) {
      console.log(`Checkout form validation failed`);
      return;
    }

    if (state.isValid) {
      // Generate a return url - this is used by adyen if we need to redirect
      // to a payment provider for 3DS. We'll jsonify it and base64 encode
      const guestDataString = encodeURIComponent(btoa(JSON.stringify(this.props.guestData)));
      const returnUrl = window.location.origin + `/finalize-booking?guestData=${guestDataString}`;

      this.props.setLoading(true);
      this.props.initiatePayment({
        propertyId: this.props.selectedPropertyId,
        arrivalDate: this.props.roomReservations[0].arrival,
        paymentMethod: state.data.paymentMethod,
        returnUrl: returnUrl,
        grossAmount: this.props.totalToPay,
        browserInfo: state.data.browserInfo,
        origin: window.location.origin.toString(),
        shopperEmail: this.props.emailAddress,
        billingAddress: state.data.billingAddress,
      });
      this.paymentComponent = component;
    }
  }

  onError(error, component) {
    console.log(`The payment widget encountered the following error: ${error}`);

    this.showPaymentError("The was an issue processing your payment. Please try again");
  }

  async onAdditionalDetails(state, component) {
    this.props.setLoading(true);

    try {
      const response = await this.props.updatePaymentDetails(state.data);
      this.processPaymentResponse(response.data);
    } catch (error) {
      console.error(`Failed to update payment details. ${error}`);
      this.showPaymentError("We were unable to complete your payment.");
    }
  }

  addGooglePayScript() {
    const googlePayJs = document.createElement("script");
    googlePayJs.src = "https://pay.google.com/gp/p/js/pay.js";
    document.body.appendChild(googlePayJs);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="payment-container">
        <div ref={this.paymentContainer} className={classes.payment}></div>
      </div>
    );
  }
}

PaymentWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  makeBooking: PropTypes.func,
  setLoading: PropTypes.func,
  validateCheckoutForm: PropTypes.func,
  onNotifOpen: PropTypes.func,
  emailAddress: PropTypes.string,
  guestData: PropTypes.object,
  // Mapped props
  totalToPay: PropTypes.number,
  selectedPropertyId: PropTypes.string,
  merchantAccountId: PropTypes.string,
  roomReservations: PropTypes.arrayOf(PropTypes.object),
  parkingReservations: PropTypes.arrayOf(PropTypes.object),
  giftCard: PropTypes.object,
  payment: PropTypes.object,
  getPaymentMethods: PropTypes.func,
  initiatePayment: PropTypes.func,
  setPaymentError: PropTypes.func,
  updatePaymentDetails: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    totalToPay: calculateTotalToPay(
      [...state.booking.roomReservations, ...state.booking.parkingReservations],
      state.booking.giftCard
    ),
    selectedPropertyId: state.property.selectedPropertyId,
    merchantAccountId: state.property.properties.find(
      (p) => p.code === state.property.selectedPropertyId
    )?.booking_flow_settings?.adyen_merchant_id,
    roomReservations: state.booking.roomReservations,
    parkingReservations: state.booking.parkingReservations,
    giftCard: state.booking.giftCard,
    payment: state.payment,
  };
};

const mapDispatchToProps = {
  getPaymentMethods,
  initiatePayment,
  setPaymentError,
  updatePaymentDetails,
};

const ConnectedPaymentWidget = connect(mapStateToProps, mapDispatchToProps)(PaymentWidget);
export default withStyles(paymentWidgetStyle)(ConnectedPaymentWidget);
