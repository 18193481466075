import React from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Button,
  Grid,
  Modal,
  Slide,
  makeStyles,
  Toolbar,
  Typography,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import molliesLogoRed from "images/Mollies_Ico_Red.svg";
import sohoHouseLogo from "images/soho_house_logo.svg";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    height: "100%",
    maxWidth: "90%",
    overflowY: "scroll",
    overflowX: "hidden",
    position: "absolute",
    right: 0,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexWrap: "nowrap",
    [theme.breakpoints.up("tablet")]: {
      maxWidth: "720px",
    },
  },
  appBar: {
    position: "sticky",
    top: "0",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: "none",
  },
  closeButton: {
    color: theme.palette.text.red,
    "&:hover": { backgroundColor: "inherit" },
  },
  navbarLogo: {
    width: "150px",
    [theme.breakpoints.up("tablet")]: {
      width: "180px",
    },
  },
  toolbar: {
    justifyContent: "space-between",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    minHeight: "inherit",
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: theme.spacing(5),
    },
  },
}));

const SideBarModal = (props) => {
  const classes = useStyles();
  const appTheme = useSelector((state) => state.booking.appTheme);
  const isSoho = appTheme && appTheme === "soho";
  const modalTitle = props.modalTitle || "";

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Slide in={props.open} direction="left" timeout={300}>
        <Grid container className={classes.modalContainer}>
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              {modalTitle ? (
                <Typography variant="h6">{modalTitle}</Typography>
              ) : (
                <img
                  className={classes.navbarLogo}
                  src={isSoho ? sohoHouseLogo : molliesLogoRed}
                  alt="Brand Logo"
                />
              )}
              <Button className={classes.closeButton} onClick={props.onClose}>
                <CloseIcon />
              </Button>
            </Toolbar>
            <Divider />
          </AppBar>
          {props.children}
        </Grid>
      </Slide>
    </Modal>
  );
};

SideBarModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  modalTitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default SideBarModal;
