import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  diningSelections: {},
  diningBookingsResponse: [],
};

const diningSlice = createSlice({
  name: "dining",
  initialState,
  reducers: {
    addDiningSelections: (state, { payload }) => {
      state.diningSelections = payload;
    },
    addDiningBookingResponse: (state, { payload }) => {
      state.diningBookingsResponse = payload;
    },
    removeDiningSelections: (state) => {
      state.diningSelections = initialState.diningSelections;
    },
    resetDiningBookings: (state) => {
      state.diningBookingsResponse = [];
      state.diningSelections = {};
    },
  },
});

export const {
  addDiningSelections,
  addDiningBookingResponse,
  removeDiningSelections,
  resetDiningBookings,
} = diningSlice.actions;
export default diningSlice.reducer;
