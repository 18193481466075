import moment from "moment";

export const validatePresence = (value) => (value === "" ? `is required` : null);

export const validateEmailFormat = (value) => {
  // https://www.w3resource.com/javascript/form/email-validation.php
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  return emailRegex.test(value) ? null : "invalid email format";
};

export const validateMinCharacters = (value, quantity) =>
  value.length < quantity ? `should be at least ${quantity} characters long` : null;

// Returns whether the arrival and departure dates are valid
// Both must be in the future, and the departure date after arrival
export const validStayDates = (arrivalDate, departureDate) => {
  const today = moment();
  const arrivalMoment = moment(arrivalDate);
  const departureMoment = moment(departureDate);

  return (
    arrivalMoment.isSameOrAfter(today, "day") &&
    departureMoment.isSameOrAfter(today, "day") &&
    arrivalMoment.isBefore(departureMoment, "day")
  );
};
