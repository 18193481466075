import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "features/user/userSlice";
import roomReducer from "features/room/roomSlice";
import propertyReducer from "features/property/propertySlice";
import bookingReducer from "features/booking/bookingSlice";
import paymentReducer from "features/payment/paymentSlice";
import diningReducer from "features/dining/diningSlice";

// Store persistance - to enable user auth and resume from payment redirect
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["payment"], // dont cache these reducers
};

const store = configureStore({
  reducer: persistReducer(
    persistConfig,
    combineReducers({
      user: userReducer,
      room: roomReducer,
      property: propertyReducer,
      booking: bookingReducer,
      payment: paymentReducer,
      dining: diningReducer,
    })
  ),
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

const persistor = persistStore(store);

export { store, persistor };
