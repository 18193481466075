import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Grid,
  Typography,
  Button,
  Popper,
  Tooltip,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { formatCurrency } from "utils/helpers";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import WorkIcon from "@material-ui/icons/Work";
import PromoIcon from "@material-ui/icons/MonetizationOn";

const useStyles = makeStyles((theme) => ({
  packageRow: {
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexWrap: "nowrap",
  },
  packageRowVertical: {
    flexDirection: "column",
  },
  divider: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(3),
  },
  bookButton: {
    marginLeft: "10px",
  },
  bookButtonVerticalModeContainer: {
    alignSelf: "flex-end",
  },
  roomInfo: {
    background: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    padding: "1.25rem",
    maxWidth: "80vw",
  },
  roomInfoContainer: {
    zIndex: 100000,
  },
  infoIcon: {
    color: theme.palette.text.primary,
    fontSize: "1.3rem",
    marginLeft: "10px",
    cursor: "pointer",
  },
  unitName: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  discountIcon: {
    marginTop: "-2px",
    marginRight: "4px",
    color: theme.palette.success.darkText,
  },
  redText: {
    color: theme.palette.text.red,
    fontWeight: 700,
  },
}));

export const AvailableUnit = ({
  showDivider,
  availability,
  onReservationSelect,
  stayNights,
  verticalMode,
  sideBarModalMode,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));

  const [roomInfoAnchor, setRoomInfoAnchor] = React.useState(null);
  const open = Boolean(roomInfoAnchor);

  const handlePopperOpen = (event) => {
    setRoomInfoAnchor(event.target);
  };

  const handlePopperClose = () => {
    setRoomInfoAnchor(null);
  };

  return (
    <Grid
      container
      className={clsx(classes.packageRow, {
        [classes.packageRowVertical]: verticalMode,
        [classes.divider]: showDivider,
      })}
      style={sideBarModalMode ? { height: "100%" } : {}}>
      <Grid item>
        <Typography variant="subtitle1" color="textPrimary" className={classes.unitName}>
          {availability.isCorporate ? (
            availability.corporateCode ? (
              <Tooltip title={`Partner: ${availability.corporateCode}`}>
                <WorkIcon className={classes.discountIcon} fontSize="small" />
              </Tooltip>
            ) : (
              <Tooltip title="Promo">
                <PromoIcon className={classes.discountIcon} fontSize="small" />
              </Tooltip>
            )
          ) : null}
          {availability.ratePlan.name}
          <InfoIcon
            className={classes.infoIcon}
            onMouseEnter={handlePopperOpen}
            onMouseLeave={handlePopperClose}
          />
          <Popper
            id="room-info"
            open={open}
            anchorEl={roomInfoAnchor}
            className={classes.roomInfoContainer}>
            <Grid className={classes.roomInfo} item>
              <Typography variant="body1">{availability.ratePlan.description}</Typography>
              <Typography variant="body1">{availability.cancellationFee.description}</Typography>
            </Grid>
          </Popper>
        </Typography>
        <Typography variant="body1" className={classes.redText}>
          {formatCurrency(
            availability.totalGrossAmount.currency,
            availability.totalGrossAmount.amount
          )}
          <Typography variant="body2" color="textPrimary" component="span">
            {" "}
            for {stayNights} night{stayNights > 1 ? "s" : null}
          </Typography>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {availability.cancellationPolicy}
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx({
          [classes.bookButtonVerticalModeContainer]: verticalMode,
        })}>
        <Button
          className={classes.bookButton}
          variant="contained"
          color="primary"
          onClick={(e) => onReservationSelect(availability, e)}>
          {tabletUpScreen || verticalMode ? "Book room" : "Book"}
        </Button>
      </Grid>
    </Grid>
  );
};

AvailableUnit.propTypes = {
  showDivider: PropTypes.bool,
  availability: PropTypes.object,
  onReservationSelect: PropTypes.func,
  stayNights: PropTypes.number,
  verticalMode: PropTypes.bool,
  sideBarModalMode: PropTypes.bool,
};
