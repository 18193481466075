import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  makeStyles,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

import api from "utils/api";
import EnquiryConfirmation from "components/EnquiryConfirmation";
import handleFormValuesToSubmit from "./handleFormValuesToSubmit.js";
import SideBarModal from "components/SideBarModal";
import { handleEnquirySuccessFormData } from "./utils.js";
import getEventFormFields, {
  initialEnquiryContactDetails,
  getAdditionalRequirementsFields,
} from "./EnquiryEventFormFields.js";
import {
  validateRequiredInputs,
  validateContactDetails,
  applyErrorStateToFormFields,
  applyErrorStateToContactFormFields,
} from "utils/formHelpers.js";
import { addDefaults } from "./utils/addDefaults.js";

// Step Components

// Step 1 - Choose facilities (optional depending on whether facility is provided)
import ChooseFacilityStep from "./steps/Step 1/ChooseFacilityStep.js";

// Step 2 - Event details
import EventDetailsStep from "./steps/Step 2/EventDetailsStep.js";

// Step 3 - Your details
import ContactDetailsStep from "./steps/Step 3/ContactDetailsStep.js";

const useStyles = makeStyles((theme) => ({
  mainContentContainer: {
    width: "100%",
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  detailsCard: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  CTAContainer: {
    display: "flex",
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
  stepper: {
    background: "none",
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  stepperEditIcon: {
    color: theme.palette.primary.main,
    width: "22px",
  },
  stepHeadingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textButton: { paddingLeft: 0, "&:hover": { backgroundColor: "inherit" } },
  hidden: {
    display: "none",
  },
}));

const EnquirySideBarModal = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));
  const desktopUpScreen = useMediaQuery(theme.breakpoints.up("desktop"));

  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [enquiryConfirmation, setEnquiryConfirmation] = useState(null);
  const selectedProperty = props.property;

  // Initialize state with props. If the facility is not provided, the user will have to choose a facility first
  const [facility, setFacility] = useState(props.facility);
  const [facilityServices, setFacilityServices] = useState(props.facilityServices);
  const [selectedRoomId, setSelectedRoomId] = useState(props.selectedRoomId);

  const [eventDetails, setEventDetails] = useState({});
  const [enquiryContactDetails, setEnquiryContactDetails] = useState(initialEnquiryContactDetails);
  const [additionalRequirements, setAdditionalRequirements] = useState(
    getAdditionalRequirementsFields(facility?.type)
  );

  useEffect(() => {
    setFacility(props.facility);
    setFacilityServices(props.facilityServices);
    setSelectedRoomId(props.selectedRoomId);
  }, [props.facilityServices, props.facility, props.selectedRoomId]);

  useEffect(() => {
    // DEV NOTE: Needs to be in useEffect as modal may load before facilityServices
    setEventDetails(
      getEventFormFields({
        facilityType: facility?.type,
        facilityServices: facilityServices,
        selectedRoomId: selectedRoomId,
      })
    );

    // Below also in here to clear fields when new enquiry is started (dependent on enquiryConfirmation)
    setEnquiryContactDetails(initialEnquiryContactDetails);
    setAdditionalRequirements(getAdditionalRequirementsFields(facility?.type));
  }, [facilityServices, facility, selectedRoomId, enquiryConfirmation]);

  const handleAdditionalRequirementsChange = (key, value) => {
    setAdditionalRequirements((prev) => ({ ...prev, [key]: { ...prev[key], value } }));
  };

  const handleEnquiryContactDetailsChange = (key, value) => {
    setEnquiryContactDetails((prevState) => ({
      ...prevState,
      [key]: { ...prevState[key], value },
    }));
  };
  const handleEventDetailsChange = (key, value) => {
    setEventDetails((prevState) => ({ ...prevState, [key]: { ...prevState[key], value } }));
  };

  const makeBooking = async (data) => {
    const response = await api.makeEnquiry(data);
    return response.data;
  };

  const handleSubmit = ({
    eventDetails,
    enquiryContactDetails,
    additionalRequirements,
    selectedProperty,
  }) => {
    const data = handleFormValuesToSubmit({
      eventDetails,
      enquiryContactDetails,
      additionalRequirements,
      selectedFacilityType: facility?.type,
      selectedProperty,
    });
    setIsLoading(true);

    return makeBooking(data)
      .then((response) => {
        setIsLoading(false);
        return response;
      })
      .catch((error) => {
        const errorMessage = "Unknown error";
        const message = `Your enquiry could not be completed. ${errorMessage}`;
        setIsLoading(false);
        props.onNotifOpen(message, { variant: "error" });
        return Promise.reject(errorMessage);
      });
  };

  const enquirySteps = () => {
    let steps = [];

    if (props.chooseFacility) {
      steps.push({
        label: "Choose facility",
        labelContent: (index, activeStep) =>
          activeStep > index &&
          facility && (
            // Only show label content for this step if it's been completed
            <Typography variant="body2" color="textSecondary">
              {facility.name}
            </Typography>
          ),
        content: (
          <ChooseFacilityStep
            property={selectedProperty}
            handleNext={(facility, facilityServices) => {
              setFacility(facility);
              setFacilityServices(facilityServices);

              setActiveStep((prev) => prev + 1);
            }}
          />
        ),
      });
    }

    steps = steps.concat([
      {
        label: "Event Details",
        labelContent: null,
        content: (
          <EventDetailsStep
            eventDetails={addDefaults(eventDetails)}
            handleEventDetailsChange={handleEventDetailsChange}
            additionalRequirements={additionalRequirements}
            handleAdditionalRequirementsChange={handleAdditionalRequirementsChange}
            handleNext={() => {
              const isValid = validateRequiredInputs(eventDetails);
              if (!isValid) {
                // Update errors in eventDetails
                setEventDetails((prevState) => {
                  const updatedFormFields = applyErrorStateToFormFields(prevState);
                  return updatedFormFields;
                });

                return false;
              } else {
                setActiveStep((prev) => prev + 1);
              }
            }}
          />
        ),
      },
      {
        label: "Your Details",
        labelContent: null,
        content: (
          <ContactDetailsStep
            onNotifOpen={props.onNotifOpen}
            enquiryContactDetails={enquiryContactDetails}
            handleEnquiryContactDetailsChange={handleEnquiryContactDetailsChange}
            handleNext={() => {
              const isContactDetailsValid = validateContactDetails(enquiryContactDetails);

              if (!isContactDetailsValid) {
                setEnquiryContactDetails((prevState) => {
                  const updatedFormFields = applyErrorStateToContactFormFields(prevState);
                  return updatedFormFields;
                });
                return false;
              }

              handleSubmit({
                eventDetails,
                enquiryContactDetails,
                additionalRequirements,
                selectedProperty,
              })
                .then((data) => {
                  setEnquiryConfirmation(handleEnquirySuccessFormData(data));
                  setHasSubmitted(true);
                  setActiveStep((prev) => prev + 1);
                })
                .catch((errorMessage) => {
                  return false;
                });
            }}
          />
        ),
      },
    ]);

    return steps;
  };

  function getStep(index) {
    return enquirySteps()[index];
  }

  function handleShowEditIcon({ currentStep, activeStep }) {
    if (currentStep >= activeStep) {
      return false;
    } else {
      return true;
    }
  }

  const stepLabels = enquirySteps().map((step) => step.label);

  const stepTo = (step) => {
    if (step <= activeStep) {
      setActiveStep(step);
    }
  };

  const handleBackClick = () => {
    props.onClose();
    props.handleChooseRoomModalOpen && props.handleChooseRoomModalOpen();
  };

  const pageContents = (
    <>
      {!isLoading && !enquiryConfirmation && (
        <Box className={classes.mainContentContainer}>
          <Box>
            {props.hideBackButton ? null : (
              <Button
                className={classes.textButton}
                color="primary"
                size="small"
                variant="text"
                disableRipple
                onClick={() => handleBackClick()}>
                <Box className={classes.CTAContainer}>
                  <ArrowBack />
                  <Typography variant="subtitle1">Back</Typography>
                </Box>
              </Button>
            )}
            <Box className={classes.detailsCard}>
              <Stepper
                className={classNames(classes.stepper, isLoading ? classes.hidden : null)}
                activeStep={activeStep}
                orientation="vertical">
                {stepLabels.map((stepLabel, index) => {
                  const step = getStep(index);
                  return (
                    <Step
                      style={{
                        position: "relative",
                      }}
                      key={stepLabel}
                      active={activeStep === index}>
                      <StepLabel
                        onClick={() => {
                          stepTo(index);
                        }}>
                        <Box className={classes.stepHeadingContainer}>
                          <div>
                            <Typography variant={tabletUpScreen ? "h4" : "h5"}>
                              {stepLabel}
                            </Typography>
                            {step.labelContent && step.labelContent(index, activeStep)}
                          </div>
                          {handleShowEditIcon({ currentStep: index, activeStep }) && (
                            <Button>
                              <CreateOutlinedIcon
                                className={classes.stepperEditIcon}
                                color="primary"
                              />
                            </Button>
                          )}
                        </Box>
                      </StepLabel>
                      <StepContent
                        hidden={activeStep !== index && !desktopUpScreen}
                        style={{ paddingRight: tabletUpScreen ? "inherit" : 0 }}>
                        {step.content}
                      </StepContent>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          </Box>
        </Box>
      )}
      {(isLoading || hasSubmitted) && (
        <EnquiryConfirmation
          embedded={props.embedded}
          enquiryConfirmation={enquiryConfirmation}
          isLoading={isLoading}
          onClose={props.onClose}
          setHasSubmitted={setHasSubmitted}
          setEventDetails={setEventDetails}
          setEnquiryConfirmation={setEnquiryConfirmation}
          setActiveStep={setActiveStep}
          selectedProperty={selectedProperty}
          facilityName={facility?.name}
        />
      )}
    </>
  );

  return props.embedded ? (
    pageContents
  ) : (
    <SideBarModal modalTitle="Send enquiry" {...props}>
      {pageContents}
    </SideBarModal>
  );
};

EnquirySideBarModal.propTypes = {
  embedded: PropTypes.bool, // Embedded mode - i.e full page
  hideBackButton: PropTypes.bool, // Used for embedded mode
  property: PropTypes.object,
  chooseFacility: PropTypes.bool,
  facility: PropTypes.object,
  facilityServices: PropTypes.array,
  selectedRoomId: PropTypes.number,
  onClose: PropTypes.func,
  onNotifOpen: PropTypes.func,
  handleChooseRoomModalOpen: PropTypes.func,
};

export default EnquirySideBarModal;
